import ServerErrorPageConst from '../../../components/ErrorDialog/const';

/**
 * serverErrorNotifierAction {Function}
 * @description - function is passing action with error data to redux state and activate error notifier
 *
 * */
export default (error, next, action) => {
    let {response={}} = error;
    let {status='default_error', data=null, statusText=''} = response;
    next({
        type: ServerErrorPageConst.ERROR_PAGE_SERVER_ERROR,
        payload: {
            actionType: action.type,
            statusText: statusText,
            requestData: action.requestData,
            errorStatus: status,
            errorData: data,
            requestStatus: 'error',
            language: localStorage.getItem('language') ?  localStorage.getItem('language') : window.navigator && window.navigator.language.split('-').length ? window.navigator.language.split('-')[0] : 'en'
        }
    });

}


