import Const from './../const';

/**
 * requestStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestStart = () => {
    return {
        type: Const.MANAGEMENT_START_FETCH_REQUEST
    }
};

/**
 * fetchManagers {React Action}
 * @description - send request to the server to get orders data
 *
 * */
export function fetchManagers(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.MANAGEMENT_FETCH_REQUEST,
            ...requestData
        });
    }
}

/**
 * switchTab {React Action}
 * @description - switch between table views based on tab
 *
 * */
export function switchTab(isAllTableDataShouldBeRendered) {
    return dispatch => {
        dispatch({
            type: Const.MANAGEMENT_SWITCH_TAB,
            payload: isAllTableDataShouldBeRendered
        });
    }
}

/**
 * showPopup {React Action}
 * @description - show popup to add new user
 *
 * */
export function showPopup() {
    return dispatch => {
        dispatch({
            type: Const.MANAGEMENT_SHOW_POPUP
        });
    }
}

/**
 * hidePopup {React Action}
 * @description - hide popup to add new user
 *
 * */
export function hidePopup() {
    return dispatch => {
        dispatch({
            type: Const.MANAGEMENT_HIDE_POPUP
        });
    }
}

/**
 * changeStatus {Function}
 * @description - change status
 *
 * */
export function changeStatus(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.MANAGEMENT_CHANGE_STATUS,
            ...requestData
        });
    }
}

/**
 * requestResetPassword {Function}
 * @description - change status
 *
 * */
export function requestResetPassword(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.MANAGEMENT_RESET_PASSWORD,
            ...requestData
        });
    }
}

/**
 * changeNewAdminData {React Action}
 * @description - change new admin data (name, last name, email)
 *
 * */
export function changeNewAdminData(value, fieldName) {
    return dispatch => {
        dispatch({
            type: Const.MANAGEMENT_CHANGE_NEW_ADMIN_DATA,
            payload: {
                value,
                fieldName
            }
        });
    }
}

/**
 * setNewAdminDataError {React Action}
 * @description - set error to the new admin fields (name, last name, email)
 *
 * */
export function setNewAdminDataError(fieldName) {
    return dispatch => {
        dispatch({
            type: Const.MANAGEMENT_SET_ERROR,
            payload: {
                fieldName
            }
        });
    }
}


/**
 * addNewAdmin {Function}
 * @description - send request to create new admin
 *
 * */
export function addNewAdmin(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.MANAGEMENT_CREATE_NEW_ADMIN,
            ...requestData
        });
    }
}

/**
 * clearCurrentAdmin {Function}
 * @description - clear currentAdmin admin data from redux state
 *
 * */
export function clearCurrentAdmin() {
    return dispatch => {
        dispatch({
            type: Const.MANAGEMENT_CLEAR_CURRENT_ADMIN,
        });
    }
}

/**
 * setErrorToAddNewUserPopup {Function}
 * @description - set what's was wrong in popup for adding new user
 *
 * */
export function setErrorToAddNewUserPopup() {
    return dispatch => {
        dispatch({
            type: Const.MANAGEMENT_SET_ERRORS_TO_ADD_NEW_ADMIN_POPUP,
        });
    }
}
