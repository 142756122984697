import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router";
import Breadcrumbs from '@material-ui/lab/Breadcrumbs';
import {
    Home,
    ChevronRight,
    ShoppingCart,
    Replay,
    Group,
    CreditCard,
    Description,
    Settings,
    Security,
    Timeline
} from '@material-ui/icons';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import CustomTranslation from './../CustomTranslation/CustomTranslation';
import CustomAnimate from "./../../containers/CustomAnimate/CustomAnimate";

const styles = theme => {
    return {
        pageHeader: {
            background: theme.palette.brand.main.dark,
            color: theme.palette.primary.contrastText,
        },
        pageHeaderText: {
            color: theme.palette.primary.contrastText,
        },
        breadCrumbs: {
            color: theme.palette.brand.main[50]
        },
        chip: {
            height: 24,
            color: theme.palette.brand.main[50],
            backgroundColor: 'transparent',
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.brand.main[600]
            }
        },
        link: {
            display: 'flex',
        },
        icon: {
            marginRight: theme.spacing(1) / 2,
            width: 20,
            height: 20,
        },
        breadCrumbsSeparator: {}
    }
};


class PageHeader extends Component {
    /**
     * getIcon {Function}
     * @description - defines appropriate icon for main navigation item item
     * @ return {Material-ui Icon Component}
     *
     * */
    getIcon = (menuId) => {
        switch (menuId) {
            case 'orders':
                return <ShoppingCart/>;
            case 'return.orders':
                return <Replay/>;
            case 'customers':
                return <Group/>;
            case 'billing':
                return <CreditCard/>;
            case 'reports':
                return <Description/>;
            case 'settings':
                return <Settings/>;
            case 'security':
                return <Security/>;
            case 'dashboards':
                return <Timeline/>;
            default:
                return ''
        }
    };

    getPageTitle = (isForIcon = false) => {
        let {match} = this.props;
        let {path} = match;
        let prefix = isForIcon ? '' : 'sideMenu.';
        let pathBase = path.split('/')[1] ? path.split('/')[1].replace('/', '') : path.split('/')[0];

        return pathBase === '' ? `${prefix}orders` : pathBase === 'returns' ? `${prefix}${pathBase.replace('s', '')}.orders` : `${prefix}${pathBase}`;
    };

    render() {
        let {classes, match, isFoldedOpen} = this.props;

        return (
            <div
                className={isFoldedOpen ? `b-page-header b-page-header--padding_left ${classes.pageHeader}` : `b-page-header ${classes.pageHeader}`}>
                <div className="b-page-header__breadcrumbs">
                    <Breadcrumbs
                        arial-label="Breadcrumb"
                        className={`${classes.breadCrumbs}`}
                        separator={<ChevronRight fontSize={'small'} className={classes.breadCrumbsSeparator}/>}
                    >
                        <Link color="inherit" href="#/" className={classes.link}>
                            <Home className={classes.icon}/>
                            Main
                        </Link>
                        {
                            match.path !== '/'
                                ?
                                <Typography component='div' color="inherit">
                                    <CustomTranslation id={this.getPageTitle()}/>
                                </Typography>
                                :
                                ''
                        }
                    </Breadcrumbs>
                </div>
                <div className="b-page-header__additional">
                    <CustomAnimate animation="transition.bounceIn" delay={200}>
                        <div className="b-page-header__title">
                            <Typography component="h4" variant="h4" className={classes.pageHeaderText}>
                                {this.getIcon(this.getPageTitle(true))} <CustomTranslation
                                id={this.getPageTitle()}/>
                            </Typography>
                        </div>
                    </CustomAnimate>
                    <div className="b-page-header__controls"></div>
                </div>
            </div>
        )
    };
}


function mapStateToProps({leftSideBarState}) {
    return {
        ...leftSideBarState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {},
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles, {withTheme: true})(withRouter(PageHeader)));