import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";


const noteStyles = theme => {
    return {
        noteP: {
            margin: '0 0 5px 0'
        },
        invalidToken: {
            textAlign: 'center'
        }
    }
};

class PasswordWasUpdatedNote extends Component {
    render () {
        let {classes, actionClick} = this.props;
        return (
            <div className='b-reset-password__item'>
                <div className='b-reset-password__note'>
                    <div className='b-reset-password__note_header'>
                        <Typography variant='h5' component='h3'>
                            <Translate id='login.restore.password.reseted.text' />
                        </Typography>
                    </div>
                    <div className={`${classes.invalidToken} b-reset-password__note_body`}>
                        <Typography component='p' className={classes.noteP}>
                            <Translate id='login.restore.password.again.link.text' />
                        </Typography>
                    </div>
                    <div className='b-reset-password__note_controls'>
                        <Button href='#/login' color='primary' onClick={actionClick}>
                            <Translate id='login.restore.password.back.to.login.button.text' />
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(noteStyles)(PasswordWasUpdatedNote);