import Const from './../const';
import config from "../../../configs/configs";

const defaultState = {
    wasLocalizationLoaded: null,
    wasReturnsDataLoaded: null,
    wasInBoundDataLoaded: null,
    isActive: false,
    notes: config.notificationDialogs.notes
};


export default function (state = defaultState, action = {}) {
    switch (action.type) {
        case Const.ERROR_DIALOG_LOCALIZATION_ERROR:
            return {
                ...state,
                isActive: true,
                wasLocalizationLoaded: false
            };
        case Const.ERROR_DIALOG_RETURNS_DATA_ERROR:
            return {
                ...state,
                isActive: true,
                wasReturnsDataLoaded: false
            };
        case Const.ERROR_DIALOG_INBOUND_DATA_ERROR:
            return {
                ...state,
                isActive: true,
                wasInBoundDataLoaded: false
            };
        case Const.ERROR_DIALOG_CLOSE:
            return {
                ...state,
                isActive: false,
                wasLocalizationLoaded: null,
                wasReturnsDataLoaded: null,
                wasInBoundDataLoaded: null
            };
        default:
            return state;
    }
}