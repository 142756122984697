import Const from './../const';
import ReturnsConst from "../../../pages/Returns/const";
/**
 * requestStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestStart = () => {
    return {
        type: Const.RETURNS_TABLE_FETCH_REQUEST_START
    }
};

/**
 * fetchReturns {React Action}
 * @description - send request to the server to get returns data
 *
 * */
export function fetchReturns(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.RETURNS_TABLE_FETCH_RETURNS,
            ...requestData
        });
    }
}

/***
 * setTableCurrentPage {react Action}
 * @description - change table page
 *
 * */
export function setTableCurrentPage(page) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_TABLE_CHANGE_TABLE_CURRENT_PAGE,
            payload: page
        });
    }
}

/***
 * setReturnOrderFilter {React Action}
 * @description - changes filters
 *
 * */
export function setReturnOrderFilter(label, value) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_TABLE_CHANGE_ORDER_FILTER,
            payload: {
                label,
                value
            }
        });
    }
}

/**
 * showBeforeSwitchReturnPopup {React Action}
 * @description - dispatch action to show before switch between return dialog
 *
 * */
export function showBeforeSwitchReturnPopup(nextSwitchReturnId) {
    return dispatch => {
        dispatch({
            type: ReturnsConst.RETURNS_CONFIRM_POPUP_BEFORE_SWITCH_RETURN_SHOW,
            payload: nextSwitchReturnId
        });
    }
}

/**
 * clearFixedFeeControl {React Action}
 * @description - dispatch action to clear previous fixed return fee variables
 *
 * */
export function clearFixedFeeControl() {
    return dispatch => {
        dispatch({
            type: ReturnsConst.RETURNS_CLEAR_FIXED_RETURN_FEE
        });
    }
}