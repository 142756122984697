import Const from './../const';

/**
 * requestStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestStart = () => {
    return {
        type: Const.RETURN_EVENT_LOG_POPUP_START_FETCH_EVENTS
    }
};

/**
 * requestStart {Function}
 * @return Object in React Action format before request update start
 *
 * */
let requestUpdateStart = () => {
    return {
        type: Const.RETURN_EVENT_LOG_POPUP_START_UPDATE_EVENT
    }
};

/**
 * fetchEvents {React Action}
 * @description - send request to the server to get returns data
 *
 * */
export function fetchEvents(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.RETURN_EVENT_LOG_POPUP_FETCH_EVENTS,
            ...requestData
        });
    }
}

/**
 * updateEventLog {React Action}
 * @description - send request to the server to get returns data
 *
 * */
export function updateEventLog(requestData) {
    return dispatch => {
        dispatch(requestUpdateStart());
        dispatch({
            type: Const.RETURN_EVENT_LOG_POPUP_UPDATE_EVENT,
            ...requestData
        });
    }
}

/**
 * toggleEventState {React Action}
 * @description - send request to the server to get returns data
 *
 * */
export function toggleEventState(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.RETURN_EVENT_LOG_POPUP_TOGGLE_EVENT,
            ...requestData
        });
    }
}

/**
 * show {react Action}
 * @description - change state to show popup
 *
 * */
export function show(returnOrderId=null) {
    return dispatch => {
        dispatch({
            type: Const.RETURN_EVENT_LOG_POPUP_SHOW,
            payload: {
                returnOrderId
            }
        });
    }
}

/**
 * hide {React Action}
 * @description - change state to hide popup
 *
 * */
export function hide() {
    return dispatch => {
        dispatch({
            type: Const.RETURN_EVENT_LOG_POPUP_HIDE
        });
    }
}

/**
 * clear {React Action}
 * @description - clear event log state
 *
 * */
export function clear() {
    return dispatch => {
        dispatch({
            type: Const.RETURN_EVENT_LOG_CLEAR
        });
    }
}

/**
 * closeStaticEventLog {React Action}
 * @description - close static event log
 *
 * */
export function closeStaticEventLog() {
    return dispatch => {
        dispatch({
            type: Const.RETURN_EVENT_LOG_STATIC_CLOSE
        });
    }
}
