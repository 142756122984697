import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import {setCurrentPageTitle} from "./../../components/Header/actions/headerActions";
import {getBooleanShopProperty} from "./../../utilites";
import {Redirect} from "react-router";

class Settings extends Component {

    componentDidMount() {
        let {pageName} = this.props;
        this.props.setCurrentPageTitle(pageName)
    }

    render() {
        return (
            getBooleanShopProperty(this.props, 'settings.page.enabled')
                ?
                <div className='b-app__page'>
                    <PageHeader/>
                </div>
                :
                <Redirect to="/"/>
        )
    };
}

function mapStateToProps() {
    return {};
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            setCurrentPageTitle
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(Settings);