import Const from './../const';
import GeneralModel from './../../../models/GeneralModel';

const defaultState = {
    data: new GeneralModel([]),
    isMetricsLoading: false,
};

export default function (state = defaultState, action = {}) {
    let { type, data} = action;
    switch (type) {
        case Const.DASHBOARDS_METRICS_START_FETCH_REQUEST:
            return {
                ...state,
                isMetricsLoading: true
            };
        case Const.DASHBOARDS_METRICS_FETCH_REQUEST:
            return {
                ...state,
                data: data,
                isMetricsLoading: false
            };
        default:
            return state;
    }
}
