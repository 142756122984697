import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import detailsIcons from './../../images/returns/return-details-icons.png';
import {
    toFixedCurrency,
    formatDate,
    transformShippingAddress,
    transFormReturnItemName,
    isNeedToShowAttributesInReturnItemName,
    getRoundedTwoDecimals
} from "./../../utilites";
import {
    setRefund,
    setReduceSum,
    clearReturnFees,
    clearReducedValues,
    clearRefund
} from "./actions/returnDescriptionActions";
import {
    show
} from "./../ReturnEventLogPopup/actions/returnEventLogPopupActions";
import CustomTranslation from "./../CustomTranslation/CustomTranslation";
import {isEmpty, isArray, isObject} from 'lodash';
import {withStyles} from "@material-ui/core";
import {Error as SupportIcon, History as HistoryIcon} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import FixedFeeSelector from "./../FixedFeeSelector/FixedFeeSelector";
import configuration from "./../../configs/configs";
import ReturnEventLogPopup from "./../ReturnEventLogPopup/ReturnEventLogPopup";
import TooltipWrapper from "./../../containers/TooltipWrapper/TooltipWrapper";
import IconButton from '@material-ui/core/IconButton';

const divStyle = {
    backgroundImage: 'url(' + detailsIcons + ')',
};
const {PROCESSED, REMOVED, REJECTED} = configuration.states;

class ReturnDescription extends Component {

    constructor(props){
        super(props);
        this.state = {
            isReconversionVisible : true
        }
    }

    /**
     * wasReducedValuesChangedOnClientSide {Function}
     * @description - define was prices reduced manually before saving or not
     * @return {Boolean}
     *
     * */
    wasReducedValuesChangedOnClientSide = () => {
        let {reducedValues, returnItem} = this.props;
        let wasChangedOnClient = false;
        let currentOrderId = returnItem.getField('returnOrderId');
        let currentReducedValues = reducedValues[currentOrderId];
        for(let i in currentReducedValues) {
            if(currentReducedValues[i].defaultDiminishedPrice !== currentReducedValues[i].newDiminishedPrice) {
                wasChangedOnClient = true;
            }
        }
        return wasChangedOnClient;
    };

    /**
     * getTotal {Function}
     * @description - get total as a sum of all return's items priced
     * @return {Number}
     *
     * */
    getTotal = (returnItem) => {
        let total = 0;
        let items = [];

        if(returnItem && returnItem.getField) {
            items = returnItem.getField('items');

            items.forEach((product)=>{
                let {orderItem} = product;
                total += orderItem.price;
            })
        }

        return getRoundedTwoDecimals(total, true);
    };

    /**
     * getReconversionTotal {Function}
     * @description - in case if we have reconversion object we return value from 'total' attribute, otherwise - 0
     * @return {Number}
     *
     * */
    getReconversionTotal = (reconversionOrder) => {
        if (reconversionOrder) {
            return reconversionOrder.total
        }

        return 0;
    };

    /**
     * getReconversionItemNames {Function}
     * @description - in case if we have reconversionItems object we return list with names, otherwise - '-'
     * @return {String}
     *
     * */
    getReconversionItemNames = (reconversionItems) => {
        let {mainPropertiesState} = this.props;
        if (reconversionItems) {
            let list = reconversionItems.map((rItem, key) => {
                let {productVariant = {}, sku} = rItem;
                let index = key + 1;
                let attributes = rItem.productVariant && rItem.productVariant.attributes ? rItem.productVariant.attributes : null;
                let skuId = sku && sku !== productVariant.productVariantExternalId ? sku : null;
                return (
                    <li className='b-return-description__sublist_item' key={key}>
                        {`${index}. `}
                        {transFormReturnItemName(productVariant.name, attributes, isNeedToShowAttributesInReturnItemName(mainPropertiesState), true, index)}
                        <div className='b-return-description__sublist_item_article_id'>
                            <span className='b-return-description__sublist_item_article_id_push'>{`${index}. `}</span>
                            <CustomTranslation id='return.order.details.article.id'/>: {`${ skuId ? productVariant.productVariantExternalId +' ('+ skuId + ')' : productVariant.productVariantExternalId }`}
                        </div>

                    </li>
                )
            });
            return (<ul className='b-return-description__sublist'> {list} </ul>)
        }

        return '-'
    };

    /**
     * isReconversionDataPresent {Function}
     * @description - defines if we need to show reconversion item
     * @return {Boolean}
     *
     * */
    isReconversionDataPresent = (reconversionOrder) => {
        let isReconversionDataPresent = true;

        if (isEmpty(reconversionOrder)) {
            isReconversionDataPresent = false
        }

        return isReconversionDataPresent;
    };

    /**
     * isReconversionGiftDataPresent {Function}
     * @description - defines if we need to show store credit (gift) item
     * @return {Boolean}
     *
     * */
    isReconversionGiftDataPresent = (gift) => {
        let isReconversionGiftDataPresent = true;

        if (!gift) {
            isReconversionGiftDataPresent = false
        }

        return isReconversionGiftDataPresent;
    };

    /**
     * getShipmentStatusColor {Function}
     * @description - get appropriate color fo returns item shipment status
     * @return {String} - color hex
     * */
    getShipmentStatusColor = (status) => {
        let {theme: {palette: {brand: {returnShipmentStatusColors}}}} = this.props;
        return returnShipmentStatusColors[status] || 'none';
    };

    /**
     * setRefund {Function}
     * @description - set refund
     *
     * */
    setRefund = () => {
        let {
            returnItem = {},
            currentItemProducts = {},
            items
        } = this.props;
        let currentReturnItem = returnItem && returnItem.toJSONView ? returnItem.toJSONView() : returnItem;
        let {
            returnOrderId,
            reconversionOrder,
            shippingFee = 0,
            grossShippingRefund = 0,
        } = currentReturnItem;
        let total = this.getTotal(returnItem);
        let currentReturnFee = this.getReturnFee();
        let reducedSum = this.getTotalReducedSum();
        let reconversionSum = this.isReconversionDataPresent(reconversionOrder) ? this.getReconversionTotal(reconversionOrder): 0;
        let isSomeItemRemovedOrRejected = currentItemProducts ? this.isSomeItemRemovedOrRejected(currentItemProducts) : false;
        let newRefund;

        if(isSomeItemRemovedOrRejected) {
            grossShippingRefund = 0
        }

        newRefund = total > 0 ? total - reducedSum - currentReturnFee - reconversionSum - shippingFee + grossShippingRefund: 0;

        if(reconversionSum > total - reducedSum - currentReturnFee - shippingFee + grossShippingRefund) {
            newRefund += reconversionSum;
            this.setState({
                isReconversionVisible: false
            }, ()=> {
                this.updateRefund(items, newRefund, returnOrderId);
            })
        } else {
            this.setState({
                isReconversionVisible: true
            }, ()=> {
                this.updateRefund(items, newRefund, returnOrderId);
            })
        }

    };

    /**
     * updateRefund {Function}
     * @description - before set the refund we need to define were all items in return removed or not
     *
     * */
    updateRefund(items, newRefund, returnOrderId) {
        let isAllItemsRemoved = true;
        if(items && items.length) {
            items.forEach((item)=>{
                if(item.status !== REMOVED && item.status !== REJECTED) {
                    isAllItemsRemoved = false;
                }
            })
        }
        this.props.setRefund(isAllItemsRemoved ? 0 : newRefund, returnOrderId);
    }

    /**
     * isSomeItemRemovedOrRejected {Function}
     * @description - define is some of the current items in rejected or removed status
     * @return {Boolean}
     *
     * */
    isSomeItemRemovedOrRejected = (items) => {
        let isSomeItemRemovedOrRejected = false;

        if(isArray(items) && items) {
            items.forEach((item)=>{
                if(item.status === REMOVED || item.status === REJECTED) {
                    isSomeItemRemovedOrRejected = true;
                }
            });
        }

        if(isObject(items) && items) {
            for(let i in items) {
                if(items.hasOwnProperty(i) && (items[i].status === REMOVED || items[i].status === REJECTED)) {
                    isSomeItemRemovedOrRejected = true;
                }
            }
        }

        return isSomeItemRemovedOrRejected;
    };

    getReturnFee = () => {
        let {
            returnItem = {},
            returnFees = {}
        } = this.props;
        let currentReturnItem = returnItem && returnItem.toJSONView ? returnItem.toJSONView() : returnItem;
        let {
            returnOrderId
        } = currentReturnItem;
        let returnFee = currentReturnItem.returnFee;

        if (returnFees[returnOrderId] || returnFees[returnOrderId] === 0) {
            let currentFee = returnFees[returnOrderId];
            returnFee = currentFee
        }

        return returnFee;
    };

    /**
     * getReducedSum {Function}
     * @description - get default reduced value form server. It's sum of differences between product original price and product diminished price
     * @return {Number}
     *
     * */
    getReducedDefaultSum = () => {
        let {returnItem} = this.props;
        let reducedDefaultSum = 0;

        if(returnItem && returnItem.getField && returnItem.getField('items')) {

            returnItem.getField('items').forEach((product)=>{
                let {orderItem: {price=0}, diminishedPrice=0} = product;
                if(product.status !== 'REMOVED') {
                    if(price !== diminishedPrice) {
                        reducedDefaultSum += (price - diminishedPrice)
                    }
                }

            })

        }

        return reducedDefaultSum;
    };

    /**
     * getReducedSum {Function}
     * @description - get reduced value
     * TODO: maybe it does make sense to set this property as object according to returnOrderId instead just a number as we have now
     * @return {Number}
     *
     * */
    getReducedSum = (isGetFromServer=false) => {
        let {reducedSum=0, reducedValues={}} = this.props;
        let decreasedValueTotal = 0;

        if(isGetFromServer) {
            let decreasedSum = 0;

            for(let key in reducedValues) {
                if(reducedValues.hasOwnProperty(key)) {
                    let currentReducedData = reducedValues[key];
                    for(let jey in currentReducedData) {
                        if(currentReducedData.hasOwnProperty(jey)) {
                            let currentReducedDataItem = currentReducedData[jey];
                            decreasedSum += currentReducedDataItem.decreasedValue
                        }
                    }
                }
            }

            decreasedValueTotal = decreasedSum

        } else {
            decreasedValueTotal = reducedSum
        }

        return decreasedValueTotal;

    };

    /**
     * getTotalReducedSum {Function}
     * @description - we have two reduced values (from serve data by default and values we get from appropriate reduce value control).
     *                This function accumulate the sum of these values
     * @return {Number}
     *
     * */
    getTotalReducedSum = () => {
        if(this.wasReducedValuesChangedOnClientSide()) {
            return this.getReducedSum()
        } else {
            return this.getReducedSum(true)
        }
    };

    /**
     * wasReducedValuesChanged {Function}
     * @description - defines was reduced values changed in general or not
     * @return {Boolean}
     *
     * */
    wasReducedValuesChanged = (prevReduced, currentReduced, id) => {
        let wasChanged = false;

        if(prevReduced[id] && currentReduced[id]) {
            wasChanged =  JSON.stringify(prevReduced[id]) !== JSON.stringify(currentReduced[id])
        }

        return wasChanged
    };

    componentDidMount() {
        this.setRefund();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {returnItem, reducedValues, returnFees, reducedSum, refund, reducedSumChanged} = this.props;
        let currentReturnItem = returnItem && returnItem.toJSONView ? returnItem.toJSONView() : returnItem;
        let prevCurrentItem = prevProps.returnItem && prevProps.returnItem.toJSONView ? prevProps.returnItem.toJSONView() : prevProps.returnItem;

        let currentOrderId = returnItem.getField('returnOrderId');
        let previousOrderId = prevProps.returnItem.getField('returnOrderId');

        if(previousOrderId !== currentOrderId) {
            this.props.clearRefund(previousOrderId);
            this.props.clearReducedValues();
            this.props.clearReturnFees();
        }

        if(prevProps.reducedValues !== reducedValues) {
            this.props.setReduceSum(reducedValues);
        }

        if(
            this.wasReducedValuesChanged(prevProps.reducedValues, reducedValues, currentOrderId)
            ||
            prevProps.returnFees !== returnFees
            ||
            prevProps.reducedSum !== reducedSum
            ||
            prevProps.refund !== refund && refund[currentOrderId] === undefined
            ||
            currentReturnItem.returnFee !== prevCurrentItem.returnFee
            ||
            prevProps.reducedSumChanged !== reducedSumChanged
        ) {
            this.setRefund();
        }

    }

    /**
     * getGrid {Function}
     * @description - generate grid sizes
     * @return {Object}
     *
     * */
    getGridClass = (isReconversion) => {
        if(isReconversion) {
            return 'b-return-description__main_list_item--reconversion'
        } else {
            return ''
        }
    };

    render() {
        let {
            styleClass,
            returnItem={},
            onReturnFixedFeeChange=()=>{},
            isInbound=false,
            returnFeeFixedError=null,
            status='',
            refund,
            returnFees,
            currentItemProducts
        } = this.props;
        let currentReturnItem = returnItem && returnItem.toJSONView ? returnItem.toJSONView() : returnItem;
        let {
            order: {customer = {}, phone = '', shipAddress = '', date = '', shopId=null},
            shipments,
            reconversionOrder,
            reconversionItems,
            shippingFee = 0,
            grossShippingRefund = 0,
            returnFee = 0,
            currency = '',
            createDate = '',
            modifiedDate = '',
            gift = 0,
            returnOrderId
        } = currentReturnItem;
        let currentRefund = refund[returnOrderId] !== undefined ? refund[returnOrderId] : 0;
        let currentReturnFee = this.getReturnFee();
        let isReconversion = this.isReconversionDataPresent(reconversionOrder) && this.state.isReconversionVisible;

        return (
            <div className={styleClass ? `b-return-description ${styleClass}` : 'b-return-description'}>
                <ReturnEventLogPopup isPopupView={true} />
                <ul className={`b-return-description__main_list b-flex b-flex--wrap`}>
                    <li className={`b-return-description__main_list_item ${this.getGridClass(isReconversion)}`}>
                        <div className="b-return-description__main_list_item_inner">
                            <div className="b-return-description__title b-return-description__title--customer">
                                <div className="b-return-description__icon b-return-description__icon--customer" style={divStyle}/>
                                <CustomTranslation id='order.list.columns.customer'/>
                            </div>
                            <ul className="b-return-description__list">
                                <li className="b-return-description__list_item"> {`${customer.firstName} ${customer.lastName}`}</li>
                                <li className="b-return-description__list_item"> {`${transformShippingAddress(shipAddress)}`}</li>
                                <li className="b-return-description__list_item"> {`${phone}`}</li>
                                <li className="b-return-description__list_item"> {`${customer.email}`}</li>
                            </ul>
                        </div>
                    </li>
                    <li className={`b-return-description__main_list_item ${this.getGridClass(isReconversion)}`}>
                        <div className="b-return-description__main_list_item_inner">
                            <div className="b-return-description__title b-return-description__title--refund">
                                <div className="b-return-description__icon b-return-description__icon--refund" style={divStyle}/>
                                <CustomTranslation id='products.report.returnType.REFUND'/>
                            </div>
                            <ul className="b-return-description__list">
                                <li className="b-return-description__list_item">
                                    <span className="b-return-description__list_item_title">
                                        <CustomTranslation id='return.order.details.return.value'/>:
                                    </span>
                                    <span className="b-return-description__list_item_description b-return-description__list_item_description--right">
                                        {`  ${toFixedCurrency(this.getTotal(returnItem), currency)}`}
                                    </span>
                                </li>
                                {
                                    currentReturnFee > 0 || currentReturnFee === 0 && returnFee > 0
                                        ?
                                        <li className="b-return-description__list_item">
                                            <span className="b-return-description__list_item_title">
                                                <CustomTranslation id='return.order.details.return.fee'/>:
                                            </span>
                                            <span
                                                className="b-return-description__list_item_description b-return-description__list_item_description--right">
                                                {currentReturnFee > 0 ? `-${toFixedCurrency(currentReturnFee, currency)}` : toFixedCurrency(currentReturnFee, currency)}
                                            </span>
                                        </li>
                                        :
                                        ''
                                }
                                {
                                    shippingFee > 0
                                        ?
                                        <li className="b-return-description__list_item">
                                            <span className="b-return-description__list_item_title">
                                                <CustomTranslation id='return.order.details.return.ShippingFee'/>:
                                            </span>
                                            <span
                                                className="b-return-description__list_item_description b-return-description__list_item_description--right">
                                                {shippingFee > 0 ? `-${toFixedCurrency(shippingFee, currency)}` : `${toFixedCurrency(0, currency)}`}
                                            </span>
                                        </li>
                                        :
                                        ''
                                }

                                {
                                    grossShippingRefund > 0 && !this.isSomeItemRemovedOrRejected(currentItemProducts)
                                        ?
                                        <li className="b-return-description__list_item">
                                            <span className="b-return-description__list_item_title">
                                                <CustomTranslation id='return.order.details.return.grossShippingRefund'/>:
                                            </span>
                                            <span
                                                className="b-return-description__list_item_description b-return-description__list_item_description--right">
                                                {` ${toFixedCurrency(grossShippingRefund, currency)}`}
                                            </span>
                                        </li>
                                        :
                                        ''
                                }
                                {
                                    this.getTotalReducedSum() > 0
                                        ?
                                        <li className="b-return-description__list_item">
                                            <span className="b-return-description__list_item_title">
                                                <CustomTranslation id='return.order.details.return.product.decrease.value'/>:
                                            </span>
                                            <span
                                                className="b-return-description__list_item_description b-return-description__list_item_description--right">
                                                {this.getTotalReducedSum() > 0 ? `-${toFixedCurrency(this.getTotalReducedSum(), currency)}` : toFixedCurrency(0, currency)}
                                            </span>
                                        </li>
                                        :
                                        ''
                                }
                                {
                                    isReconversion ?
                                        <li className="b-return-description__list_item">
                                            <span className="b-return-description__list_item_title">
                                                <CustomTranslation id='return.order.details.return.reconversion.value'/>:
                                            </span>
                                            <span
                                                className="b-return-description__list_item_description b-return-description__list_item_description--right">
                                                {this.getReconversionTotal(reconversionOrder) > 0 ? `-${toFixedCurrency(this.getReconversionTotal(reconversionOrder), currency)}` : toFixedCurrency(0, currency)}
                                            </span>
                                        </li>
                                        :
                                        ''
                                }
                            </ul>
                            <div className="b-return-description__return_fixed_fee">
                                <ul className="b-return-description__list">
                                    <li className="b-return-description__list_item">
                                        <span className="b-return-description__list_item_title b-return-description__list_item_title uppercase">
                                            <CustomTranslation id='return.order.details.return.toBeRefunded'/>:
                                        </span>
                                        <span className="b-return-description__list_item_description b-return-description__list_item_description--right">
                                            {` ${toFixedCurrency(currentRefund, currency, 2, false)}`}
                                        </span>
                                    </li>
                                </ul>
                                <FixedFeeSelector
                                    isDisabled={status === PROCESSED}
                                    onChangeAction={onReturnFixedFeeChange}
                                    returnFees={returnFees}
                                    isInbound={isInbound}
                                    returnOrderId={returnOrderId}
                                    defaultReturnFee={returnFee}
                                    returnFeeFixedError={returnFeeFixedError}
                                    isReconversionRemoved={this.isReconversionDataPresent(reconversionOrder) && !this.state.isReconversionVisible}
                                    isReturnFeeAllow={currentRefund >= 0}
                                    shopId={shopId}
                                />
                            </div>
                        </div>
                    </li>
                    {
                        this.isReconversionDataPresent(reconversionOrder) && this.isReconversionGiftDataPresent(gift)
                            ?
                            <li className={`b-return-description__main_list_item ${this.getGridClass(isReconversion)}`}>
                                <div className="b-return-description__main_list_item_inner">
                                    <div className="b-return-description__title b-return-description__title--reconversion">
                                        <div className="b-return-description__icon b-return-description__icon--reconversion" style={divStyle}/>
                                        <CustomTranslation id='return.order.details.return.Reconversion'/>
                                    </div>
                                    <ul className="b-return-description__list">
                                        <li className="b-return-description__list_item">
                                            <span className="b-return-description__list_item_title">
                                                <CustomTranslation id='customer.list.columns.storeCredit'/>:
                                            </span>
                                            <span className="b-return-description__list_item_description">
                                                {` ${toFixedCurrency(gift, currency)}`}
                                            </span>
                                        </li>
                                        <li className="b-return-description__list_item">
                                            <span className="b-return-description__list_item_title">
                                                <CustomTranslation id='return.order.details.return.productValue'/>:
                                            </span>
                                            <span className="b-return-description__list_item_description">
                                                {` ${toFixedCurrency(this.getReconversionTotal(reconversionOrder), currency)}`}
                                            </span>
                                        </li>
                                        <li className="b-return-description__list_item">
                                            <div className="b-return-description__list_item_description b-return-description__list_item_description--sublist">
                                                {this.getReconversionItemNames(reconversionItems)}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            :
                            this.isReconversionGiftDataPresent(gift)
                                ?
                                <li className={`b-return-description__main_list_item ${this.getGridClass(isReconversion)}`}>
                                    <div className="b-return-description__main_list_item_inner">
                                        <div className="b-return-description__title b-return-description__title--reconversion">
                                            <div className="b-return-description__icon b-return-description__icon--reconversion" style={divStyle}/>
                                            <CustomTranslation id='return.order.details.return.Reconversion'/>
                                        </div>
                                        <ul className="b-return-description__list">
                                            <li className="b-return-description__list_item">
                                                <span className="b-return-description__list_item_title"><CustomTranslation
                                                    id='customer.list.columns.storeCredit'/>:
                                                </span>
                                                <span className="b-return-description__list_item_description">
                                                    {` ${toFixedCurrency(gift, currency)}`}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                :
                                this.isReconversionDataPresent(reconversionOrder)
                                    ?
                                    <li className={`b-return-description__main_list_item ${this.getGridClass(isReconversion)}`}>
                                        <div className="b-return-description__main_list_item_inner">
                                            <div className="b-return-description__title b-return-description__title--reconversion">
                                                <div className="b-return-description__icon b-return-description__icon--reconversion" style={divStyle}/>
                                                <CustomTranslation id='return.order.details.return.Reconversion'/>
                                            </div>
                                            <ul className="b-return-description__list">
                                                <li className="b-return-description__list_item">
                                                    <span className="b-return-description__list_item_title">
                                                        <CustomTranslation id='return.order.details.return.productValue'/>:
                                                    </span>
                                                    <span className="b-return-description__list_item_description">
                                                        {` ${toFixedCurrency(this.getReconversionTotal(reconversionOrder), currency)}`}
                                                    </span>
                                                </li>
                                                <li className="b-return-description__list_item">
                                                    <div className="b-return-description__list_item_description b-return-description__list_item_description--sublist">
                                                        {this.getReconversionItemNames(reconversionItems)}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    :
                                    ''
                    }
                    <li className={`b-return-description__main_list_item ${this.getGridClass(isReconversion)}`}>
                        <div className="b-return-description__main_list_item_inner">
                            <div className="b-return-description__title b-return-description__title--actions">
                                <div className="b-return-description__icon b-return-description__icon--actions" style={divStyle}/>
                                <CustomTranslation id='return.order.details.return.actionsMade'/>
                                <IconButton className="b-log-event-popup__tooltip_btn" onClick={()=>{
                                    this.props.show(returnOrderId)
                                }}>
                                    <TooltipWrapper text={<CustomTranslation id='eventlog.control.tooltip.open' renderAsString={true} />} className='b-log-event-popup__tooltip'>
                                        <HistoryIcon className="b-return-description__log_icon" fontSize="small" />
                                    </TooltipWrapper>
                                </IconButton>
                            </div>
                            <ul className="b-return-description__list">
                                <li className="b-return-description__list_item">
                                    <span className="b-return-description__list_item_title">
                                        <CustomTranslation id='return.order.details.return.orderRegistered'/>:
                                    </span>
                                    <span className="b-return-description__list_item_description b-return-description__list_item_description--right">
                                        {` ${formatDate(date)}`}
                                    </span>
                                </li>
                                <li className="b-return-description__list_item">
                                    <span className="b-return-description__list_item_title">
                                        <CustomTranslation id='return.order.details.return.returnRegistered'/>:
                                    </span>
                                    <span className="b-return-description__list_item_description b-return-description__list_item_description--right">
                                        {` ${formatDate(createDate)}`}
                                    </span>
                                </li>
                                <li className="b-return-description__list_item">
                                    <span className="b-return-description__list_item_title">
                                        <CustomTranslation id='return.order.details.return.returnLastEdited'/>:
                                    </span>
                                    <span className="b-return-description__list_item_description b-return-description__list_item_description--right">
                                        {` ${formatDate(modifiedDate)}`}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </li>
                    {
                        shipments && shipments.length
                            ?
                            <li className={`b-return-description__main_list_item ${this.getGridClass(isReconversion)}`}>
                                <div className="b-return-description__main_list_item_inner">
                                    <div className="b-return-description__title b-return-description__title--shipping">
                                        <div className="b-return-description__icon b-return-description__icon--shipping" style={divStyle}/>
                                        <CustomTranslation id='return.order.list.columns.shipmentStatus'/>
                                    </div>
                                    {
                                        shipments.map((shipment, key) => {
                                            let {shipmentProvider = null, trackingNumber = null, status = '', externalStatusCode = '', externalStatusDescription = ''} = shipment;

                                            return (
                                                trackingNumber || status
                                                    ?
                                                    <ul key={key} className="b-return-description__list">
                                                        <li className="b-return-description__list_item b-return-description__list_item--parcel">
                                                            <CustomTranslation id='return.description.multiple.shipping.parcels' data={{
                                                                shippingParcelIndex: key+1,
                                                                shippingParcelTotal: shipments.length
                                                            }}/>
                                                        </li>
                                                        {
                                                            trackingNumber
                                                                ?
                                                                <li className="b-return-description__list_item">
                                                                    <span className="b-return-description__list_item_title">
                                                                        <CustomTranslation id='return.order.details.tracking.number'/>:
                                                                    </span>
                                                                    <span className="b-return-description__list_item_description b-return-description__list_item_description--logtrade">
                                                                        {`${shipmentProvider ? shipmentProvider + ',' : ''} ${trackingNumber}`}
                                                                    </span>
                                                                </li>
                                                                :
                                                                ''
                                                        }
                                                        {
                                                            status
                                                                ?
                                                                <li className="b-return-description__list_item">
                                                                    <span className="b-return-description__list_item_title">
                                                                        <CustomTranslation id='return.order.details.status'/>:
                                                                    </span>
                                                                    <span className="b-return-description__list_item_description b-return-description__list_item_description--shipment_status">
                                                                        <span className="b-return-description__title_status" style={{backgroundColor: this.getShipmentStatusColor(status)}}>
                                                                            <CustomTranslation id={`return.order.shipmentStatuses.${status}`}/>
                                                                        </span>
                                                                    </span>
                                                                    {
                                                                        externalStatusCode && externalStatusDescription
                                                                            ?
                                                                            <>
                                                                                <div className="b-return-description__flex_breaker"/>
                                                                                <div className="b-return-description__external_status">
                                                                                    {externalStatusCode}
                                                                                    <span className="b-return-description__tooltip">
                                                                                        <Tooltip title={externalStatusDescription} placement="top">
                                                                                            <SupportIcon fontSize='small' className="b-custom-tooltip-icon b-return-description__tooltip_icon"/>
                                                                                        </Tooltip>
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            ''
                                                                    }
                                                                </li>
                                                                :
                                                                ''
                                                        }

                                                    </ul>
                                                    :
                                                    ''

                                            )

                                        })
                                    }
                                </div>
                            </li>
                            :
                            ''

                    }
                </ul>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.returnDescriptionState,
        ...state.fixedPercentageSelectorState,
        mainPropertiesState: state.mainPropertiesState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            setRefund,
            setReduceSum,
            clearReturnFees,
            clearReducedValues,
            clearRefund,
            show
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(null, {withTheme: true})(ReturnDescription));