import Const from './../const';
import GeneralModel from './../../../models/GeneralModel';
import countries from './../mapping/countries';
import {setDataToSS} from "../../../utilites";


const defaultState = {
    mainSettings: new GeneralModel({}),
    subShops: new GeneralModel({}),
    countries: countries,
    isLoading: false,
    wasMainPropertiesLoaded: undefined,
    wasSubShopsLoaded: undefined,
};

export default function (state = defaultState, action = {}) {
    let {data, type} = action;
    switch (type) {
        case Const.SHOP_PROPERTIES_GET_PROPERTIES_REQUEST_START:
            return {
                ...state,
                isLoading: true
            };
        case Const.SHOP_PROPERTIES_GET_PROPERTIES_REQUEST:
            let {requestStatus} = data.data;
            setDataToSS('mainProps', data.toJSONView().response);
            return {
                ...state,
                isLoading: false,
                mainSettings: data,
                wasMainPropertiesLoaded:  requestStatus === 'error' ? false : true
            };
        case Const.SHOP_PROPERTIES_GET_SUBSHOPS_REQUEST:
            return {
                ...state,
                subShops: data
            };
        case Const.GET_RETURN_REASONS_REQUEST:
            return {
                ...state,
                returnReasons: data.data.response
            };
        default:
            return state;
    }
}