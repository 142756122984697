import Const from './../const';

const defaultState = {
    isActive: false
};

export default function (state = defaultState, action = {}) {
    let {type, payload} = action;

    switch (type) {
        case Const.RIGHT_MENU_IS_ACTIVE:
            return {
                ...state,
                isActive: payload
            };
        default:
            return state;
    }
}
