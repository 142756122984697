import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {setCurrentPageTitle} from "./../../components/Header/actions/headerActions";
import {fetchOrders, setOrderFilter, clearState} from "./actions/ordersActions";
import PageContent from "../../containers/PageContent/PageContent";
import {
    getFiltersAppropriateMarkets,
    isNeedToShowMarketLanguage,
    isNeedToShowMarket,
    getDataFromSS, getBooleanShopProperty
} from './../../utilites/index';
import {withRouter} from "react-router";
import configuration from '../../configs/configs';
import {getTranslate} from "react-localize-redux";
import PageFiltersItems from "../../components/PageFiltersItems/PageFiltersItems";
import Search from "../../components/Search/Search";
import defaultRequestConst from './defaultRequestConst';
import OrderPageTable from "./components/OrderPageTable";

class Orders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: true,
            currentPage: 0,
            pageSizeOption: defaultRequestConst.limit,
            searchValue: '',
        };
    }

    /**
     * onSearchField {Function}
     * @description - callback on change search field. Does specific action
     *
     * */
    onSearchField = (event) => {
        let searchValue = event.currentTarget.value;
        let {DefaultRequestConst} = this.props;
        if (!searchValue) {
            this.setState({
                searchValue: ''
            }, () => {
                this.fetchOrders({
                    ...DefaultRequestConst,
                    searchValue: ''
                });
            })
        } else {
            this.setState({
                searchValue: searchValue
            })
        }
    };

    /**
     * clearSearch {Function}
     * @description - clearing the search field
     *
     * */
    clearSearch = () => {
        let {match: {params: {orderId}}} = this.props;

        if (orderId) {
            this.setState({
                searchValue: ''
            }, () => {
                this.props.clearState();
            })
        } else {
            this.setState({
                searchValue: ''
            }, () => {
                this.props.clearState();
            });
        }

    };

    /**
     * onSubmit {function}
     * @description - action on search
     *
     * */
    onSubmit = (model) => {
        let searchValue = model.search;
        let {DefaultRequestConst} = this.props;

        this.fetchOrders({
            ...DefaultRequestConst,
            search: searchValue
        });
    };

    /**
     * fetchOrders {Function}
     * @description - main function to get orders from server
     *
     * */
    fetchOrders = (requestData = null) => {
        let {DefaultRequestConst} = this.props;
        let dataForRequest = requestData ? requestData : {
            ...DefaultRequestConst
        };

        this.props.fetchOrders({
            route: 'getOrders',
            isWithGetParams: true,
            requestData: dataForRequest
        });
    };

    /**
     * getFiltersValue {Function}
     * @description - get default values for Filters
     * @return {Object}
     *
     * */
    getFiltersValue = () => {
        let {filters = {}} = this.props;
        let {shopName} = filters;

        return {
            0: shopName
        }
    };

    /**
     * getDisabledStates {Function}
     * @description - get default enabled/disabled states for Filters
     * @return {Object}
     *
     * */
    getDisabledStates = () => {
        return {
            0: false,
        }
    };

    /**
     * getFilterShopNames {Function}
     * @description - generates appropriate options for Market dropdown in Filters
     * @return {Object}
     *
     * */
    getFilterShopNames = () => {
        let {mainPropertiesState} = this.props;
        return getFiltersAppropriateMarkets(mainPropertiesState, isNeedToShowMarket(mainPropertiesState), isNeedToShowMarketLanguage(mainPropertiesState));
    };

    /**
     * changeFilterAction {Function}
     * @description - change filter action value
     *
     * */
    changeFilterAction = (value, item) => {
        let {label = ''} = item;

        this.setState({
            expanded: true,
            currentPage: 0,
            pageSizeOption: 50
        }, () => {
            this.props.setOrderFilter(label, value);
        })
    };

    /**
     * clearFilterAction {Function}
     * @description - clear filter action value
     *
     * */
    clearFilterAction = (value, item) => {
        let {label='', clearedValue=''} = item;

        this.setState({
            expanded: true,
            currentPage: 0,
            pageSizeOption: 50,
            searchValue: ''
        }, () => {
            this.props.setOrderFilter(label, clearedValue);
        });
    };

    /**
     * isFilterAbsent
     * @description - check are there any filter to be implemented
     * @return Boolean
     *
     * */
    isFiltersAbsent = () => {
        let isFiltersAbsent = true;
        let {filters} = this.props;

        for (let i in filters) {
            if (filters[i]) {
                isFiltersAbsent = false;
            }
        }

        return isFiltersAbsent;
    };

    /**
     * openWidget {Function}
     * @description - open Widget.
     *
     * */
    openWidget = (order) => {
        let {_original} = order;
        if(window.Widget && window.Widget.open) {
            window.Widget.open(_original.orderExternalId, _original.customer.email.replace(/\+/g, '%2B'), _original.shopId, this.getUsername());
        }
    };

    /**
     * getUsername {Function}
     * @description - get user name from session storage
     * @return {String}
     *
     * */
    getUsername = () => {
        return getDataFromSS('login') ? getDataFromSS('login').manager.username : ''
    };

    /**
     * initWidget
     * @description - widget initialization
     *
     * */
    initWidget = () => {
        let queryString = function () {
            // This function is anonymous, is executed immediately and
            // the return value is assigned to QueryString!
            let query_string = {};
            let query = window.location.search.substring(1);
            let vars = query.split("&");
            for (let i = 0; i < vars.length; i++) {
                let pair = vars[i].split("=");
                // If first entry with this name
                if (typeof query_string[pair[0]] === "undefined") {
                    query_string[pair[0]] = decodeURIComponent(pair[1]);
                    // If second entry with this name
                } else if (typeof query_string[pair[0]] === "string") {
                    let arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
                    query_string[pair[0]] = arr;
                    // If third or later entry with this name
                } else {
                    query_string[pair[0]].push(decodeURIComponent(pair[1]));
                }
            }
            return query_string;
        }();

        let link = "/index.html#/login";
        let returnadoToken = queryString.returnadoToken;

        let widgetUrl = link;
        let widgetHost = configuration.BASE_URL + configuration.WIDGET_URL;


        window.returnadoOptions = {
            widget_host: widgetHost,
            widget_url: widgetUrl,
            returnado_token: returnadoToken,
            tab_orientation: "left",
            tab_indent: "50%",
            tab_bg_color: "#009E86",
            tab_border_color: "#FFFFFF",
            tab_border_width: 2,
            show_tab: false,
            doNotOpenWidgetAfterRefresh: true
        };

        this.insertReturnadoScript();
    };

    /**
     * initWidget
     * @insertReturnadoScript - add script fo loading main widget functionality
     *
     * */
    insertReturnadoScript = () => {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = window.returnadoOptions.widget_host + '/returnado.js';
        document.getElementsByTagName('head')[0].appendChild(script);
    };

    /**
     * handleWidgetClose {Function}
     * @description - closing widget on esc press
     *
     * */
    handleWidgetClose = (e) => {
        let {Widget = undefined} = window;
        if (e.keyCode === 27) {
            //Do whatever when esc is pressed
            if (Widget && Widget.widgetElement && Widget.widgetElement.style.display === 'block') {
                Widget.close()
            }
        }
    };

    componentDidMount() {
        let {pageName, DefaultRequestConst, filters, match: {params: {orderId}}} = this.props;
        this.props.setCurrentPageTitle(pageName);

        if (orderId) {
            this.setState({
                searchValue: orderId
            }, () => {
                this.changeFilterAction(orderId, {label: 'search'});
            })
        } else {
            if (!this.isFiltersAbsent()) {
                let {shopName, search} = filters;
                this.fetchOrders({
                    ...DefaultRequestConst,
                    shopId: shopName,
                    search: search,
                })
            } else {
                this.fetchOrders();
            }
        }

        if(!document.querySelector('script[src$="returnado.js"]')) {
            this.initWidget();
        }
    }

    componentDidUpdate(prevProps) {
        let {DefaultRequestConst, match: {params: {orderId}}, filters, history} = this.props;
        let {searchValue} = this.state;

        if (prevProps.filters !== filters) {
            let {shopName, search} = filters;
            let searchFiltersObj = {
                ...DefaultRequestConst,
                search,
                shopId: shopName,
            };

            if(!search && !searchValue && orderId) {
                history.push('/orders');
            } else {
                this.fetchOrders(searchFiltersObj)
            }

        }

        if (prevProps.match.params.orderId !== orderId && !orderId) {
            this.fetchOrders();
        }

        if (prevProps.match.params.orderId !== orderId && orderId) {
            let {shopName} = this.props.filters;
            this.fetchOrders({
                ...DefaultRequestConst,
                shopId: shopName,
                search: orderId,
            })
        }
    };

    componentWillUnmount() {
        this.props.clearState()
    }

    render() {
        let {isFoldedOpen, data, isLoading, mainPropertiesState, translate, filters, DefaultRequestConst, match: {params: {orderId}}} = this.props;
        let {searchValue} = this.state;
        let isExternalIdToShow = getBooleanShopProperty(this.props, 'use.sequential.order.external.id');
        return (
            <div className='b-app__page b-orders-page' onKeyDown={this.handleWidgetClose} tabIndex="0">
                <PageContent isFoldedOpen={isFoldedOpen} classNameCustom='b-page-content--orders'>
                    <div className="b-app__page_body">
                        <div className="b-app__body_item">
                            <Search
                                placeholder={orderId || translate('search')}
                                onSubmitAction={this.onSubmit}
                                onValidAction={this.enableButton}
                                onInvalidAction={this.disableButton}
                                onSearchFieldAction={this.onSearchField}
                                onClearSearchAction={this.clearSearch}
                                isSearchButtonControlPresent={true}
                                isSearchBtnDisabled={!searchValue}
                                isClearSearchControlPresent={searchValue || orderId}
                                styleClass='b-search--orders'
                            />

                            <div className="b-app__page_controls b-flex b-returns-page__controls">
                                <div className="b-app__page_controls_item" style={{flexGrow: 1}}>
                                    <PageFiltersItems
                                        pageId="orders"
                                        classStyles="b-returns-page__filters"
                                        defaultValues={this.getFiltersValue()}
                                        disabledStates={this.getDisabledStates()}
                                        changeAction={this.changeFilterAction}
                                        clearAction={this.clearFilterAction}
                                        isLabeled={true}
                                        replaceFilter={{
                                            'customer.list.columns.shopName': this.getFilterShopNames()
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="b-app__table_wrapper">
                                <OrderPageTable
                                    data={data}
                                    isLoading={isLoading}
                                    mainPropertiesState={mainPropertiesState}
                                    translate={translate}
                                    filters={filters}
                                    DefaultRequestConst={DefaultRequestConst}
                                    openWidgetAction={this.openWidget}
                                    isExternalIdToShow={isExternalIdToShow}
                                />
                            </div>
                        </div>
                    </div>
                </PageContent>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {
        ...state.ordersPageState,
        mainPropertiesState: state.mainPropertiesState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            setCurrentPageTitle,
            setOrderFilter,
            fetchOrders,
            clearState
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Orders));