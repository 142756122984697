import Const from '../const';

/*
* requestStart {Function}
* @return Object in React Action format before request start
*
* */
let requestStart = () =>{
    return {
        type: Const.LOGIN_SEND_START_REQUEST
    }
};

/*
* setIsPasswordVisible {React Action}
* @description - change visibility in the password field
*
* */
export function setIsPasswordVisible(value) {
    return dispatch => {
        dispatch({
            type: Const.LOGIN_SET_IS_PASSWORD_VISIBLE,
            payload: value
        });
    }
}

/*
* setLoginStateToDefault {React Action}
* @description - set login state to default
*
* */
export function setLoginStateToDefault() {
    return dispatch => {
        dispatch({
            type: Const.LOGIN_SET_TO_DEFAULT
        });
    }
}

/*
* login {React Action}
* @description - send request to the server to get login data
*
* */
export function login(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.LOGIN_SEND_REQUEST,
            ...requestData
        });
    }
}


/*
* login {React Action}
* @description - send request to the server to get login data
*
* */
export function is2F(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.LOGIN_CHECK_2F,
            ...requestData
        });
    }
}

/*
* setUsername {React Action}
* @description - set username
*
* */
export function setUsername(value) {
    return dispatch => {
        dispatch({
            type: Const.LOGIN_SET_USERNAME,
            payload: value
        });
    }
}

/*
* setPassword {React Action}
* @description - set password
*
* */
export function setPassword(value) {
    return dispatch => {
        dispatch({
            type: Const.LOGIN_SET_PASSWORD,
            payload: value
        });
    }
}

/*
* setVerificationCode {React Action}
* @description - set password
*
* */
export function setVerificationCode(value) {
    return dispatch => {
        dispatch({
            type: Const.LOGIN_SET_2FA_CODE,
            payload: value
        });
    }
}