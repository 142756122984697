import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Button} from '@material-ui/core';

let getBtnState = (isLoading, isdisabled) => {
    return !isLoading && !isdisabled ? false : true;
};

function CustomButton(rest) {
    let {loading, className='', name, isdisabled=false, ...restProps} = rest;
    return (
        <div className='b-btn__wrapper'>
            <Button {...restProps} className={`b-btn ${className}`} disabled={getBtnState(loading, isdisabled)}>{name}</Button>
            {loading && <CircularProgress className='b-btn__loading' size={24} />}
        </div>
    );
}


export default CustomButton;