import Const from './../const';
import {getDataFromLS, setDataToLS} from './../../../utilites/index';
import configuration from '../../../configs/configs';

/*
* getLanguageByDefault {Function}
* @description - check if language supported
* @return code {String}
*
* */
let getLanguageByDefault = (supportedLanguages) => {
    let defaultLanguage = navigator.language && navigator.language.split('-')[0];
    let isDefaultLanguageSupported = false;

    supportedLanguages.forEach((language)=>{
        if(language.code === defaultLanguage) {
            isDefaultLanguageSupported = true;
        }
    });

    if(isDefaultLanguageSupported) {
        return defaultLanguage;
    }

    return supportedLanguages[0].code;

};

/*
* getCurrentLanguage {Function}
* @description - get current language (empty or data from session storage)
* @return model {GeneralModel entity}
*
* */
let getCurrentLanguage = (supportedLanguages) => {
    let localStorageData = getDataFromLS('language');
    let defaultLanguage = getLanguageByDefault(supportedLanguages);
    let language;

    if (localStorageData) {
        language = localStorageData;
    } else {
        language = defaultLanguage;
        setDataToLS('language', language);
    }

    return language;
};

const defaultState = {
    translation: {

    },
    supportedLanguages : configuration.supportedLanguages,
    isTranslationLoading: false,
    wasGeneralTranslationLoaded: undefined
};

defaultState.currentLanguage = getCurrentLanguage(defaultState.supportedLanguages);

export default function (state = defaultState, action = {}) {
    let {type, data} = action;
    let {requestStatus} = data && data.toJSONView ? data.toJSONView() : '';

    switch (type) {
        case Const.LOCALIZATION_GET_LOCALIZATION_REQUEST_START:
            return {
                ...state,
                isTranslationLoading: true
            };
        case Const.LOCALIZATION_GET_LOGIN_LOCALIZATION_REQUEST:
            return {
                ...state,
                currentLanguage: getCurrentLanguage(state.supportedLanguages),
                translation: action.data.data,
                isTranslationLoading: false
            };
        case Const.LOCALIZATION_GET_LOCALIZATION_REQUEST:
            return {
                ...state,
                currentLanguage: getCurrentLanguage(state.supportedLanguages),
                translation: {
                    ...state.translation,
                    ...(data ? data.toJSONView() : {})
                },
                isTranslationLoading: false,
                wasGeneralTranslationLoaded: requestStatus === 'error' ? false : true
            };
        default:
            return state;
    }
}