import Const from './../const';
import {getDataFromSS} from "./../../../utilites";

/**
* getShopId {Function}
* @describe - application need to use shpoId parameter for localization. This method is getter for shopId.
* @return shopId {Int}
*
* */
let getShopId = () => {
    let userSessionData = getDataFromSS('login');
    return userSessionData ? userSessionData.shopId : undefined
};

/**
* requestPropsStart {Function}
* @return Object in React Action format before request start
*
* */
let requestPropsStart = () =>{
    return {
        type: Const.SHOP_PROPERTIES_GET_PROPERTIES_REQUEST_START
    }
};

/**
* getShopProperties {React Action}
* @description - send request to get shop properties
*
* */
export function getMainProperties(requestData) {
    return dispatch => {
        dispatch(requestPropsStart());
        dispatch({
            type: Const.SHOP_PROPERTIES_GET_PROPERTIES_REQUEST,
            ...requestData,
            requestData: {
                ...requestData.requestData,
                shopId: getShopId()
            }
        });
    }
}

/**
* getShopProperties {React Action}
* @description - send request to get shop properties
*
* */
export function getSubShops(requestData) {
    return dispatch => {
        dispatch(requestPropsStart());
        dispatch({
            type: Const.SHOP_PROPERTIES_GET_SUBSHOPS_REQUEST,
            ...requestData,
            requestData: {
                ...requestData.requestData,
            }
        });
    }
}

export function getReturnReasons(requestData) {
    return dispatch => {
        dispatch(requestPropsStart());
        dispatch({
            type: Const.GET_RETURN_REASONS_REQUEST,
            ...requestData
        });
    }
}