import GeneralModel from "./../../../models/GeneralModel";
import serverSuccessNotifierAction from "./../../customActions/serverSuccessAction/serverSuccessNotifierAction";
import {isObject, isArray, isBoolean, isString} from 'lodash';

/*
* defaultAction {Function}
* @description - function is passing action with data from server's response to redux state
*
* */
export default (response, next, action) => {
    let {isResponseJSON=true} = action;
    let nextPushData;

    if(isResponseJSON) {
        if(isObject(response.data)) {
            nextPushData = new GeneralModel({
                ...response.data,
                requestData: action.requestData,
                requestStatus: 'success'
            })
        }

        if(isArray(response.data) || isBoolean(response.data) || isString(response.data)) {
            nextPushData = new GeneralModel({
                response: response.data,
                requestData: action.requestData,
                requestStatus: 'success'
            })
        }

    }

    if(!isResponseJSON) {
        nextPushData = {
            response: response.data,
            requestData: action.requestData,
            requestStatus: 'success'
        }
    }

    next({
        ...action,
        data: nextPushData
    });



    // function is passing action with success response from server to redux state
    serverSuccessNotifierAction(response, next, action);
}
