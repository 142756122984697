import Const from "./../const";


/**
 * setReduceProductValue {Function}
 * @description - fire an event to set an appropriate data to reducedValues in current state
 *
 * */
export function setReduceProductValue(value, productId, returnOrderId, defaultProductPrice, percentageValue, diminishedPrice) {
    return dispatch => {
        dispatch({
            type: Const.FIXED_REDUCED_PERCENTAGE_REDUCE_VALUE,
            payload: {
                productId,
                value,
                returnOrderId,
                defaultProductPrice,
                percentageValue,
                diminishedPrice
            }
        });
    }
}

/**
 * sendUpdatedProductValues {Function}
 * @description - fire an event with updated reducedValues
 *
 * */
export function sendUpdatedProductValues(updatedValues) {
    return dispatch => {
        dispatch({
            type: Const.FIXED_REDUCED_PERCENTAGE_REDUCE_VALUE_UPDATE,
            payload: {
                updatedValues
            }
        });
    }
}


/**
 * clearReducedData {Function}
 * @description - clear reducedValues un current state
 *
 * */
export function clearReducedData() {
    return dispatch => {
        dispatch({
            type: Const.FIXED_REDUCED_PERCENTAGE_REDUCE_VALUE_CLEAR,
        });
    }
}

/**
 * setReducedValuesToDefault {Function}
 * @description - set to default
 *
 * */
export function setReducedValuesToDefault(productId, returnOrderId, defaultProductPrice, diminishedPrice) {
    return dispatch => {
        dispatch({
            type: Const.FIXED_REDUCED_PERCENTAGE_REDUCE_VALUE_SET_TO_DEFAULT,
            payload: {
                productId, returnOrderId, defaultProductPrice, diminishedPrice
            }
        });
    }
}