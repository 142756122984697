import jwt from 'jsonwebtoken';
import _, {filter, isString, isNumber, isObject, isArray, sortBy, find, isEmpty} from 'lodash';
import MobileDetect from 'mobile-detect';
import moment from 'moment';
import CustomTranslation from "../components/CustomTranslation/CustomTranslation";
import React from "react";
import GeneralModel from "../models/GeneralModel";

const md = new MobileDetect(window.navigator.userAgent);
const merchantsBrands = {
    'ROYAL_DESIGN': 'ROYAL_DESIGN',
    'BJORN_BORG': 'BJORNBORG',
};

/**
 * getParsedJWT {Function}
 * @description - parsed data in  JWT format
 * @return {Object} parsed data in JSON format
 * */
export function getParsedJWT(jwtData) {
    let parsedData = jwt.decode(jwtData, {complete: true});

    if (parsedData) {
        return {
            ...parsedData.payload
        }
    } else {
        return {};
    }

}

/**
 * setDataToSS {Function}
 * @description - set data to Session Storage
 *
 * */
export function setDataToSS(key, data) {
    let dataForSaving = _.isString(data) ? data : JSON.stringify(data);

    sessionStorage.setItem(key, dataForSaving);
}

/**
 * getDataFromSS {Function}
 * @description - get data from Session Storage
 * @return Data {Object} or Null;
 *
 * */
export function getDataFromSS(key) {
    let data = sessionStorage.getItem(key);

    if (data) {
        try {
            return JSON.parse(data)
        } catch (error) {
            return data;
        }
    }

    return null;
}

/**
 * getBooleanShopProperty {Function}
 * @description - get value by setting name. Setting should be boolean.
 * @return {Boolean}
 *
 * */
export function getBooleanShopProperty(props, name) {
    let cachedShopProperties = getDataFromSS('mainProps');
    let shopProperties = cachedShopProperties ? cachedShopProperties : props.mainConfig ? props.mainConfig.mainSettings.getField('response') : {};

    let isSettingsPageEnabled = filter(shopProperties, (property) => {
        return property.name === name;
    });

    if (isSettingsPageEnabled && isSettingsPageEnabled.length) {
        return isSettingsPageEnabled[0].value === 'true';
    }

    return false;
}

/**
 * setDataToLS {Function}
 * @description - set data to Local Storage
 *
 * */
export function setDataToLS(key, data) {
    let dataForSaving = _.isString(data) ? data : JSON.stringify(data);

    localStorage.setItem(key, dataForSaving);
}

/**
 * getDataFromLS {Function}
 * @description - get data from Local Storage
 * @return Data {Object} or Null;
 *
 * */
export function getDataFromLS(key) {
    let data = localStorage.getItem(key);

    if (data) {
        try {
            return JSON.parse(data)
        } catch (error) {
            return data;
        }
    } else {

    }

    return null;
}

/**
 * toFixedCurrency {Function}
 * @description - format field in "toFixed" + Currency view
 *
 * */
export function toFixedCurrency(field = 0, currency = '', amountAfterDot = 2, isNegativeValueAccepted = true) {
    let validField = parseFloat(field);
    if (isNegativeValueAccepted) {
        return `${validField.toFixed(amountAfterDot)} ${currency}`
    } else {
        return `${validField > 0 ? validField.toFixed(amountAfterDot) : (0).toFixed(amountAfterDot)} ${currency}`
    }

}

/**
 * switchCurrentLanguage {Function}
 * @description - detect current language from local storage and change it to another
 *
 * */
export function switchCurrentLanguage(lang) {
    setDataToLS('language', lang);
}

/**
 * isMobileState {Function}
 * @description - detect is mobile state
 *
 * */
export function isMobileState() {
    return md.mobile();
}

/**
 * formatDate {Function}
 * @description - format Date
 *
 * */
export function formatDate(dateStr, mask = 'DD/MM/YYYY HH:mm') {
    let date;
    if (isString(dateStr)) {
        date = moment(dateStr).format(mask)
    }

    if (isNumber(dateStr) && dateStr.toString().length === 10) {
        date = moment.unix(dateStr).format(mask)
    }

    return date;
}

/**
 * transformShippingAddress {Function}
 * @description - format address data
 *
 * */
export function transformShippingAddress(str) {
    if (!_.isString(str)) {
        return str;
    }

    let data = str.split(';'),
        address = null,
        country = null,
        city = null,
        postalCode = null;

    let matchBracketsData = /\[(.*?)\]/gi;
    let matchBrackets = /[\[\]]/g;
    let matchBracketsDataFormat = /\[(.*?)\](;?)/gi;

    if (data.length === 4) {
        address = data[2] && data[2].replace(matchBrackets, '');
        country = data[0];
        city = data[1];
        postalCode = data[3];
    } else {
        address = str.match(matchBracketsData) && str.match(matchBracketsData)[0] &&
            str.match(matchBracketsData)[0].replace(matchBrackets, '');

        if (!address) {
            return str;
        }

        data = str.replace(matchBracketsDataFormat, '').split(';');
        country = data[0];
        city = data[1];
        postalCode = data[2];
    }

    address = address ? address : '';
    country = country ? ', ' + country : '';
    city = city ? ', ' + city.charAt(0).toUpperCase() + city.substr(1).toLowerCase() : '';
    postalCode = postalCode ? ', ' + postalCode : '';

    return address + postalCode + city + country;
}

/**
 * removejscCssfile {Function}
 * @description - remove css or js file specify by name in parameters
 *
 * */
export function removejscCssfile(filename, filetype) {
    let targetElement = (filetype === "js") ? "script" : (filetype === "css") ? "link" : "none";
    let targetattr = (filetype === "js") ? "src" : (filetype === "css") ? "href" : "none";
    let allsuspects = document.getElementsByTagName(targetElement)
    for (let i = allsuspects.length; i >= 0; i--) {
        if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) !== null && allsuspects[i].getAttribute(targetattr).indexOf(filename) !== -1) {
            allsuspects[i].parentNode.removeChild(allsuspects[i])
        }
    }
}

/**
 * getMarketLabel {Function}
 * @description - need to format market's label in some cases
 * @return {String}
 *
 * */
export function getMarketLabel(label) {
    let newLabel = '';

    switch (label) {
        case 'Royal Design':
            newLabel = 'RD';
            break;
        default:
            newLabel = label;
            break;
    }

    return newLabel
}

/**
 * isMarket {Function}
 * @description - define is current market equal to the passed through parameter
 * @return {Boolean}
 *
 * */
export function isMarket(marketName, settings, type='shop.type') {
    let isMarket = false;
    let {mainSettings = {}} = settings;
    let shopType;

    if (mainSettings && mainSettings.toJSONView) {
        let mainSets = mainSettings.toJSONView();
        if (mainSets.response && mainSets.response.length) {
            shopType = find(mainSets.response, (setting) => {
                return setting.name === type
            });

            if (shopType && shopType.value === marketName) {
                isMarket = true;
            }

        }
    }

    return isMarket
}

/**
 * getAppropriateMarket {Function}
 * @description - get appropriate market
 * @return {String}
 *
 * */
export function getAppropriateMarket(mainProperties, shopId, isNeedToShowMarket, isNeedToShowMarketLanguage) {
    let marketTitle;
    let subShops = mainProperties.subShops && mainProperties.subShops.toJSONView() && mainProperties.subShops.toJSONView().response;
    let label = [];
    let subShop = (filter(subShops, (subshop) => {
        return shopId === subshop.shopId
    }));

    if (subShop && subShop.length) {
        marketTitle = filter(mainProperties.countries, (country) => {
            return country.code === subShop[0].country
        });
    }

    if (subShop && subShop.length && marketTitle && marketTitle.length) {
        let currentMarket = marketTitle[0];
        let currentSubShop = subShop[0];
        if (isNeedToShowMarket) {
            label.push(getMarketLabel(currentSubShop.name));
            if (isMarket(merchantsBrands.ROYAL_DESIGN, mainProperties)) {
                label.push(currentMarket.name);
            }
        } else {
            if(isMarket(merchantsBrands.BJORN_BORG, mainProperties, 'ui.theme') && !currentMarket.name) {
                label.push(currentMarket.code)
            } else {
                label.push(currentMarket.name)
            }
        }

        if (isNeedToShowMarketLanguage && !isNeedToShowMarket) {
            label.push(currentSubShop.lang)
        }
    }

    return marketTitle && marketTitle.length ? label.join(' - ') : '-';
}

/**
 * getFiltersAppropriateMarkets {Function}
 * @description - get filters appropriate market
 * @return {Array of Objects}
 *
 * */
export function getFiltersAppropriateMarkets(mainProperties, isNeedToShowMarket, isNeedToShowMarketLanguage) {
    let subShops = mainProperties && mainProperties.subShops ? mainProperties.subShops.toJSONView().response : [];
    let countries = mainProperties && mainProperties.countries ? mainProperties.countries : [];
    let filterShopNames = [];
    let sortedShopNames = [];
    let customCountriesCodes = ['EU', 'WORLD', ''];

    subShops && subShops.length && subShops.forEach((currentSubShop, key) => {
        if(currentSubShop.shopId % 1000 !== 0 && !currentSubShop._parent_shop) {
            countries.forEach((country) => {
                if (country.code === currentSubShop.country) {
                    let label = [];

                    if (isNeedToShowMarket) {
                        if (isMarket(merchantsBrands.ROYAL_DESIGN, mainProperties)) {
                            if (customCountriesCodes.includes(currentSubShop.country) || (currentSubShop.country && currentSubShop.country.length > 2) || !currentSubShop.country) {
                                label.push(currentSubShop.name);
                            } else {
                                label.push(country.name);
                            }
                        }
                        label.push(getMarketLabel(currentSubShop.name));
                    } else {

                        if(isMarket(merchantsBrands.BJORN_BORG, mainProperties, 'ui.theme')) {
                            label.push(country.code)
                        } else {

                            if (customCountriesCodes.includes(currentSubShop.country) || (currentSubShop.country && currentSubShop.country.length > 2) || !currentSubShop.country) {
                                label.push(currentSubShop.name);
                            } else {
                                label.push(country.name);
                            }

                        }
                    }

                    if (isNeedToShowMarketLanguage && !isNeedToShowMarket) {
                        label.push(currentSubShop.lang)
                    }

                    filterShopNames.push({
                        label: label.join(' - '),
                        value: currentSubShop.shopId.toString()
                    })
                }
            });
        }
    });

    sortedShopNames = sortBy(filterShopNames, (option) => {
        return option.label
    });

    sortedShopNames.unshift({
        label: <CustomTranslation id='marketComponent.default'/>,
        value: ''
    });

    return sortedShopNames;
}


/**
 * getReturnReasons {Function}
 * @described - was written by Oksenenko. Get the reasons for reports pages from main settings
 * @return {Array}
 *
 * */
export function getReturnReasons(mainProperties) {
    let returnReasons = mainProperties && mainProperties.returnReasons ? mainProperties.returnReasons
        .map((returnReason) => ({label: returnReason, value: returnReason})) : [];
    returnReasons.unshift({label: 'ALL', value: ''});

    return returnReasons;
}

/**
 * isNeedToShowMarket
 * @description - in some cases we need to show market name instead of country.
 *                This function defines should we do this or not.
 * @return {Boolean}
 * */
export function isNeedToShowMarket(mainProperties) {
    let isNeedToShowMarket = false;
    let {mainSettings} = mainProperties;

    if (mainProperties && mainSettings && mainSettings.getField('requestStatus') === 'success') {
        let isNeedToShowMarketSetting = find(mainSettings.getField('response'), (setting) => {
            return setting.name === 'show.shop.name.as.market.name';
        });

        if (!isEmpty(isNeedToShowMarketSetting) && isNeedToShowMarketSetting.value === 'true') {
            isNeedToShowMarket = true;
        }
    }

    return isNeedToShowMarket;
}

/**
 * isNeedToShowMarketLanguage
 * @description - in some cases we no need to show language in title.
 * @return {Boolean}
 * */
export function isNeedToShowMarketLanguage(mainProperties) {
    let isNeedToShowMarket = false;
    let {mainSettings} = mainProperties;

    if (mainProperties && mainSettings && mainSettings.getField('requestStatus') === 'success') {
        let isNeedToShowMarketSetting = find(mainSettings.getField('response'), (setting) => {
            return setting.name === 'show.shop.language.in.name';
        });

        if (!isEmpty(isNeedToShowMarketSetting) && isNeedToShowMarketSetting.value === 'true') {
            isNeedToShowMarket = true;
        }
    }

    return isNeedToShowMarket;
}

/**
 * getWhatWasChanged {Function}
 * @description - compare two return items state (default and updated). Return attributes which were changed
 * @return {Object}
 * */
export function getWhatWasChanged(returnItemDefault, currentItemProducts, isInboundPage = false) {
    let defaultProducts = returnItemDefault.getField('items');
    let updatedFields = {};
    let updateFieldsTitle = [];
    let excludedKeys = 'wasStatusChanged, changedValues, wasStatusChangedByUser, isCommentsAreaExpanded, ean';

    defaultProducts && defaultProducts.length && defaultProducts.forEach((defaultProduct) => {
        let currentDefaultProductVariantId = defaultProduct.orderItem.orderItemId;
        let updatedProduct = isInboundPage ? find(currentItemProducts, (product) => {
            return product.orderItem.orderItemId === currentDefaultProductVariantId
        }) : currentItemProducts[currentDefaultProductVariantId];

        for (let key in updatedProduct) {
            if (!updatedFields[currentDefaultProductVariantId]) {
                updatedFields[currentDefaultProductVariantId] = {}
            }
            // changedValues is a custom field created to detect changes in return item's filters,
            // so we need to exclude it
            if (defaultProduct[key] !== updatedProduct[key] && excludedKeys.indexOf(key) < 0) {
                updatedFields[currentDefaultProductVariantId][key] = {
                    name: key,
                    defaultValue: defaultProduct[key],
                    updatedValue: updatedProduct[key],
                    wasChanged: true
                };
                updateFieldsTitle.push(key)
            }
        }

    });

    return {
        updatedFieldsTitle: updateFieldsTitle,
        updatedFields
    };
}

/**
 * getRefundTotal {Function}
 * @description - go through return products and return a sum of prices
 * @return {Number}
 * */
export function getRefundTotal(currentReturnItem, currentItemProducts, isInboundPage = false) {
    let defaultProducts = currentReturnItem.getField('items');
    let refundTotal = 0;

    defaultProducts && defaultProducts.length && defaultProducts.forEach((defaultProduct) => {
        let currentDefaultProductVariantId = defaultProduct.orderItem.orderItemId;
        let updatedProduct = isInboundPage ? find(currentItemProducts, (product) => {
            return product.orderItem.orderItemId === currentDefaultProductVariantId
        }) : currentItemProducts[currentDefaultProductVariantId];

        refundTotal += updatedProduct.orderItem.price;

    });

    return refundTotal;
};

/**
 * getRefundAndReconversion {Function}
 * @description - get proper format for refund minus reconversion
 * @return {String}
 * */
export function getRefundAndReconversion(refund, reconversion, currentReturnItem = null) {
    let refundDiff = refund - reconversion < 0 ? 0 : refund - reconversion;
    let returnFee = currentReturnItem.getField('returnFee') || 0;
    let shippingFee = currentReturnItem.getField('shippingFee') || 0;
    let grossShippingRefund = currentReturnItem.getField('grossShippingRefund') || 0;

    let result = ((refundDiff - returnFee - shippingFee) + grossShippingRefund).toFixed(2);

    return result;
};

/**
 * getDiminishedPrice {Function}
 * @description - get proper format for refund minus reconversion
 * @return {Number}
 * */
export function getDiminishedPrice(returnItems, currentReturnItem = null) {
    let diminishedPriceTotal = 0;
    let returnFee = currentReturnItem.getField('returnFee') || 0;
    let shippingFee = currentReturnItem.getField('shippingFee') || 0;
    let grossShippingRefund = currentReturnItem.getField('grossShippingRefund') || 0;

    if (isObject(returnItems)) {
        for (let i in returnItems) {
            diminishedPriceTotal += parseFloat(returnItems[i].diminishedPrice);
        }
    }

    return (diminishedPriceTotal - returnFee - shippingFee) + grossShippingRefund;
};

/**
 * getPureDiminishedPrice {Function}
 * @description - get proper format for refund minus reconversion
 * @return {Number}
 * */
export function getPureDiminishedPrice(returnItems) {
    let diminishedPriceTotal = 0;

    if (isArray(returnItems)) {
        returnItems.forEach((item) => {
            diminishedPriceTotal += parseFloat(item.diminishedPrice)
        });
    }

    return diminishedPriceTotal;
};

/**
 * getRoundedTwoDecimals {Function}
 * @description - get proper rounded numeric value with two decimals
 * @return {Number}
 * */
export function getRoundedTwoDecimals(numericValue, isNumericNeed=false) {
    let roundedValue = (Math.round(numericValue*100)/100).toFixed(2);
    return isNumericNeed ?  parseFloat(roundedValue) : roundedValue;
};

/**
 * getBeforeReturnUpdateTextsToConfirm {Function}
 * @description - function generate appropriate content for confirmation return's changes to be saved.
 * @return {Array}
 * */
export function getBeforeReturnUpdateTextsToConfirm(data) {
    let {
        returnItemDefault = new GeneralModel({}),
        currentReturnItem = new GeneralModel({}),
        currentItemProducts = [],
        isReturnItemTotallyHandled = () => {
        },
        isReturnsPartiallyHandled = () => {
        },
        isInbound = false,
        reducedValues = {},
        returnFeeFixed = null,
        refund
    } = data;
    let currentReturnId = currentReturnItem.getField('returnOrderId');
    let currentReducedValues = reducedValues[currentReturnId] || null;
    //let currentReturnFixedFee = isInbound ? returnFeeFixed[currentReturnId] : returnFeeFixed;
    let currency = currentReturnItem.getField('currency');
    let currentRefund = refund[currentReturnId];
    let asideCalculatedRefund = getRoundedTwoDecimals(getRefundTotal(currentReturnItem, currentItemProducts, isInbound), true);
    let reconversionOrder = returnItemDefault.getField('reconversionOrder');
    let reconversionItems = returnItemDefault.getField('reconversionItems');
    let reconversionTotal = reconversionOrder ? reconversionOrder.total : 0;
    let shippingFee = currentReturnItem.getField('shippingFee');
    let grossShippingFee = currentReturnItem.getField('grossShippingRefund');
    let returnFee = currentReturnItem.getField('returnFee');
    let actionsToConfirm = [];
    let prioritize = {
        isReturnCanceled: true,
        isNoRefundWillBeMade: false,
        isReturnValueLow: false,
        isReconversionApproved: false,
        refundApproved: false
    };
    let changedFieldsData = getWhatWasChanged(returnItemDefault, currentItemProducts, isInbound);
    let isOnlySecondaryFieldsWereChanged = true;
    let aggregatedFee = returnFeeFixed > 0 || returnFeeFixed === 0 ? returnFeeFixed : returnFee;

    //need to know was product values reduced on client side before submit or not
    let wasReducedProductValueChangedOnClient = ((currentReducedValues) => {
        let wasChangedOnClient = false;
        for (let i in currentReducedValues) {
            if (currentReducedValues[i].defaultDiminishedPrice !== currentReducedValues[i].newDiminishedPrice) {
                wasChangedOnClient = true;
            }
        }
        return wasChangedOnClient;
    })(currentReducedValues);
    // get total default prices and reduced prices values
    let pricesTotal = ((currentReducedValues, reconversionTotal, currentRefund, shippingFee, asideCalculatedRefund, grossShippingFee, aggregatedFee) => {
        let defaultPrices = 0;
        let reducedPrices = 0;
        let decreasedPrices = 0;

        for (let i in currentReducedValues) {
            defaultPrices += currentReducedValues[i].defaultProductPrice;
            reducedPrices += currentReducedValues[i].newDiminishedPrice;
            decreasedPrices += currentReducedValues[i].decreasedValue;

        }

        return {
            defaultPrices,
            reducedPrices,
            decreasedPrices,
            reconversionTotal,
            currentRefund,
            shippingFee,
            asideCalculatedRefund,
            grossShippingFee,
            aggregatedFee
        }

    })(currentReducedValues, reconversionTotal, currentRefund, shippingFee, asideCalculatedRefund, grossShippingFee, aggregatedFee);
    // we need to find should we show reconversion notify or not
    let isReconVersionVisible = getRoundedTwoDecimals(pricesTotal.asideCalculatedRefund - pricesTotal.decreasedPrices - pricesTotal.aggregatedFee - pricesTotal.shippingFee + pricesTotal.grossShippingFee, true) >= pricesTotal.reconversionTotal;

    for (let key in currentItemProducts) {
        if (currentItemProducts[key].status !== 'REMOVED') {
            prioritize.isReturnCanceled = false
        }
    }

    if (!currentReturnItem.getField('approved')) {
        if (changedFieldsData.updatedFieldsTitle.length) {
            changedFieldsData.updatedFieldsTitle.forEach((item) => {
                if (item !== 'privateComment' && item !== 'managerComment' && item !== 'flagged') {
                    isOnlySecondaryFieldsWereChanged = false;
                }
            })
        } else {
            isOnlySecondaryFieldsWereChanged = false;
        }
    } else {
        isOnlySecondaryFieldsWereChanged = false;
    }


    let getReconversionItems = (reconversionItems) => {
        let strArr = [];
        if (reconversionItems) {
            reconversionItems.forEach((rItem, key) => (
                strArr.push(`<span class="b-confirm-before-update-dialog__reconversion_item">${rItem.productVariant.name}</span>, `)
            ));
        }
        return strArr.join('')
    };

    /* start generate confirmation content */
    if (!returnItemDefault.getField('isApprovedFromTheServer') && !isOnlySecondaryFieldsWereChanged) {

        if (prioritize.isReturnCanceled) {
            actionsToConfirm.push(
                <CustomTranslation id='return.before.update.popup.action.ReturnDelete'/>
            )
        }

        if (!prioritize.isReturnCanceled) {
            if (currentReturnItem.getField('approved')) {
                if (currentRefund < 0) {
                    prioritize.isNoRefundWillBeMade = true;
                    actionsToConfirm.push(
                        <CustomTranslation id='return.before.update.popup.action.noRefundBeMade' data={{
                            refund: `<span class="b-confirm-before-update-dialog__reconversion_item">${currentRefund > 0 ? currentRefund : 0}</span>`,
                            currency: `<span class="b-confirm-before-update-dialog__reconversion_item">${currency}</span>`
                        }}/>
                    )
                }

                if (!prioritize.isNoRefundWillBeMade) {
                    if (reconversionOrder && reconversionTotal) {
                        if (asideCalculatedRefund < reconversionTotal) {
                            prioritize.isReturnValueLow = true;
                            actionsToConfirm.push(
                                <CustomTranslation id='return.before.update.popup.action.refundApproved' data={{
                                    refundTotal: `<span class="b-confirm-before-update-dialog__reconversion_item">${getRoundedTwoDecimals(getDiminishedPrice(currentItemProducts, currentReturnItem))}</span>`,
                                    currency: `<span class="b-confirm-before-update-dialog__reconversion_item">${currency}</span>`
                                }}/>
                            )
                        }
                    }

                    if (!prioritize.isReturnValueLow) {
                        if (wasReducedProductValueChangedOnClient) {
                            //console.log('was changed');
                        } else {
                            //console.log('was not changed');
                        }

                        if (currentRefund >= 0) {
                            prioritize.refundApproved = true;
                            actionsToConfirm.push(
                                <CustomTranslation id='return.before.update.popup.action.refundApproved' data={{
                                    refundTotal: `<span class="b-confirm-before-update-dialog__reconversion_item">${currentRefund > 0 ? getRoundedTwoDecimals(currentRefund) : 0}</span>`,
                                    currency: `<span class="b-confirm-before-update-dialog__reconversion_item">${currency}</span>`
                                }}/>
                            )
                        }

                        if (reconversionItems && reconversionItems.length && reconversionTotal) {
                            if (isReconVersionVisible) {
                                prioritize.isReconversionApproved = true;
                                actionsToConfirm.push(
                                    <CustomTranslation id='return.before.update.popup.action.reconversionApproved'
                                                       data={{
                                                           reconversionOrderItems: `<span class="b-confirm-before-update-dialog__reconversion_item">${getReconversionItems(reconversionItems)}</span>`,
                                                           reconversionTotal: `<span class="b-confirm-before-update-dialog__reconversion_item">${reconversionTotal}</span>`,
                                                           currency: `<span class="b-confirm-before-update-dialog__reconversion_item">${currency}</span>`
                                                       }}/>
                                )
                            }
                        }

                    }

                    if (currentReturnItem.getField('gift') > 0) {
                        actionsToConfirm.push(
                            <CustomTranslation id='return.before.update.popup.action.giftApproved' data={{
                                giftTotal: `<span class="b-confirm-before-update-dialog__reconversion_item">${currentReturnItem.getField('gift')}</span>`,
                                currency: `<span class="b-confirm-before-update-dialog__reconversion_item">${currency}</span>`
                            }}/>
                        )
                    }


                    if (!isReturnItemTotallyHandled(currentItemProducts)) {
                        if (isReturnsPartiallyHandled(currentItemProducts)) {
                            actionsToConfirm.push(
                                <CustomTranslation id='return.before.update.popup.action.partiallyHandledReturn'/>
                            )
                        }
                    }

                }

            } else {
                actionsToConfirm.push(
                    <CustomTranslation id='return.before.update.popup.description'/>
                )
            }
        }

    } else {
        actionsToConfirm.push(
            <CustomTranslation id='return.before.update.popup.description'/>
        )
    }

    if (actionsToConfirm.length === 0) {
        actionsToConfirm.push(
            <CustomTranslation id='return.before.update.popup.description'/>
        )
    }

    return actionsToConfirm;
}

/**
 * getSessionUser {Function}
 * @description - get user from session storage
 * */
export function getSessionUser() {
    return getDataFromSS('login') ? getDataFromSS('login').manager : {}
}

/**
 * isNeedToShowAttributesInReturnItemName
 * @description - in some cases we no need to show attributes in return item name.
 * @return {Boolean}
 * */
export function isNeedToShowAttributesInReturnItemName(mainProperties) {
    let isNeedToShowAttributes = false;
    let {mainSettings} = mainProperties;

    if (mainProperties && mainSettings && mainSettings.getField('requestStatus') === 'success') {
        let isNeedToShowAttr = find(mainSettings.getField('response'), (setting) => {
            return setting.name === 'show.attributes.in.variant.name';
        });

        if (!isEmpty(isNeedToShowAttr) && isNeedToShowAttr.value === 'true') {
            isNeedToShowAttributes = true;
        }
    }

    return isNeedToShowAttributes;
}

/**
 * transFormReturnItemName {Function}
 * @description - transform return's item name to the proper format
 * @return {String}
 *
 * */
export function transFormReturnItemName(returnName, returnItemAttributes, isNeedToShowAttributesInReturnItemName = false, isNeedToSetPush=false, pushValue='') {
    let transformedName = returnName;
    let attributes;
    let attributesValues = [];

    if (isNeedToShowAttributesInReturnItemName) {
        if (returnItemAttributes && isString(returnItemAttributes)) {
            attributes = JSON.parse(returnItemAttributes);
            if (isObject(attributes)) {
                for (let key in attributes) {
                    attributesValues.push(attributes[key].value)
                }
                transformedName = `${transformedName}`
            }
        }
    }

    return (
        <span className='b-return-description__reconversion_item_data'>
            <span className='b-return-description__reconversion_item_name'>{transformedName}</span>
            <span className={isNeedToSetPush ? 'b-return-description__reconversion_item_attr b-return-description__reconversion_item_attr--with_push' : 'b-return-description__reconversion_item_attr'}>{
                isNeedToSetPush
                    ?
                    <>
                        <span className='b-return-description__sublist_item_article_id_push'>{`${pushValue}. `}</span>
                        {attributesValues.join(', ')}
                    </>
                    :
                    attributesValues.join(', ')
            }</span>
        </span>
    );
}

/**
 * getTableHeight {Function}
 * @description - calculate table height depends on table data. Specific for react-table component
 * @return {String}
 *
 * */
export function getTableHeight(tableData, overTenItemHeight = '400px') {
    let tableDataArray = tableData ? tableData : [];
    return tableDataArray.length > 10 ? overTenItemHeight : tableDataArray.length ? "auto" : "140px"
}

/**
 * createTemporaryLinkElement {createTemporaryLinkElement}
 * @description - in some cases (to ave file) we need to emulate click on link to upload the file.
 *                Bu we don't need to have this link always in DOM. This function solves this issue
 *
 * */
export function createTemporaryLinkElement(fileName, blob) {
    let body = document.getElementsByTagName('body');
    let link = document.createElement('a');
    let href = window.URL.createObjectURL(blob);

    link.setAttribute('href', href);
    link.setAttribute('download', fileName);
    body[0].appendChild(link);
    link.click();
    link.remove();
}

/**
 * getSortedArray {createTemporaryLinkElement}
 * @description - sorts array by specific field (1 level)
 *
 * */
export function getSortedArray(array, fieldName) {
    return sortBy(array, (item) => {
        return item[fieldName]
    })
};


/**
 * validateEmail {Function}
 * @description - test if value fit the email pattern
 * @return {Boolean}
 * */
export function validateEmail(value) {
    let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
    return emailPattern.test(value);
}

/**
 * isCurrentManagerSuperAdmin {Function}
 * @description - return appropriate attribute form login data
 * @return {Boolean}
 * */
export function isCurrentManagerSuperAdmin() {
    let user = getDataFromSS('login') || {};

    return user && user.manager ? user.manager.admin : false;
}

/**
 * getSupportEmail {Function}
 * @description - return email
 * @return {HTML element}
 * */
export function getSupportEmail() {
    return (
        <a href='mailto:helpdesk@returnado.com'>helpdesk@returnado.com</a>
    )
};