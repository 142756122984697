import GeneralModel from './../../../models/GeneralModel';
import DefaultRequestConstObject from './../defaultRequestConst';
import Const from "./../const";
import {getChangedFilters} from "./ordersReducerMethods";


const defaultState = {
    data: new GeneralModel({}),
    isLoading: false,
    isFoldedOpen: false,
    filters: {
        shopName: '',
        search: ''
    },
    DefaultRequestConst: DefaultRequestConstObject
};


export default function (state = defaultState, action = {}) {
    let {type, data, payload} = action;
    switch (type) {
        case Const.LEFT_SIDE_BAR_IS_FOLDED_OPEN:
            return {
                ...state,
                isFoldedOpen: payload
            };
        case Const.ORDERS_START_FETCH_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Const.ORDERS_FETCH_REQUEST:
            return {
                ...state,
                data: data,
                isLoading: false
            };
        case Const.ORDERS_CHANGE_ORDER_FILTER:
            return {
                ...state,
                filters: getChangedFilters(state.filters, payload)
            };
        case Const.ORDERS_CLEAR_STATE:
            return {
                data: new GeneralModel({}),
                isLoading: false,
                isFoldedOpen: false,
                filters: {
                    shopName: '',
                    search: ''
                },
                DefaultRequestConst: DefaultRequestConstObject
            };
        default:
            return state;
    }
}
