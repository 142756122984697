const Const = {
    LOGIN_SEND_REQUEST: 'LOGIN_SEND_REQUEST',
    LOGIN_CHECK_2F: 'LOGIN_CHECK_2F',
    LOGIN_SET_TO_DEFAULT: 'LOGIN_SET_TO_DEFAULT',
    LOGIN_SEND_START_REQUEST: 'LOGIN_SEND_START_REQUEST',
    LOGIN_SET_IS_PASSWORD_VISIBLE: 'LOGIN_SET_IS_PASSWORD_VISIBLE',
    LOGIN_SET_USERNAME: 'LOGIN_SET_USERNAME',
    LOGIN_SET_PASSWORD: 'LOGIN_SET_PASSWORD',
    LOGIN_SET_2FA_CODE: 'LOGIN_SET_2FA_CODE',
    LOGIN_CLEAN_CREDENTIALS: 'LOGIN_CLEAN_CREDENTIALS',
};

export default Const;