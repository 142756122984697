import LeftSideBarConst from './../../components/LeftSideBar/const';

const Const = {
    RETURN_DATA_REPORT_START_FETCH_REQUEST: 'RETURN_DATA_REPORT_START_FETCH_REQUEST',
    RETURN_DATA_REPORT_CHANGE_FILTER: 'RETURN_DATA_REPORT_CHANGE_FILTER',
    RETURN_DATA_REPORT_CLEAR_FILTERS: 'RETURN_DATA_REPORT_CLEAR_FILTERS',
    RETURN_DATA_REPORT_CSV_FETCH: 'RETURN_DATA_REPORT_CSV_FETCH',
    RETURN_DATA_REPORT_FETCH: 'RETURN_DATA_REPORT_FETCH',
    [LeftSideBarConst.LEFT_SIDE_BAR_IS_FOLDED_OPEN]: LeftSideBarConst.LEFT_SIDE_BAR_IS_FOLDED_OPEN
};

export default Const;