import React, {Component} from 'react';
import Formsy from "formsy-react";
import SearchField from "./../CustomFields/SearchField/SearchField";
import CustomTranslation from "../CustomTranslation/CustomTranslation";
import CustomButton from "../../containers/CustomButton/CustomButton";
import CloseIcon from '@material-ui/icons/Cancel';
import BackSpaceIcon from '@material-ui/icons/Backspace';

class Search extends Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.formRef = React.createRef();
    }

    clearSearchForm=()=>{
        let { formRef = this.formRef, onClearSearchAction=()=>{} } = this.props;
        onClearSearchAction();

        if(this.formRef && this.formRef.current) {
            this.formRef.current.resetModel();
        } else {
            if(formRef && formRef.current) {
                formRef.current.resetModel();
            }
        }
    };

    render() {
        let {
            value='',
            placeholder='Search',
            onSearchFieldAction=()=>{},
            onSubmitAction=()=>{},
            onValidAction=()=>{},
            onInvalidAction=()=>{},
            isSearchButtonControlPresent=false,
            isSearchBtnDisabled=false,
            formRef=this.formRef,
            inputRef=this.inputRef,
            styleClass='',
            styleClassCleanCtr='',
            isItInbound=false
        } = this.props;

        let isClearSearchControlPresent = inputRef && inputRef.current && inputRef.current.value || this.props.isClearSearchControlPresent;

        return (
            <div className={styleClass ? `b-search ${styleClass}` : 'b-search'}>
                <Formsy
                    onValidSubmit={onSubmitAction}
                    onValid={onValidAction}
                    onInvalid={onInvalidAction}
                    autoComplete='off'
                    ref={formRef}
                >
                    {
                        isSearchButtonControlPresent
                        ?
                        <div className="b-search__item b-flex">
                            <div className="b-search__p">
                                {
                                    value
                                    ?
                                    <SearchField inputRef={inputRef} name='search' value={value} onChange={onSearchFieldAction} placeholder={placeholder} />
                                    :
                                    <SearchField inputRef={inputRef} name='search' onChange={onSearchFieldAction} placeholder={placeholder} />
                                }
                            </div>
                            <div className="b-search__p">
                                <CustomButton
                                    type='submit'
                                    variant='contained'
                                    fullWidth={true}
                                    color='primary'
                                    isdisabled={isSearchBtnDisabled}
                                    aria-label={<CustomTranslation renderAsString={true} id="search" />}
                                    name={<CustomTranslation renderAsString={true} id="search" />}
                                    className="b-search__submit"
                                />
                            </div>
                        </div>
                        :
                            value
                            ?
                            <SearchField inputRef={inputRef} name={'search'} value={value} onChange={onSearchFieldAction} placeholder={placeholder} />
                            :
                            <SearchField inputRef={inputRef} name={'search'} onChange={onSearchFieldAction} placeholder={placeholder} />
                    }
                </Formsy>
                {
                    isItInbound
                        ?
                            isClearSearchControlPresent && <BackSpaceIcon className={isSearchButtonControlPresent ?  `b-search__search_clear b-search__search_clear--with_search_control ${styleClassCleanCtr}` : `b-search__search_clear ${styleClassCleanCtr}`} onClick={()=>{this.clearSearchForm();}} />
                        :
                            isClearSearchControlPresent && <CloseIcon className={isSearchButtonControlPresent ? `b-search__search_clear b-search__search_clear--with_search_control ${styleClassCleanCtr}` : `b-search__search_clear ${styleClassCleanCtr}`} onClick={()=>{this.clearSearchForm();}} />

                }
            </div>
        );
    }
}



export default Search;