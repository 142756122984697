import Const from './../const';


/**
 * requestStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestStart = () => {
    return {
        type: Const.DASHBOARD_FETCH_REQUEST_START
    }
};

/**
 * fetchDashboard {React Action}
 * @description - send request to the server to get dashboard data
 *
 * */
export function fetchDashboard(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.DASHBOARD_FETCH,
            ...requestData
        });
    }
}

/**
 * setDashboardFilter {React Action}
 * @description - set dashboards filters
 *
 * */
export function setDashboardFilter(label, value) {
    return dispatch => {
        dispatch({
            type: Const.DASHBOARD_CHANGE_FILTER,
            payload: {
                label,
                value
            }
        });
    }
}

/**
 * setGenerateAndExportButtonsSate {React Action}
 * @description - set dashboards buttons state (generate and export as csv)
 *
 * */
export function setGenerateAndExportButtonsSate(filters) {
    return dispatch => {
        dispatch({
            type: Const.DASHBOARD_SET_DASHBOARD_BUTTONS_STATE,
            payload: {
                filters
            }
        });
    }
}

/**
 * clearFilters {React Action}
 * @description - set filters to default
 *
 * */
export function clearFilters() {
    return dispatch => {
        dispatch({
            type: Const.DASHBOARD_SET_CLEAR_FILTERS,
        });
    }
}

/**
 * fetchReturnDataReportSCV {Function}
 * @return Object in React Action format before request start
 *
 * */

export function fetchReturnDataReportSCV(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.DASHBOARD_REPORT_CSV_FETCH,
            ...requestData
        });
    }
}
