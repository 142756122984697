import Const from './../const';


const defaultState = {
    currentPageTitle: ''
};

export default function (state = defaultState, action = {}) {
    switch (action.type) {
        case Const.HEADER_SET_CURRENT_PAGE_TITLE:
            return {
                ...state,
                currentPageTitle: action.payload
            };
        default:
            return state;
    }
}
