import Const from './../const';

const defaultState = {
    wasDataCleared: false
};

export default function (state = defaultState, action = {}) {
    switch (action.type) {
        case Const.FIXED_FEE_SELECTOR_CLEAR:
            return {
                wasDataCleared: !state.wasDataCleared
            };
        default:
            return state;
    }
}
