import React, {Component} from 'react';
//import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CustomTranslation from "./../../components/CustomTranslation/CustomTranslation";
import {isArray, isFunction} from 'lodash';

// const styles = theme => {
//     return {
//         dialogBtn: {
//             width: '30%',
//             display: 'block',
//             margin: '0 auto'
//         }
//     }
// };

class ConfirmBeforeUpdateDialog extends Component {

    /**
     * handleKeyPress {Function}
     * @description - handle specific key pressing
     *
     * */
    handleKeyPress = (e, confirmAction) => {
        let {charCode} = e;

        if (charCode === 13 || charCode === 32) {
            confirmAction()
        }
    };

    /**
     * getContent {Function}
     * @description - return formatted content
     *
     * */
    getContent = (content) => {
        return (
            <span className="b-confirm-before-update-dialog__list">
                {
                    content.map((contentItem, key)=>{
                        return <span key={key} className="b-confirm-before-update-dialog__list_item">
                            {contentItem}
                        </span>
                    })
                }
            </span>
        )
    };

    render() {
        let {
            isOpen = false,
            title = '',
            maxWidth = 'sm',
            content,
            isTitleUpperCase = false,
            confirmAction = () => {},
            closeAction = () => {},
            isConfirmBtnActive = true,
            isCloseBtnActive = true,
            acceptBtnName = <CustomTranslation id='return.before.update.popup.btnName.confirm'/>,
            closeBtnName = <CustomTranslation id='return.before.update.popup.btnName.goBack' />,
        } = this.props;

        return (
            <Dialog
                fullWidth={true}
                maxWidth={maxWidth}
                open={isOpen}
                onClose={isCloseBtnActive ? closeAction : ()=>{}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onKeyPress={(e) => {
                    this.handleKeyPress(e, confirmAction)
                }}
                className="b-confirm-before-update-dialog"
            >
                {isCloseBtnActive && <CancelIcon className='b-confirm-before-update-dialog__close_ctrl' onClick={closeAction} />}
                <DialogTitle id="alert-dialog-title">
                    <span className={isTitleUpperCase ? "b-confirm-before-update-dialog__title uppercase" : "b-confirm-before-update-dialog__title"}>
                        {title}
                    </span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span className="b-confirm-before-update-dialog__description"/>
                        <span className="b-confirm-before-update-dialog__content">
                            { isArray(content) ? this.getContent(content) : isFunction(content) ? content() : content}
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='b-confirm-before-update-dialog__actions'>
                    { isCloseBtnActive && <Button onClick={closeAction} variant="contained" color="default" className="b-confirm-before-update-dialog__dialog-btn">
                        {closeBtnName}
                    </Button> }
                    { isConfirmBtnActive && <Button onClick={confirmAction} variant="contained" color="primary" className="b-confirm-before-update-dialog__dialog-btn">
                        {acceptBtnName}
                    </Button> }
                </DialogActions>
            </Dialog>
        )
    };
}


function mapStateToProps() {
    return {};
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {},
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(ConfirmBeforeUpdateDialog));