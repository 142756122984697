import Const from './../const';

/**
 * cleanComment {React Action}
 * @description - clean specific comment
 *
 * */
export function cleanComment(commentId) {
    return dispatch => {
        dispatch({
            type: Const.RETURN_COMMENTS_AREAS_CLEAN_COMMENT,
            payload: {
                commentId
            }
        });
    }
}

/**
 * commentWasChanged {React Action}
 * @description - clean specific comment
 *
 * */
export function commentWasChanged(orderItemId, state) {
    return dispatch => {
        dispatch({
            type: Const.RETURN_COMMENTS_COMMENT_WAS_CHANGED,
            payload: {
                orderItemId,
                changedState: state
            }
        });
    }
}

/**
 * setCommentsAreasStateToFalse {React Action}
 * @description - set specific comment areas state to false
 *
 * */
export function setCommentsAreasStateToFalse(ids) {
    return dispatch => {
        dispatch({
            type: Const.RETURN_COMMENTS_COMMENT_SET_TO_FALSE,
            payload: ids
        });
    }
}