import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    fetchReturnsItem,
    fetchMoreReturns,
    clearReturnsState,
    setReturnOrderFilter,
    clearCurrentReturnsItem,
    setTableCurrentPage,
    closeSendToCustomerDialog,
    hideErrorMessage,
    showBeforeSwitchReturnPopup,
    clearFixedFeeControl,
    changeSubmitState,
    setFixedReturnFeeValue
} from "./actions/returnsActions";
import {setCurrentPageTitle} from "./../../components/Header/actions/headerActions";
import PageContent from "./../../containers/PageContent/PageContent";
import CustomTranslation from "./../../components/CustomTranslation/CustomTranslation";
import {withRouter} from "react-router";
import {
    createTemporaryLinkElement, getBooleanShopProperty,
} from './../../utilites/index';
import ReturnsItem from './components/ReturnsItem';
import {find} from 'lodash';
import ConfirmUpdateDialog from './components/ConfirmUpdateDialog';
import {getTranslate} from "react-localize-redux";
import ReturnsTable from "./../../components/ReturnsTable/ReturnsTable";
import defaultRequestConst from './defaultRequestConst';
import WarningIcon from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from "../../containers/Loader/Loader";


class Returns extends Component {

    constructor(props) {
        super(props);
        this.wrapperEl = React.createRef();
        this.state = {
            expanded: true,
            currentPage: 0,
            pageSizeOption: defaultRequestConst.limit,
            searchValue: ''
        };
        this.searchTimeout = null;
    }

    /**
     * onSearchField {Function}
     * @description - callback on change search field. Does specific action
     *
     * */
    onSearchField = (event) => {
        let searchValue = event.currentTarget.value;
        let {DefaultRequestConst} = this.props;

        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = setTimeout(() => {
            if (searchValue && searchValue.length > 2) {

                this.setState({
                    searchValue: searchValue
                }, () => {
                    this.fetchReturns({
                        ...DefaultRequestConst,
                        search: searchValue
                    });
                });

            }
        }, 900);

        if (!searchValue) {
            this.setState({
                searchValue: ''
            }, () => {
                this.fetchReturns({
                    ...DefaultRequestConst,
                    search: ''
                });
            });

        }
    };

    /**
     * getStatusColor {Function}
     * @description - get appropriate color fo returns item status
     * @return {String} - color hex
     * */
    getStatusColor = (status) => {
        let {theme: {palette: {brand: {returnStatusColors}}}} = this.props;
        return returnStatusColors[status] || 'none';
    };

    /**
     * getShipmentStatusColor {Function}
     * @description - get appropriate color fo returns item shipment status
     * @return {String} - color hex
     * */
    getShipmentStatusColor = (status) => {
        let {theme: {palette: {brand: {returnShipmentStatusColors}}}} = this.props;
        return returnShipmentStatusColors[status] || 'none';
    };

    /**
     * clickOnRow {Function}
     * @description - action fires when click on specific table's row
     *
     * */
    clickOnRow = (id) => {
        let {history} = this.props;
        this.setState({
            expanded: false
        }, () => {
            this.props.clearFixedFeeControl();
            history.push('/returns/:returnId'.replace(':returnId', id));
        })
    };

    /**
     * handleTableRowClick {Function}
     * @description - function return object with action which fires when click on specific table's row
     * @return {Object}
     *
     * */
    handleTableRowClick = (state, rowInfo = {}) => {
        let {match: {params: {returnId}}, isReadyForSubmit, wasReturnItemLoaded} = this.props;
        let {row = {}} = rowInfo;
        let {_original = {}} = row;
        let {returnOrderId} = _original;
        return {
            onClick: (e) => {
                /* TODO: define were comments changed or not */
                if (isReadyForSubmit && wasReturnItemLoaded) {
                    this.props.showBeforeSwitchReturnPopup(returnOrderId);
                    this.props.clearFixedFeeControl();
                } else {
                    this.clickOnRow(returnOrderId)
                }

            },
            style: {
                background: returnOrderId === parseInt(returnId) ? '#5C616F' : '',
                color: returnOrderId === parseInt(returnId) ? 'white' : ''
            },
        }
    };

    /**
     * onChangeCurrentPageAction {Function}
     * @description - action calls on page change using table next/previous controls
     *
     * */
    onChangeCurrentPageAction = (nextCurrentPage) => {
        let {DefaultRequestConst, filters} = this.props;
        let {pageSizeOption} = this.state;
        let overWroteFilters = {};

        /* START to overwrite filters */

        /*
        * filter logic created in way to set values form filters drop downs
        * but in some cases we need to send these values like key field with the values of boolean type (server side API requirements)
        * to avoid reorganization filter handling we just over write this cases here
        * */

        if (filters.shopName) {
            DefaultRequestConst.shopId = filters.shopName
        } else {
            delete DefaultRequestConst.shopId
        }

        if (filters.remarksFilter) {
            overWroteFilters[filters.remarksFilter] = true;
        }

        if (filters.returnType === 'reconversion') {
            overWroteFilters['reconversion'] = true;
            overWroteFilters['isComplaintOrder'] = false;
        }

        if (filters.returnType === 'isComplaintOrder') {
            overWroteFilters['reconversion'] = false;
            overWroteFilters['isComplaintOrder'] = true;
        }

        /* END to overwrite filters */

        this.setState({
            currentPage: nextCurrentPage
        }, () => {
            this.fetchReturns({
                ...DefaultRequestConst,
                ...filters,
                ...overWroteFilters,
                limit: pageSizeOption,
                page: nextCurrentPage
            })
        })

    };

    /**
     * onPageSizeChange {Function}
     * @description - action calls when page size was changed using table page size dropdown
     *
     * */
    onPageSizeChange = (pageSize) => {
        let {DefaultRequestConst} = this.props;

        this.setState({
            pageSizeOption: pageSize
        }, () => {
            this.fetchReturns({
                ...DefaultRequestConst,
                limit: pageSize
            })
        });

    };

    /**
     * getRowCurrency {Function}
     * @description - take a row data as parameter and return currency attribute value
     * @return {String}
     *
     * */
    getRowCurrency = (row) => {
        return row.currency || row._original.currency;
    };

    /**
     * getShipmentStatuses {Function}
     * @description - take a row data as parameter and return currency attribute value
     * @return {Array}
     *
     * */
    getShipmentStatuses = (shipments = [], classes) => {
        return shipments.map((shipment, key) => {
            let shipmentStatus = shipment.status;
            return (
                <span key={key} className={`b-page__statuses_table_item ${classes.returnStatusStyles}`}
                      style={{backgroundColor: this.getShipmentStatusColor(shipmentStatus)}}>
                    <CustomTranslation id={`return.order.shipmentStatuses.${shipmentStatus}`}/>
                </span>
            )
        })
    };

    /**
     * getReturnId {Function}
     * @description - generate order id data based on mismatch value
     * @return {HTML elements}
     *
     * */
    getReturnId = (mismatch, returnId, mismatchDescription = '') => {
        return (
            <>
                {
                    mismatch ?
                        <span className="b-returns-page__mismatch_warning">
                    <Tooltip title={mismatchDescription} className="b-returns-page__in_table_tooltip" placement="top">
                         <WarningIcon className="b-returns-page__mismatch_warning_icon" fontSize="small"/>
                    </Tooltip>

                            {returnId}
                </span>
                        :
                        <span>{returnId}</span>
                }
            </>
        )
    };

    /**
     * clearSearch {Function}
     * @description - clearing the search field
     *
     * */
    clearSearch = () => {
        let {DefaultRequestConst} = this.props;

        this.setState({
            searchValue: ''
        }, () => {
            this.fetchReturns({
                ...DefaultRequestConst,
                search: ''
            });
        });

    };

    /**
     * downloadShipmentLabel {Function}
     * @description - handling shipment label downloading as pdf file
     *
     * */
    downloadShipmentLabel = (shipmentData, returnId) => {
        /* TODO: need to find the way how to get file name and file type from response headers */
        let reader = new FileReader();
        let fileName = `return_order_${returnId}_label.pdf`;
        let blob = new Blob([new Uint8Array(shipmentData)], {type: 'application/pdf'});

        reader.onload = () => {
            createTemporaryLinkElement(fileName, blob)
        };

        reader.readAsArrayBuffer(blob);
    };


    /**
     * fetchReturnItem {Function}
     * @description - send request to get specific order item by returnId
     *
     * */
    fetchReturnItem = () => {
        let {match: {params: {returnId}}} = this.props;
        this.props.fetchReturnsItem({
            route: 'getReturnsItem',
            requestData: {
                returnsId: returnId
            }
        });
    };

    /**
     * closeDialog {Function}
     * @description - change state to close send to customer service Dialog
     *
     * */
    closeSendToCsDialog = () => {
        this.props.closeSendToCustomerDialog()
    };

    redirectToMain = () => {
        this.props.hideErrorMessage()
    };

    componentDidMount() {
        let {match: {params: {returnId}}, pageName} = this.props;
        this.props.setCurrentPageTitle(pageName);
        if (returnId) {
            this.fetchReturnItem();
            this.setState({
                expanded: false
            })
        }
    }

    componentWillUnmount() {
        this.props.clearReturnsState()
    };

    componentDidUpdate(prevProps, prevState, snapShots) {
        let {
            match: {params: {returnId}},
            isErrorDialogShouldBeVisible,
            were3ImagesUploaded,
            reducedValues,
            data
        } = this.props;

        let {searchValue} = this.state;

        if (returnId && prevProps.match.params.returnId !== returnId) {
            this.fetchReturnItem();
        }

        if (prevProps.match.params.returnId !== returnId && !returnId) {
            this.props.clearCurrentReturnsItem()
        }

        if (prevProps.shipmentLabelData !== this.props.shipmentLabelData) {
            this.downloadShipmentLabel(this.props.shipmentLabelData.response, returnId);
        }


        if (prevProps.isErrorDialogShouldBeVisible !== this.props.isErrorDialogShouldBeVisible && isErrorDialogShouldBeVisible === false) {
            this.props.history.push('/returns');
        }

        /*TODO: ATTENTION HERE IS REQUEST AFTER UPDATE */
        if (prevProps.were3ImagesUploaded !== were3ImagesUploaded) {
            this.fetchReturnItem();
        }

        if (prevProps.reducedValues !== reducedValues) {
            this.props.changeSubmitState(reducedValues, {})
        }

        // each time when returns items were fetched after search control changed, we need to be sure that we are rendering the proper return details.
        // in case we aren't - need to fetch proper details
        if (prevProps.data !== data) {
            let returns = data && data.getField ? data.getField('data') : [];
            let isCurrentSearchValueValidId = searchValue ? find(returns, (returnItem) => {
                return returnItem.returnOrderId === parseInt(searchValue)
            }) : false;
            if (isCurrentSearchValueValidId) {
                this.clickOnRow(searchValue)
            }
        }

        if(prevProps.data !== data && data.getField('requestStatus') === 'error') {
            console.log('returns data was not loaded')
        }
    };

    render() {
        let {
            isFoldedOpen,
            currentReturnItem,
            currentReturnItemUpdateInfo,
            isDialogSendToCSActive,
            isErrorDialogShouldBeVisible,
            isSCEmailSuccess,
            mainPropertiesState,
            isReadyForSubmit,
            wasReturnItemLoaded,
            isReturnItemLoading
        } = this.props;
        let isExternalIdToShow = getBooleanShopProperty(this.props, 'use.sequential.order.external.id');
        let email = find(mainPropertiesState.mainSettings.getField('response'), (setting) => {
            return setting.name === 'shop.support.email'
        });

        return (
            <div className='b-app__page b-returns-page'>
                <ConfirmUpdateDialog
                    isOpen={isDialogSendToCSActive}
                    title={<CustomTranslation
                        id={isSCEmailSuccess ? 'login.v2.inboundPage.SendToCs.popup.title' : 'login.v2.inboundPage.SendToCs.popup.fail.title'}/>}
                    content={<CustomTranslation
                        id={isSCEmailSuccess ? 'login.v2.inboundPage.SendToCs.popup.text' : 'login.v2.inboundPage.SendToCs.popup.fail.text'}
                        data={{
                            customerSupportEmail: email ? email.value : '',
                        }}/>}
                    confirmAction={this.closeSendToCsDialog}
                />
                <PageContent isFoldedOpen={isFoldedOpen} classNameCustom='b-page-content--returns'>
                    <>
                        <div className="b-app__page_body" ref={this.wrapperEl}>
                            <div className="b-app__body_item">
                                <ReturnsTable
                                    mainPropertiesState={mainPropertiesState}
                                    isReadyForSubmit={isReadyForSubmit}
                                    wasReturnItemLoaded={wasReturnItemLoaded}
                                    currentReturnItem={currentReturnItem}
                                    isExternalIdToShow={isExternalIdToShow}
                                />
                            </div>
                            <div className="b-app__body_item">
                                {
                                    !isReturnItemLoading
                                        ?
                                        currentReturnItem && currentReturnItem.getField('requestStatus') !== 'error'
                                            ?
                                            <ReturnsItem
                                                item={currentReturnItem}
                                                updatedInfoItem={currentReturnItemUpdateInfo}
                                                {...this.props}
                                            />
                                            :
                                            currentReturnItem && currentReturnItem.getField('requestStatus') === 'error'
                                                ?
                                                <ConfirmUpdateDialog
                                                    isOpen={isErrorDialogShouldBeVisible}
                                                    title={<CustomTranslation
                                                        id='return.order.popup.restrictedAccess.title'/>}
                                                    content={<CustomTranslation
                                                        id='return.order.popup.restrictedAccess.content' data={{
                                                        customerSupportEmail: email ? email.value : '',
                                                    }}/>}
                                                    confirmAction={this.redirectToMain}
                                                />
                                                :
                                                ''
                                        :
                                        <div className="b-app__return_item_loading">
                                            <Loader/>
                                        </div>
                                }
                            </div>
                        </div>
                    </>
                </PageContent>
            </div>

        )
    };
}

function mapStateToProps(state) {
    return {
        ...state.returnsPageState,
        ...state.returnCommentsAreasState,
        ...state.fixedPercentageSelectorState,
        mainPropertiesState: state.mainPropertiesState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            fetchMoreReturns,
            fetchReturnsItem,
            clearReturnsState,
            setReturnOrderFilter,
            clearCurrentReturnsItem,
            setTableCurrentPage,
            setCurrentPageTitle,
            closeSendToCustomerDialog,
            hideErrorMessage,
            showBeforeSwitchReturnPopup,
            clearFixedFeeControl,
            changeSubmitState,
            setFixedReturnFeeValue
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Returns));