import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {setCurrentPageTitle} from "./../../components/Header/actions/headerActions";
import {
    fetchManagers,
    switchTab,
    showPopup,
    hidePopup,
    changeNewAdminData,
    addNewAdmin,
    setNewAdminDataError,
    clearCurrentAdmin,
    setErrorToAddNewUserPopup
} from "./actions/managementActions";
import PageContent from "./../../containers/PageContent/PageContent";
import {getTranslate} from "react-localize-redux";
import defaultRequestConst from './defaultRequestConst';
import ManagerPageTable from "./components/ManagerPageTable";
import ConfirmBeforeUpdateDialog from "./../../components/ConfirmBeforeUpdateDialog/ConfirmBeforeUpdateDialog";
import GeneralModel from "./../../models/GeneralModel";
import CustomTranslation from "./../../components/CustomTranslation/CustomTranslation";
import {PersonAdd} from '@material-ui/icons';
import {Redirect} from "react-router";
import {getDataFromSS, validateEmail, isCurrentManagerSuperAdmin, getBooleanShopProperty} from "../../utilites";

class Management extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: true,
            currentPage: 0,
            pageSizeOption: defaultRequestConst.limit,
            searchValue: '',
        };
    }

    /**
     * showPopup {Function}
     * @description - show add new user popup
     *
     * */
    showPopup = () => {
        this.props.showPopup()
    };

    /**
     * hidePopup {Function}
     * @description - hide add new user popup
     *
     * */
    hidePopup = () => {
        this.props.hidePopup()
    };

    /**
     * changeNewAdminData {Function}
     * @description - on change action in add new admin popup fields
     *
     * */
    changeNewAdminData = (e, fieldName) => {
        this.props.changeNewAdminData(e.target.value, fieldName);
    };

    /**
     * getPopupContent {Function}
     * @description - generate content to add new user
     * @return {HTML elements}
     *
     * */
    getPopupContent = () => {
        let {
            newAdminName,
            newAdminLastName,
            newAdminEmail,
            newAdminNameError,
            newAdminLastNameError,
            newAdminEmailError,
            wasNewAdminCreated,
            wasRequestToResetPasswordDelivered,
            wasNewAdminCreationFailed,
            translate,
            newAdminNameCurrent,
            newAdminEmailCurrent,
            isEmailAlreadyInUseError
        } = this.props;

        if (wasNewAdminCreated) {
            return (
                <span className="b-management-page__popup_content">
                    {translate("management.popup.content.newUserCreated",  {'name':newAdminNameCurrent, 'email': newAdminEmailCurrent})}
                </span>
            )
        }

        if (wasNewAdminCreationFailed) {
            return (
                <span className="b-management-page__popup_content">
                    {translate("management.popup.content.newUserCreationFailed",  {'name':newAdminNameCurrent, 'email': newAdminEmailCurrent})}
                </span>
            )
        }

        if (wasRequestToResetPasswordDelivered) {
            return (
                <span className="b-management-page__popup_content">
                    {translate("management.popup.content.resendInvite", {'email': newAdminEmailCurrent})}
                </span>
            )
        }

        return (
            <span className="b-management-page__popup_content">
                <span className="b-management-page__popup_content_item b-flex">
                    <span className="b-management-page__popup_content_control_wrapper">
                        <label className="b-management-page__popup_content_control_label b-management-page__popup_content_control_label--fixed_min_width" >
                            {translate("management.popup.user.firstName")}
                        </label>
                        <span className="b-management-page__popup_content_input_wrapper">
                            <input value={newAdminName}
                                   type="text"
                                   className={newAdminNameError ? "b-management-page__popup_content_control_input error":"b-management-page__popup_content_control_input"}
                                   placeholder={translate('management.table.column.title.name')}
                                   onChange={(e)=>{this.changeNewAdminData(e, 'newAdminName')}}
                            />
                            <span className="b-management-page__popup_content_error_text">{newAdminNameError && translate("management.popup.validation.error.required.firstName")}</span>
                        </span>
                    </span>
                    <span className="b-management-page__popup_content_control_wrapper">
                        <label className="b-management-page__popup_content_control_label" >
                            {translate("management.popup.user.lastName")}
                        </label>
                         <span className="b-management-page__popup_content_input_wrapper">
                            <input value={newAdminLastName}
                                   type="text"
                                   className={newAdminLastNameError ? "b-management-page__popup_content_control_input error":"b-management-page__popup_content_control_input"}
                                   placeholder={translate('management.popup.user.lastName')}
                                   onChange={(e)=>{this.changeNewAdminData(e, 'newAdminLastName')}}
                            />
                             <span className="b-management-page__popup_content_error_text">{newAdminLastNameError && translate("management.popup.validation.error.required.lastName")}</span>
                         </span>
                    </span>
                </span>
                <span className="b-management-page__popup_content_item b-flex">
                    <span className="b-management-page__popup_content_control_wrapper b-management-page__popup_content_control_wrapper--single b-flex">
                        <label
                            className="b-management-page__popup_content_control_label b-management-page__popup_content_control_label--single">
                            {translate("management.popup.user.email")}
                        </label>
                         <span className="b-management-page__popup_content_input_wrapper b-management-page__popup_content_input_wrapper--single">
                            <input value={newAdminEmail}
                                   type="email"
                                   className={newAdminEmailError ? "b-management-page__popup_content_control_input b-management-page__popup_content_control_input--single error" : "b-management-page__popup_content_control_input b-management-page__popup_content_control_input--single"}
                                   placeholder={translate('management.popup.user.email')}
                                   onChange={(e) => {
                                       this.changeNewAdminData(e, 'newAdminEmail')
                                   }}
                            />
                             {
                                 isEmailAlreadyInUseError
                                 ?
                                 <span className="b-management-page__popup_content_error_text">{translate("management.popup.validation.error.alreadyInUse.email")}</span>
                                 :
                                 <span className="b-management-page__popup_content_error_text">{newAdminEmailError ? !newAdminEmail ? translate("management.popup.validation.error.required.email") : translate("management.popup.validation.error.invalid.email") : ''}</span>
                             }
                         </span>
                    </span>
                </span>
                <span className="b-management-page__popup_content_description">
                    {translate("management.popup.user.instructions")}
                </span>
            </span>
        )
    };

    /**
     * getPopupTitle {Function}
     * @description - gent appropriate popup titles
     *
     * */
    getPopupTitle = () => {
        let {wasNewAdminCreated, wasRequestToResetPasswordDelivered, wasNewAdminCreationFailed, translate} = this.props;

        if(wasNewAdminCreated) {
            return translate("management.popup.title.newUserCreated");
        }

        if(wasRequestToResetPasswordDelivered) {
            return translate("management.popup.title.resendInvite");
        }

        if(wasNewAdminCreationFailed) {
            return translate("management.popup.title.newUserCreationFailed")
        }

        return translate("management.popup.title.userDetails");
    };

    /**
     * getPopupConfirmAction {Function}
     * @description - generate appropriate accept action
     *
     * */
    getPopupConfirmAction = () => {
        let {
            newAdminName,
            newAdminLastName,
            newAdminEmail,
            wasNewAdminCreated,
            wasRequestToResetPasswordDelivered,
            wasNewAdminCreationFailed,
            newAdminNameError,
            newAdminLastNameError,
            newAdminEmailError
        } = this.props;
        let loginData = getDataFromSS('login');

        if(wasNewAdminCreated) {
            return ()=>{
                this.fetchManagers();
                this.props.hidePopup();
                this.props.clearCurrentAdmin();
            }
        }

        if(wasNewAdminCreationFailed) {
            return ()=>{
                this.props.hidePopup();
                this.props.setErrorToAddNewUserPopup();
                console.log('failed')
                //this.props.clearCurrentAdmin();
            }
        }

        if(wasRequestToResetPasswordDelivered) {
            return ()=>{
                this.props.hidePopup();
                this.props.clearCurrentAdmin();
            }
        }

        return ()=>{
            if(newAdminName && newAdminLastName && newAdminEmail && validateEmail(newAdminEmail) && !newAdminNameError && !newAdminLastNameError && !newAdminEmailError) {
                this.props.addNewAdmin({
                    route: 'addNewAdmin',
                    requestData: {
                        firstName: newAdminName,
                        lastName: newAdminLastName,
                        username: newAdminEmail,
                        email: newAdminEmail,
                        shopId: loginData.shopId
                    }
                });
                this.props.hidePopup();
            } else {
                if(!newAdminName) {
                    this.props.setNewAdminDataError('newAdminName');
                }

                if(!newAdminLastName) {
                    this.props.setNewAdminDataError('newAdminLastName');
                }

                if(!newAdminEmail || !validateEmail(newAdminEmail)) {
                    this.props.setNewAdminDataError('newAdminEmail');
                }
            }
        }
    };

    /**
     * isClosePopupBtnActive {Function}
     * @description - define should we display close btn in popup window or not
     * @return {Boolean}
     *
     * */
    isClosePopupBtnActive = () => {
        let {wasNewAdminCreated, wasRequestToResetPasswordDelivered, wasNewAdminCreationFailed} = this.props;

        if(wasNewAdminCreated || wasRequestToResetPasswordDelivered || wasNewAdminCreationFailed) {
            return false;
        }

        return true;
    };

    getPopupConfirmBtnName = () => {
        let {wasNewAdminCreated, wasRequestToResetPasswordDelivered, wasNewAdminCreationFailed} = this.props;

        if(wasNewAdminCreated || wasRequestToResetPasswordDelivered || wasNewAdminCreationFailed) {
            return <CustomTranslation id='return.order.confirmation.success.popup.ok.button' />
        }

        return <CustomTranslation id='return.before.update.popup.btnName.confirm'/>
    };

    /**
     * getManagementCount {Function}
     * @description -
     * @return {Number}
     * */
    getManagementCount = (isAdmin) => {
        let {data} = this.props;
        let count = 0;

        if(data && data.getField('data')) {
            if(isAdmin) {
                data.getField('data').forEach((manager)=>{
                    if(manager.admin) {
                        count+=1;
                    }
                })
            } else {
                count = data.getField('data').length;
            }
        }

        return count;
    };

    /**
     * getTableData {Function}
     * @description - get table data based on active table tab
     *
     * */
    getTableData = (data) => {
        let {isAllTableDataShouldBeRendered=true} = this.props;
        let adminsArray = [];

        if(isAllTableDataShouldBeRendered) {
            return data;
        } else {

            if (data && data.toJSONView) {
                let currentData = data.toJSONView();

                currentData.data.forEach((manager)=>{
                    if(manager.admin) {
                        adminsArray.push(manager)
                    }
                })

            }

            return new GeneralModel({
                data: adminsArray,
                paging: {
                    total: this.getManagementCount(true)
                }
            })
        }
    };

    /**
     * fetchManagers {Function}
     * @description - get managers data
     *
     * */
    fetchManagers = (requestData=null) => {
        let dataForRequest = requestData ? requestData : {
            ...defaultRequestConst
        };
        this.props.fetchManagers({
            route: 'getManagers',
            isWithGetParams: true,
            requestData: dataForRequest
        })
    };

    componentDidMount() {
        let {pageName} = this.props;
        this.props.setCurrentPageTitle(pageName);
        this.fetchManagers();
    }

    componentDidUpdate(prevProps, prevState, snapShots) {
        let {wasNewAdminCreated, wasRequestToResetPasswordDelivered, wasAdminStatusChanged, wasNewAdminCreationFailed} = this.props;
        
        if(prevProps.wasRequestToResetPasswordDelivered !== wasRequestToResetPasswordDelivered && wasRequestToResetPasswordDelivered) {
            this.showPopup()
        }

        if(prevProps.wasNewAdminCreated !== wasNewAdminCreated && wasNewAdminCreated) {
            this.showPopup()
        }

        if(prevProps.wasNewAdminCreationFailed !== wasNewAdminCreationFailed && wasNewAdminCreationFailed) {
            this.showPopup()
        }

        if(prevProps.wasNewAdminCreationFailed !== wasNewAdminCreationFailed && prevProps.wasNewAdminCreationFailed) {
            this.showPopup()
        }

        if(prevProps.wasAdminStatusChanged !== wasAdminStatusChanged && wasAdminStatusChanged) {
            this.fetchManagers();
            this.props.hidePopup();
        }
    };

    render() {
        let {isFoldedOpen, data, isLoading, mainPropertiesState, translate, isAllTableDataShouldBeRendered, isPopupVisible} = this.props;
        let tableData = this.getTableData(data);

        return (
            isCurrentManagerSuperAdmin() && getBooleanShopProperty(this.props, 'management.page.enabled')
            ?
            <div className='b-app__page b-management-page'>
                <ConfirmBeforeUpdateDialog
                    isOpen={isPopupVisible}
                    title={this.getPopupTitle()}
                    content={this.getPopupContent()}
                    closeAction={()=>{
                        this.hidePopup();
                    }}
                    isTitleUpperCase={true}
                    confirmAction={this.getPopupConfirmAction()}
                    isCloseBtnActive={this.isClosePopupBtnActive()}
                    acceptBtnName={this.getPopupConfirmBtnName()}
                    closeBtnName={<CustomTranslation id='return.before.update.popup.btnName.goBack'/>}
                />
                <PageContent isFoldedOpen={isFoldedOpen} classNameCustom='b-page-content--management'>
                    <div className="b-app__page_body">
                        <div className="b-app__body_item">
                            <div className="b-app__page_controls b-flex b-returns-page__controls b-flex">
                                <div className="b-management-page__subtitle">
                                    <CustomTranslation id="management.page.subtitle.userManagement"/>
                                </div>
                                <div className="b-management-page__add_user_control" onClick={this.showPopup}>
                                    <PersonAdd fontSize="small" className="b-management-page__add_user_control_icon" /><CustomTranslation id="management.page.subtitle.control.title.addNewUser"/>
                                </div>
                            </div>
                        </div>
                        <div className="b-app__body_item">
                            <div className="b-management-page__tabs b-flex">
                                <div className={isAllTableDataShouldBeRendered ? "b-management-page__tabs_item active": "b-management-page__tabs_item"} onClick={()=>{this.props.switchTab(true)}}>
                                    <CustomTranslation id="management.tabs.title.all"/>{` (${this.getManagementCount()})`}
                                </div>
                                <div className="b-management-page__tabs_item b-management-page__tabs_item--middle">|</div>
                                <div className={!isAllTableDataShouldBeRendered ? "b-management-page__tabs_item active": "b-management-page__tabs_item"}  onClick={()=>{this.props.switchTab(false)}}>
                                    <CustomTranslation id="management.tabs.title.administrator"/>{` (${this.getManagementCount(true)})`}
                                </div>
                            </div>
                            <div className="b-app__table_wrapper management-table">
                                <ManagerPageTable
                                    data={tableData}
                                    isLoading={isLoading}
                                    mainPropertiesState={mainPropertiesState}
                                    translate={translate}
                                    DefaultRequestConst={defaultRequestConst}
                                />
                            </div>
                        </div>
                    </div>
                </PageContent>
            </div>
            :
            <Redirect to="/"/>
        )
    };
}

function mapStateToProps(state) {
    return {
        ...state.managementPageState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            setCurrentPageTitle,
            fetchManagers,
            switchTab,
            showPopup,
            hidePopup,
            changeNewAdminData,
            addNewAdmin,
            setNewAdminDataError,
            clearCurrentAdmin,
            setErrorToAddNewUserPopup
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(Management);