/**
 * setFiltersViaMetrics {Function}
 * @description - update return filters received from metrics component and replace null to empty string
 * @return {Object} filters
 *
 * */
export function setFiltersViaMetrics(metricsFilters) {
    let filters = {};
    let flaggedItemFilterKey = 'itemFlagged';
    let mismatchedItemFilterKey = 'mismatch';
    let remarksFilterKey = 'remarksFilter';

    for(let key in metricsFilters) {
        if(metricsFilters[key] === null) {
            filters[key] = ''
        } else {

            if(key === flaggedItemFilterKey && metricsFilters[key]) {
                filters[remarksFilterKey] = flaggedItemFilterKey;
            }

            if (key === mismatchedItemFilterKey && metricsFilters[key]) {
                filters[remarksFilterKey] = mismatchedItemFilterKey;
            }

            if (key !== flaggedItemFilterKey && key !== mismatchedItemFilterKey) {
                filters[key] = metricsFilters[key]
            }

            if(key === flaggedItemFilterKey && metricsFilters[key] === false) {
                filters[remarksFilterKey] = '';
            }
        }
    }


    return filters;
}

/**
 * getChangedFilters {Function}
 * @description - change filters
 * @return {Object} updatedFilters
 *
 * */
export let getChangedFilters = (filters, payload) => {
    let {label, value} = payload;
    let labelArr = label.split('.');
    let filterName = labelArr[labelArr.length-1];

    return {
        ...filters,
        [filterName]: value
    }
};