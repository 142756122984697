import React from 'react';
import TextFieldFormsy from '../../FormsyBasedFields/TextFieldFormsy';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

function ResetPasswordFields({isPasswordVisible=false, setIsPasswordVisible=()=>{}, TranslateComponent}) {
    return (
        <div className='b-reset-password__item'>
            <div className='b-reset-password__p'>
                <TextFieldFormsy
                    type={isPasswordVisible ? 'text' : 'password'}
                    name='password'
                    label={<TranslateComponent id='login.restore.password.input.placeholder.text' />}
                    fullWidth={true}
                    variant='outlined'
                    validations={{minLength:8, isCustomEmpty: (values, value)=>{return value && value.length > 0 ? true : false;}}}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='Toggle password visibility'
                                    onClick={()=>{setIsPasswordVisible(!isPasswordVisible)}}
                                >
                                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className='b-reset-password__p'>
                <TextFieldFormsy
                    type={isPasswordVisible ? 'text' : 'password'}
                    name='confirm_password'
                    label={<TranslateComponent id='reset.form.confirmPassword' />}
                    fullWidth={true}
                    validations={{minLength:8, equalsField:'password', isCustomEmpty: (values, value)=>{return value && value.length > 0 ? true : false;}}}
                    validationErrors={{
                        equalsField: <TranslateComponent id='reset.form.required.confirmPassword' />
                    }}
                    variant='outlined'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='Toggle password visibility'
                                    onClick={()=>{setIsPasswordVisible(!isPasswordVisible)}}
                                >
                                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        </div>
    )
}

export default ResetPasswordFields;