import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Translate, getTranslate } from 'react-localize-redux';
import CircularProgress from '@material-ui/core/CircularProgress';


class CustomTranslation extends Component {

    render() {
        let {
            isTranslationLoading,
            renderAsString=false,
            translate,
            replaceAction=(translateString)=>{
                return translateString;
            },
            ...rest
        } = this.props;

        if(renderAsString || rest.id === 'search') {
           return replaceAction(translate(rest.id))
        } else {
            return (
                isTranslationLoading
                ?
                <CircularProgress color="secondary" className="b-translation__loader" />
                :
                <Translate {...rest} />
            )
        }

    };
}

function mapStateToProps(state) {
    return {
        ...state.localizationState,
        translate: getTranslate(state.localize)
    };
}

export default connect(mapStateToProps, null)(CustomTranslation);