import Const from "./../const";
import {isObject} from "lodash";
import moment from "moment";

let startDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
let endDate =  moment(new Date()).format('YYYY-MM-DD');
let baseCurrency = 'SEK';

const defaultState = {
    ratesCards: [],
    valuesCards: [],
    itemConditions: [],
    itemNextActions: [],
    csvData: null,
    charts: {
        performance: [],
        returnReasons: [],
        productConditions: [],
        nextActions: [],
        highestValueGenerated: [],
        lowestValueGenerated: [],
        returnRate: [],
        reconversionRate: []
    },
    data: [],
    filters: {
        startDate: startDate,
        endDate: endDate,
        status: '',
        shipmentStatus: '',
        returnType: '',
        shopName: '',
        creationType: '',
        remarksFilter: '',
        reason: '',
        condition: '',
        nextAction: '',
        baseCurrency: baseCurrency
    },
    isReadyToGenerateOrExport: false
};

/**
 * getWithIndexField {Function}
 * @description - go through data for dashboard table and add 'index' field
 * @return {Array} newData
 *
 * */
let getWithIndexField = (data) => {
    let newData = [];
    if(data && data.length) {
        data.forEach((dataItem, key) => {
            newData.push({
                ...dataItem,
                index: dataItem.index ? dataItem.index : key + 1
            })
        });
    }
    return newData;
};

/**
 * getChangedFilters {Function}
 * @description - change filters
 * @return {Object} updatedFilters
 *
 * */
let getChangedFilters = (filters, payload) => {
    let {label, value} = payload;
    let labelArr = label.split('.');
    let filterName = labelArr[labelArr.length-1];

    // this is necessary to handle date range filter
    if(isObject(value) && value.type) {
        let filterValue = value[value.type];
        return {
            ...filters,
            [value.type]: filterValue ? filterValue.format('YYYY-MM-DD') : ''
        }
    // this is necessary to handle all other filter
    } else {
        return {
            ...filters,
            [filterName]: value
        }
    }


};

/**
 * getButtonState {Function}
 * @description - compare filter object from default State and changed state
 * @return {Boolean}
 * */
let getButtonState = (defaultStateFilters, payloadFilters) => {
    return false;/*JSON.stringify(defaultStateFilters) === JSON.stringify(payloadFilters)*/
};


export default function (state = defaultState, action = {}) {
    let {type, payload={}, data} = action;
    switch (type) {
        case Const.DASHBOARD_FETCH_REQUEST_START:
            return {
                ...state,
                isLoading: true
            };
        case Const.DASHBOARD_FETCH:
            let responseData = data && data.toJSONView ? data.toJSONView() : {};
            let {charts={},ratesCards=[],valuesCards=[], itemConditions=[], itemNextActions=[]} = responseData;
            return {
                ...state,
                data: getWithIndexField(responseData.data),
                ratesCards: ratesCards,
                valuesCards: valuesCards,
                charts: charts,
                itemConditions: itemConditions,
                itemNextActions: itemNextActions,
                isLoading: false
            };
        case Const.DASHBOARD_CHANGE_FILTER:
            return {
                ...state,
                filters: getChangedFilters(state.filters, payload)
            };
        case Const.DASHBOARD_SET_DASHBOARD_BUTTONS_STATE:
            return {
                ...state,
                isReadyToGenerateOrExport: getButtonState(defaultState.filters, payload.filters)
            };
        case Const.DASHBOARD_SET_CLEAR_FILTERS:
            return {
                ...state,
                filters: {
                    startDate: startDate,
                    endDate: endDate,
                    status: '',
                    shipmentStatus: '',
                    returnType: '',
                    shopName: '',
                    creationType: '',
                    remarksFilter: '',
                    reason: '',
                    condition: '',
                    nextAction: '',
                    baseCurrency: baseCurrency
                }
            };
        case Const.DASHBOARD_REPORT_CSV_FETCH:
            return {
                ...state,
                csvData: data,
                isLoading: false
            };
        default:
            return state;
    }
}
