import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    AppBar,
    IconButton,
    Tooltip,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Button,
    Grid
} from "@material-ui/core";
import {
    toggleCommentArea,
    changeItemState,
    toggleApproveRefundExchange,
    changeProductFilters,
    setDefaultFilters,
    getShipmentLabel,
    updateReturnOrder,
    sendNoteToCustomer,
    preApprove,
    fetchReturnsItem,
    toggleFlaggedState,
    showBeforeConfirmUpdateOrder,
    closeBeforeConfirmUpdateOrder,
    closeBeforeSwitchReturnPopup,
    setDescriptionRefund,
    clearReducedValuesObject
} from './../actions/returnsActions';
import {
    setCommentsAreasStateToFalse
} from './../../../components/ReturnCommentsAreas/actions/returnCommentsAreasActions';
//import CropSquareIcon from "@material-ui/icons/CropDin";
import {Delete, Flag as FlagIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Error} from "@material-ui/icons";
import {withRouter} from "react-router";
import {
    toFixedCurrency,
    getDataFromSS,
    getBeforeReturnUpdateTextsToConfirm,
    transFormReturnItemName,
    isNeedToShowAttributesInReturnItemName,
    getDiminishedPrice
} from "./../../../utilites";
import {setReduceProductValue} from './../../../components/FixedPercentageSelector/actions/fixedPercentageSelectorReducerActions';
import CustomTranslation from "./../../../components/CustomTranslation/CustomTranslation";
import PageFiltersItems from "./../../../components/PageFiltersItems/PageFiltersItems";
import ImageZoom from 'react-medium-image-zoom';
import Loader from './../../../containers/Loader/Loader';
import configuration from '../../../configs/configs';
import {isArray, isObject, find} from 'lodash';
import ReturnDescription from "./../../../components/ReturnDescription/ReturnDescription";
import CustomButton from "./../../../containers/CustomButton/CustomButton";
import PartialApproval from "../../../containers/PartialApproval/PartialApproval";
import CustomDialog from './../../../components/CustomDialog/CustomDialog';
import ConfirmBeforeUpdateDialog from "./../../../components/ConfirmBeforeUpdateDialog/ConfirmBeforeUpdateDialog";
import ReturnCommentsAreas from "./../../../components/ReturnCommentsAreas/ReturnCommentsAreas";
import SendToCustomerServiceControl from "./../../../containers/SendToCustomeServiceControl/SendToCustomeServiceControl";
import detailsIcons from "./../../../images/returns/return-details-icons.png";
import {clearData} from './../../../components/FixedFeeSelector/actions/fixedFeeSelectorActions';
import {fetchReturns} from "./../../../components/ReturnsTable/actions/returnsTableActions";
import DropZone from "./../../../components/DropZone/DropZone";
import ReclamationImagesGallery from "../../../components/ReclamationImagesGallery/ReclamationImagesGallery";
import {Warning as WarningIcon} from "@material-ui/icons";
import ReturnEventLogPopup from "../../../components/ReturnEventLogPopup/ReturnEventLogPopup";

const {PENDING, REJECTED, REMOVED, ARRIVED, PROCESSED} = configuration.states;
const spanStyle = {
    backgroundImage: 'url(' + detailsIcons + ')',
};

const styles = theme => {
    return {}
};

class ReturnsItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            isReturnUpdate: false
        };
    }

    /**
     * fetchReturnItem {Function}
     * @description - send request to get specific order item by returnId
     *
     * */
    fetchReturnItem = () => {
        let {item} = this.props;
        this.props.fetchReturnsItem({
            route: 'getReturnsItem',
            requestData: {
                returnsId: item.getField('returnOrderId')
            }
        });
    };

    /**
     * getDefaultValues {Function}
     * @description - functionality to define default values for filters controls for each product
     * @return {Object} - where object KEY is index of the control. Value - is value for appropriate control
     * */
    getDefaultValues = (product) => {
        return {
            0: product.condition || '',
            1: product.nextAction || '',
            2: product.diminishedPrice || 0
        }
    };

    /**
     * getDefaultSettings {Function}
     * @description - functionality to define default settings for filters controls for each product
     * @return {Object}
     * */
    getDefaultSettings = (product) => {
        let {orderItem} = product;
        return {
            2: {
                max: orderItem.price,
                step: '0.01'
            }
        }
    };

    /**
     * getDisabledStates {Function}
     * @description - functionality to define disabled/enabled state for filters controls for each product
     * @return {Object} - where object KEY is index of the control. Value - is boolean for enabled/disabled state
     * */
    getDisabledStates = (currentState) => {
        let {item} = this.props;
        let disabledStates = {
            0: true,
            1: true,
            2: true
        };

        if (item.getField('isApprovedFromTheServer')) {
            return disabledStates;
        }

        switch (currentState.status) {
            case ARRIVED:
            case PENDING:
                return {
                    0: false,
                    1: false,
                    2: false
                };
            case REJECTED:
                return {
                    0: false,
                    1: false,
                    2: true
                };
            case REMOVED:
            default:
                return disabledStates;
        }


    };

    getErrors = () => {
        let {errors} = this.state;
        let errorsObj = {};

        if (errors['diminishedPrice']) {
            errorsObj[2] = <CustomTranslation id='return.order.details.input.error.message'/>
        }

        return errorsObj;
    };

    /**
     * getStateClass {Function}
     * @description - according to states return appropriate class for Styling
     *
     * */
    getStateClass = (state) => {
        switch (state) {
            case PENDING:
                return 'b-pending-state-bg-color';
            case ARRIVED:
                return 'b-arrived-state-bg-color';
            case REJECTED:
                return 'b-reject-state-bg-color';
            case REMOVED:
                return 'b-removed-state-bg-color';
            default:
                return 'b-pending-state-bg-color';
        }

    };

    /**
     * getCurrentShopType {Function}
     * @description - return type of the current shop
     * @return {String or Null}
     *
     * */
    getCurrentShopType = () => {
        let {mainPropertiesState} = this.props;
        let mainSettings = mainPropertiesState.mainSettings && mainPropertiesState.mainSettings.getField ? mainPropertiesState.mainSettings.getField('response') : null;
        let shopType = null;

        if(mainSettings) {
           let shopTypeObject = find(mainSettings, (setting) => { return setting.name === "shop.type"});

            if(shopTypeObject) {
                shopType = shopTypeObject.value
            }
        }

        return shopType;
    };

    /**
     * changeItemState {Function}
     * @description - change item status to one from RECEIVED/REJECT/REMOVE
     *
     * */
    changeItemState = (id, state, currentState) => {
        let currentShopType = this.getCurrentShopType();
        this.props.changeItemState(id, state, currentShopType, currentState);
    };


    /**
     * isBtnActive {Function}
     * @description - define in one of the product buttons (RECEIVED/REJECT/REMOVE) active {highlighted}
     * @return Boolean
     *
     * */
    isBtnActive = (state, btnType) => {
        return state.status === btnType;
    };


    /**
     * isBtnDisabled {Function}
     * @description - define in one of the product buttons (RECEIVED/REJECT/REMOVE) disabled
     * @return Boolean
     *
     * */
    isBtnDisabled = (state, btnType, returnItem) => {
        let isBtnDisabled = false;
        if (this.isBtnActive(state, btnType)) {
            isBtnDisabled = false;
        } else {
            if (returnItem.getField('isApprovedFromTheServer') && state.status !== PENDING) {
                isBtnDisabled = true;
            } else {
                isBtnDisabled = false;
            }
        }

        return isBtnDisabled;
    };

    /**
     * changeManagerComment {Function}
     * @description - change manager (customer) comment
     *
     * */
    changeManagerComment = (e, productId, currentShopType) => {
        this.props.changeManagerComment(e.currentTarget.value, productId, currentShopType)
    };

    /**
     * changeInternalComment {Function}
     * @description - change internal (customer) comment
     *
     * */
    changeInternalComment = (e, productId, currentShopType) => {
        this.props.changeInternalComment(e.currentTarget.value, productId, currentShopType)
    };

    /**
     * changeFilterAction {Function}
     * @description - change filter action value
     *
     * */
    changeFilterAction = (value, item, productId, defaultSettings, key) => {
        let {label = ''} = item;
        let {errors} = this.state;


        let filterNameArray = label.split('.');
        let filterName = filterNameArray[filterNameArray.length - 1];


        if (filterName === 'diminishedPrice') {
            value = value ? parseFloat(value) : 0;
        }

        if (filterName === 'REFUND') {
            this.props.clearReducedValuesObject();
        }

        if (defaultSettings && defaultSettings[key]) {
            if (defaultSettings[key].max && value > defaultSettings[key].max) {
                this.setState({
                        errors: {
                            ...errors,
                            [filterName]: true
                        }
                    }, () => {
                        this.props.changeProductFilters(filterName, value, productId)
                    }
                )
            } else {
                this.setState({
                        errors: {
                            ...errors,
                            [filterName]: false
                        }
                    }, () => {
                        this.props.changeProductFilters(filterName, value, productId)
                    }
                )
            }
        } else {
            this.props.changeProductFilters(filterName, value, productId)
        }

    };

    /**
     * getConfigurableConditions {Function}
     * @description - converts conditions of the current Return Order to proper format for passing them to PageFilters component
     * @return {Array}
     * */
    getConfigurableConditions = (returnOrder) => {
        let conditionsArr = [];
        let configurableConditions = returnOrder.getField('itemConditions');

        if (isArray(configurableConditions) && configurableConditions.length) {
            configurableConditions.forEach((condition) => {
                conditionsArr.push({
                    label: condition,
                    value: condition
                })
            });
        }

        return conditionsArr;
    };

    /**
     * sendNoteToCustomer {Function}
     * @description - send an email to the customer service
     *
     * */
    sendNoteToCustomer = (returnItem) => {
        let returnId = returnItem.getField('returnOrderId');
        this.props.sendNoteToCustomer({
            route: 'sendToCs',
            requestData: {
                returnId: returnId
            }
        })
    };


    /**
     * checkCommentUpdate {Function}
     * @description - check were comment updated or not
     *
     * */
    checkCommentUpdate = () => {
        let {updatedComments} = this.props;
        let isCurrentReturnUpdate = false;

        for (let key in updatedComments) {
            if (updatedComments[key]) {
                isCurrentReturnUpdate = true;
            }
        }

        this.setState({
            isReturnUpdate: isCurrentReturnUpdate
        });
    };

    /**
     * getFilterNextActions {Function}
     * @description - generates options to set in next actions filter dropdown
     * @return {Array}
     *
     * */
    getFilterNextActions = (item) => {
        let actions = item.getField && item.getField("itemNextActions") ? item.getField("itemNextActions") : [];
        let filterActions = [];

        actions.forEach((actionItem)=>{
            filterActions.push({
                label: actionItem,
                value: actionItem
            })
        });

        return filterActions;
    };

    /**
     * getFilterConditions {Function}
     * @description - generates options to set in next conditions filter dropdown
     * @return {Array}
     *
     * */
    getFilterConditions = (item) => {
        let conditions = item.getField && item.getField("itemConditions") ? item.getField("itemConditions") : [];
        let filterConditions = [];

        conditions.forEach((conditionItem)=>{
            filterConditions.push({
                label: conditionItem,
                value: conditionItem
            })
        });

        return filterConditions;
    };

    /**
     * renderProducts {Function}
     * @description - method for rendering return item product
     * @return  {React component Array}
     *
     * */
    renderProducts = (items, item, returnOrderId) => {
        let {currentItemProducts, isSendToSCLoading, mainPropertiesState={}} = this.props;
        return items.map((productItem, key) => {
            let {orderItem: {productVariant, orderItemId, sku}} = productItem;
            let {orderItem: {productVariant: product}} = productItem;
            let defaultProductPrice = productItem && productItem.orderItem && productItem.orderItem.price ? productItem.orderItem.price : 0;
            let stateId = orderItemId;
            let currentState = currentItemProducts[stateId];
            let commentRefId = productItem.returnOrderItemId;
            let {orderItem} = currentState;
            let {attributes=null} = productVariant;
            let currency = item.getField('currency');
            if(!this[commentRefId]) {
                this[commentRefId] = React.createRef();
            }
            let skuID = sku && sku !== productVariant.productVariantExternalId ? sku : null;
            let itemFilterActions = this.getFilterNextActions(item);
            let itemFilterConditions = this.getFilterConditions(item);

            return (
                <div key={key} className="b-returns-item__product">
                    <div className="b-returns-item__product_header">
                        <AppBar className={`b-returns-item__head`} position="relative" color="default">
                            <div className="b-toolbar">
                                <div className="b-returns-item__product_counter">{`${key + 1}/${items.length}`}</div>
                                <div className="b-returns-item__pusher">
                                    <PageFiltersItems pageId="returnsProduct"
                                                      classStyles='b-returns-item__filters'
                                                      returnOrderId={returnOrderId}
                                                      defaultValues={this.getDefaultValues(currentState)}
                                                      defaultSettings={this.getDefaultSettings(currentState)}
                                                      disabledStates={this.getDisabledStates(currentState)}
                                                      errors={this.getErrors()}
                                                      changeAction={this.changeFilterAction}
                                                      currency={currency}
                                                      defaultProductPrice={defaultProductPrice}
                                                      returnsProductId={stateId}
                                                      replaceFilter={{
                                                          'return.order.details.condition': itemFilterConditions,
                                                          'return.order.details.nextAction': itemFilterActions
                                                      }}
                                                      isLabeled={true}
                                    />
                                </div>
                                <div className='b-returns-item__header_controls'>
                                    <IconButton color="inherit"
                                                className={this.isBtnActive(currentState, REMOVED) ? `b-returns-item__delete_btn` : ``}
                                                onClick={() => {
                                                    if (item.getField('status') !== PROCESSED) {
                                                        this.changeItemState(stateId, currentState.status === REMOVED ? PENDING : REMOVED, currentState, currentItemProducts, item)
                                                    }
                                                }}
                                                disabled={this.isBtnDisabled(currentState, REMOVED, item)}
                                    >
                                        <Tooltip
                                            title={<CustomTranslation id='login.v2.inboundPage.tooltip.Remove.text'/>}
                                            placement="top">
                                            <Delete/>
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton color="inherit" onClick={() => {
                                        this.props.toggleFlaggedState(stateId)
                                    }}>
                                        <Tooltip title={<CustomTranslation
                                                id={currentState.flagged ? 'login.v2.inboundPage.tooltip.ClearFlag.text' : 'login.v2.inboundPage.tooltip.AddFlag.text'}/>}
                                                placement="top">
                                            <FlagIcon
                                                className={
                                                    currentState.flagged
                                                        ? `b-returns-item__report_icon b-returns-item__report_icon--active`
                                                        : `b-returns-item__report_icon`}
                                            />
                                        </Tooltip>
                                    </IconButton>
                                </div>
                                <div className="b-returns-item__state">
                                    <div className="b-returns-item__product_state_title">
                                        <CustomTranslation id='return.order.details.status'/>
                                    </div>
                                    <div className={`b-returns-item__product_state_value ${this.getStateClass(currentState.status)} b-state-color`}>{
                                        <CustomTranslation id={`return.order.status.${currentState.status}`}/>}</div>
                                </div>
                            </div>
                        </AppBar>
                    </div>
                    <div className="b-returns-item__product_body">
                        <Grid container className={`b-grid__container`} spacing={0}>
                            <Grid item xs={12} md={2} lg={2} className="b-returns-item__block">
                                <div className="b-returns-item__product_image">
                                    <ImageZoom
                                        image={{
                                            src: productVariant.imageUrl,
                                            alt: product.name,
                                            className: "b-returns-item__product_img"
                                        }}
                                        zoomImage={{
                                            src: productVariant.imageUrl,
                                            alt: product.name,
                                            className: "b-returns-item__product_img b-returns-item__product_img--big",
                                        }}
                                        defaultStyles={{
                                            overlay: {
                                                backgroundColor: '#7D818C',
                                                opacity: 0.9
                                            }
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8} className="b-returns-item__block">
                                <div className="b-returns-item__product_info">
                                    <div className="b-returns-item__product_info_item b-returns-item__additional_data">
                                        <Grid container className={`b-grid__container`} spacing={0}>
                                            <Grid item xs={2} lg={2} className="b-returns-item__block">
                                                <div className="b-returns-item__product_info_title"><CustomTranslation id='return.order.details.product.id'/></div>
                                                <div className="b-returns-item__product_info_value">{skuID ? productVariant.productVariantExternalId +' ('+ skuID +')' : productVariant.productVariantExternalId || '-'}</div>
                                            </Grid>
                                            <Grid item xs={4} lg={4} className="b-returns-item__block">
                                                <div className="b-returns-item__product_info_title"><CustomTranslation id='return.order.details.product.name'/></div>
                                                <div className="b-returns-item__product_info_value">{transFormReturnItemName(product.name, attributes, isNeedToShowAttributesInReturnItemName(mainPropertiesState))  || '-'}</div>
                                            </Grid>
                                            <Grid item xs={2} lg={2} className="b-returns-item__block">
                                                <div className="b-returns-item__product_info_title"><CustomTranslation id='return.order.details.price'/></div>
                                                <div className="b-returns-item__product_info_value">{toFixedCurrency(orderItem.price, item.getField('currency')) || '-'}</div>
                                            </Grid>
                                            <Grid item xs={4} lg={4} className="b-returns-item__block">
                                                <div className="b-returns-item__product_info_title"><CustomTranslation id='return.order.details.reason'/></div>
                                                <div className="b-returns-item__product_info_value">{currentState.reclamationReason ? <CustomTranslation id={`products.report.returnReason.${currentState.reclamationReason}`}/> : '-'}</div>
                                            </Grid>
                                        </Grid>
                                        <div className="b-returns-item__product_comments_block">
                                            <div className="b-returns-item__comments">
                                                <ReturnCommentsAreas
                                                    returnOrderId={returnOrderId}
                                                    managerComment={{
                                                        value: currentState.managerComment || '',
                                                        disabled: item.getField('isApprovedFromTheServer')
                                                    }} privateComment={{
                                                        value: currentState.privateComment || '',
                                                    }}
                                                    referrer={this[commentRefId]}
                                                    orderItemId={commentRefId}
                                                    managerValue={currentState.managerComment || ''}
                                                    privateValue={currentState.privateComment || ''}
                                                />
                                            </div>
                                            <div className="b-returns-item__dropdown_zone">
                                                <Grid container className={`b-grid__container`} spacing={0}>
                                                    <Grid item xs={6} lg={6} className="b-returns-item__block">
                                                        {
                                                            currentState && currentState.reclamationImages && currentState.reclamationImages.length
                                                            ?
                                                                currentState.reclamationImages.length == 1
                                                                    ?
                                                                    <div className="b-reclamation-images-gallery__comment_label">
                                                                        <CustomTranslation id='return.order.details.customer.reclamation.image' renderAsString={true} />
                                                                    </div>
                                                                    :
                                                                    <div className="b-reclamation-images-gallery__comment_label">
                                                                        <CustomTranslation id='return.order.details.customer.reclamation.images' renderAsString={true} />
                                                                    </div>
                                                            :
                                                            ''
                                                        }

                                                        <ReclamationImagesGallery images={currentState.reclamationImages || []} comment={currentState.comment} />
                                                    </Grid>
                                                    <Grid item xs={6} lg={6} className="b-returns-item__block">
                                                        <div className="b-drop-zone__images_label">
                                                            <CustomTranslation id='return.order.details.internal.images' renderAsString={true} />
                                                        </div>
                                                        <DropZone defaultImages={currentState.merchantImages} returnOrderId={returnOrderId} orderItemId={commentRefId} />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={2} lg={2} className="b-returns-item__block">
                                <div className="b-returns-item__product_control">
                                    <Button variant="contained"
                                            className={this.isBtnActive(currentState, ARRIVED) ? `b-btn-received b-returns-item__btn b-state-color` : `b-btn-received-inactive b-returns-item__btn b-state-color`}
                                            onClick={() => {
                                                if (item.getField('status') !== PROCESSED) {
                                                    this.changeItemState(stateId, currentState.status === ARRIVED ? PENDING : ARRIVED, currentState, currentItemProducts, item);
                                                }
                                            }}
                                            disabled={this.isBtnDisabled(currentState, ARRIVED, item)}>
                                        <CustomTranslation id={this.isBtnActive(currentState, ARRIVED) ? "return.order.details.button.arrived" : "return.order.details.button.arrive"}/>
                                    </Button>
                                </div>
                                <div className="b-returns-item__product_control">
                                    <Button variant="contained"
                                            className={this.isBtnActive(currentState, REJECTED) ? `b-btn-rejected b-returns-item__btn b-state-color` : `b-btn-rejected-inactive b-returns-item__btn b-state-color`}
                                            onClick={() => {
                                                if (item.getField('status') !== PROCESSED) {
                                                    this.changeItemState(stateId, currentState.status === REJECTED ? PENDING : REJECTED, currentState, currentItemProducts, item);
                                                }
                                            }}
                                            disabled={this.isBtnDisabled(currentState, REJECTED, item)}>
                                        <CustomTranslation id="return.order.details.button.reject"/>
                                    </Button>
                                </div>
                                <div className="b-returns-item__product_control">
                                    <SendToCustomerServiceControl
                                        sendNoteToCustomerAction={()=>{this.sendNoteToCustomer(item)}}
                                        isLoading={isSendToSCLoading}
                                        lastTimeStamp={item.getField('sentToCsTimeStamp')} />
                                </div>
                            </Grid>
                        </Grid>
                        {

                            currentState.comment
                                ?

                                <ExpansionPanel className={currentState.isCommentsAreaExpanded ? "b-returns-item__accordion b-returns-item__accordion--customer_reclamation_block" : "b-returns-item__accordion b-returns-item__accordion--customer_reclamation_block b-returns-item__accordion--customer_reclamation_block_collapsed"} expanded={currentState.isCommentsAreaExpanded} onChange={() => {
                                    this.props.toggleCommentArea(stateId, this.getCurrentShopType())
                                }}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} className="b-returns-item__accordion_summary">
                                        {
                                            currentState.isCommentsAreaExpanded ?
                                                <div className='b-returns-item__accordion_item'>
                                                    <ExpandLessIcon className='b-returns-item__accordion_left_icon'
                                                                    color={"inherit"}/>
                                                    <CustomTranslation id="login.v2.inboundPage.collapse"/>
                                                </div>
                                                :
                                                <div className='b-returns-item__accordion_item'>
                                                    <ExpandMoreIcon className='b-returns-item__accordion_left_icon'
                                                                    color={"inherit"}/>
                                                    <CustomTranslation id="login.v2.inboundPage.expand"/>
                                                </div>
                                        }
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className="b-returns-item__message_wrapper">
                                        <div className="b-returns-item__message">
                                            <Grid container spacing={0}>
                                                {
                                                    <Grid item xs={12} lg={12} className="b-returns-item__block">
                                                        <div className="b-returns-item__comments_title b-returns-item__comments_title--reclamation_textarea_title">
                                                            <CustomTranslation  id='return.order.details.commentFromCustomer' />
                                                        </div>
                                                        <div className={
                                                            currentState.comment
                                                                ?
                                                                "b-returns-item__message_text b-returns-item__message_text--reclamation_textarea_text active"
                                                                :
                                                                "b-returns-item__message_text b-returns-item__message_text--reclamation_textarea_text"}>
                                                            {currentState.comment}
                                                        </div>
                                                    </Grid>
                                                }

                                            </Grid>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                :
                                ''
                        }
                    </div>
                </div>
            )
        });

    };

    /**
     * isReturnItemTotallyHandled {Function}
     * @description - go through all return item products and check if they were handled
     * @return  {Boolean}
     *
     * */
    isReturnItemTotallyHandled = (items) => {
        let isReturnItemTotallyHandled = true;

        for (let i in items) {
            let currentItem = items[i];
            if (currentItem.status === PENDING) {
                isReturnItemTotallyHandled = false;
            }
        }

        return isReturnItemTotallyHandled;
    };

    /**
     * isReturnsPartiallyHandled {Function}
     * @description - go through all return item products and check if at least one of them has no status PENDING
     * @return  {Boolean}
     *
     * */
    isReturnsPartiallyHandled = (items) => {
        let isPartiallyHanded = false;

        for (let i in items) {
            let currentItem = items[i];
            if (currentItem.status !== PENDING) {
                isPartiallyHanded = true
            }
        }

        return isPartiallyHanded;
    };

    /**
     * isAllItemsRejectedRemoved {Function}
     * @description - go through all return item products and check if all of them have status REMOVED and REJECTED
     * @return  {Boolean}
     *
     * */
    isAllItemsRejectedRemoved = (items) => {
        let isAllItemsRejectedRemoved = true;

        for (let i in items) {
            let currentItem = items[i];
            if(currentItem.status !== REJECTED && currentItem.status !== REMOVED) {
                isAllItemsRejectedRemoved = false;
            }
        }

        return isAllItemsRejectedRemoved;
    };

    /**
     * toggleApproveRefundExchange {Function}
     * @description - handle the ability to enable/disable approve refund/exchange checkbox
     *
     * */
    toggleApproveRefundExchange = () => {
        this.props.toggleApproveRefundExchange()
    };

    /**
     * getStatusColor {Function}
     * @description - get appropriate color fo returns item status
     * @return {String} - color hex
     *
     * */
    getStatusColor = (status) => {
        let {theme: {palette: {brand: {returnStatusColors}}}} = this.props;
        return returnStatusColors[status] || 'none';
    };

    /**
     * getShipmentStatusColor {Function}
     * @description - get appropriate color fo returns item shipment status
     * @return {String} - color hex
     *
     * */
    getShipmentStatusColor = (status) => {
        let {theme: {palette: {brand: {returnShipmentStatusColors}}}} = this.props;
        return returnShipmentStatusColors[status] || 'none';
    };

    /**
     * isPreApproval {Function}
     * @description - check if the given return order is in pre-approval state
     * @return Boolean
     *
     * */
    isPreApproval = (returnOrder) => {
        return returnOrder.getField('status') === "PRE_APPROVAL";
    };

    /**
     * isShipmentNeedToBeShow {Function}
     * @description - check if need to show control to generate shipment label
     * @return Boolean
     *
     * */
    isShipmentNeedToBeShow = (item) => {
        let isShipmentNeedToBeShow = false;
        let shipment = item.getField('shipment');

        if (shipment && shipment.shipmentProvider && shipment.shipmentProvider !== 'NONE') {
            isShipmentNeedToBeShow = true;
        }

        return isShipmentNeedToBeShow;
    };


    /**
     * preApprove {Function}
     * @description - send request for data necessary for shipment label pdf link
     *
     * */
    preApprove = () => {
        let {item} = this.props;
        this.props.preApprove({
            route: 'preApprove',
            isResponseJSON: false,
            requestData: {
                returnsId: item.getField('returnOrderId')
            }
        });
    };

    /**
     * generateShippingLabel {Function}
     * @description - send request for data necessary for shipment label pdf link
     *
     * */
    generateShippingLabel = () => {
        let {item} = this.props;
        this.props.getShipmentLabel({
            route: 'getShipmentLabel',
            isResponseJSON: false,
            requestData: {
                returnsId: item.getField('returnOrderId')
            }
        });
    };

    hasPendingState = (product) => {
        if (!isObject(product) || product.deleted || !product.status) {
            return false;
        }
        return !product.deleted && product.status === PENDING;
    };

    hasProductsInPendingState = () => {
        let {currentItemProducts} = this.props;

        for (let i in currentItemProducts) {
            let product = currentItemProducts[i];
            if (this.hasPendingState(product)) {
                return true;
            }
        }

        return false;
    };

    /**
     * getBeforeUpdateConfirmNotes {Function}
     * @description - defines what was changed in return and generate appropriate content for popup Dialog
     * @return {React Component}
     *
     * */
    getBeforeUpdateConfirmNotes = () => {
        let {returnItemDefault, currentReturnItem, currentItemProducts, reducedValues, returnFeeFixed, refund } = this.props;
        /*TODO refund probably will be undefined As we removed ReturnDescriptionState from mapStateToProps method*/
        return getBeforeReturnUpdateTextsToConfirm({
            returnItemDefault,
            currentReturnItem,
            currentItemProducts,
            isReturnItemTotallyHandled: this.isReturnItemTotallyHandled,
            isReturnsPartiallyHandled: this.isReturnsPartiallyHandled,
            reducedValues,
            returnFeeFixed,
            refund
        })
    };

    /**
     * updateReturn {Function}
     * @description - send request to update return
     *
     * */
    updateReturn = () => {
        let {
            currentReturnItem,
            currentItemProducts,
            currentReturnItemUpdateInfo,
            updatedComments,
            returnFees,
            reducedValues,
            merchantFiles
        } = this.props;
        let returnOrderId = currentReturnItem.getField('returnOrderId');
        let loginData = getDataFromSS('login');
        let requestData = {};
        let commentsAreasIds = [];
        let productReducedValues = reducedValues && reducedValues[returnOrderId] ? reducedValues[returnOrderId] : null;
        let isApprovedByStatus = true;
        let currentReturnFee = returnFees ? returnFees[returnOrderId] || returnFees[returnOrderId] == 0 ? returnFees[returnOrderId] : null : null;

        requestData.returnOrderId = returnOrderId;
        requestData.newGift = currentReturnItemUpdateInfo.gift;
        requestData.removeReconversionOrder = currentReturnItemUpdateInfo.removeReconversionOrder;
        requestData.shopId = loginData.shopId;
        requestData.items = [];

        if (returnFees && currentReturnFee || currentReturnFee === 0) {
            requestData.returnFeeFixed = currentReturnFee;
        }

        for (let i in currentItemProducts) {
            let product = currentItemProducts[i];
            let {returnOrderItemId, orderItem: {orderItemId}, status} = product;
            let productMerchantFiles = merchantFiles && merchantFiles[returnOrderItemId] ? merchantFiles[returnOrderItemId] : null;

            if(status !== REJECTED && status !== REMOVED) {
                isApprovedByStatus = false
            }

            let updatedProduct = {
                diminishedPrice: status === REMOVED || status === REJECTED ? 0 : productReducedValues && productReducedValues[orderItemId] && productReducedValues[orderItemId].newDiminishedPrice !== undefined ? parseFloat(productReducedValues[orderItemId].newDiminishedPrice) : product.diminishedPrice,
                nextActionApiKey: product.nextAction,
                removed: product.deleted,
                returnOrderItemId: product.returnOrderItemId,
                statusApiKey: status,
                flagged: product.flagged
            };
            commentsAreasIds.push(returnOrderItemId);
            if (updatedComments && updatedComments[returnOrderItemId]) {
                if (this[returnOrderItemId] && this[returnOrderItemId].current) {
                    let textareaFields = this[returnOrderItemId].current.querySelectorAll('textarea');

                    if (textareaFields && textareaFields.length === 2) {
                        if (textareaFields[0] && textareaFields[0].value) {
                            updatedProduct.managerComment = textareaFields[0].value;
                        }

                        if (textareaFields[1] && textareaFields[1].value) {
                            updatedProduct.privateComment = textareaFields[1].value;
                        }
                    }
                }
            }

            if(productMerchantFiles) {
                updatedProduct.merchantImages = productMerchantFiles.map((fileItem)=>{
                    let {file={}} = fileItem;
                    let {name=null, path='', size=null} = file;

                    return {
                        file: {
                            path: size ? name : path
                        }
                    }
                })
            }

            if (product.condition) {
                updatedProduct.conditionApiKey = product.condition;
            }

            if (product.status === ARRIVED) {
                updatedProduct.arrivalDate = new Date().getTime();
            }

            requestData.items.push(updatedProduct);
        }

        requestData.approved = currentReturnItem.getField('approved') || isApprovedByStatus;


        this.setState({
            isReturnUpdate: false
        }, () => {
            this.props.setCommentsAreasStateToFalse(commentsAreasIds);
            this.props.clearReducedValuesObject();
            this.props.updateReturnOrder({
                route: 'updateReturnOrder',
                requestData: requestData
            });
            this.props.clearData()
        });

    };

    /**
     * switchToNextReturnFromPopup {Function}
     * @description - close appropriate popup and go to specific return
     *
     * */
    switchToNextReturnFromPopup = (isAfterReturnUpdate) => {
        let {history, returnIdToSwitch, returnIdToSwitchAfterUpdate} = this.props;
        this.props.closeBeforeSwitchReturnPopup();
        history.push('/returns/:returnId'.replace(':returnId', isAfterReturnUpdate ? returnIdToSwitchAfterUpdate : returnIdToSwitch));
    };

    /**
     * getShipmentsStatuses {Function}
     * @description - generate shipment statuses blocks based on server side data
     * @return {Array}
     *
     * */
    getShipmentsStatuses = (shipments = []) => {
        return shipments.map((shipment, key) => {
            let {status=''} = shipment;
            return (
                <span key={key} className='b-returns-item__title_status' style={{backgroundColor: this.getShipmentStatusColor(status)}}>
                    {`${key+1}. `}<CustomTranslation id={`return.order.shipmentStatuses.${status}`}/>
                </span>
            )
        })
    };

    componentDidUpdate(prevProps, prevState, snapshots) {
        let {
            isPreApproved,
            returnIdToSwitchAfterUpdate,
            updatedComments,
            currentItemProducts,
            item,
            returnFeeFixedError,
            currentProductState,
            reducedValues
        } = this.props;

        if (isPreApproved && !prevProps.isPreApproved) {
            if(!returnFeeFixedError) {
                this.fetchReturnItem();
                this.props.fetchReturns();
            }
        }

        if(prevProps.returnIdToSwitchAfterUpdate !== returnIdToSwitchAfterUpdate && returnIdToSwitchAfterUpdate) {
            this.switchToNextReturnFromPopup(true);
        }

        if(prevProps.updatedComments !== updatedComments) {
            this.checkCommentUpdate()
        }

        if(prevProps.currentItemProducts !== currentItemProducts) {
            let wasDiminishedPriceChanged = false;

            for(let key in currentItemProducts) {
                if(prevProps.currentItemProducts[key] && currentItemProducts[key].diminishedPrice !== prevProps.currentItemProducts[key].diminishedPrice) {
                    wasDiminishedPriceChanged = true;
                }
            }

            if(wasDiminishedPriceChanged) {
                this.props.setDescriptionRefund(getDiminishedPrice(currentItemProducts, item), item.getField('returnOrderId'))
            }
        }

        if(prevProps.currentProductState !== currentProductState) {
            let {currentShopType, id, state} = currentProductState;
            let currentState = currentItemProducts && currentItemProducts[id] ? currentItemProducts[id] : {};
            switch (state) {
                case REJECTED:
                    this.props.changeProductFilters('nextAction', 'SEND_BACK', id, false, currentShopType);
                    this.props.changeProductFilters('diminishedPrice', 0, id, false, currentShopType);
                    break;
                case REMOVED:
                    this.props.changeProductFilters('diminishedPrice', 0, id, false, currentShopType);
                    break;
                case ARRIVED:
                    if (currentState.changedValues) {
                        for (let i in currentState.changedValues) {
                            if (currentState.changedValues.hasOwnProperty(i)) {
                                this.props.changeProductFilters(i, currentState.changedValues[i], id, currentShopType);
                            }
                        }
                    } else {
                        this.props.setDefaultFilters(id, currentShopType);
                    }
                    break;
                case PENDING:
                    this.props.setDefaultFilters(id, currentShopType);
                    break;
                default:
                    break;
            }
        }

    };

    render() {
        let {
            item,
            isReturnItemLoading,
            currentItemProducts,
            isReadyForSubmit,
            isBeforeUpdateConfirmPopupActive,
            isPreApprovalLoading,
            isUpdateReturnLoading,
            isBeforeSwitchBetweenReturnPopupActive,
            translate,
            returnFeeFixedError
        } = this.props;
        let items = item.getField('items') || [];
        let returnOrderId = item.getField('returnOrderId') || null;
        let isReturnUpdate = this.state.isReturnUpdate;
        return (
            <div className='b-returns-item'>
                <ConfirmBeforeUpdateDialog
                    isOpen={isBeforeUpdateConfirmPopupActive}
                    title={<CustomTranslation id='return.before.update.popup.title' />}
                    description={<CustomTranslation id='return.before.update.popup.description' />}
                    content={isBeforeUpdateConfirmPopupActive ? this.getBeforeUpdateConfirmNotes(): []}
                    confirmAction={
                        () => {
                            this.props.closeBeforeConfirmUpdateOrder();
                            this.updateReturn();
                        }
                    }
                    closeAction={
                        this.props.closeBeforeConfirmUpdateOrder
                    }
                />
                <CustomDialog
                    isOpen={isBeforeSwitchBetweenReturnPopupActive}
                    title={<CustomTranslation id='return.order.leave.unsaved.warning.title' />}
                    content={`${translate('return.order.leave.unsaved.warning.text')}`}
                    closeAction={()=>{
                        this.switchToNextReturnFromPopup();
                    }}
                    acceptAction={() => {
                        this.updateReturn();
                        this.props.closeBeforeSwitchReturnPopup();
                    }}
                    acceptBtnName={<CustomTranslation id='return.order.leave.unsaved.warning.save.button'/>}
                    closeBtnName={<CustomTranslation id='return.order.leave.unsaved.warning.leave.button'/>}
                />
                {
                    isReturnItemLoading && <Loader wrapperClass='b-returns-item__loader_wrapper'/>
                }
                <div className="b-returns-item__body">
                    <div className="b-returns-item__body_header">
                        <div className="b-returns-item__header">
                            {
                                item.getField('mismatch')
                                ?
                                    <Tooltip title={''} className="b-mismatch-tooltip" placement="top">
                                        <WarningIcon className="b-mismatch-tooltip__mismatch_warning_icon" fontSize="default"/>
                                    </Tooltip>
                                    :
                                    ''
                            }
                            <CustomTranslation id='return.order.list.columns.rma.number'/>{`${item.getField('returnOrderId')} `}
                            <span className='b-returns-item__title_status' style={{backgroundColor: this.getStatusColor(item.getField('status'))}}>
                                <CustomTranslation id={`return.order.statuses.${item.getField('status')}`}/>
                            </span>{' '}
                            <span className='b-returns-item__shipping_icon' style={spanStyle}></span>{this.getShipmentsStatuses(item.getField('shipments'))}
                            {
                                item.getField('mismatch')
                                    ?
                                    <ReturnEventLogPopup isPopupView={false} returnOrderId={item.getField('returnOrderId')}/>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                    <div className="b-returns-item__body_item">
                        <Grid container className={`b-grid__container`} spacing={0}>
                            <Grid item xs={12} md={9} lg={9} className="b-returns-item__block">
                                <ReturnDescription
                                    returnItem={item}
                                    items={items}
                                    currentItemProducts={currentItemProducts}
                                    styleClass='b-return-description--return'
                                    returnFeeFixedError={returnFeeFixedError}
                                    status={item.getField('status')}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} lg={3} className="b-returns-item__block">
                                <div className="b-returns-item__controls">
                                    <div className="b-returns-item__controls_item">
                                        {
                                            this.isPreApproval(item) ?
                                                <div className="b-returns-item__shipment_control">
                                                    <CustomButton
                                                        variant="contained"
                                                        color="primary"
                                                        className='b-returns-item__save_btn'
                                                        isdisabled={isPreApprovalLoading}
                                                        loading={isPreApprovalLoading}
                                                        onClick={()=>{this.preApprove()}}
                                                        name={<CustomTranslation id="return.order.details.generateAndSendShippingLabel.btn" />}
                                                    />
                                                </div> :
                                                ''
                                        }
                                        <CustomButton
                                            variant='contained'
                                            fullWidth={true}
                                            color='primary'
                                            aria-label={<CustomTranslation id="login.form.button" />}
                                            isdisabled={(!isReadyForSubmit || isUpdateReturnLoading) && !isReturnUpdate}
                                            loading={isUpdateReturnLoading}
                                            name={<CustomTranslation id="return.order.confirmation.submit.button" />}
                                            className='b-returns-item__save_btn'
                                            onClick={this.props.showBeforeConfirmUpdateOrder}
                                        />
                                        {
                                            this.isShipmentNeedToBeShow(item)
                                                ?
                                                <div className="b-returns-item__shipment_control">
                                                    <Button variant="contained" color="primary"
                                                            className='b-returns-item__save_btn' onClick={() => {
                                                        this.generateShippingLabel()
                                                    }}>
                                                        <CustomTranslation id="return.order.details.shippingLabel.btn"/>
                                                    </Button>
                                                </div>
                                                :
                                                ''
                                        }
                                    </div>
                                    <div className="b-returns-item__controls_item">
                                        <div className="b-returns-item__arrived_indicator">
                                            <PartialApproval
                                                className="b-partial-approval--returns"
                                                toggleApproveRefundExchange={this.toggleApproveRefundExchange}
                                                isReturnItemTotallyHandled={this.isReturnItemTotallyHandled(currentItemProducts)}
                                                isReturnsPartiallyHandled={this.isReturnsPartiallyHandled(currentItemProducts)}
                                                isAllItemsRejectedRemoved={this.isAllItemsRejectedRemoved(currentItemProducts)}
                                                item={item}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="b-returns-item__body_item">
                        {items && items.length && this.renderProducts(items, item, returnOrderId)}
                    </div>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {};
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            toggleApproveRefundExchange,
            changeProductFilters,
            toggleCommentArea,
            setDefaultFilters,
            updateReturnOrder,
            getShipmentLabel,
            changeItemState,
            sendNoteToCustomer,
            preApprove,
            fetchReturnsItem,
            toggleFlaggedState,
            showBeforeConfirmUpdateOrder,
            closeBeforeConfirmUpdateOrder,
            closeBeforeSwitchReturnPopup,
            setCommentsAreasStateToFalse,
            setDescriptionRefund,
            clearReducedValuesObject,
            clearData,
            setReduceProductValue,
            fetchReturns
        },
        dispatcher,
    );
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, matchDispatchToProps)(withRouter(ReturnsItem)));