import Const from './../const';

/**
 * requestStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestStart = () =>{
    return {
        type: Const.DROP_ZONE_START_FILES_HANDLING
    }
};

/**
 * loadFiles {React Action}
 *
 * */
export function loadFiles(files, returnOrderId, orderItemId) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.DROP_ZONE_LOAD_FILES,
            payload: {
                files,
                returnOrderId,
                orderItemId
            }
        });
    }
}

/**
 * replaceFiles {React Action}
 *
 * */
export function replaceFiles(files, returnOrderId, orderItemId) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.DROP_ZONE_REPLACE_FILES,
            payload: {
                files,
                returnOrderId,
                orderItemId
            }
        });
    }
}

/**
 * merchantFilesWereUpdated {React Action}
 *
 * */
export function merchantFilesWereUpdated(merchantFiles, currentReturnOrderId) {
    return dispatch => {
        dispatch({
            type: Const.DROP_ZONE_FILES_WERE_UPDATED,
            payload: {
                merchantFiles,
                currentReturnOrderId
            }
        });
    }
}

/**
 * clearState {React Action}
 *
 * */
export function clearState() {
    return dispatch => {
        dispatch({
            type: Const.DROP_ZONE_CLEAR_STATE,
        });
    }
}

/**
 * clearState {React Action}
 *
 * */
export function imagesWereUploaded() {
    return dispatch => {
        dispatch({
            type: Const.DROP_ZONE_S3_IMAGES_UPLOADED,
        });
    }
}