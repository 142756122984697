import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {
    sendDataOnEmail,
    checkToken,
    setIsPasswordVisible,
    setUserNameFormTokenToState,
    updatePassword,
    clearState
} from './actions/resetPasswordActions';
import {connect} from 'react-redux';
import Grow from '@material-ui/core/Grow';
import logoSrc from '../../images/login/logo-element.png';
import bgSrc from '../../images/login/login-bg.jpg';
import Formsy from 'formsy-react';
import EmailField from './components/EmailField';
import ResetPasswordFields from './components/ResetPasswordFields';
import EmailSendNote from './components/EmailSendNote';
import InvalidTokenNote from './components/InvalidTokenNote';
import PasswordWasUpdatedNote from './components/PasswordWasUpdatedNote';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import SupportIcon from '@material-ui/icons/Error';
import CustomButton from './../../containers/CustomButton/CustomButton';
import Loader from './../../containers/Loader/Loader';
import {getParsedJWT} from './../../utilites';
import CustomTranslation from "../CustomTranslation/CustomTranslation";


const TRANSITION_SPEED = 800;

class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            canSubmit: false
        };
    }

    /**
     * getTokenFromUrl {Function}
     * @description - get token from url
     * @return token {String} or Null
     *
     * */
    getTokenFromUrl = () => {
        let {props: {match: {params: {token = null}}}} = this;

        return token;
    };

    /**
     * setIsPasswordVisible {Function}
     * @description - set visible/invisible password in the appropriate fields
     *
     * */
    setIsPasswordVisible = () => {
        this.props.setIsPasswordVisible();
    };

    /**
     * disableButton {Function}
     * @description - change state for submit button disabling
     *
     * */
    disableButton = () => {
        this.setState({canSubmit: false});
    };

    /**
     * enableButton {Function}
     * @description - change state for submit button enabling
     *
     * */

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    /**
     * onSubmit {Function}
     * @description - submit reset password form
     *
     * */
    onSubmit = (model) => {
        let {isTokenValid, wasTokenVerified, tokenUserName} = this.props;
        let token = this.getTokenFromUrl();

        if (wasTokenVerified && isTokenValid) {
            this.props.updatePassword({
                route: 'updatePassword',
                requestHeaders: {
                    Authorization: `Bearer ${token}`
                },
                requestData: {
                    password: model.password,
                    username: tokenUserName,
                }
            });
        } else {
            this.props.sendDataOnEmail({
                route: 'askForPasswordReset',
                requestData: {
                    ...model,
                }
            });
        }
    };

    /**
     * getAppropriateNoteView {Function}
     * @description - function defines what exactly note should be rendered
     * @return null or view {React Component}
     *
     * */
    getAppropriateNoteView = () => {
        let {wasEmailSend, wasTokenVerified, wasPasswordUpdated, isTokenValid} = this.props;
        let view = null;

        if (wasEmailSend) {
            view = <EmailSendNote TranslateComponent={CustomTranslation}/>;
        }

        if (wasTokenVerified && !isTokenValid) {
            view = <InvalidTokenNote TranslateComponent={CustomTranslation} actionClick={this.props.clearState}/>;
        }

        if (wasPasswordUpdated) {
            view = <PasswordWasUpdatedNote TranslateComponent={CustomTranslation} actionClick={this.props.clearState}/>;
        }

        return view;

    };

    componentWillUnmount() {
        this.props.clearState();
    }

    componentDidMount() {
        let token = this.getTokenFromUrl();
        let parsedTokenData = token && getParsedJWT(token).manager ? JSON.parse(getParsedJWT(token).manager) : null;

        /**
         * if token exist:
         *  - need to parse it and get current user name
         *  - need to check if token is valid.
         *
         * */
        if (token) {
            if (parsedTokenData && parsedTokenData.username) {
                this.props.setUserNameFormTokenToState(parsedTokenData.username);
            }
            this.props.checkToken({
                route: 'checkTokenForPasswordReset',
                requestHeaders: {
                    Authorization: `Bearer ${token}`
                },
                requestData: {}
            });
        }

    }

    render() {
        const {
            canSubmit,
            isResetPasswordFieldsShouldBeRendered
        } = this.state;

        /** state variables*/
        let {isLoading, isCheckTokenLoading, isPasswordVisible, isTokenValid, wasEmailSend, wasTokenVerified, wasPasswordUpdated, userNameFromLoginPage} = this.props;

        /** state methods */
        let {setIsPasswordVisible} = this.props;

        return (
            <Grow in={true} timeout={TRANSITION_SPEED}>
                <div className='b-reset-password' style={{backgroundImage: `url(${bgSrc})`}}>
                    <div
                        className={(wasEmailSend) || (wasTokenVerified && !isTokenValid) || (wasPasswordUpdated) ? 'b-reset-password__body b-reset-password__body--with_note' : 'b-reset-password__body'}>
                        <div className="b-reset-password__header">
                            {
                                wasTokenVerified && isTokenValid
                                    ?
                                    !wasPasswordUpdated && <Tooltip title={
                                        <React.Fragment>
                                            <Typography color='inherit'>
                                                <CustomTranslation id='reset.passwordRules.title'/>
                                            </Typography>
                                            <ul>
                                                {
                                                    [1, 2, 3, 4, 5, 6, 7].map((item, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <CustomTranslation
                                                                    id={`reset.passwordRules.passwordRule.${item}`}/>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </React.Fragment>
                                    } className="b-reset-password__tooltip" placement="top">
                                        <SupportIcon className="b-custom-tooltip-icon" fontSize='small' />
                                    </Tooltip>
                                    :
                                    ''
                            }
                            <img className='b-reset-password__logo' src={logoSrc} alt='login logo'/>
                        </div>
                        {
                            this.getAppropriateNoteView()
                                ?
                                this.getAppropriateNoteView()
                                :
                                isCheckTokenLoading
                                    ?
                                    <Loader color="primary"/>
                                    :
                                    <Formsy
                                        onValidSubmit={this.onSubmit}
                                        onValid={this.enableButton}
                                        onInvalid={this.disableButton}
                                        className='flex flex-col justify-center w-full b-reset-password__form'
                                        autoComplete='off'
                                    >
                                        {
                                            wasTokenVerified && isTokenValid
                                                ?
                                                // Case when we should provide user with possibility to reset password
                                                <ResetPasswordFields isPasswordVisible={isPasswordVisible}
                                                                     setIsPasswordVisible={setIsPasswordVisible}
                                                                     TranslateComponent={CustomTranslation}/>
                                                :
                                                // Case when we should provide user wir possibility to make a request for sending a link for reset password on email
                                                <EmailField userNameFromLoginPage={userNameFromLoginPage} TranslateComponent={CustomTranslation}/>
                                        }

                                        <div className='b-reset-password__item'>
                                            <div className='b-reset-password__p'>
                                                <CustomButton
                                                    type='submit'
                                                    variant='contained'
                                                    fullWidth={true}
                                                    color='primary'
                                                    aria-label={<CustomTranslation id='login.form.restore.password'/>}
                                                    isdisabled={!canSubmit}
                                                    loading={isLoading}
                                                    name={isResetPasswordFieldsShouldBeRendered && isTokenValid ?
                                                        <CustomTranslation id='reset.form.button'/> :
                                                        <CustomTranslation id='login.form.restore.password'/>}
                                                />
                                            </div>
                                            <div className='b-login__p'>
                                                <Link href={'#/login'} color='primary'>
                                                    <CustomTranslation id='login.v2.inboundPage.btn.goBack.text'/>
                                                </Link>
                                            </div>
                                        </div>
                                    </Formsy>
                        }
                    </div>
                </div>
            </Grow>
        )
    };
}

function mapStateToProps({resetPasswordState}) {
    return {
        ...resetPasswordState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            updatePassword,
            setUserNameFormTokenToState,
            setIsPasswordVisible,
            sendDataOnEmail,
            checkToken,
            clearState
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(ResetPassword);