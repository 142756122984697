import Const from './../const';
import {getDataFromSS} from "./../../../utilites";

/*
* getShopId {Function}
* @describe - application need to use shpoId parameter for localization. This method is getter for shopId.
* @return shopId {Int}
*
* */
let getShopId = () => {
    let userSessionData = getDataFromSS('login');
    return userSessionData ? userSessionData.shopId : undefined
};

/*
* requestTranslationStart {Function}
* @return Object in React Action format before request start
*
* */
let requestTranslationStart = () =>{
    return {
        type: Const.LOCALIZATION_GET_LOCALIZATION_REQUEST_START
    }
};

/*
* getLocale {React Action}
* @description - send request to get locale
*
* */
export function getLoginLocale(requestData) {
    return dispatch => {
        dispatch(requestTranslationStart());
        dispatch({
            type: Const.LOCALIZATION_GET_LOGIN_LOCALIZATION_REQUEST,
            ...requestData
        });
    }
}

/*
* getLocale {React Action}
* @description - send request to get locale
*
* */
export function getLocale(requestData) {
    return dispatch => {
        dispatch(requestTranslationStart());
        dispatch({
            type: Const.LOCALIZATION_GET_LOCALIZATION_REQUEST,
            ...requestData,
            requestData: {
                ...requestData.requestData,
                shopId: getShopId()
            }
        });
    }
}