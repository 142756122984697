import Const from './../const';

/**
 * LocalizationError {React Action}
 *
 * */
export function localizationError() {
    return dispatch => {
        dispatch({
            type: Const.ERROR_DIALOG_LOCALIZATION_ERROR,
        });
    }
}

/**
 * returnsDataError {React Action}
 *
 * */
export function returnsDataError() {
    return dispatch => {
        dispatch({
            type: Const.ERROR_DIALOG_RETURNS_DATA_ERROR,
        });
    }
}

/**
 * inboundDataError {React Action}
 *
 * */
export function inboundDataError() {
    return dispatch => {
        dispatch({
            type: Const.ERROR_DIALOG_RETURNS_DATA_ERROR,
        });
    }
}

/**
 * closeErrorDialog {React Action}
 *
 * */
export function closeErrorDialog() {
    return dispatch => {
        dispatch({
            type: Const.ERROR_DIALOG_CLOSE,
        });
    }
}