import Const from './../const';

/*
* setRightMenuActive {React Action}
* @description - open right menu
*
* */

export function setRightMenuActive() {
    return dispatch => {
        dispatch({
            type: Const.RIGHT_MENU_IS_ACTIVE,
            payload: true
        });
    }
}

/*
* setLeftMenuActive {React Action}
* @description - open left menu
*
* */

export function setLeftMenuActive() {
    return dispatch => {
        dispatch({
            type: Const.LEFT_SIDE_BAR_MOBILE_IS_ACTIVE,
            payload: true
        });
    }
}

/*
* setCurrentPageTitle {React Action}
* @description - set appropriate page title to the header
*
* */
export function setCurrentPageTitle(pageTitle) {
    return dispatch => {
        dispatch({
            type: Const.HEADER_SET_CURRENT_PAGE_TITLE,
            payload: pageTitle
        });
    }
}