import Const from './../const';

/**
* requestStart {Function}
* @return Object in React Action format before request start
*
* */
let requestStart = () =>{
    return {
        type: Const.RETURN_DATA_REPORT_START_FETCH_REQUEST
    }
};

/**
* setReturnDataReportFilter {Function}
* @return Object in React Action format before request start
*
* */
export function setReturnDataReportFilter(label, value) {
    return dispatch => {
        dispatch({
            type: Const.RETURN_DATA_REPORT_CHANGE_FILTER,
            payload: {
                label,
                value
            }
        });
    }
}

/**
* fetchReturnDataReport {Function}
* @return Object in React Action format before request start
*
* */

export function fetchReturnDataReport(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.RETURN_DATA_REPORT_FETCH,
            ...requestData
        });
    }
}

/**
* fetchReturnDataReportSCV {Function}
* @return Object in React Action format before request start
*
* */

export function fetchReturnDataReportSCV(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.RETURN_DATA_REPORT_CSV_FETCH,
            ...requestData
        });
    }
}

/**
* clearFilters {Function}
* @return set filters to default state
*
* */

export function clearFilters() {
    return dispatch => {
        dispatch({
            type: Const.RETURN_DATA_REPORT_CLEAR_FILTERS
        });
    }
}