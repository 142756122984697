import Const from "./../const";

export function setRefundListReturnFee(returnFee, returnOrderId) {
    return dispatch => {
        dispatch({
            type: Const.FIXED_FEE_SELECTOR_SET_RETURN_FEE,
            payload: {
                returnOrderId,
                returnFee
            }
        });
    }
}

export function clearData() {
    return dispatch => {
        dispatch({
            type: Const.FIXED_FEE_SELECTOR_CLEAR,
            payload: true
        });
    }
}