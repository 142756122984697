import LeftSideBarConst from './../../components/LeftSideBar/const';

const Const = {
    ORDERS_START_FETCH_REQUEST: 'ORDERS_START_FETCH_REQUEST',
    ORDERS_FETCH_REQUEST: 'ORDERS_FETCH_REQUEST',
    ORDERS_CHANGE_ORDER_FILTER: 'ORDERS_CHANGE_ORDER_FILTER',
    ORDERS_CLEAR_STATE: 'ORDERS_CLEAR_STATE',
    [LeftSideBarConst.LEFT_SIDE_BAR_IS_FOLDED_OPEN]: LeftSideBarConst.LEFT_SIDE_BAR_IS_FOLDED_OPEN
};

export default Const;