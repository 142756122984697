import ServerErrorPageConst from '../ErrorDialog/const';
import LoginPageConst from './../../components/Login/const';

const Const = {
    SERVER_ERROR_NOTIFIER_IS_ACTIVE: 'SERVER_ERROR_NOTIFIER_IS_ACTIVE',
    SERVER_ERROR_NOTIFIER_SET_ERROR_TEXT: 'SERVER_ERROR_NOTIFIER_SET_ERROR_TEXT',
    SERVER_ERROR_NOTIFIER_CLEAR: 'SERVER_ERROR_NOTIFIER_CLEAR',
    SERVER_ERROR_500_502: 'SERVER_ERROR_500_502',
    [ServerErrorPageConst.ERROR_PAGE_SERVER_ERROR]: ServerErrorPageConst.ERROR_PAGE_SERVER_ERROR,
    [LoginPageConst.LOGIN_CLEAN_CREDENTIALS]: LoginPageConst.LOGIN_CLEAN_CREDENTIALS
};

export default Const;