import Const from './../const';
import FixedPercentageSelectorReducerConst from "./../../../components/FixedPercentageSelector/const";

/**
 * requestStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestStart = () => {
    return {
        type: Const.RETURNS_START_FETCH_REQUEST
    }
};

/**
 * requestGetItemStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestGetItemStart = () => {
    return {
        type: Const.RETURNS_START_GET_ITEM_REQUEST
    }
};

/**
 * requestSendToCSStart {Function}
 * @return Object in React Action format before request send to customer support start
 *
 * */
let requestSendToCSStart = () => {
    return {
        type: Const.RETURNS_SEND_TO_CS_START
    }
};

/**
 * requestGetPreApprovalStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestGetPreApprovalStart = () => {
    return {
        type: Const.RETURNS_START_GET_PREAPPROVAL_REQUEST
    }
};

/**
 * requestUpdateRequestStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestUpdateRequestStart = () => {
    return {
        type: Const.RETURNS_START_UPDATE_REQUEST
    }
};

/**
 * fetchMoreReturns {React Action}
 * @description - send request to the server to get returns data
 *
 * */
export function fetchMoreReturns(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.RETURNS_FETCH_MORE_REQUEST,
            ...requestData
        });
    }
}

/***
 * fetchReturnsItem {React Action}
 * @description - send request to the server to get specific returns item data
 *
 * */
export function fetchReturnsItem(requestData) {
    return dispatch => {
        dispatch(requestGetItemStart());
        dispatch({
            type: Const.RETURNS_GET_RETURN_ITEM,
            ...requestData
        });
    }
}


/***
 * toggleCommentArea {react Action}
 * @description - toggle the comment area of the specific product item
 *
 * */
export function toggleCommentArea(id, currentShopType) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_TOGGLE_RETURN_ITEM_PRODUCT_COMMENT_AREA,
            payload: {
                id,
                currentShopType
            }
        });
    }
}

/***
 * setTableCurrentPage {react Action}
 * @description - change table page
 *
 * */
export function setTableCurrentPage(page) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CHANGE_TABLE_CURRENT_PAGE,
            payload: page
        });
    }
}


/***
 * changeItemState {react Action}
 * @description - change product item state
 *
 * */
export function changeItemState(id, state, currentShopType, currentOrderId, currentPrice, currentDiminishedPrice, currentState) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CHANGE_RETURN_ITEM_STATE,
            payload: {
                id,
                state,
                currentShopType,
                currentOrderId,
                currentPrice,
                currentDiminishedPrice,
                currentState
            }
        });
    }
}

/***
 * clearReturnsState {react Action}
 * @description - clear return page state
 *
 * */
export function clearReturnsState() {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CLEAR_STATE
        });
    }
}

/***
 * clearCurrentReturnsItem {react Action}
 * @description - clear return page current item
 *
 * */
export function clearCurrentReturnsItem() {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CLEAR_CURRENT_RETURNS_ITEM
        });
    }
}

/***
 * toggleApproveRefundExchange {react Action}
 * @description - toggle returns item approve flag
 *
 * */
export function toggleApproveRefundExchange() {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_TOGGLE_APPROVE_FLAG
        });
    }
}

/***
 * preApprove {React Action}
 * @description - pre-approve the return
 *
 * */
export function preApprove(requestData) {
    return dispatch => {
        dispatch(requestGetPreApprovalStart());
        dispatch({
            type: Const.RETURNS_PRE_APPROVE,
            ...requestData
        });
    }
}

/***
 * getShipmentLabel {React Action}
 * @description - get returns shipment label
 *
 * */
export function getShipmentLabel(requestData) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_GET_SHIPMENT_LABEL,
            ...requestData
        });
    }
}

/***
 * changeProductFilters {React Action}
 * @description - change specific filter in returns order product
 *
 * */
export function changeProductFilters(filterName, value, productId, isSaveAsChangedValue = true, currentShopType) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CHANGE_PRODUCT_FILTER,
            payload: {
                filterName,
                value,
                productId,
                isSaveAsChangedValue,
                currentShopType
            }
        });
    }
}

/***
 * setDefaultFilters {React Action}
 * @description - set specific filter to defaultState
 *
 * */
export function setDefaultFilters(id, currentShopType) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CHANGE_PRODUCT_FILTER_TO_DEFAULT,
            payload: {
                id,
                currentShopType
            }
        });
    }
}

/***
 * updateReturnOrder {React Action}
 * @description - send request to update return
 *
 * */
export function updateReturnOrder(requestData) {
    return dispatch => {
        dispatch(requestUpdateRequestStart());
        dispatch({
            type: Const.RETURNS_CONFIRM_UPDATE_ORDER,
            ...requestData
        });
    }
}

/***
 * sendNoteToCustomer {React Action}
 * @description - send request to customer service
 *
 * */
export function sendNoteToCustomer(requestData) {
    return dispatch => {
        dispatch(requestSendToCSStart());
        dispatch({
            type: Const.RETURNS_SEND_TO_CS,
            ...requestData
        });
    }
}

/***
 * closeSendToCustomerDialog {React Action}
 * @description - close dialog with confirmation about email was sent to the customer
 *
 * */
export function closeSendToCustomerDialog() {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CLOSE_SEND_NOTE_TO_THE_CUSTOMER,
        });
    }
}

/***
 * setReturnOrderFilter {React Action}
 * @description - changes filters
 *
 * */
export function setReturnOrderFilter(label, value) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CHANGE_ORDER_FILTER,
            payload: {
                label,
                value
            }
        });
    }
}

/***
 * hideErrorMessage {React Action}
 * @description - hide dialog about error
 *
 * */
export function hideErrorMessage() {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_HIDE_ERROR_MESSAGE,
        });
    }
}


/***
 * toggleFlaggedState {react Action}
 * @description - toggle fagged state
 *
 * */
export function toggleFlaggedState(id) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_TOGGLE_RETURN_ITEM_PRODUCT_FLAGGED_STATE,
            payload: {
                id
            }
        });
    }
}


/***
 * showBeforeConfirmUpdateOrder {React Action}
 * @description - dispatch action to show before update confirmation dialog
 *
 * */
export function showBeforeConfirmUpdateOrder() {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CONFIRM_POPUP_BEFORE_UPDATE_ORDER_SHOW,
        });
    }
}


/***
 * closeBeforeConfirmUpdateOrder {React Action}
 * @description - dispatch action to close before update confirmation dialog
 *
 * */
export function closeBeforeConfirmUpdateOrder() {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CONFIRM_POPUP_BEFORE_UPDATE_ORDER_CLOSE,
        });
    }
}

/**
 * showBeforeSwitchReturnPopup {React Action}
 * @description - dispatch action to show before switch between return dialog
 *
 * */
export function showBeforeSwitchReturnPopup(nextSwitchReturnId) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CONFIRM_POPUP_BEFORE_SWITCH_RETURN_SHOW,
            payload: nextSwitchReturnId
        });
    }
}

/**
 * closeBeforeSwitchReturnPopup {React Action}
 * @description - dispatch action to close before switch between return dialog
 *
 * */
export function closeBeforeSwitchReturnPopup() {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CONFIRM_POPUP_BEFORE_SWITCH_RETURN_CLOSE,
        });
    }
}

/**
 * setDescriptionRefund {React Action}
 * @description - dispatch action to change return description refund value
 *
 * */
export function setDescriptionRefund(refund, returnOrderId) {
    return dispatch => {
        dispatch({
            type: Const.RETURN_DESCRIPTION_SET_REFUND,
            payload: {
                refund,
                returnOrderId
            }
        });
    }
}

/**
 * setFixedReturnFeeValue {React Action}
 * @description - dispatch action to set fixed return fee
 *
 * */
export function setFixedReturnFeeValue(returnFees, reducedValues) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_SET_FIXED_RETURN_FEE,
            payload: {
                returnFees,
                reducedValues
            }
        });
    }
}

/**
 * clearFixedFeeControl {React Action}
 * @description - dispatch action to clear previous fixed return fee variables
 *
 * */
export function clearFixedFeeControl() {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CLEAR_FIXED_RETURN_FEE
        });
    }
}

/**
 * changeSubmitState {React Action}
 * @description - change submit button state based on reduced values
 *
 * */
export function changeSubmitState(reducedValues, returnFees) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CHANGE_SUBMIT_STATE_BASED_ON_REDUCED_VALUES,
            payload: {
                reducedValues,
                returnFees
            }
        });
    }
}

/**
 * clearReducedValuesObject {React Action}
 * @description - clear reduced values state
 *
 * */
export function clearReducedValuesObject() {
    return dispatch => {
        dispatch({
            type: FixedPercentageSelectorReducerConst.FIXED_REDUCED_PERCENTAGE_REDUCE_VALUE_CLEAR,
        });
    }
}



