import Const from './../const';
import GeneralModel from './../../../models/GeneralModel';
import ReturnDescriptionConst from "./../../../components/ReturnDescription/const";
import DropZoneConst from "./../../../components/DropZone/const";
import {
    autoToggleApproveItemState,
    changeProductState,
    concatenateData,
    getCurrentReturnItemUpdateInfo,
    getItemProduct,
    getToggleExpandedItemProduct,
    isReadyForSubmit,
    toggleApproveItemState,
    updateCurrentReturnItem,
    updateProductFilter,
    updateProductFilterToDefault,
    updateSCTimestamp,
    getToggleItemProductFlaggedState,
    isReadyForSubmitAfterReducedValue,
    isReadyForSubmitAfterFixedFee,
    isReadyForSubmitAfterMerchantFiles,
    getMerchantFiles
} from "./returnsReducerMethods";
import FixedFeeSelectorConst from "./../../../components/FixedFeeSelector/const";


const defaultState = {
    returnItemDefault: null,
    currentReturnItem: null,
    currentReturnItemUpdateInfo: null,
    currentItemProducts: {},
    isReturnItemLoading: false,
    wasReturnItemLoaded: false,
    were3ImagesUploaded: 0,
    shipmentLabelData: null,
    isLoading: false,
    isPreApprovalLoading: false,
    isUpdateReturnLoading: false,
    isSendToSCLoading: false,
    isFoldedOpen: false,
    isReadyForSubmit: false,
    isUpdateConfirmPopupActive: 0,
    isBeforeUpdateConfirmPopupActive: false,
    isBeforeSwitchBetweenReturnPopupActive: false,
    returnIdToSwitch: null,
    returnIdToSwitchAfterUpdate: null,
    isDialogSendToCSActive: false,
    isSCEmailSuccess: true,
    isErrorDialogShouldBeVisible: true,
    isPreApproved: false,
    returnFeeFixed: null,
    returnFeeFixedError: null,
    currentProductState: {},
    returnFees: {},
    refund: {},
    reducedValues: {},
    merchantFiles: {}
};

// memoization for reducedValues and returnFees to avoid reducer changes
// receives their values on RETURNS_SET_FIXED_RETURN_FEE and RETURNS_CHANGE_SUBMIT_STATE_BASED_ON_REDUCED_VALUES actions
// it a temporary solution. Need to rethink this functionality in general
let reducedValues = {};
let returnFees = {};
let merchantFiles = {};

export default function (state = defaultState, action = {}) {
    let {type, data, payload} = action;
    switch (type) {
        case Const.RETURNS_START_FETCH_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Const.RETURNS_START_UPDATE_REQUEST:
            return {
                ...state,
                isUpdateReturnLoading: true
            };
        case Const.RETURNS_START_GET_PREAPPROVAL_REQUEST:
            return {
                ...state,
                isPreApprovalLoading: true
            };
        case Const.RETURNS_SEND_TO_CS_START:
            return {
                ...state,
                isSendToSCLoading: true
            };
        case Const.RETURNS_FETCH_MORE_REQUEST:
            return {
                ...state,
                data: concatenateData(state.data, data),
                tableCurrentPage: state.tableCurrentPage += 1,
                isLoading: false
            };
        case Const.RETURNS_CHANGE_TABLE_CURRENT_PAGE:
            return {
                ...state,
                tableCurrentPage: payload
            };
        case Const.RETURNS_START_GET_ITEM_REQUEST:
            return {
                ...state,
                isReturnItemLoading: true,
                wasReturnItemLoaded: false
            };
        case Const.LEFT_SIDE_BAR_IS_FOLDED_OPEN:
            return {
                ...state,
                isFoldedOpen: payload
            };
        case Const.RETURNS_CLEAR_CURRENT_RETURNS_ITEM:
            return {
                ...state,
                returnItemDefault: null,
                currentReturnItem: null,
                currentReturnItemUpdateInfo: null,
                defaultCurrentItem: null,
                currentItemProducts: {},
                shipmentLabelData: null,
                isReturnItemLoading: false,
                wasReturnItemLoaded: false,
                returnFeeFixed: null,
                returnFeeFixedError: null,
                currentProductState: {}
            };
        case Const.RETURNS_CLEAR_STATE:
            return {
                data: new GeneralModel({}),
                returnItemDefault: null,
                currentReturnItem: null,
                currentReturnItemUpdateInfo: null,
                currentItemProducts: {},
                isReturnItemLoading: false,
                wasReturnItemLoaded: false,
                were3ImagesUploaded: 0,
                shipmentLabelData: null,
                isLoading: false,
                isPreApprovalLoading: false,
                isUpdateReturnLoading: false,
                isSendToSCLoading: false,
                isFoldedOpen: false,
                isReadyForSubmit: false,
                isUpdateConfirmPopupActive: 0,
                isBeforeUpdateConfirmPopupActive: false,
                isBeforeSwitchBetweenReturnPopupActive: false,
                returnIdToSwitch: null,
                returnIdToSwitchAfterUpdate: null,
                isDialogSendToCSActive: false,
                isSCEmailSuccess: true,
                isErrorDialogShouldBeVisible: true,
                isPreApproved: false,
                returnFeeFixed: null,
                returnFeeFixedError: null,
                currentProductState: {},
                returnFees: {},
                refund: {},
                reducedValues: {},
                merchantFiles: {}
            };
        case Const.RETURNS_GET_SHIPMENT_LABEL:
            return {
                ...state,
                shipmentLabelData: data
            };
        case Const.RETURNS_GET_RETURN_ITEM:
            return {
                ...state,
                isReturnItemLoading: false,
                wasReturnItemLoaded: true,
                returnItemDefault: updateCurrentReturnItem(data),
                currentReturnItem: updateCurrentReturnItem(data),
                currentReturnItemUpdateInfo: getCurrentReturnItemUpdateInfo(data.toJSONView()),
                currentItemProducts: getItemProduct(data),
                isReadyForSubmit: false,
                returnIdToSwitch: null,
                returnIdToSwitchAfterUpdate: null
            };
        case Const.RETURNS_TOGGLE_APPROVE_FLAG:
            return {
                ...state,
                currentReturnItem: toggleApproveItemState(state.currentReturnItem),
                isReadyForSubmit: isReadyForSubmitAfterMerchantFiles(merchantFiles, state.currentReturnItem) || isReadyForSubmitAfterReducedValue(reducedValues, state.currentReturnItem) || isReadyForSubmitAfterFixedFee(state.returnFees, state.currentReturnItem) || isReadyForSubmit(state.returnItemDefault, toggleApproveItemState(state.currentReturnItem), state.currentItemProducts)
            };
        case Const.RETURNS_CHANGE_RETURN_ITEM_STATE:
            let changeReturnItemState = {
                ...state,
                currentItemProducts: changeProductState(state.currentItemProducts, payload),
            };
            return {
                ...changeReturnItemState,
                currentProductState: {
                    id: payload.id,
                    state: payload.state,
                    currentShopType: payload.currentShopType,
                    currentOrderId: payload.currentOrderId,
                    currentPrice: payload.currentPrice,
                    currentDiminishedPrice: payload.currentDiminishedPrice,
                    currentState: payload.currentState
                },
                currentReturnItem: autoToggleApproveItemState(state.currentReturnItem, changeReturnItemState.currentItemProducts, payload.currentShopType),
                isReadyForSubmit: isReadyForSubmitAfterMerchantFiles(merchantFiles, state.currentReturnItem) || isReadyForSubmitAfterReducedValue(reducedValues, state.currentReturnItem) || isReadyForSubmitAfterFixedFee(state.returnFees, state.currentReturnItem) || isReadyForSubmit(state.returnItemDefault, state.currentReturnItem, changeReturnItemState.currentItemProducts)
            };
        case Const.RETURNS_TOGGLE_RETURN_ITEM_PRODUCT_COMMENT_AREA:
            let toggleReturnItemProductCommentAreaState = {
                ...state,
                currentItemProducts: getToggleExpandedItemProduct(state.currentItemProducts, payload.id),
            };
            return {
                ...toggleReturnItemProductCommentAreaState,
                currentReturnItem: autoToggleApproveItemState(state.currentReturnItem, toggleReturnItemProductCommentAreaState.currentItemProducts, payload.currentShopType),
                isReadyForSubmit: isReadyForSubmitAfterMerchantFiles(merchantFiles, state.currentReturnItem) || isReadyForSubmitAfterReducedValue(reducedValues, state.currentReturnItem) || isReadyForSubmitAfterFixedFee(state.returnFees, state.currentReturnItem) || isReadyForSubmit(state.returnItemDefault, state.currentReturnItem, toggleReturnItemProductCommentAreaState.currentItemProducts)
            };
        case Const.RETURNS_TOGGLE_RETURN_ITEM_PRODUCT_FLAGGED_STATE:
            let toggleReturnItemProductFlaggedState = {
                ...state,
                currentItemProducts: getToggleItemProductFlaggedState(state.currentItemProducts, payload.id),
            };
            return {
                ...toggleReturnItemProductFlaggedState,
                currentReturnItem: autoToggleApproveItemState(state.currentReturnItem, toggleReturnItemProductFlaggedState.currentItemProducts, payload.currentShopType),
                isReadyForSubmit: isReadyForSubmitAfterMerchantFiles(merchantFiles, state.currentReturnItem) || isReadyForSubmitAfterReducedValue(reducedValues, state.currentReturnItem) || isReadyForSubmitAfterFixedFee(state.returnFees, state.currentReturnItem) || isReadyForSubmit(state.returnItemDefault, state.currentReturnItem, toggleReturnItemProductFlaggedState.currentItemProducts)
            };
        case Const.RETURNS_CHANGE_PRODUCT_FILTER:
            let changeProductFilterState = {
                ...state,
                currentItemProducts: updateProductFilter(state.currentItemProducts, payload),
            };
            return {
                ...changeProductFilterState,
                currentReturnItem: autoToggleApproveItemState(state.currentReturnItem, changeProductFilterState.currentItemProducts, payload.currentShopType),
                isReadyForSubmit: isReadyForSubmitAfterMerchantFiles(merchantFiles, state.currentReturnItem) || isReadyForSubmitAfterReducedValue(reducedValues, state.currentReturnItem) || isReadyForSubmitAfterFixedFee(state.returnFees, state.currentReturnItem) || isReadyForSubmit(state.returnItemDefault, state.currentReturnItem, changeProductFilterState.currentItemProducts)
            };
        case Const.RETURNS_CHANGE_PRODUCT_FILTER_TO_DEFAULT:
            let changeProductFilterToDefaultState = {
                ...state,
                currentItemProducts: updateProductFilterToDefault(state.currentItemProducts, state.currentReturnItem, payload.id)
            };
            return {
                ...changeProductFilterToDefaultState,
                currentReturnItem: autoToggleApproveItemState(state.currentReturnItem, changeProductFilterToDefaultState.currentItemProducts, payload.currentShopType),
                currentItemProducts: updateProductFilterToDefault(changeProductFilterToDefaultState.currentItemProducts, state.currentReturnItem, payload.id),
                isReadyForSubmit: isReadyForSubmitAfterMerchantFiles(merchantFiles, state.currentReturnItem) || isReadyForSubmitAfterReducedValue(reducedValues, state.currentReturnItem) || isReadyForSubmitAfterFixedFee(state.returnFees, state.currentReturnItem) || isReadyForSubmit(state.returnItemDefault, state.currentReturnItem, changeProductFilterToDefaultState.currentItemProducts)
            };
        case Const.RETURNS_CONFIRM_UPDATE_ORDER:
            return {
                ...state,
                isUpdateConfirmPopupActive: state.isUpdateConfirmPopupActive+1,
                returnIdToSwitchAfterUpdate: state.returnIdToSwitch ? state.returnIdToSwitch : null,
                returnFeeFixedError: data && data.toJSONView ? data.toJSONView().requestStatus === 'error' ? data.toJSONView().message : null : null,
            };
        case Const.RETURNS_CONFIRM_POPUP_BEFORE_UPDATE_ORDER_SHOW:
            return {
                ...state,
                isBeforeUpdateConfirmPopupActive: true
            };
        case Const.RETURNS_CONFIRM_POPUP_BEFORE_UPDATE_ORDER_CLOSE:
            return {
                ...state,
                isBeforeUpdateConfirmPopupActive: false
            };
        case Const.RETURNS_SEND_TO_CS:
            return {
                ...state,
                currentReturnItem: updateSCTimestamp(state.currentReturnItem, data),
                isDialogSendToCSActive: true,
                isSendToSCLoading: false,
                isSCEmailSuccess: data.getField('success')
            };
        case Const.RETURNS_CLOSE_SEND_NOTE_TO_THE_CUSTOMER:
            return {
                ...state,
                isDialogSendToCSActive: false,
                isSCEmailSuccess: true
            };
        case Const.RETURNS_HIDE_ERROR_MESSAGE:
            return {
                ...state,
                isErrorDialogShouldBeVisible: false
            };
        case Const.RETURNS_PRE_APPROVE:
            return {
                ...state,
                isPreApprovalLoading: false,
                isPreApproved: data && (data.requestStatus === 'success')
            };
        case Const.RETURNS_CONFIRM_POPUP_BEFORE_SWITCH_RETURN_SHOW:
            return {
                ...state,
                isBeforeSwitchBetweenReturnPopupActive: true,
                returnIdToSwitch: payload
            };
        case Const.RETURNS_CONFIRM_POPUP_BEFORE_SWITCH_RETURN_CLOSE:
            return {
                ...state,
                isBeforeSwitchBetweenReturnPopupActive: false,
                returnIdToSwitch: state.isUpdateReturnLoading ? state.returnIdToSwitch : null
            };
        case Const.RETURNS_CHANGE_SUBMIT_STATE_BASED_ON_REDUCED_VALUES:
            reducedValues = payload.reducedValues;
            return {
                ...state,
                reducedValues,
                isReadyForSubmit: isReadyForSubmitAfterMerchantFiles(merchantFiles, state.currentReturnItem) || isReadyForSubmitAfterReducedValue(payload.reducedValues, state.currentReturnItem) || isReadyForSubmitAfterFixedFee(state.returnFees, state.currentReturnItem) || isReadyForSubmit(state.returnItemDefault, state.currentReturnItem, state.currentItemProducts)
            };
        case FixedFeeSelectorConst.FIXED_FEE_SELECTOR_SET_RETURN_FEE:
            let fixedFeeObject = {
                returnFees: {
                    ...state.returnFees,
                    [payload.returnOrderId]: !payload.returnFee && payload.returnFee !== '0' ? '' : parseFloat(payload.returnFee)
                },
            };
            return {
                ...state,
                returnFees: fixedFeeObject.returnFees,
                isReadyForSubmit: isReadyForSubmitAfterFixedFee(fixedFeeObject.returnFees, state.currentReturnItem) || isReadyForSubmitAfterReducedValue(reducedValues, state.currentReturnItem) || isReadyForSubmit(state.returnItemDefault, state.currentReturnItem, state.currentItemProducts)
            };
        case ReturnDescriptionConst.RETURN_DESCRIPTION_SET_REFUND:
            return {
                ...state,
                refund: {
                    ...state.refund,
                    [payload.returnOrderId]: payload.refund
                }
            };
        case Const.RETURNS_CLEAR_FIXED_RETURN_FEE:
            return {
                ...state,
                returnFeeFixedError: null
            };
 /*       case Const.RETURNS_CHANGE_SUBMIT_STATE_BASED_ON_REDUCED_VALUES:
            reducedValues = payload.reducedValues;
            return {
                ...state,
                isReadyForSubmit: isReadyForSubmitAfterMerchantFiles(merchantFiles, state.currentReturnItem) || isReadyForSubmitAfterReducedValue(payload.reducedValues, state.currentReturnItem) || isReadyForSubmitAfterFixedFee(payload.returnFees, state.currentReturnItem) || isReadyForSubmit(state.returnItemDefault, state.currentReturnItem, state.currentItemProducts)
            };*/
        case DropZoneConst.DROP_ZONE_FILES_WERE_UPDATED:
            merchantFiles = payload.merchantFiles;
            return {
                ...state,
                merchantFiles: getMerchantFiles(merchantFiles, state.currentReturnItem),
                isReadyForSubmit: isReadyForSubmitAfterMerchantFiles(merchantFiles, state.currentReturnItem) || isReadyForSubmitAfterReducedValue(reducedValues, state.currentReturnItem) || isReadyForSubmitAfterFixedFee(returnFees, state.currentReturnItem) || isReadyForSubmit(state.returnItemDefault, state.currentReturnItem, state.currentItemProducts)
            };
        case Const.DROP_ZONE_S3_IMAGES_UPLOADED: {
            return {
                ...state,
                isUpdateReturnLoading: false,
                were3ImagesUploaded: state.were3ImagesUploaded+1
            }
        }
        default:
            return state;
    }
}
