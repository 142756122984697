import React, {Component} from "react";
import {
    Chart,
    BarSeries,
    ArgumentAxis,
    ValueAxis,
    Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import {Grid} from "@material-ui/core";
import {EventTracker, HoverState} from '@devexpress/dx-react-chart';
import CustomTranslation from "../../../components/CustomTranslation/CustomTranslation";
import {withLocalize} from "react-localize-redux";
import {chunk} from 'lodash'

class BarChart extends Component {

    /**
     * getTranslatedChartName {Function}
     * @description generates proper localization key
     * @return {React Component}
     * */
    getTranslatedChartName = (title) => {
        let {chartControlName} = this.props;
        let prefix = '';

        if (chartControlName === 'highestChartControls') {
            prefix = 'dashboard.chart.highestValueGenerated.tab'
        }
        if (chartControlName === 'returnsChartControls') {
            prefix = 'dashboard.chart.returnReasons.tab'
        }



        return <CustomTranslation id={`${prefix}.${title}`}/>;
    };

    /**
     * getTranslatedChartLabels {Function}
     * @description generates proper localization key for bar chart labels
     * @return {React Component}
     * */
    getTranslatedChartLabels = (props) => {
        let {text, x, y, dy, textAnchor} = props;

        let label = text;
        let labelParts = label ? label.split(' ') : [];
        let partsNumber = 0;

        // need to chunk more in case of small resolution
        if(window.innerWidth > 1441) {
            partsNumber = 3;
        } else {
            partsNumber = 2;
        }


        let partsToJoin = chunk(labelParts, partsNumber);

        return (
            <text className="b-analytics-page__bar_chart_argument_label" x={x} y={y} dy={dy} textAnchor={textAnchor}>
                {partsToJoin.map((textPart, key)=>{
                    return(
                        <tspan key={key} dy={dy} x={x} textAnchor="middle">{textPart.join(' ')}</tspan>
                    )
                })}
            </text>
        )
    };

    render() {
        let {
            chartData = [], chartTitle = '', chartControls = [], chartControlName = '', chartControlAction = () => {
            }
        } = this.props;
        return (
            <Grid container className="b-analytics-page__grid_container" spacing={0}>
                <Grid item xs={12} md={12} lg={12} className="b-analytics-page__block">
                    <div className="b-analytics-page__chart_wrapper_header b-flex">
                        <div
                            className="b-analytics-page__chart_wrapper_title">{this.getTranslatedChartName(chartTitle)}</div>
                        <div className="b-analytics-page__chart_wrapper_controls">
                            <ul className="b-analytics-page__chart_wrapper_list">
                                { chartControls.map((control, key) => (
                                    <li
                                        key={key}
                                        className={`b-analytics-page__chart_wrapper_list_item${control.isActive ? ' active' : ''}`}
                                        onClick={() => {
                                            chartControlAction(control.type, chartControlName);
                                            /*let el = document; // This can be your element on which to trigger the event
                                            let event = document.createEvent('HTMLEvents');
                                            event.initEvent('resize', true, false);
                                            el.dispatchEvent(event)*/
                                        }}>
                                        {this.getTranslatedChartName(control.type)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className={`b-analytics-overtime-chart`}>
                            <Chart data={chartData} height={308}>
                                {
                                    chartControlName === 'returnsChartControls'
                                        ?
                                        <ArgumentAxis indentFromAxis={5} labelComponent={(props) => {
                                            return this.getTranslatedChartLabels(props)
                                        }}/>
                                        :
                                        <ArgumentAxis indentFromAxis={5}/>
                                }

                                <ValueAxis/>
                                <BarSeries valueField='valueField' argumentField='argumentField' color="#767676"
                                           barWidth={0.4}/>
                                <EventTracker/>
                                <HoverState/>
                                <Tooltip/>
                            </Chart>
                    </div>
                </Grid>
            </Grid>
        )
    };
}

export default withLocalize(BarChart);
