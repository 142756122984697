import Const from "./../const";
import GeneralModel from "./../../../models/GeneralModel";

const defaultState = {
    isFoldedOpen: false,
    isLoading: false,
    isPopupVisible: false,
    data: new GeneralModel(),
    isAllTableDataShouldBeRendered: true,
    wasAdminStatusChanged: false,
    wasNewAdminCreated: false,
    wasRequestToResetPasswordDelivered: false,
    wasNewAdminCreationFailed: false,
    newAdminNameCurrent: '',
    newAdminLastNameCurrent: '',
    newAdminEmailCurrent: '',
    newAdminName: '',
    newAdminLastName: '',
    newAdminEmail: '',
    newAdminNameError: false,
    newAdminLastNameError: false,
    newAdminEmailError: false,
    isEmailAlreadyInUseError: false
};


export default function (state = defaultState, action = {}) {
    let {type, data, payload={}, middleData={}} = action;
    switch (type) {
        case Const.LEFT_SIDE_BAR_IS_FOLDED_OPEN:
            return {
                ...state,
                isFoldedOpen: payload
            };
        case Const.MANAGEMENT_START_FETCH_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Const.MANAGEMENT_FETCH_REQUEST:
            return {
                ...state,
                data: data,
                isLoading: false
            };
        case Const.MANAGEMENT_SHOW_POPUP:
            return {
                ...state,
                isPopupVisible: true
            };
        case Const.MANAGEMENT_HIDE_POPUP:
            return {
                ...state,
                isPopupVisible: false,
                wasAdminStatusChanged: false,
                wasRequestToResetPasswordDelivered: false,
                wasNewAdminCreationFailed: false,
                wasNewAdminCreated: false,
                newAdminName: '',
                newAdminLastName: '',
                newAdminEmail: '',
                newAdminNameError: false,
                newAdminLastNameError: false,
                newAdminEmailError: false,
                isEmailAlreadyInUseError: false
            };
        case Const.MANAGEMENT_SWITCH_TAB:
            return {
                ...state,
                isAllTableDataShouldBeRendered: payload,
                isLoading: false
            };
        case Const.MANAGEMENT_CHANGE_STATUS:
            return {
                ...state,
                isLoading: false,
                wasAdminStatusChanged: true
            };
        case Const.MANAGEMENT_RESET_PASSWORD:
            return {
                ...state,
                isLoading: false,
                wasRequestToResetPasswordDelivered: true,
                newAdminNameCurrent: middleData.firstName,
                newAdminLastNameCurrent: middleData.lastName,
                newAdminEmailCurrent: middleData.email,
            };
        case Const.MANAGEMENT_CHANGE_NEW_ADMIN_DATA:
            return {
                ...state,
                [payload.fieldName]: payload.value,
                [payload.fieldName+'Current']: payload.value,
                [payload.fieldName+'Error']: false,
                isEmailAlreadyInUseError: false
            };
        case Const.MANAGEMENT_SET_ERROR:
            return {
                ...state,
                [payload.fieldName+'Error']: true
            };
        case Const.MANAGEMENT_CREATE_NEW_ADMIN:
            let isRequestSuccess = data.getField('success');
            return {
                ...state,
                isLoading: false,
                wasNewAdminCreated: isRequestSuccess ? true : false,
                wasNewAdminCreationFailed :  isRequestSuccess ? false : true
            };
        case Const.MANAGEMENT_SET_ERRORS_TO_ADD_NEW_ADMIN_POPUP:
            return {
                ...state,
                isEmailAlreadyInUseError: true,
                newAdminName: state.newAdminNameCurrent,
                newAdminLastName: state.newAdminLastNameCurrent,
                newAdminEmail: state.newAdminEmailCurrent,
                newAdminEmailError: true
            };
        case Const.MANAGEMENT_CLEAR_CURRENT_ADMIN:
            return {
                ...state,
                newAdminNameCurrent: '',
                newAdminLastNameCurrent: '',
                newAdminEmailCurrent: '',
            };
        default:
            return state;
    }
}
