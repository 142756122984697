import {createMuiTheme} from "@material-ui/core/styles";

const fuseDark = {
    50  : '#ECECEE',
    100 : '#C5C6CB',
    200 : '#9EA1A9',
    300 : '#7D818C',
    400 : '#5C616F',
    500 : '#3C4252',
    600 : '#353A48',
    700 : '#2D323E',
    800 : '#262933',
    900 : '#1E2129',
    A100: '#C5C6CB',
    A200: '#9EA1A9',
    A400: '#5C616F',
    A700: '#2D323E',
    dark: 'rgb(48, 48, 48)',
    red: '#da2127',
    green: '#009e86',
    blue: '#039be5',
    light: '#FFFFFF',
    lightGray: '#f5f5f5',
    lightTextColor: '#f5f5f5',
    activeControlColor: '#303030'
};

let returnBtnColors = {
    "reject": "#fa6532",
    "delete": "#da2127",
    "approve": "#009e86",
    "pending": "#aaaaaa"
};

let returnStatusColors = {
    "NULL": "inherit",
    "ALL": "inherit",
    "PRE_APPROVAL": "#a12127",
    "LABEL_FAILED": "#da2127",
    "NOT_PROCESSED": "#aaaaaa",
    "PROCESSING": "#fa6532",
    "ALL_ITEMS_BACK": "#009e86"
};

let returnShipmentStatusColors = {
    "ALL": "#aaaaaa",
    "NOT_IN_TRANSIT": "#aaaaaa",
    "READY_FOR_PICKUP": "#EF6C00",
    "DROPPED_OFF": "#e6b600",
    "IN_TRANSIT": "#e6b600",
    "DELIVERED": "#009e86",
    "WARNING": "#da2127",
    "OTHER": "#aaaaaa",
    "NA": "#aaaaaa"
};

export default createMuiTheme({
    spacing: 0,
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: {
            main: '#009E86',
        },
        secondary: {
            main: '#f5f5f5',
        },
        brand: {
            main: fuseDark,
            dark: '#000000',
            background: '#f5f5f5',
            returnStatusColors: returnStatusColors,
            returnShipmentStatusColors: returnShipmentStatusColors,
            btnColors: returnBtnColors
        },
    },
});