import React, {Component} from 'react';
import {withFormsy} from 'formsy-react';
import _ from 'lodash';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {withStyles} from "@material-ui/core/styles";

const styles = theme => {
    return {
        search: {
            position: 'relative',
            backgroundColor: fade(theme.palette.brand.main.light, 0.8),
            '&:hover': {
                backgroundColor: theme.palette.brand.main.light,
            },
            border: '1px solid #ebebeb',
            borderRadius: '5px',
            marginLeft: 0,
            width: 'auto',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        searchIconWrapper: {
            width: theme.spacing(9),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        searchIcon: {
            color: theme.palette.brand.main.dark,
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
        },
        inputInput: {
            paddingTop: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(10),
            transition: theme.transitions.create('width'),
            minHeight: '30px',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 120,
                '&:focus': {
                    width: 200,
                },
            },
            [theme.breakpoints.up('lg')]: {
                width: '80%',
                '&:focus': {
                    width: '100%',
                },
            },
        },
    }
};

class SearchField extends Component {

    changeValue = (event) => {
        this.props.setValue(event.currentTarget.value);
        if ( this.props.onChange ) {
            this.props.onChange(event);
        }
    };

    render() {
        let {classes, classNameCustom=''} = this.props;
        const importedProps = _.pick(this.props, [
            'autoComplete',
            'autoFocus',
            'children',
            'className',
            'defaultValue',
            'disabled',
            'FormHelperTextProps',
            'fullWidth',
            'id',
            'InputLabelProps',
            'inputProps',
            'InputProps',
            'inputRef',
            'label',
            'multiline',
            'name',
            'onBlur',
            'onChange',
            'onFocus',
            'placeholder',
            'required',
            'rows',
            'rowsMax',
            'select',
            'SelectProps',
            'type',
            'variant'
        ]);

        const errorMessage = this.props.errorMessage;
        const value = this.props.value || '';

        return (
            <div className={`${classes.search} ${classNameCustom} `}>
                <div className={classes.searchIconWrapper}>
                    <SearchIcon className={classes.searchIcon} />
                </div>
                <InputBase
                    {...importedProps}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    onChange={this.changeValue}
                    value={value}
                    error={Boolean(errorMessage)}
                />
            </div>
        );
    }
}

export default withFormsy(withStyles(styles)(SearchField));
