import Const from './../const';

/**
* requestStart {Function}
* @return Object in React Action format before request start
*
* */
let requestStart = () =>{
    return {
        type: Const.ITEM_DATA_REPORT_START_FETCH_REQUEST
    }
};

/**
* setItemDataReportFilter {Function}
* @return Object in React Action format before request start
*
* */
export function setItemDataReportFilter(label, value) {
    return dispatch => {
        dispatch({
            type: Const.ITEM_DATA_REPORT_CHANGE_FILTER,
            payload: {
                label,
                value
            }
        });
    }
}

/**
* fetchItemDataReport {Function}
* @return Object in React Action format before request start
*
* */

export function fetchItemDataReport(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.ITEM_DATA_REPORT_FETCH,
            ...requestData
        });
    }
}

/**
* fetchItemDataReportSCV {Function}
* @return Object in React Action format before request start
*
* */

export function fetchItemDataReportSCV(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.ITEM_DATA_REPORT_CSV_FETCH,
            ...requestData
        });
    }
}

/**
* clearFilters {Function}
* @return clear filters to default state
*
* */

export function clearFilters() {
    return dispatch => {
        dispatch({
            type: Const.ITEM_DATA_CLEAR_FILTERS
        });
    }
}