import React, {Component} from "react";
import _ from 'lodash';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {
    close,
    cleanLoginFields
} from './actions/serverErrorNotifierActions';
import {
    Dialog, 
    DialogContent, 
    DialogTitle, 
    DialogContentText, 
    Slide, 
    withStyles
} from '@material-ui/core';
import CustomTranslation from "../CustomTranslation/CustomTranslation";
import ResetPasswordConst from './../ResetPassword/const';
import CloseIcon from '@material-ui/icons/Cancel';
import {getTranslate} from "react-localize-redux";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {getSupportEmail} from "./../../utilites";

const credentialsExpiredMessage = 'Credentials expired';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => {
    return {
        btn: {
            width: '50%',
        }
    }
};

class ServerErrorNotifier extends Component {

    constructor(props) {
        super(props);
        this.isLoginError = false;
    }

    /**
     * getTokenValidationErrors {Function}
     * @description - method for handling and localize errors from server related to proper new password format
     *
     * */
    getTokenValidationErrors = (messagesString) => {
        let messagesArr = messagesString.split(',');
        return (
            <span className='b-server-error-notifier__list'>
                {
                    messagesArr.map((localizationKey, key) => {
                        return <span key={key} className='b-server-error-notifier__item'>{<CustomTranslation
                            id={`reset.passwordRules.${localizationKey}`}/>}</span>
                    })
                }
            </span>
        )
    };

    getDefaultMessage = (messageType) => {
        let {translate , notes, language} = this.props;
        if(translate('return.order.details.remarksFilter.WARNING') === 'No translation found!') {
            if(!notes[language]) {
                return `${notes['en'][messageType]}`;
            } else {
                return (<span>{notes[language][messageType]} {messageType === 'description' ? getSupportEmail() : ''}</span>);
            }
        }
    };

    getErrorTitle = (status, errorData=null, actionType) => {
        let titleStr = '';
        switch (status) {
            case 401:
                this.isLoginError = true;
                if (_.isEmpty(errorData) || !errorData) {
                    titleStr = <CustomTranslation id='login.warning.wrong.credentials.title' />;
                } else {
                    titleStr = this.isCredentialsExpired(status, errorData.message) ? <CustomTranslation id='login.warning.expired.credentials.title'/>  : <CustomTranslation id='login.warning.wrong.credentials.title' />
                }
                break;
            case 422:
                switch (actionType) {
                    case 'RETURNS_CONFIRM_UPDATE_ORDER':
                        titleStr = <CustomTranslation id='return.order.leave.unsaved.warning.title' />;
                        break;
                    default:
                        titleStr = titleStr = <CustomTranslation id='reset.passwordRules.title' />;
                }
                break;
            case 500:
                titleStr = <CustomTranslation id='server.response.error.something.wrong.headline' />;
                break;
            case 502:
                titleStr = <CustomTranslation id='server.response.error.something.wrong.headline' />;
                break;
            case 504:
                titleStr = <CustomTranslation id='server.response.error.connection.not.successful.headline' />;
                break;
            case 'default_error':
                titleStr = this.getDefaultMessage('title');
                break;
            default:
                titleStr = <CustomTranslation id='return.order.details.remarksFilter.WARNING' />;
                break;
        }

        return titleStr;
    };

    /**
     * getErrorText {Function}
     * @description - get Text for displaying in error block.
     *
     * */
    getErrorText = (text, errorData, status, actionType) => {
        let textStr = '';
        switch (status) {
            case 401:
                this.isLoginError = true;
                if (_.isEmpty(errorData)) {
                    textStr = <CustomTranslation id='login.warning.wrong.credentials.description' />;
                } else {
                    textStr = this.isCredentialsExpired(status, errorData.message) ? <CustomTranslation id='login.form.expired.credentials.error'/> : errorData.message
                }
                break;
            case 422:
                switch (actionType) {
                    case ResetPasswordConst.RESET_PASSWORD_UPDATE_PASSWORD:
                        if (_.isEmpty(errorData)) {
                            textStr = text;
                        } else {
                            textStr = this.getTokenValidationErrors(errorData.message);
                        }
                        break;
                    case 'RETURNS_CONFIRM_UPDATE_ORDER':
                        textStr = <CustomTranslation id={errorData.message} />;
                        break;
                    default:
                        textStr = (errorData && errorData.message) || text || 'Server Error';
                }
                break;
            case 500:
                textStr = <CustomTranslation id='server.response.error.something.wrong.bread' data={{customerSupportEmail: getSupportEmail()}} />;
                break;
            case 502:
                textStr = <CustomTranslation id='server.response.error.something.wrong.bread' data={{customerSupportEmail: getSupportEmail()}} />;
                break;
            case 504:
                textStr = <CustomTranslation id='server.response.error.connection.not.successful.bread' />;
                break;
            case 'default_error':
                textStr = this.getDefaultMessage('description');
                break;
            default:
                textStr = 'Unknown Error';
        }

        return textStr;
    };

    /**
     * handleClose {Function}
     * @description - close the notifier
     *
     * */
    handleClose = () => {
        this.props.close()
    };

    /**
     * handleWrongLoginError {Function}
     * @description - close the notifier and clear login form
     *
     * */
    handleWrongLoginError = () => {
        this.props.cleanLoginFields();
        this.isLoginError = false;
        this.handleClose()
    };

    /**
     * isErrorWithHelpdesk {function}
     * @description - based on error status define do we need to show 'helpdesk' email or not
     * @return {Boolean}
     * */
    isErrorWithHelpdesk = (status) => {
        switch (status) {
            case 504:
                return true;
            default:
                return false;
        }
    };

    /**
     * isErrorWithHelpdesk {function}
     * @description - based on error status define is this about expired credentials
     * @return {Boolean}
     * */
    isCredentialsExpired = (status, message) => {
        return status === 401 && message === credentialsExpiredMessage;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {isActive, errorDialogState} = this.props;

        if(prevProps.isActive !== isActive && isActive) {
           if(errorDialogState.isActive){
               this.props.close()
           }
        }

        if(prevProps.errorDialogState.isActive !== errorDialogState.isActive && errorDialogState.isActive){
            this.props.close()
        }
    }

    render() {
        let {isActive, text, data, status, actionType, classes, errorDialogState={}, notes, language} = this.props;
        let {wasLocalizationLoaded} = errorDialogState;
        return (
            <Dialog
                open={isActive}
                onClose={this.handleClose}
                maxWidth={'sm'}
                TransitionComponent={Transition}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={isActive ? `${status === 'default_error' ? 'b-server-error-notifier b-server-error-notifier--error_dialog' : 'b-server-error-notifier'} b-server-error-notifier--active` : 'b-server-error-notifier'}
            >
                {this.handleClose && <CloseIcon className='b-server-error-notifier__close_ctrl' onClick={()=>{
                    if(this.isLoginError) {
                        this.handleWrongLoginError()
                    } else {
                        this.handleClose()
                    }
                }} />}
                <DialogTitle id="alert-dialog-slide-title" className="b-server-error-notifier__title_wrapper">
                        {isActive ? this.getErrorTitle(status, data, actionType) : ''}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <span className="b-server-error-notifier__text">
                            <span className="b-server-error-notifier__text_p">
                                {isActive ? this.getErrorText(text, data, status, actionType) : ''}{' '}
                                {
                                    this.isCredentialsExpired(status, data && data.message ? data.message : '')
                                        ?
                                        <><CustomTranslation id='login.warning.wrong.credentials.footer' /> {getSupportEmail()}</>
                                        :
                                        ''
                                } {
                                    this.isErrorWithHelpdesk(status)
                                        ? getSupportEmail()
                                        :
                                        ''
                                }
                            </span>
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="b-server-error-notifier__actions">
                    <Button onClick={()=>{
                        if(this.isLoginError) {
                            this.handleWrongLoginError()
                        } else {
                            this.handleClose()
                        }
                    }} variant="contained" className={`${classes.btn}`} >
                        {
                            !wasLocalizationLoaded
                            ?
                                !notes[language]
                                ?
                                notes['en'].goBack
                                    :
                                notes[language].goBack
                            :
                            <CustomTranslation id='return.before.update.popup.btnName.goBack' />
                        }

                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.serverErrorNotifierState,
        translate: getTranslate(state.localize),
        mainPropertiesState: state.mainPropertiesState,
        errorDialogState: state.errorDialogState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            close,
            cleanLoginFields
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles)(ServerErrorNotifier));