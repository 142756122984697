export const local = {
    environment: 'local',
    BASE_URL: 'https://admin.vanilla.returnado.com/',//'https://dev.returnado.com/',//'https://staging.returnado.com/', //'https://hh-staging.returnado.com/',//'https://dev.returnado.com/', //'http://localhost:8082/',
    BASE_API: 'admin-api/v2/',
    // WARNING: widget url should not have a trailing slash
    WIDGET_URL: 'widget-ui'
};

export const aws = {
    environment: 'aws',
    BASE_URL: '',
    BASE_API: '../admin-api/v2/',
    // WARNING: widget url should not have a trailing slash
    WIDGET_URL: '../widget-ui'
};
