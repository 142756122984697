import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CustomTranslation from "./../../../components/CustomTranslation/CustomTranslation";

const styles = theme => {
    return {
        dialogBtn: {
            width: '30%',
            display: 'block',
            margin: '0 auto'
        }
    }
};

class ConfirmUpdateDialog extends Component {

    /**
     * handleKeyPress {Function}
     * @description - handle specific key pressing
     *
     * */
    handleKeyPress = (e, confirmAction) => {
        let {charCode} = e;

        if(charCode === 13 || charCode === 32) {
            confirmAction()
        }
    };

    render() {
        let {classes, isOpen=false, title, content, confirmAction=()=>{}} = this.props;

        return  (
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onKeyPress={(e)=>{this.handleKeyPress(e, confirmAction)}}
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={confirmAction} variant="contained" color="primary" className={classes.dialogBtn} >
                        <CustomTranslation id='return.order.confirmation.success.popup.ok.button' />
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };
}


function mapStateToProps() {
    return {};
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {},
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles)(withRouter(ConfirmUpdateDialog)));