import React from 'react';
import {Route, Redirect} from 'react-router';
import Header from './../../components/Header/Header';
import RightMenu from "./../../components/RightMenu/RightMenu";
import LeftMenu from "./../../components/LeftSideBar/LeftSideBar";
import {ShopPropsContext} from "./../../contexts/ShopPoprsContext";

/**
 * isParamsForRedirect {Function}
 * @description - defines is there any params in url for redirect
 * @return {Boolean}
 *
 * */
let isParamsForRedirect = (props) => {
    return props.location && props.location.pathname && props.location.pathname !== '/';
};

/**
 * getParamsForRedirect {Function}
 * @description - get params for redirect from location search
 * @return {String}
 *
 * */
let getParamsForRedirect = (props) => {
    return props.location && props.location.pathname ? props.location.pathname : '';
};

export default isLoggedIn => ({component: Component, pageName = '', ...rest}) => {
    return (
        <Route
            {...rest}
            render={props => {
                return isLoggedIn ? (
                    <React.Fragment>
                        <ShopPropsContext.Consumer>
                            {
                                (mainConfig) => {
                                    return (
                                        <React.Fragment>
                                            <LeftMenu mainConfig={mainConfig}/>
                                            <div className="b-app__layout">
                                                <div className="b-app__header">
                                                    <Header isLoggedIn={isLoggedIn}/>
                                                </div>
                                                <div className="b-app__body">
                                                    <Component {...props} mainConfig={mainConfig}
                                                                   pageName={pageName}/>

                                                </div>
                                                <div className="b-app__footer">
                                                </div>
                                            </div>
                                            <RightMenu/>
                                        </React.Fragment>
                                    )
                                }
                            }
                        </ShopPropsContext.Consumer>
                    </React.Fragment>
                ) : (
                    isParamsForRedirect(props)
                        ?
                        <Redirect to={`/login?redirectTo=${getParamsForRedirect(props)}`}/>
                        :
                        <Redirect to="/login"/>
                );
            }}
        />
    )
};