import Const from './../const';

/**
* close {React Action}
* @description - close an error notify
*
* */
export function close() {
    return dispatch => {
        dispatch({
            type: Const.SERVER_ERROR_NOTIFIER_CLEAR,
        });
    }
}

/**
 * cleanLoginFields {React Action}
 * @description - clean login fields
 *
 * */
export function cleanLoginFields() {
    return dispatch => {
        dispatch({
            type: Const.LOGIN_CLEAN_CREDENTIALS,
        });
    }
}
