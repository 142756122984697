import Const from './../const';

const setUpdatedCommentsToFalse = (state, ids=[]) => {
    let updatedComments = {};

    ids.forEach((idToSetFalse)=>{
        if(state[idToSetFalse]) {
            updatedComments[idToSetFalse] = false
        } else {
            updatedComments[idToSetFalse] = state[idToSetFalse];
        }
    });

    return updatedComments
};

const defaultState = {
    updatedComments: {}
};

export default function (state = defaultState, action = {}) {
    let {type, payload={}} = action;
    let orderItemId = payload ? payload.orderItemId : 0;
    let changedState = payload ? payload.changedState : false;
    switch (type) {
        case Const.RETURN_COMMENTS_AREAS_CLEAN_COMMENT:
            return {
                updatedComments: {}
            };
        case Const.RETURN_COMMENTS_COMMENT_WAS_CHANGED:
            return {
                ...state,
                updatedComments: {
                    ...state.updatedComments,
                    [orderItemId]: changedState
                }
            };
        case Const.RETURN_COMMENTS_COMMENT_SET_TO_FALSE:
            return {
                ...state,
                updatedComments: setUpdatedCommentsToFalse(state.updatedComments, payload)
            };
        default:
            return state;
    }
}
