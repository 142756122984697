import Const from './../const';


/*
* setFoldedOpen {Function}
* @description - handling for open/close left menu
*
* */

export function setFoldedOpen(isFoldedOpen) {
    return dispatch => {
        dispatch({
            type: Const.LEFT_SIDE_BAR_IS_FOLDED_OPEN,
            payload: isFoldedOpen
        });
    }
}

/*
* handleMobileLeftSideBarActiveState {Function}
* @description - handling for open/close left menu
*
* */

export function handleMobileLeftSideBarActiveState(isMobileActive) {
    return dispatch => {
        dispatch({
            type: Const.LEFT_SIDE_BAR_MOBILE_IS_ACTIVE,
            payload: isMobileActive
        });
    }
}

