import _ from 'lodash';
import jwt from 'jsonwebtoken';

/*
* GeneralModel {Class}
* @description: class to create entities using data from server
* @methods:
*
*  - getFieldValue {Function}
*       @description: method to get specific field from data.
*       @param: key {String} - value key
*       @return: key's value
*
*  - getParsedJWT {Function}
*       @description: method to get specific field from data in case if this filed is JWT.
*       @param: key {String} - value key
*       @return: object with two attributes "header" and "payload"
* */

class GeneralModel {

    constructor(data) {
        this.data = data;
    }

    getField(key) {
        let data = this.data;
        let value;

        if(_.isObject(data)) {
            if (data.hasOwnProperty(key)) {
                value = data[key];
            }
        }

        return value;
    }

    getParsedJWT(token) {
        let decode = jwt.decode(token, {complete: true});

        return {
            header: decode.header,
            ...decode.payload
        }
    }

    toJSONView() {
        return this.data;
    }
}

export default GeneralModel;