import React, {Component} from 'react';
import {setIsPasswordVisible, login, is2F, setLoginStateToDefault, setPassword, setUsername, setVerificationCode} from './actions/loginActions';
import {bindActionCreators} from 'redux';
import {TextFieldFormsy} from './../FormsyBasedFields/index';
import {Redirect} from 'react-router';
import {connect} from 'react-redux';
import Grow from '@material-ui/core/Grow';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import {Visibility, VisibilityOff, Security} from '@material-ui/icons';
import Link from '@material-ui/core/Link';
import logoSrc from './../../images/login/logo-element.png';
import bgSrc from './../../images/login/login-bg.jpg';
import twoFactorIconSrc from './../../images/login/google-authenticator-icon.png';
import Formsy from 'formsy-react';
import CustomButton from './../../containers/CustomButton/CustomButton';
import CustomTranslation from "./../CustomTranslation/CustomTranslation";

const TRANSITION_SPEED = 800;

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false
        };
        this.wasSubmitBtnPressed = false;
        this.authRef = React.createRef()
    }

    /**
    * disableButton {Function}
    * @description - change state for submit button disabling
    *
    * */
    disableButton = () => {
        this.setState({canSubmit: false});
    };

    /**
    * enableButton {Function}
    * @description - change state for submit button enabling
    *
    * */

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    /**
    * onSubmit {Function}
    * @description - submit login form
    *
    * */
    onSubmit = (model) => {
        let {username, was2fAChecked, is2fAuthentication} = this.props;
        this.wasSubmitBtnPressed = true;

        if (!was2fAChecked && username) {
            this.is2fAuthentication(username);
        } else {
            if (was2fAChecked && username) {
                this.login(model);
            }
        }

        if (was2fAChecked && is2fAuthentication) {
            this.login(model);
        }

    };

    /**
    * is2fAuthentication {Function}
    * @description - defines if 2f authentication enabled or not
    *
    * */
    is2fAuthentication = (username) => {
        if(username) {
            this.props.is2F({
                route: 'check2f',
                requestData: {
                    username: username
                }
            });
        }
    };

    /**
    * login {Function}
    * @description - send request for login
    *
    * */
    login = (requestData) => {
        this.props.login({
            route: 'login',
            requestData: {
                ...requestData
            }
        });
    };

    componentWillUnmount() {
        this.props.setLoginStateToDefault();
        this.wasSubmitBtnPressed = false;
    }

    componentDidUpdate(prevProps) {
        let {username, password, was2fAChecked, is2fAuthentication} = this.props;

        if(prevProps.was2fAChecked !== was2fAChecked && was2fAChecked && !is2fAuthentication && this.wasSubmitBtnPressed) {
            this.login({
                username,
                password
            })
        }

        if(prevProps.was2fAChecked !== was2fAChecked && was2fAChecked && is2fAuthentication) {
            this.authRef.current.focus();
        }

    }

    render() {
        const {canSubmit} = this.state;
        let {location} = window;
        let {isLoading, isAutoFocus, isPasswordVisible, isLoggedIn, is2fAuthentication, username, password, verificationCode} = this.props;
        let {setIsPasswordVisible} = this.props;
        let isParamFroRedirect = location.hash && location.hash.split('redirectTo=').length ? location.hash.split('redirectTo=')[1] : null;
        return (
            isLoggedIn
                ?
                    isParamFroRedirect
                    ?
                    <Redirect to={isParamFroRedirect} />
                    :
                    <Redirect to={'/analytics'} />
                :
                <Grow in={true} timeout={TRANSITION_SPEED}>
                    <div className='b-login' style={{backgroundImage: `url(${bgSrc})`}}>
                        <div className='b-login__body'>
                            <img className='b-login__logo' src={logoSrc} alt='login logo'/>
                            <Formsy
                                onValidSubmit={this.onSubmit}
                                onValid={this.enableButton}
                                onInvalid={this.disableButton}
                                className='flex flex-col justify-center w-full b-login__form'
                                autoComplete='off'
                            >
                                <div className='b-login__item'>
                                    <div className='b-login__p'>
                                        <TextFieldFormsy
                                            autoFocus={isAutoFocus}
                                            type='text'
                                            name='username'
                                            label={<CustomTranslation id="login.form.username" />}
                                            fullWidth={true}
                                            value={username}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='Toggle password visibility'
                                                            onClick={()=>{setIsPasswordVisible(!isPasswordVisible)}}
                                                        >
                                                            {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                onChange: (e) => {
                                                    this.props.setUsername(e.target.value)
                                                }
                                            }}
                                            variant='outlined'
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='b-login__item'>
                                    <div className='b-login__p'>
                                        <TextFieldFormsy
                                            type={isPasswordVisible ? 'text' : 'password'}
                                            name='password'
                                            label={<CustomTranslation id="login.form.password" />}
                                            fullWidth={true}
                                            value={password}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='Toggle password visibility'
                                                            onClick={()=>{setIsPasswordVisible(!isPasswordVisible)}}
                                                        >
                                                            {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                onChange: (e)=>{
                                                    this.props.setPassword(e.target.value)
                                                }
                                            }}
                                            variant='outlined'
                                            required
                                        />
                                    </div>
                                    {
                                        is2fAuthentication
                                        ?
                                        <div className='b-login__item'>
                                            <div className='b-login__p b-flex'>
                                                <div className='b-login__two_factor_auth_icon' style={{backgroundImage: `url(${twoFactorIconSrc})`}}></div>
                                                <TextFieldFormsy
                                                    type='text'
                                                    name='verificationCode'
                                                    label={<CustomTranslation id="login.form.authCode" />}
                                                    value={verificationCode}
                                                    inputRef={this.authRef}
                                                    fullWidth={true}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position='end'>
                                                                <Security />
                                                            </InputAdornment>
                                                        ),
                                                        onChange: (e)=>{
                                                            this.props.setVerificationCode(e.target.value)
                                                        }
                                                    }}
                                                    variant='outlined'
                                                    required
                                                />
                                            </div>
                                        </div>
                                        :
                                            <div className='b-login__item'>
                                                <div className='b-login__p b-flex'>
                                                    <div className='b-login__two_factor_auth_icon' style={{backgroundImage: `url(${twoFactorIconSrc})`}}></div>
                                                    <TextFieldFormsy
                                                        type='text'
                                                        name='verificationCode'
                                                        label={<CustomTranslation id="login.form.authCode" />}
                                                        value={verificationCode}
                                                        fullWidth={true}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position='end'>
                                                                    <Security />
                                                                </InputAdornment>
                                                            ),
                                                            onChange: (e)=>{
                                                                this.props.setVerificationCode(e.target.value)
                                                            }
                                                        }}
                                                        variant='outlined'
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                    }
                                </div>
                                <div className='b-login__item'>
                                    <div className='b-login__p'>
                                        <CustomButton
                                            type='submit'
                                            variant='contained'
                                            fullWidth={true}
                                            color='primary'
                                            aria-label={<CustomTranslation id="login.form.button" />}
                                            isdisabled={!canSubmit}
                                            loading={isLoading}
                                            name={<CustomTranslation id="login.form.button" />}
                                        />
                                    </div>
                                    <div className='b-login__p b-login__p--reset_password_link'>
                                        <Link href={'#/reset-password'} color='primary'><CustomTranslation id="login.form.restore.password" /></Link>
                                    </div>
                                </div>
                            </Formsy>
                        </div>
                    </div>
                </Grow>
        )
    };
}

function mapStateToProps({loginState, returnadoState}) {
    return {
        ...returnadoState,
        ...loginState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            login,
            is2F,
            setIsPasswordVisible,
            setLoginStateToDefault,
            setPassword,
            setUsername,
            setVerificationCode
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(Login);