import LeftSideBarConst from './../../components/LeftSideBar/const';
import ReturnDescriptionConst from "../../components/ReturnDescription/const";

const Const = {
    RETURNS_TOGGLE_RETURN_ITEM_PRODUCT_COMMENT_AREA: 'RETURNS_TOGGLE_RETURN_ITEM_PRODUCT_COMMENT_AREA',
    RETURNS_CHANGE_PRODUCT_FILTER_TO_DEFAULT: 'RETURNS_CHANGE_PRODUCT_FILTER_TO_DEFAULT',
    RETURNS_CLEAR_CURRENT_RETURNS_ITEM: 'RETURNS_CLEAR_CURRENT_RETURNS_ITEM',
    RETURNS_CHANGE_TABLE_CURRENT_PAGE: 'RETURNS_CHANGE_TABLE_CURRENT_PAGE',
    RETURNS_CHANGE_RETURN_ITEM_STATE: 'RETURNS_CHANGE_RETURN_ITEM_STATE',
    RETURNS_CHANGE_INTERNAL_COMMENT: 'RETURNS_CHANGE_INTERNAL_COMMENT',
    RETURNS_CHANGE_MANAGER_COMMENT: 'RETURNS_CHANGE_MANAGER_COMMENT',
    RETURNS_START_GET_ITEM_REQUEST: 'RETURNS_START_GET_ITEM_REQUEST',
    RETURNS_CHANGE_PRODUCT_FILTER: 'RETURNS_CHANGE_PRODUCT_FILTER',
    RETURNS_CONFIRM_UPDATE_ORDER: 'RETURNS_CONFIRM_UPDATE_ORDER',
    RETURNS_START_FETCH_REQUEST: 'RETURNS_START_FETCH_REQUEST',
    RETURNS_CHANGE_ORDER_FILTER: 'RETURNS_CHANGE_ORDER_FILTER',
    RETURNS_TOGGLE_APPROVE_FLAG: 'RETURNS_TOGGLE_APPROVE_FLAG',
    RETURNS_FETCH_MORE_REQUEST: 'RETURNS_FETCH_MORE_REQUEST',
    RETURNS_GET_SHIPMENT_LABEL: 'RETURNS_GET_SHIPMENT_LABEL',
    RETURNS_PRE_APPROVE: 'RETURNS_PRE_APPROVE',
    RETURNS_GET_RETURN_ITEM: 'RETURNS_GET_RETURN_ITEM',
    RETURNS_FETCH_REQUEST: 'RETURNS_FETCH_REQUEST',
    RETURNS_CLEAR_STATE: 'RETURNS_CLEAR_STATE',
    RETURNS_CHANGE_FILTERS_BY_INBOUND_METRICA: 'RETURNS_CHANGE_FILTERS_BY_INBOUND_METRICA',
    RETURNS_SEND_TO_CS_START: 'RETURNS_SEND_TO_CS_START',
    RETURNS_SEND_TO_CS: 'RETURNS_SEND_TO_CS',
    RETURNS_CLOSE_SEND_NOTE_TO_THE_CUSTOMER: 'RETURNS_CLOSE_SEND_NOTE_TO_THE_CUSTOMER',
    RETURNS_START_GET_PREAPPROVAL_REQUEST: 'RETURNS_START_GET_PREAPPROVAL_REQUEST',
    RETURNS_START_UPDATE_REQUEST: 'RETURNS_START_UPDATE_REQUEST',
    RETURNS_HIDE_ERROR_MESSAGE: 'RETURNS_HIDE_ERROR_MESSAGE',
    RETURNS_TOGGLE_RETURN_ITEM_PRODUCT_FLAGGED_STATE: 'RETURNS_TOGGLE_RETURN_ITEM_PRODUCT_FLAGGED_STATE',
    RETURNS_CONFIRM_POPUP_BEFORE_UPDATE_ORDER_SHOW: 'RETURNS_CONFIRM_POPUP_BEFORE_UPDATE_ORDER_SHOW',
    RETURNS_CONFIRM_POPUP_BEFORE_UPDATE_ORDER_CLOSE: 'RETURNS_CONFIRM_POPUP_BEFORE_UPDATE_ORDER_CLOSE',
    RETURNS_CONFIRM_POPUP_BEFORE_SWITCH_RETURN_SHOW: 'RETURNS_CONFIRM_POPUP_BEFORE_SWITCH_RETURN_SHOW',
    RETURNS_CONFIRM_POPUP_BEFORE_SWITCH_RETURN_CLOSE: 'RETURNS_CONFIRM_POPUP_BEFORE_SWITCH_RETURN_CLOSE',
    [LeftSideBarConst.LEFT_SIDE_BAR_IS_FOLDED_OPEN]: LeftSideBarConst.LEFT_SIDE_BAR_IS_FOLDED_OPEN,
    [ReturnDescriptionConst.RETURN_DESCRIPTION_SET_REFUND]: ReturnDescriptionConst.RETURN_DESCRIPTION_SET_REFUND,
    RETURNS_SET_FIXED_RETURN_FEE: 'RETURNS_SET_FIXED_RETURN_FEE',
    RETURNS_CLEAR_FIXED_RETURN_FEE: 'RETURNS_CLEAR_FIXED_RETURN_FEE',
    RETURNS_CHANGE_SUBMIT_STATE_BASED_ON_REDUCED_VALUES: 'RETURNS_CHANGE_SUBMIT_STATE_BASED_ON_REDUCED_VALUES',
    RETURNS_SET_MERCHANT_FILES: 'RETURNS_SET_MERCHANT_FILES',
    DROP_ZONE_S3_IMAGES_UPLOADED: 'DROP_ZONE_S3_IMAGES_UPLOADED'
};

export default Const;