import React from "react";
import CustomTranslation from "../../components/CustomTranslation/CustomTranslation";
import {formatDate} from "../../utilites";
import Loader from "../Loader/Loader";

function SendToCustomerServiceControl({sendNoteToCustomerAction=()=>{}, isLoading=false, lastTimeStamp=null}) {
    return (
        <div className='b-send-to-cs-control'>
            <div className='b-send-to-cs-control__send_to_customer_control' onClick={() => {
                if(!isLoading) {
                    sendNoteToCustomerAction()
                }
            }}>
                <span className='b-send-to-cs-control__send_to_customer_loader'>{isLoading ? <Loader /> : ''}</span>
                <CustomTranslation id='login.v2.inboundPage.sendToCs'/>
            </div>
            {
                lastTimeStamp
                ?
                <div className="b-send-to-cs-control__send_to_customer_date">
                    {formatDate(lastTimeStamp)}
                </div>
                :
                ''
            }
        </div>
    );
}


export default SendToCustomerServiceControl;