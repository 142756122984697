import Const from './../const';

/*
* logout {React Action}
* @description - logout
*
* */
export function logout() {
    return dispatch => {
        dispatch({
            type: Const.RETURNADO_LOGOUT,
        });
    }
}