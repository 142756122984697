import Const from './../const';

/*
* closeMobileMenu {React Action}
* @description - close mobile menu
*
* */

export function closeMobileMenu() {
    return dispatch => {
        dispatch({
            type: Const.LEFT_SIDE_BAR_MOBILE_IS_ACTIVE,
            payload: false
        });
    }
}