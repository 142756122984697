import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {setCurrentPageTitle} from "./../../components/Header/actions/headerActions";
import {withStyles} from "@material-ui/core/styles";
import PageContent from "../../containers/PageContent/PageContent";
import Grid from "@material-ui/core/Grid";
import CustomButton from "./../../containers/CustomButton/CustomButton";
import CustomTranslation from "../../components/CustomTranslation/CustomTranslation";
import PageFiltersItems from "../../components/PageFiltersItems/PageFiltersItems";
import {
    fetchReturnDataReport,
    fetchReturnDataReportSCV,
    setReturnDataReportFilter,
    clearFilters
} from './actions/returnDataActions'
import {fade} from "./../../../node_modules/@material-ui/core/styles/colorManipulator";
import {isEmpty} from "lodash";
import moment from 'moment';
import {
    toFixedCurrency,
    getBooleanShopProperty,
    getFiltersAppropriateMarkets,
    isNeedToShowMarketLanguage,
    isNeedToShowMarket,
    getReturnReasons,
    getTableHeight,
    createTemporaryLinkElement
} from "./../../utilites";
import CustomTable from "./../../components/CustomTable/CustomTable";
import {Redirect} from "react-router";
import defaultRequestConst from "../Returns/defaultRequestConst";

const styles = (theme) => {
    return ({
        returnStatusStyles: {
            color: theme.palette.secondary.main,
        },
        gridRow: {
            flexGrow: 1,
        },
        exportCSVBtn: {
            background: fade(theme.palette.brand.btnColors.reject, 0.5),
            '&:hover': {
                background: theme.palette.brand.btnColors.reject
            }
        }
    })
};


class ReturnData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            pageSizeOption: defaultRequestConst.limit,
        };
    }

    /**
     * getFiltersValue {Function}
     * @description - get default values for Filters
     * @return {Object}
     *
     * */
    getFiltersValue = (part) => {
        let {filters={}} = this.props;
        let {startDate, endDate, status, type, reason, baseCurrency, market} = filters;

        switch(part) {
            case 1:
                return {
                    0: [startDate, endDate],
                    1: status,
                    2: reason
                };
            case 2:
                return {
                    0: type,
                    1: baseCurrency,
                    2: market
                };
            default:
                return {}

        }
    };

    /**
     * getDisabledStates {Function}
     * @description - get default enabled/disabled states for Filters
     * @return {Object}
     *
     * */
    getDisabledStates = (part) => {
        switch(part) {
            case 1:
                return {
                    0: false,
                    1: false,
                    2: false
                };
            case 2:
                return {
                    0: false,
                    1: false,
                    2: false
                };
            default:
                return {}

        }
    };

    /**
     * changeFilterAction {Function}
     * @description - change filter action value
     *
     * */
    changeFilterAction = (value, item) => {
        let {label=''} = item;


        switch(label) {
            default:
                this.props.setReturnDataReportFilter(label, value);
                break;
        }
    };

    /**
     * clearFilterAction {Function}
     * @description - clear filter action value
     *
     * */
    clearFilterAction = (value, item) => {
        let {label='', clearedValue=''} = item;

        switch(label) {
            default:
                this.props.setReturnDataReportFilter(label, clearedValue);
                break;
        }
    };

    /**
     * getFilterShopNames {Function}
     * @description - generates appropriate options for Market dropdown in Filters
     * @return {Object}
     *
     * */
    getFilterShopNames = () => {
        let {mainPropertiesState} = this.props;
        return getFiltersAppropriateMarkets(mainPropertiesState, isNeedToShowMarket(mainPropertiesState), isNeedToShowMarketLanguage(mainPropertiesState));
    };

    getReturnReasons = () => {
        let {mainPropertiesState} = this.props;
        return getReturnReasons(mainPropertiesState);
    };

    /**
     * fetchReport {Function}
     * @description - get filtered reports
     *
     * */
    fetchReport = () => {
        this.props.fetchReturnDataReport({
            route: 'getReturnDataReport',
            isWithGetParams: true,
            requestData: this.getFiltersForRequest()
        });
    };

    /**
     * fetchCSVReport {Function}
     * @description - get filtered reports
     *
     * */
    fetchCSVReport = () => {
        this.props.fetchReturnDataReportSCV({
            route: 'getReturnDataReportCsv',
            isWithGetParams: true,
            requestData: this.getFiltersForRequest()
        });
    };

    /**
     * getFiltersForRequest {Function}
     * @description - get data for request to ger report based on filters values
     * @return {Object}
     * */
    getFiltersForRequest = () => {
        let {filters} = this.props;
        let requestData = {};

        for(let filter in filters) {
            switch(filter){
                case 'startDate':
                    requestData['from'] = moment(new Date(filters[filter]).setHours(0,0,0,0)).add(moment().utcOffset(), 'minutes').toISOString();
                    break;
                case 'endDate':
                    requestData['to'] =  moment(new Date(filters[filter]).setHours(23,59,59,59)).add(moment().utcOffset(), 'minutes').toISOString();
                    break;
                case 'type':
                    requestData['returnType'] = filters[filter];
                    break;
                case 'reason':
                    requestData['reclamationReason'] = filters[filter];
                    break;
                case 'market':
                    requestData['shopId'] = filters[filter];
                    break;
                case 'baseCurrency':
                    requestData['currency'] = filters[filter];
                    break;
                default:
                    requestData[filter] = filters[filter];
                    break;
            }
        }


        return requestData;
    };

    /**
     * getTableData {Function}
     * @description - return data array to render in returns table
     * @return {Array}
     *
     * */
    getTableData = (data) => {
        return isEmpty(data.toJSONView()) ? [] : data.getField('response');
    };

    /**
     * handleTableRowClick {Function}
     * @description - function return object with action which fires when click on specific table's row
     * @return {Object}
     *
     * */
    handleTableRowClick = (state, rowInfo) => {
        return {
            onClick: (e) => {}
        }
    };

    // TODO: remove duplicates
    /**
     * getStatusColor {Function}
     * @description - get appropriate color fo returns item status
     * @return {String} - color hex
     * */
    getStatusColor = (status) => {
        let {theme :{ palette: {brand: {returnStatusColors}}} } = this.props;
        return returnStatusColors[status] || 'none';
    };

    // TODO: move this method to utility class
    capitalize = ([first,...rest]) => first ? first.toUpperCase() + (rest? rest.join('').toLowerCase() : '') : '';

    /**
     * renderTable {Function}
     * @description - render table using data from server side
     * @return {React Component}
     *
     * */
    renderTable = (tableDataP) => {
        let {data, isLoading, classes} =  this.props;
        let prefix = 'returns.report.list.columns';
        let tableData = tableDataP || this.getTableData(data);
        let columns = [
            {
                Header: <CustomTranslation id={`${prefix}.orderNumber`}/>,
                id: 'orderNumber',
                accessor: (row)=>{ return row.orderNumber},
                Cell: ({row})=>{ return row.orderNumber}
            },
            {
                Header: <CustomTranslation id={`${prefix}.returnNumber`}/>,
                id: 'returnNumber',
                accessor: (row)=>{ return row.returnNumber},
                Cell: ({row})=>{ return row.returnNumber}
            },
            {
                Header: <CustomTranslation id={`${prefix}.returnRegisteredDate`}/>,
                id: 'returnRegisteredDate',
                accessor: (row)=>{ return row.returnRegisteredDate},
                Cell: ({row})=>{ return row.returnRegisteredDate}
            },
            {
                Header: <CustomTranslation id={`${prefix}.customerEmail`}/>,
                id: 'customerEmail',
                accessor: (row)=>{ return row.customerEmail},
                Cell: ({row})=>{ return row.customerEmail}
            },
            {
                // TODO: hide this when single-market
                Header: <CustomTranslation id={`${prefix}.shopName`}/>,
                id: 'shopName',
                accessor: (row)=>{ return row.shopName},
                Cell: ({row})=>{ return row.shopName}
            },
            {
                Header: <CustomTranslation id={`${prefix}.returnStatus`}/>,
                id: 'returnStatus',
                accessor: (row)=>{ return row.returnStatus},
                Cell: ({row}) => {
                    return <span className={`b-page__statuses_table_item ${classes.returnStatusStyles}`}
                                 style={{backgroundColor: this.getStatusColor(row.returnStatus)}}>
                        <CustomTranslation id={`return.order.statuses.${row.returnStatus}`}/>
                    </span>
                }
            },
            {
                Header: <CustomTranslation id={`${prefix}.returnType`}/>,
                id: 'returnType',
                accessor: (row)=>{ return row.returnType},
                Cell: ({row})=>{ return this.capitalize(row.returnType) }
            },
            {
                Header: <CustomTranslation id={`${prefix}.returnValue`}/>,
                id: 'returnValue',
                accessor: (row)=>{ return row.returnValue},
                Cell: ({row})=>{ return toFixedCurrency(row.returnValue)}
            },
            {
                Header: <CustomTranslation id={`${prefix}.vatReturnValue`}/>,
                id: 'vatReturnValue',
                accessor: (row)=>{ return row.vatReturnValue},
                Cell: ({row})=>{ return toFixedCurrency(row.vatReturnValue)}
            },
            {
                Header: <CustomTranslation id={`${prefix}.reconversionValue`}/>,
                id: 'reconversionValue',
                accessor: (row)=>{ return row.reconversionValue},
                Cell: ({row})=>{ return toFixedCurrency(row.reconversionValue)}
            },
            {
                Header: <CustomTranslation id={`${prefix}.vatReconversionValue`}/>,
                id: 'vatReconversionValue',
                accessor: (row)=>{ return row.vatReconversionValue},
                Cell: ({row})=>{ return toFixedCurrency(row.vatReconversionValue)}
            },
            {
                Header: <CustomTranslation id={`${prefix}.refundValue`}/>,
                id: 'refundValue',
                accessor: (row)=>{ return row.refundValue},
                Cell: ({row})=>{ return toFixedCurrency(row.refundValue)}
            },
            {
                Header: <CustomTranslation id={`${prefix}.vatRefundValue`}/>,
                id: 'vatRefundValue',
                accessor: (row)=>{ return row.vatRefundValue},
                Cell: ({row})=>{ return toFixedCurrency(row.vatRefundValue)}
            }
        ];

        return <CustomTable
            className={'-highlight -striped'}
            data={tableData}
            columns={columns}
            loading={isLoading}
            isLoading={isLoading}
            getTrProps={this.handleTableRowClick}
            pageSize={tableData && tableData.length ? tableData.length : 0}
            style={{
                // This will force the table body to overflow and scroll, since there is not enough room
                height: getTableHeight(tableData)
            }}
            showPagination={false}
            isPaginationActive={false}
        />
    };

    /**
     * downloadCSVReport {Function}
     * @description - enable possibility to download csv file
     *
     * */
    downloadCSVReport = (csvDataModel) => {
        let csvData = csvDataModel.getField('response');
        let separator = ',';
        if(csvDataModel && csvData) {
            let reader = new FileReader();
            let fileName = `ReturnadoProductsReport.csv`;
            let blob = new Blob([`sep=${separator}\n${csvData}`], {type : 'text/csv;charset=utf-8'});

            reader.onload = reader.onload = () => {createTemporaryLinkElement(fileName, blob)};

            reader.readAsText(blob);
        }
    };

    componentDidMount() {
        let {pageName} = this.props;
        this.props.setCurrentPageTitle(pageName)
    }

    componentDidUpdate (prevProps) {
        if(prevProps.csvData !== this.props.csvData) {
            this.downloadCSVReport(this.props.csvData);
        }
    };

    componentWillUnmount() {
        this.props.clearFilters()
    }

    render (){
        let {isFoldedOpen, isLoading, classes, data} = this.props;
        let tableData = this.getTableData(data);
        return (
            getBooleanShopProperty(this.props, 'reports.page.enabled')
                ?
                <div className='b-app__page b-return-data-report-page'>
                    <PageContent isFoldedOpen={isFoldedOpen} classNameCustom='b-page-content--item_data_report'>
                        <div className="b-app__page_body">
                            <div className="b-app__body_item">
                                <div className="b-return-data-report-page__description">
                                    <h2 className={`b-app__page_content_title b-app__page_content_title--center_align`}>
                                        <CustomTranslation id={'returns.report.list.title'}/>
                                    </h2>
                                </div>
                                <Grid container className="b-return-data-report-page__flex-grow" spacing={0}>
                                    <Grid item xs={7} md={9} lg={10} className="b-returns-item__block">
                                        <div className='b-return-data-report-page__filters_section'>
                                            <PageFiltersItems
                                                pageId="returnDataReport1part"
                                                classStyles="b-return-data-report-page__filters"
                                                defaultValues={this.getFiltersValue(1)}
                                                disabledStates={this.getDisabledStates(1)}
                                                changeAction={this.changeFilterAction}
                                                clearAction={this.clearFilterAction}
                                                isLabeled={true}
                                                replaceFilter={{
                                                    'returns.report.list.columns.reason': this.getReturnReasons()
                                                }}
                                            />
                                        </div>
                                        <div className='b-return-data-report-page__filters_section'>
                                            <PageFiltersItems
                                                pageId="returnDataReport2part"
                                                classStyles="b-return-data-report-page__filters"
                                                defaultValues={this.getFiltersValue(2)}
                                                disabledStates={this.getDisabledStates(2)}
                                                changeAction={this.changeFilterAction}
                                                clearAction={this.clearFilterAction}
                                                isLabeled={true}
                                                replaceFilter={{
                                                    'products.report.list.columns.market': this.getFilterShopNames()
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={5} md={3} lg={2} className="b-returns-item__block">
                                        <div className='b-return-data-report-page__btn_wrapper'>
                                            <CustomButton
                                                type='button'
                                                variant='contained'
                                                fullWidth={true}
                                                color='primary'
                                                aria-label={<CustomTranslation renderAsString={true} id='products.report.list.columns.generate'/>}
                                                loading={isLoading}
                                                name={<CustomTranslation renderAsString={true} id='products.report.list.columns.generate'/>}
                                                onClick={this.fetchReport}
                                            />
                                        </div>
                                        <div className='b-return-data-report-page__btn_wrapper'>
                                            <CustomButton
                                                type='button'
                                                variant='contained'
                                                fullWidth={true}
                                                color='primary'
                                                aria-label={<CustomTranslation renderAsString={true} id='products.report.list.columns.csv'/>}
                                                loading={isLoading}
                                                isdisabled={tableData && !tableData.length}
                                                name={<CustomTranslation renderAsString={true} id='products.report.list.columns.csv'/>}
                                                className={classes.exportCSVBtn}
                                                onClick={this.fetchCSVReport}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="b-app__body_item">
                                <div className="b-return-data-report-page__counter">
                                    {
                                        tableData && tableData.length ?
                                            <CustomTranslation
                                                id='returns.report.counter'
                                                data={{
                                                    returnLength: tableData && tableData.length ? tableData.length : 0,
                                                    totalReturnLength: tableData && tableData.length ? tableData.length : 0
                                                }}
                                            />
                                            :
                                            ''
                                    }
                                </div>
                                <div className="b-app__table_wrapper">
                                    {this.renderTable(tableData)}
                                </div>
                            </div>
                        </div>
                    </PageContent>
                </div>
                :
                <Redirect to="/"/>
        )
    };
}

function mapStateToProps(state) {
    return {
        ...state.returnDataReportState,
        mainPropertiesState: state.mainPropertiesState,
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            setCurrentPageTitle,
            setReturnDataReportFilter,
            fetchReturnDataReport,
            fetchReturnDataReportSCV,
            clearFilters
        },
        dispatcher,
    );
}

export default  withStyles(styles, {withTheme: true})(connect(mapStateToProps, matchDispatchToProps)(ReturnData));