import Const from './../const';
import FixedPercentageSelectorReducerConst from "./../../../components/FixedPercentageSelector/const";

/**
 * requestStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestStart = () =>{
    return {
        type: Const.INBOUND_START_FETCH_REQUEST
    }
};

/**
 * requestMetricsStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestMetricsStart = () =>{
    return {
        type: Const.INBOUND_METRICS_START_FETCH_REQUEST
    }
};

/**
 * requestSendToCSStart {Function}
 * @return Object in React Action format before request send to customer service start
 *
 * */
let requestSendToCSStart = () =>{
    return {
        type: Const.INBOUND_SEND_NOTE_TO_THE_CUSTOMER_START
    }
};

/**
 * fetchReturns {React Action}
 * @description - send request to the server to get returns data
 *
 * */
export function searchForReturnOrder(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.INBOUND_FETCH_REQUEST,
            ...requestData
        });
    }
}

/**
 * getInboundMetrics {React Action}
 * @description - send request to the server to get inbound metrics data
 *
 * */
export function getInboundMetrics(requestData) {
    return dispatch => {
        dispatch(requestMetricsStart());
        dispatch({
            type: Const.INBOUND_METRICS_FETCH_REQUEST,
            ...requestData
        });
    }
}

/**
 * changeItemState {React Action}
 * @description - set item state
 *
 * */
export function changeItemState(status, orderItemId, currentReturnOrderId, currentShopType) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_SET_ITEM_STATE,
            payload: {
                status,
                orderItemId,
                currentReturnOrderId,
                currentShopType
            }
        });
    }
}

/**
 * setReportItemState {React Action}
 * @description - set item state
 *
 * */
export function setReportItemState(orderItemId, currentReturnOrderId) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_SET_ITEM_REPORT_STATE,
            payload: {
                orderItemId,
                currentReturnOrderId
            }
        });
    }
}

/**
 * toggleApproveRefundExchange {react Action}
 * @description - toggle returns item approve flag
 *
 * */
export function toggleApproveRefundExchange(currentReturnOrderId) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_TOGGLE_APPROVE_FLAG,
            payload: {
                currentReturnOrderId
            }
        });
    }
}

/** @description - set item state
 *
 * */
export function sortInboundItemsRotate(sortingMethod, items, currentReturnOrderId) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_SET_NEW_ITEMS_ORDER,
            payload: {
                sortingMethod,
                items,
                currentReturnOrderId
            }
        });
    }
}

/**
 * clearInboundState {React Action}
 * @description - clear the state
 *
 * */
export function clearInboundState() {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_CLEAR_STATE
        });
    }
}


/**
 * changeSearchValue {React Action}
 * @description - change search value
 *
 * */
export function changeSearchValue(value) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_CHANGE_SEARCH_VALUE,
            payload: value
        });
    }
}


/**
 * changeInternalComment {React Action}
 * @description - change search value
 *
 * */
export function changeInternalComment(value, id, currentReturnOrderId) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_CHANGE_PRIVATE_COMMENT,
            payload: {
                value,
                id,
                currentReturnOrderId
            }
        });
    }
}

/**
 * changeManagerComment {React Action}
 * @description - change search value
 *
 * */
export function changeManagerComment(value, id, currentReturnOrderId) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_CHANGE_CUSTOMER_COMMENT,
            payload: {
                value,
                id,
                currentReturnOrderId
            }
        });
    }
}

/**
 * changeProductFilters {React Action}
 * @description - change specific product filters
 *
 * */
export function changeProductFilters(filterName, value, orderItemId, currentReturnOrderId, isSaveAsChangedValue=true) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_CHANGE_PRODUCT_FILTER,
            payload: {
                filterName,
                value,
                orderItemId,
                currentReturnOrderId,
                isSaveAsChangedValue
            }
        });
    }
}

/**
 * changeProductFilters {React Action}
 * @description - change specific product filters
 *
 * */
export function changeDiminishedPrice(value, orderItemId, currentReturnOrderId) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_CHANGE_DIMINISHED_PRICE,
            payload: {
                value,
                orderItemId,
                currentReturnOrderId
            }
        });
    }
}

/**
 * closeConfirmationDialog {React Action}
 * @description - close confirmation dialog
 *
 * */
export function closeConfirmationDialog() {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_CLOSE_CONFIRMATION_DIALOG
        });
    }
}

/**
 * setDefaultFilters {React Action}
 * @description - set the dropdowns filters of the specific product to default
 *
 * */
export function setDefaultFilters(orderItemId, currentReturnOrderId) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_SET_PRODUCT_FILTER_TO_DEFAULT,
            payload: {
                orderItemId,
                currentReturnOrderId
            }
        });
    }
}

/**
 * sendNoteToCustomer {React Action}
 * @description - send request to customer service
 *
 * */
export function sendNoteToCustomer(requestData) {
    return dispatch => {
        dispatch(requestSendToCSStart());
        dispatch({
            type: Const.INBOUND_SEND_NOTE_TO_THE_CUSTOMER,
            ...requestData
        });
    }
}

/**
 * closeSendToCustomerDialog {React Action}
 * @description - close dialog with confirmation about email was sent to the customer
 *
 * */
export function closeSendToCustomerDialog() {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_CLOSE_SEND_NOTE_TO_THE_CUSTOMER,
        });
    }
}

/**
 * setExpanPanelIndex {React Action}
 * @description - close dialog with confirmation about email was sent to the customer
 *
 * */
export function setExpandPanelIndex(expandPanelIndex) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_SET_EXPAND_PANEL_INDEX,
            payload: expandPanelIndex
        });
    }
}


export function updateReturnOrder(requestData) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_CONFIRM_UPDATE_ORDER,
            ...requestData
        });
    }
}

export function closeConfirmUpdateOrder() {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_CONFIRM_UPDATE_ORDER_CLOSE,
        });
    }
}

export function setFilteringToOrderPage(filters) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_CHANGE_FILTERS_BY_INBOUND_METRICA,
            payload: filters
        });
    }
}

/**
 * setDescriptionRefund {React Action}
 * @description - dispatch action to change return description refund value
 *
 * */
export function setDescriptionRefund(refund, returnOrderId) {
    return dispatch => {
        dispatch({
            type: Const.RETURN_DESCRIPTION_SET_REFUND,
            payload: {
                refund,
                returnOrderId
            }
        });
    }
}

/**
 * setFixedReturnFeeValue {React Action}
 * @description - dispatch action to set fixed return fee
 *
 * */
export function setFixedReturnFeeValue(returnFees, reducedValues, returnId) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_SET_FIXED_RETURN_FEE,
            payload: {
                returnFees,
                reducedValues,
                returnId
            }
        });
    }
}

/**
 * changeSubmitState {React Action}
 * @description - change submit button state based on reduced values
 *
 * */
export function changeSubmitState(returnFees, reducedValues, returnId) {
    return dispatch => {
        dispatch({
            type: Const.INBOUND_CHANGE_SUBMIT_STATE_BASED_ON_REDUCED_VALUES,
            payload: {
                returnFees,
                reducedValues,
                returnId
            }
        });
    }
}

/**
 * clearReducedValuesObject {React Action}
 * @description - clear reduced values state
 *
 * */
export function clearReducedValuesObject() {
    return dispatch => {
        dispatch({
            type: FixedPercentageSelectorReducerConst.FIXED_REDUCED_PERCENTAGE_REDUCE_VALUE_CLEAR,
        });
    }
}