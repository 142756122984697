import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {setRightMenuActive, setLeftMenuActive} from './actions/headerActions';
import {logout} from "./../Returnado/actions/returnadoActions";
import { withRouter } from "react-router";
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Button  from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import {bindActionCreators} from "redux";
import CustomTranslation from "./../CustomTranslation/CustomTranslation";
import configuration from "../../configs/configs";
import {getLocale} from "../Localization/actions/localizationActions";
import {getDataFromLS, removejscCssfile, switchCurrentLanguage} from "./../../utilites";
import {Group, TrackChanges, Assessment, Security, Settings, Description, CreditCard, Replay, ShoppingCart} from '@material-ui/icons';


const styles = theme => ({
    header: {
        color: theme.palette.brand.main[600],
        background: theme.palette.secondary.main
    }
});

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            auth: this.props.isLoggedIn,
            anchorEl: null,
            anchorLang: null
        }
    }

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleLangMenu = event => {
        this.setState({ anchorLang: event.currentTarget });
    };

    handleLangClose = () => {
        this.setState({ anchorLang: null });
    };

    /**
    * logout {Function}
    * @description - user logout
    *
    * */
    logout = () => {
        let {history} = this.props;

        //clearing widget iframe src
        let widgetWrapper = document.getElementById('returnadoWidgetId');
        if(widgetWrapper) {
            widgetWrapper.setAttribute('src', '');
        }
        removejscCssfile("returnado.js", "js");

        this.props.logout();
        sessionStorage.clear();

        history.push('/login');
    };

    /**
    * changeLocale {Function}
    * @description - temporary method for testing change language functionality
    *
    * */
    changeLocale = (lang) => {
        switchCurrentLanguage(lang);
        this.props.getLocale({
            route: 'getLocale',
            requestData: {
                lang: lang
            }
        })
    };

    getCurrentPageTitle = () => {
        return (
            <div className='b-header__page_title' onClick={()=>{window.location.reload()}}>
                <span className="b-header__page_title_icon">
                    {this.getIcon(this.props.currentPageTitle)}
                </span>
                <span className="b-header__page_title_text">
                    <CustomTranslation id={`sideMenu.${this.props.currentPageTitle}`} />
                </span>
            </div>
        )
    };


    /**
    * getIcon {Function}
    * @description - defines appropriate icon for main navigation item item
    * @ return {Material-ui Icon Component}
    *
    * */
    getIcon = (menuId) => {
        switch(menuId) {
            case 'userManagement':
                return <Group />;
            case 'orders':
                return <ShoppingCart />;
            case 'return.orders':
                return <Replay />;
            case 'customers':
                return <Group />;
            case 'billing':
                return <CreditCard />;
            case 'reports':
                return <Description />;
            case 'settings':
                return <Settings />;
            case 'security':
                return <Security />;
            case 'analytics':
                return <Assessment />;
            default:
                return <TrackChanges />;
        }
    };

    render() {
        const {classes, data} = this.props;
        const { auth, anchorEl, anchorLang } = this.state;
        const currentLanguage = getDataFromLS('language');
        const open = Boolean(anchorEl);
        const openLang = Boolean(anchorLang);
        let {firstname='', lastname=''} = data.getField('manager');

        return (
            <div className='b-header'>
                <AppBar className={classes.header} position="static">
                    <Toolbar>
                        <Hidden lgUp>
                            <IconButton className='b-header__menu_btn' color="inherit" aria-label="Menu" onClick={()=>{this.props.setLeftMenuActive()}}>
                                <MenuIcon/>
                            </IconButton>
                        </Hidden>
                        <div className='b-header__title'>
                            {this.getCurrentPageTitle()}
                        </div>
                        {auth && (
                            <div className='b-header__controls'>
                                <div className="b-header__controls_item">
                                    <Button onClick={this.handleLangMenu}
                                                className={`b-header__settings_control`}
                                                color='inherit'
                                                buttonRef={node => {
                                                    this.anchorLang = node;
                                                }}>
                                        <div className="b-header__username">
                                            <CustomTranslation id={`login.v2.changeLanguage.${currentLanguage}`} /> <ArrowDropDown fontSize='small'/>
                                        </div>
                                    </Button>
                                    <Popper open={openLang} anchorEl={this.anchorLang} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={this.handleLangClose}>
                                                        <MenuList>
                                                        {
                                                            configuration.supportedLanguages.map((language, index)=>{
                                                                return <MenuItem key={index}
                                                                                 value={language.code}
                                                                                 selected={currentLanguage === language.code}
                                                                                 onClick={()=>{this.changeLocale(language.code); this.handleLangClose()}}>
                                                                    <CustomTranslation id={`login.v2.changeLanguage.${language.code}`} />
                                                                </MenuItem>
                                                            })
                                                        }
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </div>
                                {/**<IconButton onClick={this.props.setRightMenuActive} className={`b-header__settings_control`} color='inherit'>
                                    <MoreVert color='inherit' />
                                </IconButton>*/}
                                <div className="b-header__controls_item">
                                    <Button buttonRef={node => {
                                            this.anchorEl = node;
                                        }}
                                        className='b-header__settings_control'
                                        aria-owns={open ? 'menu-list-grow' : undefined}
                                        aria-haspopup="true"
                                        onClick={this.handleMenu}>
                                        <div className="b-header__username">
                                            {`${firstname} ${lastname}`} <ArrowDropDown fontSize='small'/>
                                        </div>
                                    </Button >
                                    <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={this.handleClose}>
                                                        <MenuList>
                                                            <MenuItem onClick={this.logout}>
                                                                <CustomTranslation id='login.v2.logout' />
                                                            </MenuItem>
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </div>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

function mapStateToProps({returnadoState, headerState}) {
    return {
        ...returnadoState,
        ...headerState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            setRightMenuActive,
            setLeftMenuActive,
            getLocale,
            logout
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles, {withTheme: true})(withRouter(Header)));