import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router";
import ReactSelectMaterialUi from "react-select-material-ui";
import { MultipleSelect } from "react-select-material-ui";
import CustomTranslation from './../CustomTranslation/CustomTranslation';
import {InputBase, TextField} from '@material-ui/core';
import configuration from '../../configs/configs';
import {getDataFromLS} from "./../../utilites";
import CustomAnimate from "../../containers/CustomAnimate/CustomAnimate";
import Tooltip from '@material-ui/core/Tooltip';
import SupportIcon from "@material-ui/icons/Error";
import ClearIcon from '@material-ui/icons/Cancel';
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import FixedPercentageSelector from "../FixedPercentageSelector/FixedPercentageSelector";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from "moment";
import {getTranslate} from "react-localize-redux";
import {connect} from "react-redux";

const styles = theme => {
    return {
        PageFilterInput: {
            border: `1px solid ${theme.palette.brand.main[50]}`
        },
        PageFilterLabeledInput: {
            padding: '5px 0'
        }
    }
};

class PageFiltersItems extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            filtersValues: {

            },
            selected: [],
            filtersCount: 0,
            from: undefined,
            to: undefined
        }
    }

    filterChangeHandling = (value, item) => {
        let {filtersValues} = this.state;

        this.setState({
            filtersValues: {
                ...filtersValues,
                [item.label]: {
                    value: value,
                    isDisabled: filtersValues[item.label].isDisabled
                }
            }
        })

    };

    componentDidMount() {
        this.setDefaultValuesToState();
    }

    /**
     * getTranslatedItems {Function}
     * @description - iterate filters array
     *
     * */
    getTranslatedItems = (filteringItems, label) => {
        let prefix;

        switch (label) {
            case 'return.order.details.status':
                prefix = 'return.order.statuses';
                break;
            case 'return.order.details.shipmentStatus':
                prefix = 'return.order.shipmentStatuses';
                break;
            case 'returns.report.list.columns.returnType':
                prefix = 'return.order.returnTypes';
                break;
            case 'returns.report.list.columns.reason':
                prefix = 'products.report.returnReason';
                break;
            case 'return.order.details.nextAction':
                prefix = 'return.order.details.nextActions';
                break;
            case 'return.order.details.condition':
                prefix = 'return.order.details.conditions';
                break;
            case 'customer.list.columns.shopName':
            case 'products.report.list.columns.market':
            case 'products.report.list.columns.baseCurrency':
                prefix = '';
                break;
            case 'return.order.list.column.creationType':
                prefix = 'return.order.list.column.creationType';
                break;
            case 'products.report.list.columns.type':
                prefix = 'products.report.returnType';
                break;
            case 'products.report.list.columns.status':
                prefix = 'products.report.status';
                break;
            case 'return.order.details.remarksFilter':
                prefix = 'return.order.details.remarksFilter';
                break;
            default:
                prefix = '';
                break;
        }

        if(filteringItems && filteringItems.length) {
            let lang = getDataFromLS('language') || 'en';
            return filteringItems.map((filter)=>{
                if(label === 'customer.list.columns.shopName' || label === 'products.report.list.columns.market' || label === 'products.report.list.columns.baseCurrency') {
                    return ({
                        label: filter.label,
                        value: filter.value
                    })
                } else {
                    return ({
                        /* TODO: remove operator after inbound DEMO */
                        label: filter.label==='BROKEN' ? lang === 'sv' ? 'Skadad' : 'Damaged' : <CustomTranslation id={`${prefix}.${filter.label.replace('/', '')}`} />,
                        value: filter.value
                    })
                }
            })
        }
        return [];
    };

    /**
     * setDefaultValuesToState {Function}
     * @description - go through default values passed in props and add them to the state
     *
     * */
    setDefaultValuesToState = () => {
        let {pageId='', defaultValues=null, disabledStates=null} = this.props;
        let filteringItems = configuration.filteringItems[pageId] || [];
        let stateValues = {};
        let filtersCount = 0;

        if(defaultValues && disabledStates) {
            filteringItems.forEach((item, i)=>{
                filtersCount = i+1;
                let {label} = item;
                stateValues[label] = {
                    value : defaultValues[i],
                    isDisabled: disabledStates[i]
                }
            })
        } else {
            console.error('defaultValues && disabledStates - are required')
        }

        this.setState({
            filtersValues: stateValues,
            filtersCount: filtersCount
        })
    };

    /**
     * getConditionProps {Functional}
     * @description - get filters props which are optional
     *
     * */
    getConditionProps = (item) => {
        let {isLabeled = false} = this.props;
        let props = {};

        if (isLabeled) {
            props.label = <CustomTranslation id={item.label} />;
        }

        return props;
    };

    /**
     * replaceFilters {Function}
     * @description - in some cases we need to replace filter items form default (from config.js) to items comes from component props (replaceFilter)
     * @return {Array} - replaced filters;
     *
     * */
    replaceFilters = (filters, filtersForReplace) => {
        for(let i in filtersForReplace) {
            filters.forEach((filter)=>{
                if(filter.label === i) {
                    filter.items = filtersForReplace[i]
                }
            });
        }
        return filters;
    };

    /**
     * addTooltipsData {Function}
     * @description - in some cases we need to render filter with tooltips comes from component props (tooltips)
     * @return {Array} - replaced filters;
     *
     * */
    addTooltipsData = (filters, tooltips) => {
        for(let i in tooltips) {
            filters.forEach((filter)=>{
                if(filter.label === i) {
                    filter.tooltip = tooltips[i]
                }
            });
        }
        return filters;
    };

    /**
     * getWidth {Function}
     * @description - defines width of each filter wrapper
     * @return {Number}
     *
     * */
    getWidth = (length, pageId) => {
        switch (pageId) {
            case 'orders':
                if(length === 1) {
                    return 33;
                } else {
                    return 100/length;
                }
            case 'returnsProduct':
            case 'inboundItem':
                return 28;
            default:
                return 100/length;
        }
    };

    getDateRangeValue = (type, defaultValues) => {
        let {from, to} = this.state;

        if(type === 'to') {
            return to ? to : defaultValues[1]
        }

        if(type === 'from') {
            return from ? from : defaultValues[0]
        }

    };

    showFromMonth() {
        const { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
        }
    }

    getItemsWithoutAll(items) {
        let newItems = [];
        items.forEach((item)=>{
            if(item.value !== "") {
                newItems.push(item)
            }
        });

        return newItems;
    }

    componentDidUpdate (prevProps) {
        if(prevProps.defaultValues !== this.props.defaultValues) {
            this.setDefaultValuesToState();
        }
    }

    render() {
        let {
            classes,
            pageId='',
            classStyles='',
            changeAction=this.filterChangeHandling,
            clearAction=()=>{},
            returnsProductId=null,
            defaultProductPrice=0,
            isLabeled=false,
            replaceFilter=null,
            tooltips=null,
            defaultSettings=null,
            errors={},
            returnOrderId=null,
            currency='',
            isInbound=false,
            translate
        } = this.props;
        let filteringItems = configuration.filteringItems[pageId] || [];
        let {filtersCount, from, to} = this.state;
        let modifiers = { start: from, end: to };
        if(replaceFilter) {
            filteringItems = this.replaceFilters(filteringItems, replaceFilter)
        }

        if(tooltips) {
            filteringItems = this.addTooltipsData(filteringItems, tooltips)
        }

        return (
            <div className={`b-page-filter-items b-page-filter-items--amount-${filtersCount} ${classStyles}`}>
                {
                    filteringItems.map((item, key)=> {
                        let {filtersValues} = this.state;
                        let value = filtersValues[item.label] && filtersValues[item.label].value !== undefined ? filtersValues[item.label].value : '';
                        let isDisabled = filtersValues[item.label] && filtersValues[item.label].isDisabled ? true : false;
                        let conditionProps = this.getConditionProps(item, key);
                        let fromDateRangeValue = this.getDateRangeValue('from', value);
                        let toDateRangeValue = this.getDateRangeValue('to', value);
                        let items = []

                        switch(item.type) {
                            case 'multipleSelect':
                                items = this.getTranslatedItems(item.items, item.label);
                                let arrayValues =  value.split(',');
                                let currentValues = arrayValues[0] == '' && arrayValues.length > 1 ? arrayValues.slice(1) : arrayValues;
                                let currentItems = arrayValues.length && !arrayValues.includes('') ? this.getItemsWithoutAll(items) : items;

                                return (
                                    <div key={key} className={value !== '' && item.isClearable ? 'b-page-filter-items__items b-page-filter-items__items--active' : 'b-page-filter-items__items'} style={{width: `${this.getWidth(filteringItems.length, pageId, key)}%`}}>
                                        <span className="b-page-filter-items__select_child_wrapper">
                                            {
                                                item.tooltip
                                                    ?
                                                    <Tooltip title={item.tooltip} className="b-page-filter-items__tooltip" placement="top">
                                                        <SupportIcon className="b-custom-tooltip-icon" fontSize='small' />
                                                    </Tooltip>
                                                    :
                                                    ''
                                            }

                                            <MultipleSelect
                                                className={isDisabled ? `b-page-filter-items__select b-page-filter-items__select--multiple b-page-filter-items__select--disabled`: `b-page-filter-items__select--multiple`}
                                                value={currentValues}
                                                options={currentItems}
                                                fullWidth={true}
                                                {...conditionProps}
                                                SelectProps={{
                                                    msgNoOptionsMatchFilter: translate('filters.no.options.found'),
                                                }}
                                                onChange={(e) => {
                                                    if(e.length || currentValues[0] !== "") {
                                                        changeAction(e[0] === '' && e.length > 1 ? e.slice(1).join(','): e.join(','), item, returnsProductId, defaultSettings, key)
                                                    }
                                                }}
                                            />

                                            {/*<MultiSelect
                                                options={items}
                                                selected={currentValues}
                                                onSelectedChanged={(e) => {
                                                    changeAction(e[0] === '' && e.length > 1 ? e.slice(1).join(','): e.join(','), item, returnsProductId, defaultSettings, key)
                                                }}
                                            />*/}


                                            {value !== '' && item.isClearable ? <ClearIcon className='b-page-filter-items__select_clear' fontSize="small" onClick={()=>{clearAction(null, item, returnsProductId, defaultSettings, key)}} /> : ''}
                                        </span>
                                    </div>
                                );
                            case 'select':
                                items = this.getTranslatedItems(item.items, item.label);
                                return (
                                    <div key={key} className={value !== '' && item.isClearable ? 'b-page-filter-items__items b-page-filter-items__items--active' : 'b-page-filter-items__items'} style={{width: `${this.getWidth(filteringItems.length, pageId, key)}%`}}>
                                        <span className="b-page-filter-items__select_child_wrapper">
                                            {
                                                item.tooltip
                                                    ?
                                                    <Tooltip title={item.tooltip} className="b-page-filter-items__tooltip" placement="top">
                                                        <SupportIcon className="b-custom-tooltip-icon" fontSize='small' />
                                                    </Tooltip>
                                                    :
                                                    ''
                                            }
                                            <ReactSelectMaterialUi
                                                className={`b-page-filter-items__form-control b-page-filter-items__select ${!isDisabled && 'b-page-filter-items__select--disabled'}`}
                                                options={items}
                                                value={value}
                                                onChange={(e) => {
                                                changeAction(e, item, returnsProductId, defaultSettings, key)
                                                }}
                                                placeholder={<CustomTranslation id={`${item.label}`}/>}
                                                disabled={isDisabled}
                                                {...conditionProps}
                                            />
                                            {value !== '' && item.isClearable ? <ClearIcon className='b-page-filter-items__select_clear' fontSize="small" onClick={()=>{clearAction(null, item, returnsProductId, defaultSettings, key)}} /> : ''}
                                        </span>
                                    </div>
                                );
                            case 'number':
                                return (
                                    <div className={errors[key] ? 'b-page-filter-items__item_wrapper error' : 'b-page-filter-items__item_wrapper'} key={key}>
                                        {
                                            item.label === 'return.product.filter.title.REFUND'
                                            ?
                                                <div className='b-page-filter-items__item_combine b-flex'>
                                                    {/* custom control to reduce product value */}
                                                    <FixedPercentageSelector
                                                        isInbound={isInbound}
                                                        isDisabled={isDisabled}
                                                        isLabeled={isLabeled}
                                                        returnOrderId={returnOrderId}
                                                        diminishedPrice={value}
                                                        defaultProductPrice={defaultProductPrice}
                                                        filterItem={item}
                                                        returnsProductId={returnsProductId}
                                                        defaultSettings={defaultSettings}
                                                        key={key}
                                                        callbackAction={changeAction}
                                                        currency={currency}
                                                    />
                                                </div>
                                                :
                                                <TextField className={(isLabeled ? classes.PageFilterLabeledInput: classes.PageFilterInput) + ' b-page-filter-items__item b-page-filter-items__item--number'} type='number' value={value} onChange={(e) => {
                                                    changeAction(e.target.value, item, returnsProductId, defaultSettings, key)
                                                }} disabled={isDisabled} {...conditionProps} inputProps={defaultSettings && defaultSettings[key] ? defaultSettings[key]: {} }/>

                                        }

                                        {!!errors[key] &&
                                            <CustomAnimate animation="transition.bounceIn" delay={200}>
                                                <div className='b-page-filter-items__error'>
                                                    {errors[key]}
                                                </div>
                                            </CustomAnimate>}
                                    </div>
                                );
                            case 'datepicker':
                                return (
                                    <div className={errors[key] ? 'b-page-filter-items__item_wrapper error' : 'b-page-filter-items__item_wrapper'} key={key} style={{width: `${this.getWidth(filteringItems.length, pageId, key)}%`}}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                            value={value}
                                            onChange={(e) => {
                                                changeAction(e, item, returnsProductId, defaultSettings, key)
                                            }}
                                            format='yyyy-MM-dd'
                                            {...conditionProps}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                );
                            case 'dateRange':
                                return (
                                    <div className={errors[key] ? 'b-page-filter-items__item_wrapper error' : 'b-page-filter-items__item_wrapper'} key={key} style={{width: `${this.getWidth(filteringItems.length, pageId, key)}%`}}>
                                        <div className={fromDateRangeValue || toDateRangeValue ? "b-page-filter-items__date_range_label b-page-filter-items__date_range_label--active b-page-filter-items__label" : "b-page-filter-items__date_range_label b-page-filter-items__label"}>
                                           <CustomTranslation id={item.label} />
                                        </div>
                                        <div className="b-page-filter-items__date_range_wrapper">
                                            <div className={fromDateRangeValue ? "b-page-filter-items__date_range_from b-page-filter-items__date_range_from--active InputFromTo" : "b-page-filter-items__date_range_from InputFromTo"}>
                                                <DayPickerInput
                                                    value={fromDateRangeValue}
                                                    placeholder={translate('products.report.list.columns.startDate')}
                                                    format="YYYY-MM-DD"
                                                    formatDate={formatDate}
                                                    parseDate={parseDate}
                                                    dayPickerProps={{
                                                        selectedDays: [from, {from, to}],
                                                        disabledDays: {after: to},
                                                        toMonth: to,
                                                        modifiers,
                                                        numberOfMonths: 2,
                                                        onDayClick: () => this.to.getInput().focus(),
                                                    }}
                                                    onDayChange={(from) => {
                                                        this.setState({ from }, ()=>{
                                                            this.showFromMonth();
                                                            changeAction({
                                                                startDate: from ? moment(from) : undefined,
                                                                type: 'startDate'
                                                            }, item, returnsProductId, defaultSettings, key)
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className={toDateRangeValue ? "b-page-filter-items__date_range_to b-page-filter-items__date_range_to--active InputFromTo-to" : "b-page-filter-items__date_range_to InputFromTo-to"}>
                                                <DayPickerInput
                                                      ref={el => (this.to = el)}
                                                      value={toDateRangeValue}
                                                      placeholder={translate('products.report.list.columns.endDate')}
                                                      format="YYYY-MM-DD"
                                                      formatDate={formatDate}
                                                      parseDate={parseDate}
                                                      dayPickerProps={{
                                                          selectedDays: [from, {from, to}],
                                                          disabledDays: {before: from},
                                                          modifiers,
                                                          month: from,
                                                          fromMonth: from,
                                                          numberOfMonths: 2,
                                                      }}
                                                      hideDayPicker={()=>{
                                                          return false
                                                      } }
                                                      onDayChange={(to) => {
                                                          this.setState({ to }, ()=>{
                                                              changeAction({
                                                                  endDate: to ? moment(to) : undefined,
                                                                  type: 'endDate'
                                                              }, item, returnsProductId, defaultSettings, key)
                                                          });
                                                      }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            default:
                                return (
                                    <div className={errors[key] ? 'b-page-filter-items__item_wrapper error' : 'b-page-filter-items__item_wrapper'} key={key} style={{width: `${this.getWidth(filteringItems.length, pageId, key)}%`}}>
                                        <InputBase className={(isLabeled ? classes.PageFilterLabeledInput: classes.PageFilterInput) + 'b-page-filter-items__items' } key={key} type={item.type} value={value} onChange={(e) => {
                                            changeAction(e.target.value, item, returnsProductId, defaultSettings, key)
                                        }} disabled={isDisabled} {...conditionProps} />
                                        {
                                            errors[key]
                                                ?
                                                <CustomAnimate animation="transition.bounceIn" delay={200}>
                                                    <div className='b-page-filter-items__error'>
                                                        {errors[key]}
                                                    </div>
                                                </CustomAnimate>
                                                :
                                                ''
                                        }
                                    </div>
                                )
                        }
                    })
                }
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.localize)
    };
}


export default connect(mapStateToProps, null)(withStyles(styles)(withRouter(PageFiltersItems)));