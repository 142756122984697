import React, {Component} from "react";
import {bindActionCreators} from "redux";
import classNames from 'classnames';
import {connect} from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import {close} from './actions/serverSuccessNotifierActions';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import green from '@material-ui/core/colors/green';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import ResetPasswordConst from './../../components/ResetPassword/const';
import {withStyles} from '@material-ui/core/styles';
import CustomTranslation from "../CustomTranslation/CustomTranslation";

const variantIcon = {
    success: CheckCircleIcon,
};

const noteStyles = theme => {
    return {
        success: {
            backgroundColor: green[600]
        }
    }
};

class ServerSuccessNotifier extends Component {

    /*
    * getErrorText {Function}
    * @description - get Text for displaying in success block.
    *
    * */
    getSuccessText = (text, data, status, actionType) => {

        let textStr = '';

        switch (actionType) {
            case ResetPasswordConst.RESET_PASSWORD_SEND_IDENTIFICATOR_ON_EMAIL:
                textStr = <CustomTranslation id="aLinkToResetPasswordHasBeenSent" />;
                break;
            default:
                textStr = '';
                break;
        }

        return textStr;
    };


    /*
    * handleClose {Function}
    * @description - close the notifier
    *
    * */
    handleClose = () => {
        this.props.close()
    };

    render() {
        let {classes, className, isActive, text, data, status, actionType} = this.props;
        const Icon = variantIcon['success'];

        return (
            <div className={isActive ? "b-server-success-notifier b-server-success-notifier--active" : "b-server-success-notifier"}>
                <Snackbar
                    TransitionComponent={Slide}
                    open={isActive}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    onClose={this.props.close}
                >
                    <SnackbarContent className={classNames(classes['success'], className)} aria-describedby="client-snackbar"
                         message={
                             <span className={`b-server-success-notifier__message`}>
                                <Icon className={classNames(classes.icon)} />
                                <span className="b-server-success-notifier__text">{isActive ? this.getSuccessText(text, data, status, actionType) : ''}</span>
                            </span>
                         }
                         action={[
                             <IconButton
                                 key="close"
                                 aria-label="Close"
                                 color="inherit"
                                 className={classes.close}
                                 onClick={this.handleClose}
                             >
                                 <CloseIcon className={classes.icon}/>
                             </IconButton>,
                         ]}
                    />

                </Snackbar>
            </div>
        )
    }
}

function mapStateToProps({serverSuccessNotifierState}) {
    return {
        ...serverSuccessNotifierState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            close
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(noteStyles)(ServerSuccessNotifier));