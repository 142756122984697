import React, {Component} from 'react';

function BasicWrapper (WrappedComponent) {
    return class extends Component {
        render() {
            return (
                <div className="b-app">
                    <div className="b-app__content">
                        <WrappedComponent {...this.props} />
                    </div>
                </div>
            )
        }
    }
};

export default BasicWrapper;