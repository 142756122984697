import Const from './../const';
import GeneralModel from './../../../models/GeneralModel';
import DefaultRequestConstObject from "../defaultRequestConst";
import {getChangedFilters, setFiltersViaMetrics} from "./../reducer/returnTableReducerMethods";

const defaultState = {
    data: new GeneralModel({}),
    DefaultRequestConst: DefaultRequestConstObject,
    tableCurrentPage: 0,
    isUpdateConfirmPopupActive: 0,
    isLoading: false,
    filters: {
        status: '',
        shipmentStatus: '',
        returnType: '',
        shopName: '',
        creationType: '',
        remarksFilter: ''
    },
};

export default function (state = defaultState, action = {}) {
    let {type, data, payload} = action;
    switch (type) {
        case Const.RETURNS_TABLE_FETCH_REQUEST_START:
            return {
                ...state,
                isLoading: true
            };
        case Const.RETURNS_TABLE_CHANGE_ORDER_FILTER:
            return {
                ...state,
                filters: getChangedFilters(state.filters, payload)
            };
        case Const.RETURNS_TABLE_FETCH_RETURNS:
            return {
                ...state,
                data: data,
                isLoading: false
            };
        case Const.RETURNS_TABLE_CHANGE_FILTERS_BY_INBOUND_METRICS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...setFiltersViaMetrics(payload)
                }
            };
        default:
            return state;
    }
}
