import React, {Component} from "react";
import AnimatedNumber from 'react-animated-number';
import pretty from 'pretty-number';
import CustomTranslation from "./../../../components/CustomTranslation/CustomTranslation";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

let prefix = 'dashboard.card';

class AnalyticsCard extends Component {

    getCardSubTitles = (cardType) => {
        let {translate} = this.props;
        let subTitles = [];

        switch (cardType) {
            case 'returnRate':
                subTitles.push(translate(`${prefix}.${cardType}.Returns`));
                subTitles.push(translate(`${prefix}.${cardType}.Orders`));
                break;
            case 'returnRateValue':
                subTitles.push(translate(`${prefix}.${cardType}.ReturnValue`));
                subTitles.push(translate(`${prefix}.${cardType}.OrderValue`));
                break;
            case 'rejectionRate':
                subTitles.push(translate(`${prefix}.${cardType}.RejectedItems`));
                subTitles.push(translate(`${prefix}.${cardType}.ApprovedItems`));
                break;
            case 'reConversionRate':
                subTitles.push(translate(`${prefix}.${cardType}.Reconversions`));
                subTitles.push(translate(`${prefix}.${cardType}.Returns`));
                break;
            case 'amountOfReturns':
                subTitles.push(translate(`${prefix}.${cardType}.amountOfReturns`));
                break;
            case 'returnValue':
                subTitles.push(translate(`${prefix}.${cardType}.valueOfReturns`));
                break;
            case 'valueGenerated':
                subTitles.push(`${translate(`${prefix}.${cardType}.orderValue`)} - ${translate(`${prefix}.${cardType}.returnValue`)} + ${translate(`${prefix}.${cardType}.reconversionValue`)}`);
                break;
            case 'reConversionValue':
                subTitles.push(translate(`${prefix}.${cardType}`));
                break;
            default:
                break;
        }


        return subTitles;
    };

    render (){
        let {card, type='rates'} = this.props;
        let subtitles = this.getCardSubTitles(card.type);
        let cardValue = type === 'values' ? parseInt(card.value.toFixed(0)) : card.value;
        return (
            <div className={`b-analytics-card b-analytics-card--${type}`}>
                <div className={`b-analytics-card__top b-analytics-card__top--${card.color}`}></div>
                <div className="b-analytics-card__body">
                    <div className="b-analytics-card__name">
                        <CustomTranslation id={`${prefix}.${card.type}`} />
                    </div>
                    <div className={`b-analytics-card__value b-analytics-card__value--${card.color} b-analytics-card__value--${type}`}>
                        <AnimatedNumber
                            value={cardValue}
                            duration={1000}
                            stepPrecision={0}
                            formatValue={n => pretty(n)}/>
                        {card.currency ? card.currency === '%' ? card.currency : ` ${card.currency}` : ''}
                    </div>
                </div>
                <div className="b-analytics-card__footer">
                    <div className={`b-analytics-card__titles b-analytics-card__titles--${type}`}>
                        {
                            subtitles && subtitles.length
                                ?
                                <ul className="b-analytics-card__titles_list">
                                    {
                                        subtitles.map((title, key)=>{
                                            return(
                                                <li key={key} className="b-analytics-card__titles_item">
                                                    {title}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                :
                                ''
                        }
                    </div>
                </div>
            </div>
        )
    };
}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {},
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(AnalyticsCard);