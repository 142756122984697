import Const from './../const';

const defaultState = {
    isMobileActive: false,
    isFolded: true,
    isFoldedOpen: false
};

export default function (state = defaultState, action = {}) {
    let {type, payload} = action;

    switch (type) {
        case Const.LEFT_SIDE_BAR_MOBILE_IS_ACTIVE:
            return {
                ...state,
                isMobileActive: payload
            };
        case Const.LEFT_SIDE_BAR_IS_FOLDED_OPEN:
            return {
                ...state,
                isFoldedOpen: payload
            };
        default:
            return state;
    }
}
