import React, {Component} from 'react';
import {Route, Switch, Router} from 'react-router-dom';
import {connect} from 'react-redux';
import {createHashHistory} from 'history';
import {bindActionCreators} from "redux";
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import appLayoutHOC from './../../HOC/AppLayout/AppLayout';
import ErrorBoundary from './../ErrorBoundary/ErrorBoundary';
import Login from '../Login/Login';
import OrdersPage from '../../pages/Orders/Orders';
import ReturnsPage from '../../pages/Returns/Returns';
import CustomerPage from '../../pages/Customers/Customers';
import BillingPage from '../../pages/Billing/Billing';
import ItemDataPage from '../../pages/ItemData/ItemData';
import ReturnsDataPage from '../../pages/ReturnData/ReturnData';
import SettingsPage from '../../pages/Settings/Settings';
import SecurityPage from '../../pages/Security/Security';
import InboundPage from '../../pages/Inbound/Inbound';
import ErrorDialog from "../ErrorDialog/ErrorDialog";
import AnalyticsPage from "../../pages/Analytics/Analytics";
import ResetPassword from './../../components/ResetPassword/ResetPassword';
import PageNotFound from './../../hooks/PageNotFound/PageNotFound';
import ServerErrorNotifier from './../../components/ServerErrorNotifier/ServerErrorNotifier';
import ServerSuccessNotifier from './../../components/ServerSuccessNotifier/ServerSuccessNotifier';
import Localization from './../../components/Localization/Localization';
import MainProperties from './../../components/MainProperties/MainProperties';
import returnadoTheme from './../../muiTheme/MuiTheme';
import ManagementPage from "./../../pages/Management/Management";

const history = createHashHistory();

class Returnado extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {isLoggedIn} = this.props;
        const AppLayout = appLayoutHOC(isLoggedIn);

        return (
            <>
                <ServerErrorNotifier/>
                <ServerSuccessNotifier/>
                <ErrorDialog />
                <MuiThemeProvider theme={returnadoTheme}>
                    <Localization isLoggedIn={isLoggedIn} history={history}>
                        <MainProperties isLoggedIn={isLoggedIn}>
                            <ErrorBoundary>
                                <Router history={history}>
                                    {
                                        !isLoggedIn
                                            ?
                                            <Switch>
                                                <Route exact path="/login" component={Login}/>
                                                <Route exact path="/reset-password/:token?" component={ResetPassword}/>
                                                <AppLayout exact path="*" component={PageNotFound}/>
                                            </Switch>
                                            :
                                            <Switch>
                                                <Route exact path="/login" component={Login}/>
                                                <AppLayout exact path="/analytics" component={AnalyticsPage}
                                                           pageName={'analytics'}/>
                                                <AppLayout exact path="/orders/:orderId?" component={OrdersPage}
                                                           pageName={'orders'}/>
                                                <AppLayout exact path="/returns/:returnId?" component={ReturnsPage}
                                                           pageName={'return.orders'}/>
                                                <AppLayout exact path="/customers" component={CustomerPage}
                                                           pageName={'customers'}/>
                                                <AppLayout exact path="/billing" component={BillingPage}
                                                           pageName={'billing'}/>
                                                <AppLayout exact path="/products-report" component={ItemDataPage}
                                                           pageName={'reports'}/>
                                                <AppLayout exact path="/returns-report" component={ReturnsDataPage}
                                                           pageName={'reports'}/>
                                                <AppLayout exact path="/settings" component={SettingsPage}
                                                           pageName={'settings'}/>
                                                <AppLayout exact path="/security" component={SecurityPage}
                                                           pageName={'security'}/>
                                                <AppLayout exact path="/inbound" component={InboundPage}
                                                           pageName={'inbound'}/>
                                                <AppLayout exact path="/management" component={ManagementPage}
                                                           pageName={'userManagement'}/>
                                                <AppLayout exact path="*" component={PageNotFound}/>
                                            </Switch>
                                    }
                                </Router>
                            </ErrorBoundary>
                        </MainProperties>
                    </Localization>
                </MuiThemeProvider>
            </>
        );
    }
}

function mapStateToProps({returnadoState}) {
    return {
        ...returnadoState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {},
        dispatcher,
    );
}


export default connect(mapStateToProps, matchDispatchToProps)(Returnado);