import LeftSideBarConst from './../../components/LeftSideBar/const';

const Const = {
    MANAGEMENT_START_FETCH_REQUEST: 'MANAGEMENT_START_FETCH_REQUEST',
    MANAGEMENT_FETCH_REQUEST: 'MANAGEMENT_FETCH_REQUEST',
    MANAGEMENT_SHOW_POPUP: 'MANAGEMENT_SHOW_POPUP',
    MANAGEMENT_HIDE_POPUP: 'MANAGEMENT_HIDE_POPUP',
    MANAGEMENT_SWITCH_TAB: 'MANAGEMENT_SWITCH_TAB',
    MANAGEMENT_CHANGE_STATUS: 'MANAGEMENT_CHANGE_STATUS',
    MANAGEMENT_RESET_PASSWORD: 'MANAGEMENT_RESET_PASSWORD',
    MANAGEMENT_CHANGE_NEW_ADMIN_DATA: 'MANAGEMENT_CHANGE_NEW_ADMIN_DATA',
    MANAGEMENT_CREATE_NEW_ADMIN: 'MANAGEMENT_CREATE_NEW_ADMIN',
    MANAGEMENT_SET_ERROR: 'MANAGEMENT_SET_ERROR',
    MANAGEMENT_CLEAR_CURRENT_ADMIN: 'MANAGEMENT_CLEAR_CURRENT_ADMIN',
    MANAGEMENT_SET_ERRORS_TO_ADD_NEW_ADMIN_POPUP: 'MANAGEMENT_SET_ERRORS_TO_ADD_NEW_ADMIN_POPUP',
    [LeftSideBarConst.LEFT_SIDE_BAR_IS_FOLDED_OPEN]: LeftSideBarConst.LEFT_SIDE_BAR_IS_FOLDED_OPEN
};

export default Const;