import React, {Component} from "react";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {withLocalize} from 'react-localize-redux';
import {getMainProperties, getSubShops, getReturnReasons} from "./actions/mainPropertiesActions";
import {ShopPropsContext} from './../../contexts/ShopPoprsContext';
import {find} from 'lodash';


class MainProperties extends Component {

    componentDidMount() {
        if (this.props.isLoggedIn) {
            this.getMainProperties();
            this.getSubShops();
            this.getReturnReasons();
        }
    }

    /**
     * isInspectletEnabled {Function}
     * @description - make request to get localization fail in JSON format
     *
     * */
    isInspectletEnabled = (settings) => {
        let inspectletData = settings && settings.toJSONView && settings.toJSONView().response && settings.toJSONView().response.length
            ? find(settings.toJSONView().response, (setting) => { return setting.name === 'inspectlet.enabled' }) : null;

        if(inspectletData && inspectletData.value === 'true') {
            return true;
        }

        return false;
    };

    /**
     * getShopProperties {Function}
     * @description - make request to get localization fail in JSON format
     *
     * */
    getMainProperties = () => {
        this.props.getMainProperties({
            route: 'getMainProps',
            requestData: {}
        })
    };

    /**
     *
     *
     * */
    getSubShops = () => {
        this.props.getSubShops({
            route: 'getSubShops',
            requestData: {}
        })
    };

    /**
     *
     *
     * */
    getReturnReasons = () => {
        this.props.getReturnReasons({
            route: 'getReturnReasons',
            requestData: {}
        });
    };

    componentDidUpdate(prevProps, prevState, snapshots) {
        let {isLoggedIn, mainSettings, wasMainPropertiesLoaded} = this.props;
        /** Get general localization */
        if (prevProps.isLoggedIn !== isLoggedIn && isLoggedIn === true) {
            this.getMainProperties();
            this.getSubShops();
            this.getReturnReasons();
        }

        if(prevProps.mainSettings !== mainSettings) {
            if(this.isInspectletEnabled(mainSettings)) {
                window.__insp = window.__insp || [];
                window.__insp.push(['wid', 1030310917]);
                var ldinsp = function(){
                    if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' === document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=445982849&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
                setTimeout(ldinsp, 500);
            }
        }

        if(prevProps.wasMainPropertiesLoaded !== wasMainPropertiesLoaded && !wasMainPropertiesLoaded) {
            console.log('main settings were not loaded')
        }
    }


    render() {
        let {mainSettings} = this.props;
        let contextSettings = {
            mainSettings: mainSettings
        };

        return (
            <ShopPropsContext.Provider value={contextSettings}>
                {this.props.children}
            </ShopPropsContext.Provider>
        )
    }
}

function mapStateToProps({mainPropertiesState}) {
    return {
        ...mainPropertiesState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            getMainProperties,
            getSubShops,
            getReturnReasons
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withLocalize(MainProperties));