import LeftSideBarConst from './../../components/LeftSideBar/const';

const Const = {
    ITEM_DATA_REPORT_START_FETCH_REQUEST: 'ITEM_DATA_REPORT_START_FETCH_REQUEST',
    ITEM_DATA_REPORT_CHANGE_FILTER: 'ITEM_DATA_REPORT_CHANGE_FILTER',
    ITEM_DATA_REPORT_CSV_FETCH: 'ITEM_DATA_REPORT_CSV_FETCH',
    ITEM_DATA_CLEAR_FILTERS: 'ITEM_DATA_CLEAR_FILTERS',
    ITEM_DATA_REPORT_FETCH: 'ITEM_DATA_REPORT_FETCH',
    [LeftSideBarConst.LEFT_SIDE_BAR_IS_FOLDED_OPEN]: LeftSideBarConst.LEFT_SIDE_BAR_IS_FOLDED_OPEN
};

export default Const;