import {combineReducers} from 'redux';
import { localizeReducer as localize } from "react-localize-redux";
import returnadoState from './../components/Returnado/reducer/returnadoReducer';
import localizationState from './../components/Localization/reducer/localizationReducer';
import mainPropertiesState from '../components/MainProperties/reducer/mainPropertiesReducer';
import loginState from '../components/Login/reducer/loginReducer';
import resetPasswordState from './../components/ResetPassword/reducer/resetPasswordReducer';
import serverErrorNotifierState from './../components/ServerErrorNotifier/reducer/serverErrorNotifierReducer';
import serverSuccessNotifierState from './../components/ServerSuccessNotifier/reducer/serverSuccessNotifierReducer';
import rightMenuState from './../components/RightMenu/reducer/rightMenuReducer';
import leftSideBarState from './../components/LeftSideBar/reducer/leftSideBarReducer';
import ordersPageState from './../pages/Orders/reducer/ordersReducer';
import returnsPageState from './../pages/Returns/reducer/returnsReducer';
import inboundPageState from './../pages/Inbound/reducer/inboundReducer';
import customersPageState from './../pages/Customers/reducer/customerReducer';
import headerState from './../components/Header/reducer/headerReducer';
import securityPageState from './../pages/Security/reducer/securityReducer';
import itemDataReportState from './../pages/ItemData/reducer/itemDataReducer';
import returnDataReportState from './../pages/ReturnData/reducer/returnDataReducer';
import errorDialogState from '../components/ErrorDialog/reducer/errorDialogReducer';
import dashboardsMetricsState from './../components/DashboardMetrics/reducer/dashboardsMetricsReducer';
import returnCommentsAreasState from './../components/ReturnCommentsAreas/reducer/returnCommentsAreasReducer';
import returnDescriptionState from "./../components/ReturnDescription/reducer/returnDescriptionReducer";
import fixedPercentageSelectorState from "../components/FixedPercentageSelector/reducer/fixedPercentageSelectorReducer";
import fixedFeeSelectorState from "../components/FixedFeeSelector/reducer/fixedFeeSelectorReducer";
import managementPageState from "./../pages/Management/reducer/managementReducer";
import analyticsPageState from "../pages/Analytics/reducer/analyticsReducer";
import returnsTableState from "./../components/ReturnsTable/reducer/returnTableReducer";
import dropZoneState from "./../components/DropZone/reducer/dropZoneReducer";
import returnEventLogPopupState from "./../components/ReturnEventLogPopup/reducer/returnEventLogPopupReducer";

const allReducers = combineReducers({
    localize,
    returnadoState,
    localizationState,
    mainPropertiesState,
    rightMenuState,
    leftSideBarState,
    loginState,
    resetPasswordState,
    serverErrorNotifierState,
    serverSuccessNotifierState,
    ordersPageState,
    returnsPageState,
    inboundPageState,
    customersPageState,
    headerState,
    errorDialogState,
    securityPageState,
    itemDataReportState,
    returnDataReportState,
    dashboardsMetricsState,
    returnCommentsAreasState,
    returnDescriptionState,
    fixedPercentageSelectorState,
    fixedFeeSelectorState,
    managementPageState,
    dropZoneState,
    analyticsPageState,
    returnsTableState,
    returnEventLogPopupState
});

export default allReducers;