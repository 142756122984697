import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import Returnado from './components/Returnado/Returnado';
import configuration from "./configs/configs";
import configureStore from "./store/configureStore";

import { LocalizeProvider } from "react-localize-redux";
import * as serviceWorker from './serviceWorker';
import 'normalize.css';
import "./../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import './../node_modules/react-table/react-table.css';
import './../node_modules/react-day-picker/lib/style.css';
import './styles/index.scss';

/* Set appropriate configuration file according to environment */
//const store = createStore(allReducers, applyMiddleware(thunk, returnadoMiddleWare));
const store = configureStore();

/* Run application */
ReactDOM.render(
    <Provider store={store}>
        <LocalizeProvider store={store}>
            <Returnado configuration={configuration} />
        </LocalizeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
