import ServerSuccessNotifierConst from './../../../components/ServerSuccessNotifier/const';

/*
* ServerSuccessNotifier {Function}
* @description - function is passing action with error data to redux state and activate error notifier
*
* */
export default (response, next, action) => {
    let {status, data, statusText} = response;

    next({
        type: ServerSuccessNotifierConst.SERVER_SUCCESS_NOTIFIER_IS_ACTIVE,
        payload: {
            actionType: action.type,
            requestData: action.requestData,
            statusText: statusText,
            successStatus: status,
            successData: data
        }
    });

}


