import React, {Component} from "react";
import {createHashHistory} from 'history';
import BasicWrapper from './../../HOC/BasicWrapper/BasicWrapper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Slide, withStyles} from '@material-ui/core';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getSupportEmail} from "./../../utilites/index";
import CloseIcon from "@material-ui/icons/Cancel";
import CustomTranslation from "../CustomTranslation/CustomTranslation";


function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const styles = theme => {
    return {
        btn: {
            width: '50%',
        }
    }
};

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.history = createHashHistory();
        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    /**
     * handleClose {Function}
     * @description - close the notifier
     *
     * */
    handleClose = () => {
        this.setState({
            hasError: false
        })
    };


    componentDidCatch(error, info) {
        // Here we can also log the error to an error reporting service
        console.log(error, info)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {isActive} = this.props;

        if (prevProps.isActive !== isActive && isActive) {
            if (isActive) {
                this.handleClose()
            }
        }
    }

    render() {
        let {classes} = this.props;

        let isActive = this.state.hasError;

        return (
            <>
                {!!isActive &&
                <Dialog
                    open={isActive}
                    onClose={this.handleClose}
                    maxWidth={'sm'}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={isActive ? 'b-server-error-notifier b-server-error-notifier--active' : 'b-server-error-notifier'}
                >
                    {this.handleClose &&
                    <CloseIcon className='b-server-error-notifier__close_ctrl' onClick={() => {
                        this.handleClose()
                    }}/>}
                    <DialogTitle id="alert-dialog-slide-title"
                                 className="b-server-error-notifier__title_wrapper">
                        <CustomTranslation id="server.response.error.something.wrong.headline"/>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                                        <span className="b-server-error-notifier__text">
                                            <span className="b-server-error-notifier__text_p">
                                                <CustomTranslation
                                                    id="server.response.error.something.wrong.bread"
                                                   data={{
                                                       customerSupportEmail: getSupportEmail()
                                                   }}/>
                                            </span>
                                        </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="b-server-error-notifier__actions">
                        <Button onClick={() => {
                            this.handleClose()
                        }} variant="contained" className={`${classes.btn}`}>
                            <CustomTranslation id='return.before.update.popup.btnName.goBack'/>
                        </Button>
                    </DialogActions>
                </Dialog> }
                {this.props.children}
            </>

        );
    }
}

function mapStateToProps({errorDialogState}) {
    return {
        ...errorDialogState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {},
        dispatcher,
    );
}

export default connect(mapStateToProps,
    matchDispatchToProps)(BasicWrapper((withStyles(styles)(ErrorBoundary))));