import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {withStyles} from '@material-ui/core/styles';
import {Translate} from "react-localize-redux";


const noteStyles = theme => {
    return {
        noteP: {
            margin: '0 0 5px 0'
        }
    }
};

class EmailSendNote extends Component {
    render () {
        let {classes} = this.props;
        return (
            <div className='b-reset-password__item'>
                <div className='b-reset-password__note'>
                    <div className='b-reset-password__note_header'>
                        <Typography variant='h5' component='h3'>
                            <Translate id='login.restore.password.title' />
                        </Typography>
                    </div>
                    <div className='b-reset-password__note_body'>
                        <Typography component='p' className={classes.noteP}>
                            <Translate id='login.restore.password.text' />
                        </Typography>
                    </div>
                    <div className='b-reset-password__note_controls'>
                        <Link href={'#/login'} color='primary'><Translate id='login.restore.password.button.text' /></Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(noteStyles)(EmailSendNote);