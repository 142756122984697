import React, {Component} from 'react'
import {DropzoneAreaBase} from 'material-ui-dropzone'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Carousel} from 'react-responsive-carousel';
import {
    loadFiles,
    replaceFiles,
    merchantFilesWereUpdated,
    clearState,
    imagesWereUploaded
} from "./actions/dropZoneActions";
import {uniqBy, isNumber, find} from 'lodash';
import CancelIcon from '@material-ui/icons/Cancel';
import {ArrowBackIos, ArrowForwardIos} from '@material-ui/icons';
import ImageZoom from 'react-medium-image-zoom';
import {getTranslate} from "react-localize-redux";

const filesLimit = 20;
const zoomOverlayStyles = {
    overlay: {
        backgroundColor: '#7D818C',
        opacity: 0.9
    }
};

class DropZone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
            preloadedFileNames: {}
        }
    }

    /**
     * onAdd {Function}
     * @description - add an appropriate file(s) and generate loaded files array based on or 'returnOrderId' and 'orderItemId'
     *
     * */
    onAdd = (files) => {
        let {merchantFiles, defaultImages = [], returnOrderId, orderItemId} = this.props;
        let index = defaultImages.length + files.length;
        let currentUploadedFiles = merchantFiles && merchantFiles[returnOrderId] ? merchantFiles[returnOrderId][orderItemId] : [];

        this.setState({
            currentIndex: index,
        }, () => {
            let loadedFiles = uniqBy(files, (item) => {
                let {name = '', path = ''} = item.file;
                if (name) {
                    return name && path;
                } else {
                    return path;
                }
            });
            let filteredFilesToAdd = [];

            // we should not load the file with the name in case if we already uploaded file with similar name previously
            loadedFiles.forEach((fileItem) => {
                let existedFileName = fileItem.file.name;

                if (existedFileName) {
                    if (!find(currentUploadedFiles, (newFileItem) => {
                        return newFileItem.file.name === existedFileName
                    })) {
                        filteredFilesToAdd.push(fileItem)
                    }
                } else {
                    filteredFilesToAdd.push(fileItem)
                }
            });

            this.props.loadFiles(filteredFilesToAdd, returnOrderId, orderItemId);
        });

    };

    /**
     * onDelete {Function}
     * @description - delete an appropriate file from already loaded files based on or 'returnOrderId' and 'orderItemId'
     *
     * */
    onDelete = (fileObject) => {
        let {merchantFiles, returnOrderId, orderItemId} = this.props;
        let currentLoadedFiles = merchantFiles[returnOrderId][orderItemId];
        let newCurrentFiles = [];
        let index = 0;

        currentLoadedFiles.forEach((fileItem, key) => {
            let currentUploadedFile = fileItem.file;
            let currentDeletedFile = fileObject.file;
            if (currentUploadedFile.name !== currentDeletedFile.name && currentUploadedFile.path !== currentDeletedFile.path) {
                newCurrentFiles.push(fileItem)
            } else {
                index = key;
            }
        });

        this.setState({
            currentIndex: isNumber(index) ? index - 1 === 0 || index - 1 < 0 ? 0 : index - 1 : 0
        }, () => {
            this.props.replaceFiles(newCurrentFiles, returnOrderId, orderItemId);
        });
    };


    updateFileNameToBeKey = (name) => {
        return name.replace(/\s/g, '_');
    };

    /**
     * getLoadedFiles {Function}
     * @description - get an appropriate files array based on or 'returnOrderId' and 'orderItemId'
     * @return {Array}
     *
     * */
    getLoadedFiles = () => {
        let {merchantFiles, returnOrderId, orderItemId} = this.props;
        if (merchantFiles && merchantFiles[returnOrderId] && merchantFiles[returnOrderId][orderItemId]) {
            return merchantFiles[returnOrderId][orderItemId];
        } else {
            return []
        }
    };

    /**
     * loadToS3 {Function}
     * @description - send request to S3 service by preused link
     * @return {Promise}
     *
     * */
    loadToS3(
        url,
        file,
        successCallback = (fileName) => {
            console.log("Sent to s3 storage service!", fileName)
        },
        errorCallback = (error) => {
            console.log("Sent to s3 error service!", error)
        }
    ) {
        let reader = new FileReader();
        reader.onload = function (e) {
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-type': file.file.type,
                    'Content-Disposition': 'inline'
                },
                body: e.target.result
            }).then(() => {
                successCallback(file.file.name)
            }).catch((error) => {
                errorCallback(error)
            });
        };
        reader.readAsArrayBuffer(file.file);
    }

    uploadImagesToS3(imagesToUpload, merchantFiles, loadToS3) {
        let promises = [];
        let imagesToUploadArray = [];
        let merchantItemFiles = {};

        delete imagesToUpload['requestStatus'];

        for (let merchantKey in merchantFiles) {
            if (merchantFiles.hasOwnProperty(merchantKey)) {
                let currentFilesMap = merchantFiles[merchantKey];

                for (let fileKey in currentFilesMap) {
                    if (currentFilesMap.hasOwnProperty(fileKey)) {
                        merchantItemFiles[fileKey] = currentFilesMap[fileKey]
                    }
                }

            }
        }

        for (let key in imagesToUpload) {
            if (imagesToUpload.hasOwnProperty(key)) {
                let currentItemId = key;
                let merchantFilesArray = merchantItemFiles[key];

                if (merchantFilesArray && merchantFilesArray.length) {
                    merchantFilesArray.forEach((fileItem) => {
                        let fileName = fileItem.file.name;
                        let presignedUrl = imagesToUpload[currentItemId][fileName];

                        if (presignedUrl) {
                            imagesToUploadArray.push({
                                file: fileItem,
                                presignedUrl,
                                name: fileItem.file.name
                            })
                        }
                    })
                }
                ;
            }
        }

        imagesToUploadArray.forEach((imageToUpload) => {
            let promise = new Promise(function (resolve, reject) {
                loadToS3(
                    imageToUpload.presignedUrl,
                    imageToUpload.file,
                    () => {
                        console.log(`${imagesToUploadArray.length} image(s) were uploaded to s3 storage service!`);
                        resolve();
                    },
                    (error) => {
                        console.log("upload to s3 error", error);
                        reject();
                    })
            }).catch(
                error => {
                    return error
                }
            );
            promises.push(promise);
        });

        return Promise.all(promises);
    }

    /**
     * renderFilesCarousel {Function}
     * @description -
     * @return {Array}
     *
     * */
    renderFilesCarousel = (files = []) => {
        let length = files.length;
        if (files && length) {
            return files.map((fileItem, key) => {
                let {file, data, imagePath} = fileItem;
                return (
                    <div
                        className={length === 1 ? "b-drop-zone__carousel_img_wrapper b-drop-zone__carousel_img_wrapper--single" : "b-drop-zone__carousel_img_wrapper"}
                        key={key} onClick={
                        (e) => {
                            let img = e && e.target ? e.target.querySelector('.b-drop-zone__carousel_img') : null;
                            if (img) {
                                img.click()
                            }
                        }
                    }>
                        <ImageZoom
                            image={{
                                src: data || imagePath,
                                alt: file.name,
                                className: length === 1 ? "b-drop-zone__carousel_img b-drop-zone__carousel_img--single" : "b-drop-zone__carousel_img",
                            }}
                            zoomImage={{
                                src: data || imagePath,
                                alt: file.name,
                                className: length === 1 ? "b-drop-zone__carousel_img b-drop-zone__carousel_img--big b-drop-zone__carousel_img--single" : "b-drop-zone__carousel_img b-drop-zone__carousel_img--big",
                            }}
                            defaultStyles={zoomOverlayStyles}
                        />
                        <CancelIcon
                            className={length === 1 ? "b-drop-zone__carousel_img_remove b-drop-zone__carousel_img_remove--single" : "b-drop-zone__carousel_img_remove"}
                            onClick={() => {
                                this.onDelete(fileItem)
                            }}></CancelIcon>
                    </div>
                )
            });
        }
    };

    /**
     * renderSingleCarouselFile {Function}
     * @description - to render a single image we do not need. Just render an image with similar controls.
     * @return {HTML element}
     * */
    renderSingleCarouselFile = (files = []) => {
        let currentFile = files[0];
        return (
            <div className="b-drop-zone__carousel_img_wrapper b-drop-zone__carousel_img_wrapper--single">
                <ImageZoom
                    image={{
                        src: currentFile.data,
                        alt: currentFile.file.name,
                        className: "b-drop-zone__carousel_img b-drop-zone__carousel_img--single",
                    }}
                    zoomImage={{
                        src: currentFile.data,
                        alt: currentFile.file.name,
                        className: "b-drop-zone__carousel_img b-drop-zone__carousel_img--big b-drop-zone__carousel_img--single"
                    }}
                    defaultStyles={zoomOverlayStyles}
                />
                <CancelIcon
                    className="b-drop-zone__carousel_img_remove b-drop-zone__carousel_img_remove--single"
                    onClick={() => { this.onDelete(currentFile) }}/>
            </div>
        )
    };

    componentDidMount() {
        let {defaultImages = []} = this.props;
        if (defaultImages && defaultImages.length) {
            this.onAdd(defaultImages.map((image) => {
                return {
                    data: image.imagePath,
                    file: {
                        name: image.imageId,
                        path: image.imagePath
                    }
                }
            }))
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            defaultImages = [],
            imagesToS3Upload,
            merchantFiles,
            returnOrderId,
            orderItemId,
            isSimpleReturnReFetch
        } = this.props;
        if (prevProps.merchantFiles !== merchantFiles) {
            this.props.merchantFilesWereUpdated(merchantFiles, returnOrderId);
        }

        if (prevProps.defaultImages !== defaultImages && (prevProps.returnOrderId !== returnOrderId || prevProps.orderItemId !== orderItemId)) {
            if (!merchantFiles[returnOrderId]) {
                this.onAdd(defaultImages.map((image) => {
                    return {
                        data: image.imagePath,
                        file: {
                            name: image.imageId,
                            path: image.imagePath
                        }
                    }
                }))
            } else {
                this.props.replaceFiles(defaultImages.map((image) => {
                    return {
                        data: image.imagePath,
                        file: {
                            name: image.imageId,
                            path: image.imagePath
                        }
                    }
                }), returnOrderId, orderItemId);
            }
        }

        // check if need to upload images to s3
        if (prevProps.imagesToS3Upload !== imagesToS3Upload) {
            if (imagesToS3Upload) {
                this.uploadImagesToS3(imagesToS3Upload, merchantFiles, this.loadToS3).then(() => {
                    this.props.imagesWereUploaded();
                })
            }
        }

        if (prevProps.isSimpleReturnReFetch !== isSimpleReturnReFetch) {
            this.props.imagesWereUploaded();
        }

    }


    componentWillUnmount() {
        this.props.clearState()
    }

    render() {
        let {isHandlingRunning, translate} = this.props;
        let loadedFiles = this.getLoadedFiles();
        let {length} = loadedFiles;

        return (
            <div className={isHandlingRunning ? "b-drop-zone b-drop-zone--loading b-flex" : "b-drop-zone b-flex"}>
                <div
                    className={loadedFiles && length ? length === 1 ? "b-drop-zone__carousel_item b-drop-zone__carousel_item--carousel b-drop-zone__carousel_item--single" : "b-drop-zone__carousel_item b-drop-zone__carousel_item--carousel" : "hidden"}>
                    {
                        loadedFiles && length === 1
                            ?
                            this.renderSingleCarouselFile(loadedFiles)
                            :
                            <Carousel
                                showThumbs={false}
                                showIndicators={false}
                                showStatus={false}
                                centerMode={true}
                                axis='horizontal'

                                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                    hasPrev && (
                                        <ArrowBackIos
                                            className="b-drop-zone__carousel_ctrl b-drop-zone__carousel_ctrl--prev"
                                            type="button" onClick={onClickHandler} title={label}/>
                                    )
                                }
                                renderArrowNext={(onClickHandler, hasNext, label) =>
                                    hasNext && (
                                        <ArrowForwardIos
                                            className="b-drop-zone__carousel_ctrl b-drop-zone__carousel_ctrl--next"
                                            type="button" onClick={onClickHandler} title={label}/>
                                    )
                                }
                            >
                                {
                                    this.renderFilesCarousel(loadedFiles)
                                }
                            </Carousel>
                    }

                </div>
                <div
                    className={loadedFiles && length ? length === 1 ? "b-drop-zone__carousel_item b-drop-zone__carousel_item--single" : "b-drop-zone__carousel_item" : "b-drop-zone__carousel_item b-drop-zone__carousel_item--full"}>
                    <DropzoneAreaBase
                        dropzoneText={translate('return.order.details.customer.reclamation.upload.images')}
                        filesLimit={filesLimit}
                        maxFileSize={15000000}
                        acceptedFiles={['image/jpeg', 'image/jpg', 'image/gif', 'image/png']}
                        previewGridClasses={{
                            container: 'b-drop-zone__container',
                            item: 'b-drop-zone__item',
                            image: 'b-drop-zone__image'
                        }}
                        alertSnackbarProps={{
                            autoHideDuration: 10000
                        }}
                        dropzoneClass={loadedFiles && length ? "b-drop-zone__zone b-drop-zone__zone--loaded" : "b-drop-zone__zone"}
                        dropzoneParagraphClass="b-drop-zone__intro"
                        showAlerts={['error']}
                        showPreviewsInDropzone={false}
                        onAdd={this.onAdd}
                        onDelete={this.onDelete}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.dropZoneState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            loadFiles,
            replaceFiles,
            merchantFilesWereUpdated,
            clearState,
            imagesWereUploaded
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(DropZone);