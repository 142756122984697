import Const from "./../const";

/**
 * requestMetricsStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestMetricsStart = () =>{
    return {
        type: Const.DASHBOARDS_METRICS_START_FETCH_REQUEST
    }
};

/**
 * getDashboardMetrics {React Action}
 * @description - send request to the server to get inbound metrics data
 *
 * */
export function getDashboardMetrics(requestData) {
    return dispatch => {
        dispatch(requestMetricsStart());
        dispatch({
            type: Const.DASHBOARDS_METRICS_FETCH_REQUEST,
            ...requestData
        });
    }
}

export function setFilteringToOrderPage(filters) {
    return dispatch => {
        dispatch({
            type: Const.RETURNS_TABLE_CHANGE_FILTERS_BY_INBOUND_METRICS,
            payload: filters
        });
    }
}