import Const from './../const';

const defaultState = {
    reducedValues: {},
    wasReducedValueChanged: false,
    wasReducedValueCleared: false
};

export default function (state = defaultState, action = {}) {
    let { type, payload={}} = action;
    let {returnOrderId, productId, value, defaultProductPrice, percentageValue, diminishedPrice} = payload;
    switch (type) {
        case Const.FIXED_REDUCED_PERCENTAGE_REDUCE_VALUE_SET_TO_DEFAULT:
            return {
                ...state,
                reducedValues: {
                    ...state.reducedValues,
                    [returnOrderId]: {
                        ...state.reducedValues[returnOrderId],
                        [productId]: {
                            newDiminishedPrice: defaultProductPrice,
                            defaultProductPrice: defaultProductPrice,
                            defaultDiminishedPrice: diminishedPrice,
                            decreasedValue: 0,
                            percentageValue: ''
                        }
                    }
                },
                wasReducedValueChanged: !state.wasReducedValueChanged
            };
        case Const.FIXED_REDUCED_PERCENTAGE_REDUCE_VALUE:
            return {
                ...state,
                reducedValues: {
                    ...state.reducedValues,
                    [returnOrderId]: {
                        ...state.reducedValues[returnOrderId],
                        [productId]: {
                            newDiminishedPrice: parseFloat(value),
                            defaultProductPrice: defaultProductPrice,
                            defaultDiminishedPrice: diminishedPrice,
                            decreasedValue: defaultProductPrice - parseFloat(value),
                            percentageValue: percentageValue ? parseFloat(percentageValue) : ''
                        }
                    }
                },
                wasReducedValueChanged: !state.wasReducedValueChanged
            };
        case Const.FIXED_REDUCED_PERCENTAGE_REDUCE_VALUE_CLEAR:
            return {
                ...state,
                reducedValues: defaultState.reducedValues,
                wasReducedValueCleared: !state.wasReducedValueCleared
            };
        default:
            return state;
    }
}
