import Const from './../const';

/*
* handleRightMenuActiveState {Function}
* @description - handling for open/close left menu
*
* */

export function handleRightMenuActiveState(isActive) {
    return dispatch => {
        dispatch({
            type: Const.RIGHT_MENU_IS_ACTIVE,
            payload: isActive
        });
    }
}

