import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {isEmpty} from 'lodash';
import Link from '@material-ui/core/Link';
import {Button} from '@material-ui/core';
import {
    fetchOrders
} from "./../actions/ordersActions";
import {
    formatDate,
    getAppropriateMarket,
    isNeedToShowMarketLanguage,
    isNeedToShowMarket,
    getTableHeight
} from './../../../utilites/index';
import CustomTranslation from "./../../../components/CustomTranslation/CustomTranslation";
import CustomTable from './../../../components/CustomTable/CustomTable';
import configuration from '../../../configs/configs';
import defaultRequestConst from './../defaultRequestConst';
import Loader from "./../../../containers/Loader/Loader";

let {timeouts={}} = configuration;

class OrderPageTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            pageSizeOption: defaultRequestConst.limit,
            isOpenWidgetControlVisible: false
        };
        this.widgetButtonSetTimeout = null;
    }

    /**
     * getReturnOrdersList {Function}
     * @description - return order status label based on order's data
     * @return {String}
     *
     * */
    getReturnOrdersList = (row) => {
        let isOrdersList = false;
        let orderData = row._original || {};

        if (orderData.returnOrders && orderData.returnOrders.length) {
            isOrdersList = true;
        }

        return isOrdersList ? (
            <ul className='b-page-table__order_list b-flex'>
                {
                    orderData.returnOrders.map((returnOrder, key) => {
                        return <li className='b-page-table__order_list_item' key={key}>
                            <Link href={`#/returns/${returnOrder.returnOrderId}`}
                                  color='primary'>
                                <CustomTranslation id='order.list.columns.text.order'/> {returnOrder.returnOrderId}
                            </Link>
                        </li>
                    })
                }
            </ul>
        ) : '-';
    };

    /**
     * isWidgetOpenAvailable {Function}
     * @description - defines is Widget functionality available or not
     * @return {Boolean}
     *
     * */
    isWidgetOpenAvailable = (row) => {
        let isWidgetOpenAvailable = false;
        let orderData = row._original || {};

        if (!orderData.pending) {
            isWidgetOpenAvailable = true;
        }

        return isWidgetOpenAvailable;
    };


    /**
     * getOrderStatusLabel {Function}
     * @description - return order status label based on order's data
     * @return {String}
     *
     * */
    getOrderStatusLabel = (row) => {
        let {translate} = this.props;
        let orderData = row._original || {};
        let {orderStatusLabelsKeys} = configuration;
        let statuses = [];

        if (orderData.pending) {
            statuses.push(translate(orderStatusLabelsKeys.RETURN_NOT_ALLOWED));
        }
        if (orderData.returnOrders && orderData.returnOrders.length > 0) {
            statuses.push(translate(orderStatusLabelsKeys.RETURNED));
        }
        if (orderData.sourceOrderId !== undefined) {
            statuses.push(translate(orderStatusLabelsKeys.RECONVERSION));
        }

        return statuses.map((status, key) => {
            return (
                <p key={key} className='b-orders-page__in_table_status'>{status}</p>
            )
        });
    };

    /**
     * getTableData {Function}
     * @description - return data array to render in returns table
     * @return {Array}
     *
     * */
    getTableData = (data) => {
        return data && data.toJSONView && isEmpty(data.toJSONView()) ? [] : data.getField('data');
    };

    /**
     * goToAppropriateTablePage {Function}
     * @description - callback fires when user use table pagination
     * */
    goToAppropriateTablePage = (isPrev = false) => {
        let {currentPage} = this.state;

        if (isPrev && currentPage !== 0) {
            this.onChangeCurrentPageAction(currentPage - 1)
        } else {
            this.onChangeCurrentPageAction(currentPage + 1)
        }

    };

    /**
     * onPageSizeChange {Function}
     * @description - action calls when page size was changed using table page size dropdown
     *
     * */
    onPageSizeChange = (pageSize) => {
        let {DefaultRequestConst} = this.props;

        this.setState({
            pageSizeOption: pageSize
        }, () => {
            this.fetchOrders({
                ...DefaultRequestConst,
                limit: pageSize
            })
        });

    };

    /**
     * onChangeCurrentPageAction {Function}
     * @description - action calls on page change using table next/previous controls
     *
     * */
    onChangeCurrentPageAction = (nextCurrentPage) => {
        let {DefaultRequestConst, filters} = this.props;
        let {pageSizeOption} = this.state;

        if (filters.shopName) {
            DefaultRequestConst.shopId = filters.shopName
        } else {
            delete DefaultRequestConst.shopId
        }

        this.setState({
            currentPage: nextCurrentPage
        }, () => {
            this.fetchOrders({
                ...DefaultRequestConst,
                limit: pageSizeOption,
                page: nextCurrentPage
            })
        })

    };

    /**
     * fetchOrders {Function}
     * @description - main function to get orders from server
     *
     * */
    fetchOrders = (requestData = null) => {
        let {DefaultRequestConst} = this.props;
        let dataForRequest = requestData ? requestData : {
            ...DefaultRequestConst
        };

        this.props.fetchOrders({
            route: 'getOrders',
            isWithGetParams: true,
            requestData: dataForRequest
        });
    };

    /**
     * getMarket {Function}
     * @description - receives shopId as parameter and generats appropriate market name based on main shop configurations
     * @return {String}
     *
     * */
    getMarket = (shopId) => {
        let {mainPropertiesState} = this.props;
        return getAppropriateMarket(mainPropertiesState, shopId, isNeedToShowMarket(mainPropertiesState), isNeedToShowMarketLanguage(mainPropertiesState));
    };

    /**
     * renderTable {Function}
     * @description - render table using data from server side
     * @return {React Component}
     *
     * */
    renderTable = () => {
        let {data, isLoading, openWidgetAction, isExternalIdToShow=false} = this.props;
        let {currentPage, pageSizeOption, isOpenWidgetControlVisible} = this.state;
        let prefix = 'order.list.columns';
        let tableData = this.getTableData(data);
        let totalReturnsAmount = data.getField('paging') ? data.getField('paging').total : 1;
        let totalPages = Math.ceil(totalReturnsAmount / pageSizeOption);
        const columns = [{
            //orderExternalSequentialId
            Header: <CustomTranslation id={`${prefix}.extId`}/>,
            id: "orderExternalId",
            accessor: (row) => row.orderExternalId,
            Cell: ({row}) => {
                let {orderExternalId, orderExternalSequentialId} = row._original;
                let id = isExternalIdToShow ? orderExternalSequentialId ? orderExternalSequentialId : orderExternalId : orderExternalId;
                return id;
            },
        }, {
            Header: <CustomTranslation id={`${prefix}.date`}/>,
            id: 'date',
            accessor: (row) => row.date,
            Cell: ({row}) => {
                return formatDate(row.date)
            },
            width: 130
        }, {
            Header: <CustomTranslation id={`${prefix}.email`}/>,
            id: 'customer.email',
            accessor: (row) => row.customer.email,
            Cell: ({row}) => {
                return row.customer.email
            },
        }, {
            Header: <CustomTranslation id={`${prefix}.customer`}/>,
            accessor: 'customer',
            Cell: ({row}) => {
                return `${row.customer.firstName} ${row.customer.lastName}`
            },
        }, {
            Header: <CustomTranslation id={`${prefix}.shopName`}/>,
            id: 'shopId',
            accessor: (row) => row.shopId,
            Cell: ({row}) => {
                return this.getMarket(row.shopId || row._original.shopId)
            },
        }, {
            Header: <CustomTranslation id={`${prefix}.status`}/>,
            id: 'status',
            accessor: (row) => row.status,
            Cell: ({row}) => {
                return this.getOrderStatusLabel(row)
            },
        }, {
            Header: <CustomTranslation id={`${prefix}.returnorders`}/>,
            accessor: 'returnOrders',
            Cell: ({row}) => {
                return this.getReturnOrdersList(row)
            },
        }, {
            Header: '',
            accessor: '3',
            Cell: ({row}) => {
                return this.isWidgetOpenAvailable(row) ?
                    isOpenWidgetControlVisible
                    ?
                    <Button className="b-orders-page__open_widget_btn"  variant="contained" color="primary" onClick={() => {
                        openWidgetAction(row)
                    }}>
                        <CustomTranslation id='order.list.columns.return'/>
                    </Button>
                    :
                    <span className='b-orders-page__widget_btn_loader'><Loader size={20} /></span>
                    : ''
            },
            width: 150
        }];

        return <CustomTable
            className={'-highlight -striped'}
            data={tableData}
            columns={columns}
            loading={isLoading}
            isLoading={isLoading}
            defaultPageSize={pageSizeOption}
            showPagination={false}
            pageSize={tableData ? tableData.length : defaultRequestConst.limit}
            style={{
                // This will force the table body to overflow and scroll, since there is not enough room
                height: getTableHeight(tableData, '600px')
            }}
            isPaginationActive={true}
            currentPage={currentPage}
            pageSizeOption={totalReturnsAmount}
            totalPages={totalPages}
            prevAction={() => {
                this.goToAppropriateTablePage(true)
            }}
            nextAction={() => {
                this.goToAppropriateTablePage()
            }}
            onPageSizeChangeAction={this.onPageSizeChange}
        />
    };

    componentDidMount() {
        //need to render button to open widget after several seconds
        this.widgetButtonSetTimeout = setTimeout(()=>{
            this.setState({
                isOpenWidgetControlVisible: true
            })
        }, timeouts.ordersPageOpenWidgetBtnVisibility)
    }

    componentWillUnmount() {
        clearTimeout(this.widgetButtonSetTimeout);
    }

    render() {
        return (
            <>
                {this.renderTable()}
            </>
        )
    };
}

function mapStateToProps(state) {
    return {};
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            fetchOrders
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(OrderPageTable);