import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import CustomTranslation from "../CustomTranslation/CustomTranslation";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";


class Pagination extends Component {

    /**
     * isPreviousDisabled {Function}
     * @description - define is previous button disabled
     * @return {Boolean}
     *
     * */
    isPreviousDisabled = () => {
        let {
            currentPage,
        } = this.props;

        return currentPage===0;
    };

    /**
     * isNextDisabled {Function}
     * @description - define is next button disabled
     * @return {Boolean}
     *
     * */
    isNextDisabled = () => {
        let {
            data,
            currentPage,
            totalPages
        } = this.props;
        return currentPage+1 === totalPages || !data.length
    };

    render() {
        let {
            currentPage,
            pageSizeOption,
            totalPages,
            prevAction,
            nextAction,
            isLoading
        } = this.props;
        return (
            <div className='b-pagination'>
                <Grid container spacing={0}>
                    <Grid item xs={3} lg={3} className="b-returns-item__block">
                        <div className="b-pagination__item b-pagination__item--prev">
                            <Button variant="contained" className='b-pagination__btn' onClick={() => {
                                if (!isLoading) {
                                    prevAction()
                                }
                            }} disabled={this.isPreviousDisabled()}>
                                {
                                    this.isPreviousDisabled()
                                        ?
                                        <KeyboardArrowLeft/>
                                        :
                                        <KeyboardArrowLeft color='primary'/>

                                }
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={6} lg={6} className="b-returns-item__block">
                        <div className="b-pagination__item b-pagination__item--pusher">
                            <div className="b-pagination__item b-pagination__item--addinfo">
                                <div className="b-pagination__padding_top">
                                    <CustomTranslation id='paging.PageOf' data={{
                                        currentPage: currentPage === 0 ? 1 : currentPage + 1,
                                        totalPages: totalPages,
                                        pageSizeOption: pageSizeOption
                                    }}/>
                                </div>
                            </div>
                            {/*<div className="b-pagination__item b-pagination__item--addinfo">
                                <CustomTranslation id='paging.itemsPerPage' />{' '}
                                <Select
                                    value={pageSizeOption}
                                    onChange={(e)=>{
                                        onPageSizeChangeAction(e.target.value)
                                    }}
                                    className='b-pagination__dropdown_control'
                                >
                                    {[50, 100, 150, 200].map((option, i)=>{
                                        return (
                                            <MenuItem key={i} value={option}>{option}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>*/}
                        </div>
                    </Grid>
                    <Grid item xs={3} lg={3} className="b-returns-item__block">
                        <div className="b-pagination__item b-pagination__item--next">
                            <Button variant="contained" className='b-pagination__btn' onClick={() => {
                                if (!isLoading) {
                                    nextAction()
                                }
                            }} disabled={this.isNextDisabled()}>
                                {
                                    this.isNextDisabled()
                                        ?
                                        <KeyboardArrowRight/>
                                        :
                                        <KeyboardArrowRight color='primary'/>
                                }

                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }

}

export default Pagination;