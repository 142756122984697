import Const from './../const';
import {setDataToSS, getDataFromSS} from './../../../utilites/index';
import GeneralModel from './../../../models/GeneralModel';

/**
* getDataModel {Function}
* @description - init model with appropriate data (empty or data from session storage)
* @return model {GeneralModel entity}
*
* */
let getDataModel = () => {
    let sessionStorageData = getDataFromSS('login');
    let model;

    if (sessionStorageData) {
        model = new GeneralModel(sessionStorageData);
    } else {
        model = new GeneralModel({});
    }

    return model;
};

const defaultState = {
    data: getDataModel(),
    isLoggedIn: false,
    isLoading: false
};

// check if user logged in
defaultState.isLoggedIn = defaultState.data.getField('token') ? true : false;

export default function (state = defaultState, action = {}) {
    switch (action.type) {
        case Const.LOGIN_SEND_REQUEST:
            let {data} = action;
            setDataToSS('login', data.toJSONView());
            return {
                ...state,
                data: data,
                isLoggedIn: data.getField('token') ? true : false
            };
        case Const.RETURNADO_LOGOUT:
            return {
                ...state,
                isLoggedIn: false
            };
        default:
            return state;
    }
}
