import Const from './../const';
import fixedFeeSelectorConst from "./../../FixedFeeSelector/const";
import fixedPercentageSelectorConst from "../../FixedPercentageSelector/const";

/**
* setRefund {React Action}
* @description - set appropriate refund to description
*
* */
export function setRefund(refund, returnOrderId) {
    return dispatch => {
        dispatch({
            type: Const.RETURN_DESCRIPTION_SET_REFUND,
            payload: {
                refund,
                returnOrderId
            }
        });
    }
}

/**
* setRefund {React Action}
* @description - set appropriate reduced values sum to description
*
* */
export function setReduceSum(reducedValues) {
    return dispatch => {
        dispatch({
            type: Const.RETURN_DESCRIPTION_SET_REDUCED_SUM,
            payload: reducedValues
        });
    }
}

/**
* clearReturnFees {React Action}
* @description - clear return fees
*
* */
export function clearReturnFees() {
    return dispatch => {
        dispatch({
            type: fixedFeeSelectorConst.FIXED_FEE_SELECTOR_CLEAR,
        });
    }
}

/**
 * clearReducedValues {React Action}
 * @description - clear reduced values
 *
 * */
export function clearReducedValues() {
    return dispatch => {
        dispatch({
            type: fixedPercentageSelectorConst.FIXED_REDUCED_PERCENTAGE_REDUCE_VALUE_CLEAR,
        });
    }
}

/**
 * clearRefund {React Action}
 * @description - clear reduced values
 *
 * */
export function clearRefund(id) {
    return dispatch => {
        dispatch({
            type: Const.RETURN_DESCRIPTION_CLEAR_REFUND,
            payload: id
        });
    }
}
