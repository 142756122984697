import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Drawer, Hidden} from '@material-ui/core';
import {bindActionCreators} from "redux";
import {handleMobileLeftSideBarActiveState, setFoldedOpen} from "./actions/leftSideBarActions";
import {connect} from 'react-redux';
import MainNavigation from './../MainNavigation/MainNavigation';
import classNames from 'classnames';

const navbarWidth = 280;

const styles = theme => {
    return {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            zIndex: 4,
            [theme.breakpoints.up('lg')]: {
                width: navbarWidth,
                minWidth: navbarWidth
            }
        },
        wrapperFolded: {
            [theme.breakpoints.up('lg')]: {
                width: 62,
                minWidth: 62
            }
        }
    }
};


class LeftSideBar extends React.Component {

    toggleMenu = () => {
        let {isActive} = this.props;
        this.props.handleMobileLeftSideBarActiveState(!isActive);
    };

    sideBarOpenFolded = () =>{
        this.props.setFoldedOpen(true);
    };

    sideBarCloseFolded = () =>{
        this.props.setFoldedOpen(false);
    };

    render() {
        const { classes, isMobileActive, isFolded, isFoldedOpen, mainConfig} = this.props;
        const foldedAndClosed = isFolded && !isFoldedOpen;
        const foldedAndOpened = isFolded && isFoldedOpen;
        return (
            <div className="b-left-menu">
                <div id="fuse-navbar"
                     className={
                         classNames(
                             classes.wrapper,
                             isFolded && classes.wrapperFolded
                         )}
                >
                    <Hidden mdDown>
                        <div className={isFoldedOpen ? 'b-left-menu__folded b-left-menu__folded--open' : 'b-left-menu__folded'}
                            onMouseEnter={() => foldedAndClosed && this.sideBarOpenFolded()}
                            onMouseLeave={() => foldedAndOpened && this.sideBarCloseFolded()}
                        >
                            <MainNavigation isMobile={false} isMobileActive={isMobileActive} mainConfig={mainConfig} />
                        </div>
                    </Hidden>

                    <Hidden lgUp>
                        <Drawer
                            anchor={'left'}
                            variant="temporary"
                            open={isMobileActive}
                            classes={{
                                paper: 'b-left-menu__folded--paper'
                            }}
                            onClose={()=>{this.props.handleMobileLeftSideBarActiveState(false)}}
                            ModalProps={{
                                // Better open performance on mobile.
                                keepMounted: true
                            }}
                        >
                            <MainNavigation isMobile={true} isMobileActive={isMobileActive} mainConfig={mainConfig} />
                        </Drawer>
                    </Hidden>
                </div>
            </div>
        );
    }
}

function mapStateToProps({leftSideBarState}) {
    return {
        ...leftSideBarState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            handleMobileLeftSideBarActiveState,
            setFoldedOpen
        },
        dispatcher,
    );
}


export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles, { withTheme: true })(LeftSideBar));