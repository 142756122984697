import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CustomTranslation from "../CustomTranslation/CustomTranslation";
import JsBarcode from "jsbarcode";

const styles = theme => {
    return {
        dialogBtn: {
            width: '50%',
            display: 'block',
            padding: 0
        },
        noBarcode: {
            margin: '-1px 0 0 0',
            padding: '22px 0'
        },
        btn: {
            width: '50%',
        }
    }
};

class CustomDialog extends Component {

    componentDidUpdate(prevProps) {
        if(prevProps.isOpen !== this.props.isOpen && this.props.isOpen === true && this.props.isBarCode) {
            setTimeout(()=>{
                JsBarcode(document.getElementById('confirmBtn'), 'approveReturnOrder', {
                    background: "transparent",
                    lineColor: "#ffffff",
                    height: 18,
                    width: 1,
                    fontSize: 0,
                    marginBottom: 0
                });
            }, 200)
        }
    }

    getAcceptBtnName = () => {
        let {acceptBtnName=null} = this.props;
        return acceptBtnName || <CustomTranslation id='return.order.save.approve.ok.button' />;
    };

    getCancelBtnName = () => {
        let {closeBtnName=null} = this.props;
        return closeBtnName || <CustomTranslation id='return.order.confirmation.cancel.button' />;
    };

    /**
     * handleKeyPress {Function}
     * @description - handle specific key pressing
     *
     * */
    handleKeyPress = (e, acceptAction) => {
        let {charCode} = e;

        if(charCode === 13 || charCode === 32) {
            acceptAction()
        }
    };

    render() {
       let {classes, isOpen, closeAction=()=>{}, title, content, acceptAction=()=>{}, isBarCode=false } = this.props;

       return  (
           <Dialog
               fullWidth={true}
               maxWidth={'sm'}
               open={isOpen}
               onClose={closeAction}
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description"
               onKeyPress={(e)=>{this.handleKeyPress(e, acceptAction)}}
           >
               <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
               <DialogContent>
                   <DialogContentText id="alert-dialog-description">
                       {content}
                   </DialogContentText>
               </DialogContent>
               <DialogActions>
                   <Button onClick={closeAction} variant="contained" className={isBarCode ? `${classes.dialogBtn} ${classes.noBarcode}` : `${classes.btn}`} >
                       {this.getCancelBtnName()}
                   </Button>
                   <Button onClick={acceptAction} variant="contained" color="primary" className={isBarCode ? classes.dialogBtn : `${classes.btn}`} >
                       {this.getAcceptBtnName()}
                       <div>
                        { isBarCode && <img id='confirmBtn' alt="" className='b-inbound-page__barcode' /> }
                       </div>
                   </Button>
               </DialogActions>
           </Dialog>
       )
    };
}


function mapStateToProps() {
    return {};
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {},
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles)(withRouter(CustomDialog)));