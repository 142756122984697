import GeneralModel from './../../../models/GeneralModel';
import {getCurrentReturnItemUpdateInfo} from './../../Returns/reducer/returnsReducerMethods';
import configuration from '../../../configs/configs';
import  {getSortedArray} from './../../../utilites/index';
import {find} from 'lodash';

const {NOT_PROCESSED} = configuration.states;

/**
 * updateCurrentReturnItem {Function}
 * @description - get specific returns item order
 * @return products {Object}
 *
 * */
export let updateCurrentReturnItems = (responseData) => {
    let updatedReturnsItems = [];

    if(responseData && responseData.length) {
        responseData.forEach((returnOrder)=>{
            let updatedJSON = {
                ...returnOrder,
                isApprovedFromTheServer: returnOrder.approved && returnOrder.status !== NOT_PROCESSED,
            };

            updatedReturnsItems.push(new GeneralModel(updatedJSON));
        })
    }
    return  updatedReturnsItems;
};

/**
 * getCurrentReturnItemsUpdateInfo {Function}
 * @description - get update return orders
 * @return products {Object}
 *
 * */
export let getCurrentReturnItemsUpdateInfo = (responseData) => {
    let updatedReturnsItems = {};

    if(responseData && responseData.length) {
        responseData.forEach((returnOrder)=>{
            updatedReturnsItems[returnOrder.returnOrderId] = getCurrentReturnItemUpdateInfo(returnOrder);
        })
    }
    return  updatedReturnsItems;
};

/**
 * changeReturnApprovedState {Function}
 * @description - set state for approved flag
 * @return products {Object}
 *
 * */
export let changeReturnApprovedState = (returnItems, payload) => {
    let {currentReturnOrderId} = payload;

    returnItems.forEach((returnModel)=>{
        if(returnModel.getField('returnOrderId') === currentReturnOrderId) {
            returnModel.data.approved = !returnModel.data.approved
        }
    });

    return  returnItems;
};

/**
 * getItemsWithEAN {Function}
 * @description - go through all items and add EAN if need
 * @return {Array}
 * */
export let getItemsWithEAN = (currentReturnItems = []) => {
    let itemsWithEAN = {};

    currentReturnItems.forEach((orderItem)=>{
        let orderId = orderItem.getField('returnOrderId');
        let products = orderItem.getField('items') || [];
        let productArray = [];

        products.forEach((product)=>{
            productArray.push(product)
        });

        itemsWithEAN[orderId] = productArray;
    });

    return itemsWithEAN;
};

/**
 * autoToggleApproveItemState {Function}
 * @description - change specific product specific filter
 * @return {Array}
 * */
export let autoToggleApproveItemState = (updatedReturnItems, returnItems, updatedProductsState, payload) => {
    let {currentShopType, currentReturnOrderId} = payload;
    let currentProducts = updatedProductsState[currentReturnOrderId];
    let currentReturn = find(returnItems, (r)=>{ return r.getField('returnOrderId') === currentReturnOrderId});
    let isCurrentReturnShouldBeAutoApproved = true;
    let approvedUpdatedReturnItems = [];

    if(currentShopType !== 'ROYAL_DESIGN') {
        currentProducts.forEach((product)=>{
            if(product.status === 'PENDING') {
                isCurrentReturnShouldBeAutoApproved = false
            }
        });

        updatedReturnItems.forEach((updatedReturn)=>{
            if(updatedReturn.getField('returnOrderId') !== currentReturnOrderId) {
                approvedUpdatedReturnItems.push(updatedReturn);
            } else {
                approvedUpdatedReturnItems.push(new GeneralModel({
                    ...updatedReturn.toJSONView(),
                    approved: isCurrentReturnShouldBeAutoApproved
                }))
            }
        });
    } else {
        if(!currentReturn.getField('reconversionItems') && !currentReturn.getField('reconversionOrder')) {
            currentProducts.forEach((product)=>{
                if(product.status === 'PENDING') {
                    isCurrentReturnShouldBeAutoApproved = false
                }
            });

            updatedReturnItems.forEach((updatedReturn)=>{
                if(updatedReturn.getField('returnOrderId') !== currentReturnOrderId) {
                    approvedUpdatedReturnItems.push(updatedReturn);
                } else {
                    approvedUpdatedReturnItems.push(new GeneralModel({
                        ...updatedReturn.toJSONView(),
                        approved: isCurrentReturnShouldBeAutoApproved
                    }))
                }
            });
        }
    }



    return approvedUpdatedReturnItems.length ? approvedUpdatedReturnItems : updatedReturnItems;
};


/**
 * updateProductFilter {Function}
 * @description - change specific product specific filter
 * @return {Array}
 * */
export let updateProductFilter = (products, payload) => {
    //isSaveAsChangedValue needed for comparison engine similar to returns page
    let {filterName, value, orderItemId, currentReturnOrderId, isSaveAsChangedValue} = payload;
    let currentProducts = products[currentReturnOrderId];
    let updateCurrentProducts = [];

    if(filterName === 'REFUND') {
        filterName = 'diminishedPrice'
    }

    if(filterName === 'diminishedPrice') {
        value = parseFloat(value)
    }

    currentProducts && currentProducts.length && currentProducts.forEach((product)=>{
        if(product.orderItem.orderItemId === orderItemId) {
            if(isSaveAsChangedValue) {
                updateCurrentProducts.push({
                    ...product,
                    [filterName]: value,
                    changedValues: {
                        ...product['changedValues'],
                        [filterName]: value
                    }
                })
            } else {
                updateCurrentProducts.push({
                    ...product,
                    [filterName]: value
                })
            }
        } else {
            updateCurrentProducts.push({
                ...product
            })
        }
    });

    return {
        ...products,
        [currentReturnOrderId]: updateCurrentProducts
    };

};

/**
 * isNeedToActivateDialog {Function}
 * @description - go through all items, checks their status and define if need to set variable (responsible to show dialog) in true state
 * @return Boolean
 *
 * */
export let isNeedToActivateDialog = (products, payload) => {
    let isNeedToActivateDialog = true;
    let {currentReturnOrderId} = payload;
    let currentProducts = products[currentReturnOrderId];

    currentProducts.forEach((product)=>{
        if(!product.status || product.status === 'PENDING') {
            isNeedToActivateDialog = false
        }
    });

    return isNeedToActivateDialog;
};


/**
 * changeInternalComment {Function}
 * @description - go through all items, define specific by EAN and change status
 * @return Array
 *
 * */

export let changeItemState = (products, payload) => {
    let { status, orderItemId, currentReturnOrderId} = payload;
    let currentProducts = products[currentReturnOrderId];
    let updateCurrentProducts = [];

    currentProducts.forEach((product)=>{
        if(product.orderItem.orderItemId === orderItemId) {
            updateCurrentProducts.push({
                ...product,
                status: status
            })
        } else {
            updateCurrentProducts.push({
                ...product,
            })
        }
    });

    return Object.assign({}, products, {
        [currentReturnOrderId]: updateCurrentProducts
    })
};

/**
 * changeInternalComment {Function}
 * @description - go through all items, define specific by EAN and change reportStatus
 * @return Array
 *
 * */
export let changeReportState = (products, payload) => {
    let {orderItemId, currentReturnOrderId} = payload;
    let currentProducts = products[currentReturnOrderId];
    let updateCurrentProducts = [];

    currentProducts.forEach((product)=>{
        if(product.orderItem.orderItemId === orderItemId) {
            updateCurrentProducts.push({
                ...product,
                flagged: product.flagged ? false : true
            })
        } else {
            updateCurrentProducts.push({
                ...product,
            })
        }
    });

    return Object.assign({}, products, {
        [currentReturnOrderId]: updateCurrentProducts
    })
};

/**
 * updateProductDiminishedPrice {Function}
 * @description - change diminished price of the specific product
 * @return {Array}
 * */
export let updateProductDiminishedPrice = (products, payload) => {
    let {value, productId, currentReturnOrderId} = payload;
    let currentProducts = products[currentReturnOrderId];
    let updateCurrentProducts = [];

    currentProducts.forEach((product)=>{
        if(product.ean === productId) {
            updateCurrentProducts.push({
                ...product,
                diminishedPrice: parseFloat(value)
            })
        } else {
            updateCurrentProducts.push({
                ...product,
            })
        }
    });

    return Object.assign({}, products, {
        [currentReturnOrderId]: updateCurrentProducts
    })

};

/**
 * updateProductFilterToDefault {Function}
 * @description - set dropdowns of the specific product to default
 * */
export let updateProductFilterToDefault = (products, defaultStates, payload) => {
    let { orderItemId, currentReturnOrderId } = payload;
    let currentProducts = products[currentReturnOrderId];
    let currentDefaultProducts;

    let currentProduct;
    let currentDefaultProduct;

    let updateCurrentProducts = [];


    defaultStates.forEach((returnOrderModel)=>{
        if(returnOrderModel.getField('returnOrderId') === currentReturnOrderId) {
            currentDefaultProducts =  returnOrderModel.getField('items')
        }
    });

    currentProducts.forEach((product)=>{
        if(product.orderItem.orderItemId === orderItemId) {
            currentProduct = product;
        }
    });

    currentDefaultProducts.forEach((product)=>{
        if(product.returnOrderItemId === currentProduct.returnOrderItemId) {
            currentDefaultProduct = product;
        }
    });

    currentProducts.forEach((updatedProduct)=>{

        if(updatedProduct.returnOrderItemId === currentProduct.returnOrderItemId) {
            let newUpdatedToDefaultItem = {
                ...updatedProduct
            };
            if(currentDefaultProduct.diminishedPrice !== undefined) {
                newUpdatedToDefaultItem.diminishedPrice = currentDefaultProduct.diminishedPrice
            }

            if(currentDefaultProduct.nextAction !== undefined) {
                newUpdatedToDefaultItem.nextAction = currentDefaultProduct.nextAction
            }

            if(currentDefaultProduct.condition !== undefined) {
                newUpdatedToDefaultItem.condition = currentDefaultProduct.condition
            }

            if(currentDefaultProduct.reclamationReason !== undefined) {
                newUpdatedToDefaultItem.reclamationReason = currentDefaultProduct.reclamationReason
            }
            updateCurrentProducts.push(newUpdatedToDefaultItem)
        } else {
            updateCurrentProducts.push(updatedProduct)
        }
    });

    return Object.assign({}, products, {
        [currentReturnOrderId]: updateCurrentProducts
    });
};

/**
 * isReadyForSubmit {Function}
 * @description - compare products from the server and products updated by user and check if return ready for submit
 * @return {Array}
 *
 * */
export let isReadyForSubmit = (products, defaultStates, payload=null, isReadySubmits=null, state=null) => {
    let isReadySubmitStates = isReadySubmits || {};
    let isReadyForSubmitBtnInactive = false;

    if(!products || !defaultStates || !payload) {
        products.forEach((product)=> {
            isReadySubmitStates[product.returnOrderId] = false;
        });
        return isReadySubmitStates;
    }

    let { currentReturnOrderId } = payload;
    let currentProducts = products[currentReturnOrderId] || [];
    let currentDefaultProducts = [];
    let cleanUpdatedState = [];
    /**
     * wasReturnChangedF {Function}
     * @description - compare return the server and return updated by user and check if return ready for submit
     * @return {Boolean}
     *
     * */
    let wasReturnChanged = (updatedReturns, defaultReturns, returnOrderId) => {
        let updatedReturn;
        let defaultReturn;

        updatedReturns.forEach((item)=>{
            if(item.getField('returnOrderId') === returnOrderId) {
                updatedReturn = item;
            }
        });

        defaultReturns.forEach((item)=>{
            if(item.getField('returnOrderId') === returnOrderId) {
                defaultReturn = item;
            }
        });

        return JSON.stringify(updatedReturn.toJSONView()) !== JSON.stringify(defaultReturn.toJSONView());
    };


    defaultStates.forEach((returnOrderModel)=>{
        if(returnOrderModel.getField('returnOrderId') === currentReturnOrderId) {
            currentDefaultProducts = returnOrderModel.getField('items')
        }
    });

    if(currentProducts && currentProducts.length) {
        currentProducts.forEach((product)=>{
            let cleanProduct = {};
            let {orderItem: {orderItemId}} = product;
            let stateId = orderItemId;
            let defaultCurrentProduct = {};

            currentDefaultProducts.forEach((defaultProduct)=>{
                let {orderItem: {orderItemId}} = defaultProduct;
                let defaultStateId = orderItemId;

                if(stateId === defaultStateId) {
                    defaultCurrentProduct = defaultProduct
                }

            });

            for(let i in product) {
                cleanProduct[i] = product[i];
                if(cleanProduct[i] !== undefined && defaultCurrentProduct[i] === undefined) {
                    delete  cleanProduct[i]
                }
            }

            cleanUpdatedState.push(cleanProduct);
        });
    }

    // check the diminished price is lower or equal the product price in each return order product;
    if(cleanUpdatedState && cleanUpdatedState.length) {
        cleanUpdatedState.forEach((updatedProduct)=>{
            let {orderItem} = updatedProduct;
            if(orderItem.price < updatedProduct.diminishedPrice) {
                isReadyForSubmitBtnInactive = true
            }
        });
    }

    let wasMainStateChanged = JSON.stringify(getSortedArray(cleanUpdatedState, 'returnOrderItemId')) !== JSON.stringify(getSortedArray(currentDefaultProducts, 'returnOrderItemId'));
    let wasReturnStateChanged = state ? wasReturnChanged(state.updatedReturnItems, state.currentReturnItems, currentReturnOrderId) : false;

    if(state) {
        return {
            ...isReadySubmits,
            [currentReturnOrderId]: !wasReturnStateChanged && !wasMainStateChanged ? false : true
        }
    }
    return {
        ...isReadySubmits,
        [currentReturnOrderId]: isReadyForSubmitBtnInactive ? false : wasMainStateChanged
    }


};

export let updateSCTimestamp = (updatedReturns, timestampData, action) => {
    let {requestData} = action;
    let {returnId=null} = requestData;
    let updatedSCReturns = [];

    if(returnId && timestampData && timestampData.getField('timestamp')) {
        updatedReturns.forEach((r) => {
            if (parseInt(r.getField('returnOrderId')) === parseInt(returnId)) {
                updatedSCReturns.push(new GeneralModel({
                    ...r.toJSONView(),
                    sentToCsTimeStamp: timestampData.getField('timestamp')
                }))
            } else {
                updatedSCReturns.push(r)
            }
        })
    }

    return updatedSCReturns.length ? updatedSCReturns : updatedReturns;
};

/**
 * isReadyForSubmitAfterReducedValue {Function}
 * @description - detect submit button state based on reduced values object. In case if product values was reduced - set submit button to active state.
 * @return {Object}
 *
 * */
export let isReadyForSubmitAfterReducedValue = (reducedValues) => {
    let newIsReadyForSubmitObject = {};
    for (let key in reducedValues) {
        if(reducedValues.hasOwnProperty(key)) {
            let currentValue = reducedValues[key];
            let currentReadyForSubmit = false;
            for (let jey in currentValue) {
                if(currentValue.hasOwnProperty(jey)) {
                    let currentValueItem = currentValue[jey] || {};
                    let {percentageValue, newDiminishedPrice, defaultDiminishedPrice} = currentValueItem;
                    if(newDiminishedPrice !== defaultDiminishedPrice && (percentageValue > 0 || percentageValue === '')) {
                        currentReadyForSubmit = true;
                    }
                }
            }
            newIsReadyForSubmitObject[key] = currentReadyForSubmit;
        }
    }

    return newIsReadyForSubmitObject;
};

/**
 * isAggregateReadyForSubmit {Function}
 * @description - detect submit button state based on reduced values object. In case if product values was reduced - set submit button to active state.
 * @return {Object}
 *
 * */
export let isAggregateReadyForSubmit = (returnFees, reducedValues, returnSubmitState, currentReturnItems, merchantFiles) => {
    let aggregateReadyForSubmit = {};
    let isTheSame = (currentMerchantFiles, returnOrder) => {
        let isSame = true;

        if(currentMerchantFiles && returnOrder) {
            let items = returnOrder.getField('items');

            items.forEach((orderItem)=>{
                let defaultImages = orderItem.merchantImages;
                let returnOrderItemId = orderItem.returnOrderItemId;
                let newMerchantFiles = currentMerchantFiles[returnOrderItemId];
                if(newMerchantFiles && defaultImages && newMerchantFiles.length && defaultImages.length) {
                    newMerchantFiles.forEach((newImage)=>{
                        let defaultImage = find(defaultImages, (image)=>{ return image.imagePath === newImage.data})
                        if(!defaultImage) {
                            isSame = false;
                        }
                    });
                }

                if(defaultImages && newMerchantFiles && defaultImages.length !== newMerchantFiles.length) {
                    isSame = false;
                }

                if(!defaultImages && newMerchantFiles && newMerchantFiles.length !== 0) {
                    isSame = false;
                }

            });
        }

        return isSame;
    };

    for(let key in returnSubmitState) {
        aggregateReadyForSubmit[key] = returnSubmitState[key];
        let currentReducedValues = reducedValues[key];
        let currentMerchantFiles = merchantFiles[key];
        let currentReturnItem = find(currentReturnItems, (currentReturn)=>{
            let id =  currentReturn &&  currentReturn.getField ? currentReturn.getField('returnOrderId') : currentReturn.returnOrderId;
            return id === parseFloat(key);
        });
        let currentReturnFee = currentReturnItem && currentReturnItem.getField ? currentReturnItem.getField('returnFee') : currentReturnItem && currentReturnItem.returnFee ? currentReturnItem.returnFee : 0;

        if(returnFees && returnFees[key] && (returnFees[key] > 0 && returnFees[key] !== currentReturnFee) || returnFees && returnFees[key] === 0) {
            aggregateReadyForSubmit[key] = true;
        }

        // check if submit ready because of reduced values existing
        if(currentReducedValues) {
            for(let jey in currentReducedValues) {
                let {percentageValue, newDiminishedPrice, defaultDiminishedPrice} = currentReducedValues[jey];
                if(newDiminishedPrice !== defaultDiminishedPrice && (percentageValue > 0 || percentageValue === '')) {
                    aggregateReadyForSubmit[key] = true;
                }
            }
        }

        // check if submit ready because of merchant files existing
        if(!isTheSame(currentMerchantFiles, currentReturnItem)) {
            aggregateReadyForSubmit[key] = true;
        }

    }

    return aggregateReadyForSubmit;
};