import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {bindActionCreators} from "redux";
import {handleRightMenuActiveState} from "./actions/rightMenuActions";
import {getLocale} from "../Localization/actions/localizationActions";
import {connect} from 'react-redux';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LanguageIcon from '@material-ui/icons/Language';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {getDataFromLS, switchCurrentLanguage} from "../../utilites";
import configuration from '../../configs/configs';
import CustomTranslation from "../CustomTranslation/CustomTranslation";

const drawerWidth = 240;

const styles = theme => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7 + 1),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9 + 1),
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    rightMenuHeader: {
        ...theme.mixins.toolbar,
    },
    formControl: {
        margin: theme.spacing(1),
        width: '100%'
    },
});


class RightMenu extends React.Component {

    toggleMenu = () => {
        let {isActive} = this.props;
        this.props.handleRightMenuActiveState(!isActive);
    };

    /*
    * changeLocale {Function}
    * @description - temporary method for testing change language functionality
    *
    * */
    changeLocale = (event) => {
        let langToSwitch = event.target.value;
        switchCurrentLanguage(langToSwitch);

        this.props.getLocale({
            route: 'getLocale',
            requestData: {
                lang: langToSwitch
            }
        })
    };

    render() {
        const { classes, theme, isActive } = this.props;
        const currentLanguage = getDataFromLS('language');

        return (
            <div className="b-right-menu">
                <Drawer anchor="right" open={isActive} onClose={this.toggleMenu} className={`${classes.drawer} ${isActive ? classes.drawerOpen : classes.drawerClose}`} classes={{
                    paper: classes.drawerPaper,
                }}>
                    <div className={`b-right-menu__header ${classes.rightMenuHeader}`}>
                        <IconButton onClick={this.toggleMenu}>
                            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <LanguageIcon />
                            </ListItemIcon>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="languages">
                                    <CustomTranslation id='login.v2.changeLanguage.Title' />
                                </InputLabel>
                                <Select
                                    value={currentLanguage}
                                    onChange={this.changeLocale}
                                    inputProps={{
                                        name: 'languages',
                                    }}
                                >
                                    {
                                        configuration.supportedLanguages.map((language, index)=>{
                                            return <MenuItem key={index} value={language.code}><CustomTranslation id={`login.v2.changeLanguage.${language.code}`} /></MenuItem>
                                        })
                                    }

                                </Select>
                            </FormControl>
                        </ListItem>
                    </List>
                    <Divider />
                </Drawer>
            </div>
        );
    }
}

function mapStateToProps({rightMenuState}) {
    return {
        ...rightMenuState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            handleRightMenuActiveState,
            getLocale
        },
        dispatcher,
    );
}


export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles, { withTheme: true })(RightMenu));