import React from "react";
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';
import CustomAnimate from "./../CustomAnimate/CustomAnimate";
import {withStyles} from "@material-ui/core/styles";
import {Scrollbars} from 'react-custom-scrollbars';

let styles = (theme) => {
    return {
        pageContent: {
            backgroundColor: theme.palette.secondary.main
        }
    }
};

const PageContent = (props) => {
    let {children, classes, isFoldedOpen = false, classNameCustom = '', isAnalyticsPage = false, ...rest} = props;
    let renderTrackHorizontal = props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>;
    return (
        <>
            {
                isAnalyticsPage
                    ?
                    <div
                        className={isFoldedOpen ? `b-page-content b-page-content--folded_open ${classNameCustom}` : `b-page-content ${classNameCustom}`}>
                        <Paper className={`b-page-content__wrapper ${classes.pageContent}`} {...rest}>
                            <Scrollbars className="b-page-content__body track-horizontal" universal
                                        renderTrackHorizontal={renderTrackHorizontal}>
                                {children}
                            </Scrollbars>
                        </Paper>
                    </div>
                    :
                    <CustomAnimate animation="transition.expandIn">
                        <div
                            className={isFoldedOpen ? `b-page-content b-page-content--folded_open ${classNameCustom}` : `b-page-content ${classNameCustom}`}>
                            <Paper className={`b-page-content__wrapper ${classes.pageContent}`} {...rest}>
                                <Scrollbars className="b-page-content__body track-horizontal" universal
                                            renderTrackHorizontal={renderTrackHorizontal}>
                                    {children}
                                </Scrollbars>
                            </Paper>
                        </div>
                    </CustomAnimate>
            }
        </>
    )
};

PageContent.propTypes = {
    children: PropTypes.element.isRequired
};


export default withStyles(styles)(PageContent);