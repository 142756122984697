import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Button from '@material-ui/core/Button';
import {Delete} from '@material-ui/icons';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FlagIcon from '@material-ui/icons/Flag';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from "@material-ui/core/Grid";
import CustomTranslation from './../../../components/CustomTranslation/CustomTranslation';
import PageFiltersItems from './../../../components/PageFiltersItems/PageFiltersItems';
import {
    changeProductFilters,
    changeDiminishedPrice,
    changeItemState,
    setDefaultFilters,
    sendNoteToCustomer,
    clearReducedValuesObject,
    changeSubmitState,
    setFixedReturnFeeValue
} from './../actions/inboundActions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {fade} from '@material-ui/core/styles/colorManipulator';
import {getTranslate} from 'react-localize-redux';
import ImageZoom from 'react-medium-image-zoom';
import {bindActionCreators} from "redux";
import configuration from '../../../configs/configs';
import {find, uniq} from 'lodash';
import {
    toFixedCurrency,
    transFormReturnItemName,
    isNeedToShowAttributesInReturnItemName,
} from "../../../utilites";
import ReturnCommentsAreas from "../../../components/ReturnCommentsAreas/ReturnCommentsAreas";
import SendToCustomerServiceControl from "../../../containers/SendToCustomeServiceControl/SendToCustomeServiceControl";
import DropZone from "../../../components/DropZone/DropZone";
import ReclamationImagesGallery from "../../../components/ReclamationImagesGallery/ReclamationImagesGallery";

/* TODO: need to implement in same concept on returns page*/
const {PROCESSED, PENDING, ARRIVED, REJECTED, REMOVED} = configuration.states;

const styles = theme => {
    return ({
        root: {
            flexGrow: 1,
        },
        sectionDesktop: {
            display: 'flex',
        },
        itemHeader: {},
        button: {
            color: theme.palette.secondary.main,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            minWidth: 155
        },
        btnRejected: {
            background: fade(theme.palette.brand.btnColors.reject, 0.6),
            '&:hover': {
                background: theme.palette.brand.btnColors.reject,
            }
        },
        btnRejectedREJECTED: {
            background: theme.palette.brand.btnColors.reject
        },
        btnApprove: {
            background: fade(theme.palette.brand.btnColors.approve, 0.6),
            '&:hover': {
                background: theme.palette.brand.btnColors.approve,
            }
        },
        btnApproveARRIVED: {
            background: theme.palette.brand.btnColors.approve
        },
        btnDelete: {
            color: theme.palette.brand.btnColors.pending,
            '&:hover': {
                color: theme.palette.brand.btnColors.delete,
            }
        },
        btnDeleteREMOVED: {
            color: theme.palette.brand.btnColors.delete
        },
        iconDelete: {
            color: theme.palette.brand.main.red
        },
        grow: {
            flexGrow: 1
        },
        iconReport: {
            color: theme.palette.brand.main.red
        },
        iconComment: {
            color: fade(theme.palette.brand.main.dark, 0.5)
        },
        inboundItemToolbar: {
            minHeight: 34
        },
        inboundItemSelects: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.common.white,
            '&:hover': {
                backgroundColor: theme.palette.common.white,
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '50%',
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
        commentsControlActive: {
            color: theme.palette.brand.main.activeControlColor
        }
    })
};

class InboundItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: this.getExpandedState(),
            errors: {},
            isReturnUpdate: false
        };
        this.productsIds = {};
    }

    getExpandedState = () => {
        let {item} = this.props;

        if (item.comment || item.reclamationImagePath) {
            return true
        }

        return false;
    };

    /**
     * handleChange {Function}
     * @description - method toggle expand panel state
     *
     * */
    handleChange = () => {
        let {expanded} = this.state;
        this.setState({expanded: !expanded})
    };

    /**
     * getDefaultValues {Function}
     * @description - functionality to define default values for filters controls for each product
     * @return {Object} - where object KEY is index of the control. Value - is value for appropriate control
     * */
    getDefaultValues = (product) => {
        return {
            0: product.condition || '',
            1: product.nextAction || '',
            2: product.diminishedPrice || 0
        }
    };

    /**
     * getDefaultSettings {Function}
     * @description - functionality to define default settings for filters controls for each product
     * @return {Object}
     * */
    getDefaultSettings = (product) => {
        let {orderItem} = product;
        return {
            2: {
                max: orderItem.price,
                step: '0.01'
            }
        }
    };


    getErrors = () => {
        let {errors} = this.state;
        let errorsObj = {};

        if (errors['diminishedPrice']) {
            errorsObj[2] = <CustomTranslation id='return.order.details.input.error.message'/>
        }

        return errorsObj;
    };

    /**
     * getDisabledStates {Function}
     * @description - functionality to define disabled/enabled state for filters controls for each product
     * @return {Object} - where object KEY is index of the control. Value - is boolean for enabled/disabled state
     * */
    getDisabledStates = (currentState) => {
        let {currentReturnItem} = this.props;
        let disabledStates = {
            0: true,
            1: true,
            2: true
        };

        if (currentReturnItem.getField('isApprovedFromTheServer')) {
            return disabledStates
        }

        switch (currentState.status) {
            case ARRIVED:
            case PENDING:
                return {
                    0: false,
                    1: false,
                    2: false
                };
            case REJECTED:
                return {
                    0: false,
                    1: false,
                    2: true
                };
            case REMOVED:
            default:
                return disabledStates;
        }


    };

    getTotalProductsAmount = () => {
        let {currentReturnItem} = this.props;
        let amount = 0;

        if (currentReturnItem) {
            amount = currentReturnItem.getField('items').length
        }

        return amount;
    };


    /**
     * isBtnActive {Function}
     * @description - define in one of the product buttons (RECEIVED/REJECT/REMOVE) active {highlighted}
     * @return Boolean
     *
     * */
    isBtnActive = (state, btnType) => {
        return state.status === btnType;
    };


    /**
     * isBtnDisabled {Function}
     * @description - define in one of the product buttons (RECEIVED/REJECT/REMOVE) disabled
     * @return Boolean
     *
     * */
    isBtnDisabled = (state, btnType) => {
        let {currentReturnItem} = this.props;

        if (this.isBtnActive(state, btnType)) {
            return false;
        } else {
            if (currentReturnItem.getField('isApprovedFromTheServer') && state.status !== PENDING) {
                return true;
            } else {
                return false;
            }
        }
    };

    /**
     * changeFilterAction {Function}
     * @description - change specific filter of the specific filter
     *
     * */
    changeFilterAction = (value, item, productId, defaultSettings, key) => {
        let {label = ''} = item;
        let filterNameArray = label.split('.');
        let filterName = filterNameArray[filterNameArray.length - 1] === 'reason' ? 'reclamationReason' : filterNameArray[filterNameArray.length - 1];
        let {currentReturnOrderId} = this.props;
        let {errors} = this.state;

        if (filterName === 'diminishedPrice') {
            value = value ? parseFloat(value) : 0;
        }

        if (filterName === 'REFUND') {
            this.props.clearReducedValuesObject()
        }


        if (defaultSettings && defaultSettings[key]) {
            if (defaultSettings[key].max && value > defaultSettings[key].max) {
                this.setState({
                        errors: {
                            ...errors,
                            [filterName]: true
                        }
                    }, () => {
                        this.props.changeProductFilters(filterName, value, productId, currentReturnOrderId);
                    }
                )
            } else {
                this.setState({
                        errors: {
                            ...errors,
                            [filterName]: false
                        }
                    }, () => {
                        this.props.changeProductFilters(filterName, value, productId, currentReturnOrderId);
                    }
                )
            }
        } else {
            this.props.changeProductFilters(filterName, value, productId, currentReturnOrderId);
        }


    };

    /**
     * getCurrentShopType {Function}
     * @description - return type of the current shop
     * @return {String or Null}
     *
     * */
    getCurrentShopType = () => {
        let {mainPropertiesState} = this.props;
        let mainSettings = mainPropertiesState.mainSettings && mainPropertiesState.mainSettings.getField ? mainPropertiesState.mainSettings.getField('response') : null;
        let shopType = null;

        if(mainSettings) {
            let shopTypeObject = find(mainSettings, (setting) => { return setting.name === "shop.type"});

            if(shopTypeObject) {
                shopType = shopTypeObject.value
            }
        }

        return shopType;
    };

    /**
     * changeItemState {Function}
     * @description - change the inbound item state (received, reject, remove)
     *
     * */
    changeItemState = (state, orderItemId, currentReturnOrderId) => {
        let {parentComponent} = this.props;
        parentComponent.currentOrderIdToSave = currentReturnOrderId;
        this.props.changeItemState(state, orderItemId, currentReturnOrderId, this.getCurrentShopType());
        let currentUpdatedState = parentComponent.props && parentComponent.props.updatedStateItems && parentComponent.props.updatedStateItems[currentReturnOrderId] ? parentComponent.props.updatedStateItems[currentReturnOrderId] : {} ;
        let currentState = find(currentUpdatedState, (item) => { return item.orderItem.orderItemId === orderItemId}) || {}
        /* TODO: figure out method without using setTimeout callback */
        setTimeout(() => {
            switch (state) {
                case REJECTED:
                    this.props.changeProductFilters('nextAction', 'SEND_BACK', orderItemId, currentReturnOrderId, false);
                    this.props.changeProductFilters('diminishedPrice', 0, orderItemId, currentReturnOrderId, false);
                    break;
                case REMOVED:
                    this.props.changeProductFilters('diminishedPrice', 0, orderItemId, currentReturnOrderId, false);
                    break;
                case ARRIVED:
                    if (currentState.changedValues) {
                        for (let i in state.changedValues) {
                            if (state.changedValues.hasOwnProperty(i)) {
                                this.props.changeProductFilters(i, state.changedValues[i], orderItemId, currentReturnOrderId);
                            }
                        }
                    } else {
                        this.props.setDefaultFilters(orderItemId, currentReturnOrderId);
                    }
                    break;
                case PENDING:
                    this.props.setDefaultFilters(orderItemId, currentReturnOrderId);
                    break;
                default:
                    break;
            }
        }, 100)

    };

    /**
     * sendNoteToCustomer {Function}
     * @description - tell server to send an email to the customer service
     *
     * */
    sendNoteToCustomer = (returnOrderId) => {
        this.props.sendNoteToCustomer({
            route: 'sendToCs',
            requestData: {
                returnId: returnOrderId
            }
        })
    };

    /**
     * checkCommentUpdate {Function}
     * @description - check were comment updated or not
     *
     * */
    checkCommentUpdate = () => {
        let {updatedComments, currentReturnOrderId, updateReturnCommentsStateAction, item, parentComponent} = this.props;
        let productIds = uniq(parentComponent.productIds[currentReturnOrderId]['ids']);
        let isReadyForSubmit = false;

        productIds.forEach((id)=>{
            if(updatedComments[id]) {
                isReadyForSubmit = true
            }
        });

        updateReturnCommentsStateAction({
            [currentReturnOrderId]: isReadyForSubmit
        }, item.returnOrderItemId);

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {
            item,
            returnFees,
            reducedValues,
            updatedComments,
            wasReturnUpdated,
            currentReturnOrderId,
            setFixedReturnFeeValue,
            updateReturnCommentsStateAction
        } = this.props;
        if(prevProps.updatedComments !== updatedComments) {
            this.checkCommentUpdate()
        }

        if(prevProps.reducedValues !== reducedValues) {
            this.props.changeSubmitState(returnFees, reducedValues, currentReturnOrderId);
        }

        if(prevProps.returnFees !== returnFees) {
            setFixedReturnFeeValue(returnFees, reducedValues, currentReturnOrderId);
        }

        if(prevProps.wasReturnUpdated !== wasReturnUpdated) {
            updateReturnCommentsStateAction({
                [currentReturnOrderId]: false
            }, item.returnOrderItemId);
        }
    }

    render() {
        let {
            index,
            item,
            classes,
            currency = '',
            toggleReportAction = () => {},
            rotateOrderAction = () => {},
            orderIndex,
            currentReturnItem,
            currentReturnOrderId,
            isSendToSCLoading,
            mainPropertiesState={},
            parentComponent,
            itemFilterActions,
            itemFilterConditions
        } = this.props;
        let {orderItem: {productVariant, sku}, orderItem} = item;
        let stateId = productVariant.productVariantExternalId;
        let {expanded} = this.state;
        let {attributes=null} = productVariant;
        let commentRefId = item.returnOrderItemId;
        let skuID = sku && sku !== stateId ? sku : null;
        if(!parentComponent[commentRefId]) {
            parentComponent[commentRefId] = React.createRef();
        }
        if(!this.productsIds[commentRefId]) {
            this.productsIds[commentRefId] = commentRefId
        }
        return (
            <div className={`b-inbound-item b-inbound-item--${item.status.toLowerCase()}`}
                 style={{zIndex: 100 - index}}>
                <div className="b-inbound-item__header">
                    <AppBar className={`b-inbound-item__head`} position="relative" color="default">
                        <Toolbar className={classes.inboundItemToolbar}>
                            <div className="b-inbound-item__counter">{`${index + 1}/${this.getTotalProductsAmount()}`}</div>
                            <PageFiltersItems
                                pageId="inboundItem"
                                classStyles='b-inbound-item__filters'
                                defaultValues={this.getDefaultValues(item)}
                                defaultSettings={this.getDefaultSettings(item)}
                                disabledStates={this.getDisabledStates(item)}
                                errors={this.getErrors()}
                                changeAction={this.changeFilterAction}
                                returnsProductId={orderItem.orderItemId}
                                defaultProductPrice={orderItem.price}
                                returnOrderId={currentReturnOrderId}
                                currency={currency}
                                replaceFilter={{
                                    'return.order.details.condition': itemFilterConditions,
                                    'return.order.details.nextAction': itemFilterActions
                                }}
                                isInbound={true}
                            />
                            <div className={classes.grow}/>
                            <div className={classes.sectionDesktop}>
                                <IconButton
                                    color="inherit"
                                    className={item.status ? `${classes.btnDelete} ${classes['btnDelete' + item.status]}` : `${classes.btnDelete}`}
                                    onClick={() => {
                                        if (currentReturnItem.getField('status') !== PROCESSED) {
                                            this.changeItemState(item.status === REMOVED ? PENDING : REMOVED, orderItem.orderItemId, currentReturnOrderId, item);
                                            rotateOrderAction(orderIndex, currentReturnOrderId)
                                        }
                                    }
                                    }
                                    disabled={this.isBtnDisabled(item, REMOVED)}
                                >
                                    <Tooltip title={<CustomTranslation id='login.v2.inboundPage.tooltip.Remove.text'/>}
                                             placement="top">
                                        <Delete/>
                                    </Tooltip>
                                </IconButton>
                                <IconButton color="inherit" onClick={() => {
                                    if (currentReturnItem.getField('status') !== PROCESSED) {
                                        toggleReportAction(orderItem.orderItemId, currentReturnOrderId)
                                    }
                                }}>
                                    <Tooltip title={<CustomTranslation
                                        id={item.flagged ? 'login.v2.inboundPage.tooltip.ClearFlag.text' : 'login.v2.inboundPage.tooltip.AddFlag.text'}/>}
                                             placement="top">
                                        <FlagIcon
                                            className={item.flagged ? `${classes.iconReport} b-inbound-item__report_icon b-inbound-item__report_icon--active` : `${classes.iconReport} b-inbound-item__report_icon`}/>
                                    </Tooltip>
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                </div>
                <Grid container className={`${classes.root} b-inbound-item__body`} spacing={0}>
                    <Grid item xs={12} lg={2} className="b-inbound-item__block">
                        <div className="b-inbound-item__image">
                            <ImageZoom
                                image={{
                                    src: productVariant.imageUrl,
                                    alt: productVariant.name,
                                    className: "b-inbound-item__product_img"
                                }}
                                zoomImage={{
                                    src: productVariant.imageUrl,
                                    alt: productVariant.name,
                                    className: "b-inbound-item__product_img b-inbound-item_product_img--big",
                                }}
                                defaultStyles={{
                                    overlay: {
                                        backgroundColor: '#7D818C',
                                        opacity: 0.9
                                    }
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={7} className="b-inbound-item__block">
                        <div className="b-inbound-item__common_info">
                            <Grid container className={`b-grid__container`} spacing={0}>
                                <Grid item xs={2} lg={2} className="b-returns-item__block">
                                    <div className="b-returns-item__product_info_title">
                                        <CustomTranslation id='return.order.details.product.id'/></div>
                                    <div className="b-returns-item__product_info_value">{skuID ? stateId +' ('+ skuID +')' : stateId || '-'}</div>
                                </Grid>
                                <Grid item xs={4} lg={4} className="b-returns-item__block">
                                    <div className="b-returns-item__product_info_title">
                                        <CustomTranslation id='return.order.details.product.name'/></div>
                                    <div className="b-returns-item__product_info_value">{transFormReturnItemName(productVariant.name, attributes, isNeedToShowAttributesInReturnItemName(mainPropertiesState))  || '-'}</div>
                                </Grid>
                                <Grid item xs={2} lg={2} className="b-returns-item__block">
                                    <div className="b-returns-item__product_info_title">
                                        <CustomTranslation id='return.order.details.price'/></div>
                                    <div className="b-returns-item__product_info_value">{toFixedCurrency(orderItem.price, currency) || '-'}</div>
                                </Grid>
                                <Grid item xs={4} lg={4} className="b-returns-item__block">
                                    <div className="b-returns-item__product_info_title">
                                        <CustomTranslation id='return.order.details.reason'/></div>
                                    <div className="b-returns-item__product_info_value">{item.reclamationReason ?
                                        <CustomTranslation id={`products.report.returnReason.${item.reclamationReason}`}/> : '-'}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <div className="b-inbound-item__comments">
                                <ReturnCommentsAreas
                                    orderId={currentReturnOrderId}
                                    managerComment={{
                                        value: item.managerComment || '',
                                        disabled: currentReturnItem.getField('isApprovedFromTheServer')
                                    }}
                                    privateComment={{
                                        value: item.privateComment || '',
                                    }}
                                    referrer={parentComponent[commentRefId]}
                                    orderItemId={commentRefId}
                                    managerValue={item.managerComment || ''}
                                    privateValue={item.privateComment || ''}
                                />
                            </div>
                            <div className="b-inbound-item__dropdown_zone">
                                <Grid container className={`b-grid__container`} spacing={1}>
                                    <Grid item xs={6} lg={6} className="b-returns-item__block">
                                        {
                                            item && item.reclamationImages && item.reclamationImages.length
                                            ?
                                                item.reclamationImages.length === 1
                                                ?
                                                <div className="b-reclamation-images-gallery__comment_label">
                                                    <CustomTranslation id='return.order.details.customer.reclamation.image' renderAsString={true} />
                                                </div>
                                                    :
                                                <div className="b-reclamation-images-gallery__comment_label">
                                                    <CustomTranslation id='return.order.details.customer.reclamation.images' renderAsString={true} />
                                                </div>
                                            :
                                            ''
                                        }

                                        <ReclamationImagesGallery images={item.reclamationImages || item.reclamationImages} comment={item.comment}/>
                                    </Grid>
                                    <Grid item xs={6} lg={6} className="b-returns-item__block">
                                        <div className="b-drop-zone__images_label">
                                            <CustomTranslation id='return.order.details.internal.images' renderAsString={true} />
                                        </div>
                                        <DropZone defaultImages={item.merchantImages} returnOrderId={currentReturnOrderId} orderItemId={commentRefId}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={3} className="b-inbound-item__block">
                        <div className="b-inbound-item__btn_wrapper">
                            <Button variant="contained"
                                    className={item.status ? `${classes.button} ${classes.btnApprove} ${classes['btnApprove' + item.status]}` : `${classes.button} ${classes.btnApprove}`}
                                    onClick={() => {
                                        if (currentReturnItem.getField('status') !== PROCESSED) {
                                            this.changeItemState(item.status === ARRIVED ? PENDING : ARRIVED, orderItem.orderItemId, currentReturnOrderId, item);
                                            rotateOrderAction(orderIndex, currentReturnOrderId)
                                        }
                                    }
                                    }
                                    disabled={this.isBtnDisabled(item, ARRIVED)}
                            >
                                <CustomTranslation id={this.isBtnActive(item, ARRIVED) ? "return.order.details.button.arrived" : "return.order.details.button.arrive"}/>
                            </Button>
                        </div>
                        <div className="b-inbound-item__btn_wrapper">
                            <Button variant="contained"
                                    className={item.status ? `${classes.button} ${classes.btnRejected} ${classes['btnRejected' + item.status]}` : `${classes.button} ${classes.btnRejected}`}
                                    onClick={() => {
                                        if (currentReturnItem.getField('status') !== PROCESSED) {
                                            this.changeItemState(item.status === REJECTED ? PENDING : REJECTED, orderItem.orderItemId, currentReturnOrderId, item);
                                            rotateOrderAction(orderIndex, currentReturnOrderId)
                                        }
                                    }
                                    }
                                    disabled={this.isBtnDisabled(item, REJECTED)}
                            >
                                <CustomTranslation id="return.order.details.button.reject"/>
                            </Button>
                        </div>
                        <div className="b-inbound-item__btn_wrapper">
                            <SendToCustomerServiceControl
                                sendNoteToCustomerAction={()=>{this.sendNoteToCustomer(currentReturnOrderId)}}
                                isLoading={isSendToSCLoading}
                                lastTimeStamp={currentReturnItem.getField('sentToCsTimeStamp')} />
                        </div>
                    </Grid>
                </Grid>
                {
                    item.comment
                        ?
                        <ExpansionPanel className="b-inbound-item__accordion" expanded={expanded}
                                        onChange={this.handleChange}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}
                                                   className="b-inbound-item__accordion_summary">
                                {
                                    expanded ?
                                        <div className='b-inbound-item__accordion_item'>
                                            <ExpandLessIcon className='b-inbound-item__accordion_left_icon'
                                                            color={"inherit"}/>
                                            <CustomTranslation id="login.v2.inboundPage.collapse"/>
                                        </div>
                                        :
                                        <div className='b-inbound-item__accordion_item'>
                                            <ExpandMoreIcon className='b-inbound-item__accordion_left_icon'
                                                            color={"inherit"}/>
                                            <CustomTranslation id="login.v2.inboundPage.expand"/>
                                        </div>
                                }
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className="b-inbound-item__message_wrapper">
                                <div className="b-inbound-item__message">
                                    <Grid container className={`${classes.root}`} spacing={0}>
                                        <Grid item xs={12} lg={12} className="b-inbound-item__block">
                                            <div
                                                className="b-returns-item__comments_title b-returns-item__comments_title--reclamation_textarea_title">
                                                <CustomTranslation id='return.order.details.commentFromCustomer' />
                                            </div>
                                            <div className="b-inbound-item__message_text">
                                                {item.comment}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>


                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        :
                        <div className="b-inbound-item__bottom_pusher"></div>
                }
            </div>
        )

    };
}

function mapStateToProps(state) {
    return {
        ...state.returnCommentsAreasState,
        ...state.fixedPercentageSelectorState,
        ...state.fixedFeeSelectorState,
        translate: getTranslate(state.localize),
        mainPropertiesState: state.mainPropertiesState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            changeProductFilters,
            changeDiminishedPrice,
            sendNoteToCustomer,
            setDefaultFilters,
            changeItemState,
            clearReducedValuesObject,
            changeSubmitState,
            setFixedReturnFeeValue
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles)(InboundItem));