import Const from './../const';
import GeneralModel from './../../../models/GeneralModel';
import DefaultRequestConst from './../defaultRequestConst';
import DropZoneConst from "./../../../components/DropZone/const";
import {
    updateCurrentReturnItems,
    getItemsWithEAN,
    changeItemState,
    isNeedToActivateDialog,
    changeReportState,
    updateProductFilter,
    updateProductDiminishedPrice,
    isReadyForSubmit,
    updateProductFilterToDefault,
    getCurrentReturnItemsUpdateInfo,
    changeReturnApprovedState,
    autoToggleApproveItemState,
    updateSCTimestamp,
    isAggregateReadyForSubmit
} from "./InboundReducerMethods";
import FixedFeeSelectorConst from "../../../components/FixedFeeSelector/const";
import ReturnDescriptionConst from "./../../../components/ReturnDescription/const";

const defaultState = {
    data: new GeneralModel(),
    defaultRequestConst: DefaultRequestConst,
    updatedStateItems: [],
    isLoading: false,
    currentReturnItems: [],
    updatedReturnItems: [],
    currentReturnItemsUpdated: [],
    were3ImagesUploaded: 0,
    isFoldedOpen: false,
    wereItemsFound: false,
    isOrderFound: null,
    view: 'list',
    order: 'asc',
    sortingMethod: 'rotate',
    enterLeaveAnimation: 'elevator',
    searchValue: '',
    searchValuePlaceholder: '',
    isDialogActive: false,
    isDialogSendToCSActive: false,
    isUpdateConfirmPopupActive: false,
    isSendToSCLoading: false,
    currentExpandPanel: 0,
    currentOrderIdForSaving: null,
    wasStatusChanged: false,
    wasProductChanged: false,
    wasReturnUpdated: false,
    isReadyForSubmit: null,
    returnFeeFixed: {},
    returnFeeFixedError: {},
    returnFees: {},
    refund: {},
    merchantFiles: {}
};

// memoization for reducedValues and returnFees to avoid reducer changes
// receives their values on RETURNS_SET_FIXED_RETURN_FEE and RETURNS_CHANGE_SUBMIT_STATE_BASED_ON_REDUCED_VALUES actions
// it a temporary solution. Need to rethink this functionality in general
let reducedValues = {};
let returnFees = {};
let merchantFiles = {};

export default function (state = defaultState, action = {}) {
    let {payload, type, data} = action;
    switch (type) {
        case Const.INBOUND_START_FETCH_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Const.INBOUND_SEND_NOTE_TO_THE_CUSTOMER_START:
            return {
                ...state,
                isSendToSCLoading: true
            };
        case Const.INBOUND_FETCH_REQUEST:
            let responseData = data && data.getField ? data.getField('data') : [];
            let inboundReturnsState = {
                ...state,
                data: data,
                currentReturnItems: data.getField('requestStatus') === 'error' ? state.currentReturnItems : updateCurrentReturnItems(responseData),
                updatedReturnItems: data.getField('requestStatus') === 'error' ? state.currentReturnItems : updateCurrentReturnItems(responseData),
                currentReturnItemsUpdateInfo: data.getField('requestStatus') === 'error' ? state.currentReturnItemsUpdateInfo : getCurrentReturnItemsUpdateInfo(responseData)
            };
            return {
                ...inboundReturnsState,
                updatedStateItems: data.getField('requestStatus') === 'error' ? state.updatedStateItems : getItemsWithEAN(inboundReturnsState.currentReturnItems),
                wereItemsFound: true,
                isOrderFound: responseData && responseData.length ? true : false,
                currentExpandPanel: 0,
                isLoading: false,
                searchValue: '',
                isReadyForSubmit: data.getField('requestStatus') === 'error' ? state.isReadyForSubmit : isReadyForSubmit(responseData, inboundReturnsState.currentReturnItems)
            };
        case Const.LEFT_SIDE_BAR_IS_FOLDED_OPEN:
            return {
                ...state,
                isFoldedOpen: payload
            };
        case Const.INBOUND_SET_EXPAND_PANEL_INDEX:
            return {
                ...state,
                currentExpandPanel: payload == null ? 0 : payload
            };
        case Const.INBOUND_SET_NEW_ITEMS_ORDER:
            return {
                ...state,
                sortingMethod: payload.sortingMethod,
                updatedStateItems: {
                    ...state.updatedStateItems,
                    [payload.currentReturnOrderId] : payload.items
                },
            };
        case Const.INBOUND_CHANGE_SEARCH_VALUE:
            return {
                ...state,
                searchValue: payload,
                searchValuePlaceholder: payload,
            };
        case Const.INBOUND_CLOSE_CONFIRMATION_DIALOG:
            return {
                ...state,
                isDialogActive: false,
            };
        case Const.INBOUND_SEND_NOTE_TO_THE_CUSTOMER:
            return {
                ...state,
                updatedReturnItems: updateSCTimestamp(state.updatedReturnItems, data, action),
                isDialogSendToCSActive: true,
                isSendToSCLoading: false,
            };
        case Const.INBOUND_CLOSE_SEND_NOTE_TO_THE_CUSTOMER:
            return {
                ...state,
                isDialogSendToCSActive: false,
            };
        case Const.INBOUND_CONFIRM_UPDATE_ORDER:
            return {
                ...state,
                isUpdateConfirmPopupActive: true,
                wasReturnUpdated: !state.wasReturnUpdated
            };
        case Const.INBOUND_CONFIRM_UPDATE_ORDER_CLOSE:
            return {
                ...state
            };
        case Const.INBOUND_SET_ITEM_STATE:
            let updatedChangeItemStates = {
                ...state,
                updatedStateItems: changeItemState(state.updatedStateItems, payload),
                updatedReturnItems: autoToggleApproveItemState(state.updatedReturnItems, state.currentReturnItems, changeItemState(state.updatedStateItems, payload), payload),
                isDialogActive: isNeedToActivateDialog(changeItemState(state.updatedStateItems, payload), payload),
            };
            return {
                ...updatedChangeItemStates,
                wasStatusChanged: !state.wasStatusChanged,
                wereItemsFound: payload,
                currentOrderIdForSaving: updatedChangeItemStates.isDialogActive ? payload.currentReturnOrderId : null,
                isReadyForSubmit: isAggregateReadyForSubmit(
                    state.returnFees,
                    reducedValues,
                    isReadyForSubmit(updatedChangeItemStates.updatedStateItems, state.currentReturnItems, payload, state.isReadyForSubmit),
                    state.currentReturnItems,
                    merchantFiles
                )
            };
        case Const.INBOUND_TOGGLE_APPROVE_FLAG:
            let updatedChangeApprovedItemStates = {
                ...state,
                updatedReturnItems: changeReturnApprovedState(state.updatedReturnItems, payload),
            };
            return {
                ...updatedChangeApprovedItemStates,
                isReadyForSubmit: isAggregateReadyForSubmit(
                    state.returnFees,
                    reducedValues,
                    isReadyForSubmit(updatedChangeApprovedItemStates.updatedStateItems, state.currentReturnItems, payload, state.isReadyForSubmit, updatedChangeApprovedItemStates),
                    state.currentReturnItems,
                    merchantFiles
                )
            };
        case Const.INBOUND_CHANGE_PRODUCT_FILTER: {
            let updatedChangeProductFilterStates = {
                ...state,
                updatedStateItems: updateProductFilter(state.updatedStateItems, payload)
            };
            return {
                ...updatedChangeProductFilterStates,
                wasProductChanged: !state.wasProductChanged,
                isReadyForSubmit: isAggregateReadyForSubmit(
                    state.returnFees,
                    reducedValues,
                    isReadyForSubmit(updatedChangeProductFilterStates.updatedStateItems, state.currentReturnItems, payload, state.isReadyForSubmit),
                    state.currentReturnItems,
                    merchantFiles
                )
            }
        }
        case Const.INBOUND_SET_ITEM_REPORT_STATE:
            let updatedReportStates = {
                ...state,
                updatedStateItems: changeReportState(state.updatedStateItems, payload)
            };
            return {
                ...updatedReportStates,
                wasProductChanged: !state.wasProductChanged,
                isReadyForSubmit: isAggregateReadyForSubmit(
                    state.returnFees,
                    reducedValues,
                    isReadyForSubmit(updatedReportStates.updatedStateItems, state.currentReturnItems, payload, state.isReadyForSubmit),
                    state.currentReturnItems,
                    merchantFiles
                )
            };
        case Const.INBOUND_CHANGE_DIMINISHED_PRICE: {
            let updatedDiminishedStates = {
                ...state,
                updatedStateItems: updateProductDiminishedPrice(state.updatedStateItems, payload)
            };
            return {
                ...updatedDiminishedStates,
                wasProductChanged: !state.wasProductChanged,
                isReadyForSubmit: isAggregateReadyForSubmit(
                    state.returnFees,
                    reducedValues,
                    isReadyForSubmit(updatedDiminishedStates.updatedStateItems, state.currentReturnItems, payload, state.isReadyForSubmit),
                    state.currentReturnItems,
                    merchantFiles
                )
            }
        }
        case Const.INBOUND_SET_PRODUCT_FILTER_TO_DEFAULT: {
            let updatedToDefaultItemsStates = {
                ...state,
                updatedStateItems: updateProductFilterToDefault(state.updatedStateItems, state.currentReturnItems, payload)
            };
            return {
                ...updatedToDefaultItemsStates,
                wasProductChanged: !state.wasProductChanged,
                isReadyForSubmit: isAggregateReadyForSubmit(
                    state.returnFees,
                    reducedValues,
                    isReadyForSubmit(updatedToDefaultItemsStates.updatedStateItems, state.currentReturnItems, payload, state.isReadyForSubmit),
                    state.currentReturnItems,
                    merchantFiles
                )
            }
        }
        case Const.INBOUND_CLEAR_STATE:
            return {
                ...state,
                data: new GeneralModel(),
                updatedStateItems: [],
                isLoading: false,
                currentReturnItems: [],
                currentReturnItemsUpdated: [],
                were3ImagesUploaded: 0,
                isFoldedOpen: false,
                wereItemsFound: false,
                isOrderFound: null,
                searchValue: '',
                isDialogActive: false,
                isDialogSendToCSActive: false,
                isUpdateConfirmPopupActive: false,
                isSendToSCLoading: false,
                currentExpandPanel: 0,
                currentOrderIdForSaving: null,
                wasStatusChanged: false,
                wasProductChanged: false,
                isReadyForSubmit: null
            };
        case Const.INBOUND_CHANGE_SUBMIT_STATE_BASED_ON_REDUCED_VALUES:
            reducedValues = payload.reducedValues;
            return {
                ...state,
                isReadyForSubmit: isAggregateReadyForSubmit(
                    state.returnFees,
                    reducedValues,
                    isReadyForSubmit(state.updatedStateItems, state.currentReturnItems, {currentReturnOrderId: payload.returnId}, state.isReadyForSubmit),
                    state.currentReturnItems,
                    merchantFiles
                )
            };
        case FixedFeeSelectorConst.FIXED_FEE_SELECTOR_SET_RETURN_FEE:
            let fixedFeeObject = {
                returnFees: {
                    ...state.returnFees,
                    [payload.returnOrderId]: !payload.returnFee && payload.returnFee !== 0 ? '' : parseFloat(payload.returnFee)
                },
            };
            return {
                ...state,
                returnFees: fixedFeeObject.returnFees,
                isReadyForSubmit: isAggregateReadyForSubmit(
                    fixedFeeObject.returnFees,
                    reducedValues,
                    isReadyForSubmit(state.updatedStateItems, state.currentReturnItems, {currentReturnOrderId: payload.returnOrderId}, state.isReadyForSubmit),
                    state.currentReturnItems,
                    merchantFiles
                )
            };
        case DropZoneConst.DROP_ZONE_FILES_WERE_UPDATED:
            merchantFiles = payload.merchantFiles;
            return {
                ...state,
                merchantFiles: payload.merchantFiles,
                isReadyForSubmit: isAggregateReadyForSubmit(
                    returnFees,
                    reducedValues,
                    isReadyForSubmit(state.updatedStateItems, state.currentReturnItems, {currentReturnOrderId: payload.currentReturnOrderId}, state.isReadyForSubmit),
                    state.currentReturnItems,
                    merchantFiles
                )
            };
        case ReturnDescriptionConst.RETURN_DESCRIPTION_SET_REFUND:
            return {
                ...state,
                refund: {
                    ...state.refund,
                    [payload.returnOrderId]: payload.refund
                }
            };
        case Const.DROP_ZONE_S3_IMAGES_UPLOADED: {
            return {
                ...state,
                isUpdateConfirmPopupActive: false,
                were3ImagesUploaded: state.were3ImagesUploaded+1
            }
        }
        default:
            return state;
    }
}
