import React, {Component} from "react";
import {renderToStaticMarkup} from "react-dom/server";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import { withLocalize } from 'react-localize-redux';
import _ from "lodash";
import {getLocale, getLoginLocale} from "./actions/localizationActions";
import {localizationError} from "./../ErrorDialog/actions/errorDialogActions";


class Localization extends Component {

    componentDidMount() {
        this.fetchCurrentLocale()
    }

    /**
     * fetchCurrentLocale {Function}
     * @description - make request to get localization fail in JSON format
     *
     * */
    fetchCurrentLocale = () =>{
        let {currentLanguage, isLoggedIn} = this.props;

        if(!isLoggedIn) {
            this.props.getLoginLocale({
                route: 'getLoginLocale',
                requestData: {
                    lang: currentLanguage
                }
            })
        } else {
            this.props.getLocale({
                route: 'getLocale',
                requestData: {
                    lang: currentLanguage,
                }
            })
        }
    };

    /**
     * initLocalizationEngine {Function}
     * @description - init localization engine all over the application
     *
     * */
    initLocalizationEngine = () => {
        let {initialize, translation={}, supportedLanguages, currentLanguage} = this.props;

        initialize({
            languages: supportedLanguages,
            translation: translation,
            options: {
                defaultLanguage: currentLanguage,
                renderToStaticMarkup,
                renderInnerHtml: true,
                onMissingTranslation: ({ defaultTranslation })=> {
                    return 'No translation found!';
                }
            }
        });
    };

    /**
     * switchLanguage {Function}
     * @description - switch translation to the specific language
     *
     * */
    switchLanguage = () => {
        let {addTranslationForLanguage, setActiveLanguage, translation, currentLanguage} = this.props;

        addTranslationForLanguage(translation, currentLanguage);
        setActiveLanguage(currentLanguage);
    };

    componentDidUpdate(prevProps, prevState) {
        let {translation, currentLanguage, wasGeneralTranslationLoaded, isLoggedIn} = this.props;
        /* Localization init */
        if(_.isEmpty(prevProps.translation) && !_.isEmpty(translation)) {
            this.initLocalizationEngine();
        }

        /* Add new language translation */
        if(prevProps.currentLanguage !== currentLanguage || prevProps.wasGeneralTranslationLoaded !== wasGeneralTranslationLoaded) {
            this.switchLanguage()
        }

        /* Get general localization */
        if(prevProps.isLoggedIn !== isLoggedIn && isLoggedIn === true) {
            this.props.getLocale({
                route: 'getLocale',
                requestData: {
                    lang: currentLanguage,
                }
            })
        }

        if(prevProps.wasGeneralTranslationLoaded !== wasGeneralTranslationLoaded && !wasGeneralTranslationLoaded) {
            this.props.localizationError()
        }
    }

    render() {
        // we also could use 'isTranslationLoading' parameter

        return (
            <>
                {this.props.children}
            </>
        );
    }
}

function mapStateToProps({localizationState}) {
    return {
        ...localizationState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            getLocale,
            getLoginLocale,
            localizationError
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withLocalize(Localization));