import GeneralModel from './../../../models/GeneralModel';
import configuration from '../../../configs/configs';
import {find, isArray} from 'lodash';

const {REMOVED, PENDING, NOT_PROCESSED} = configuration.states;

let getExpandedState = (item) => {
    if (item.comment || item.reclamationImagePath) {
        return true
    }
    return false;
};

/**
 * getProductForComparison {Function}
 * @description - clean state passed as parameter from the fields which were added artificially ( getItemProduct ).
 *                This need for comparison the default state and state changed by user, but need to exclude fields which are absent in defaultState, otherwise states would always be different
 *
 * @return {Object} Clean product state;
 *
 * */
let getProductForComparison =(product) => {
    let cleanProduct = {};

    for(let i in product) {
        if(i !== 'isCommentsAreaExpanded' && i !== 'wasStatusChanged' && i !== 'changedValues' && i !== 'wasStatusChangedByUser') {
            if(product.hasOwnProperty(i)) {
                cleanProduct[i] = product[i]
            }
        }
    }

    return cleanProduct;
};

/**
 * getSubmitBasedOnComparison {Function}
 * @description -
 * @return {Boolean}
 *
 * */
let getSubmitBasedOnComparison = (items, currentProductState, i) => {
    let isReadyForSubmit = false;
    let currentDefaultProductState;
    let currentComparisonId = currentProductState.returnOrderItemId;
    let defaultProductState = find(items, (defaultItem)=>{ return defaultItem.returnOrderItemId === currentComparisonId });

    if(defaultProductState) {
        let {orderItem: {orderItemId}} = defaultProductState;
        let defaultStateId = orderItemId;

        if(defaultStateId === parseInt(i)) {
            currentDefaultProductState = defaultProductState;
            // remove additional fields to exclude them from comparison
            let cleanDefaultProductState = getProductForComparison(currentDefaultProductState);
            let cleanCurrentProductState = getProductForComparison(currentProductState);

            if(!cleanDefaultProductState.privateComment && cleanCurrentProductState.privateComment === '') {
                delete cleanCurrentProductState.privateComment;
            }

            if(!cleanDefaultProductState.managerComment && cleanCurrentProductState.managerComment === '') {
                delete cleanCurrentProductState.managerComment;
            }

            if(JSON.stringify(cleanDefaultProductState) !== JSON.stringify(cleanCurrentProductState)) {
                if(cleanDefaultProductState.diminishedPrice < cleanCurrentProductState.diminishedPrice) {
                    isReadyForSubmit = false
                } else {
                    isReadyForSubmit = true;
                }
            }
        }
    }

    return isReadyForSubmit;
};

/**
 * isNeedToSetApproveItemState {Function}
 * @description - define if all product were changed state
 * @return {Boolean}
 *
 * */
let isNeedToSetApproveItemState = (products, returnOrderState) => {
    let isNeedToSetApproveItemState = true;
    let isAllProductsInPendingState = true;

    if (returnOrderState && returnOrderState.toJSONView()) {
        if (returnOrderState.toJSONView().isApprovedFromTheServer) {
            return true;
        }
    }

    for (let i in products) {
        let currentProduct = products[i];

        if (!currentProduct.wasStatusChanged) {
            isNeedToSetApproveItemState = false
        }

        if (currentProduct.status !== PENDING) {
            isAllProductsInPendingState = false
        }
    }

    if (isAllProductsInPendingState) {
        isNeedToSetApproveItemState = false
    }

    return isNeedToSetApproveItemState
};


let roundNumber = (num, scale) => {
    if (!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale) + "e-" + scale);
    } else {
        let arr = ("" + num).split("e");
        let sig = "";
        if (+arr[1] + scale > 0) {
            sig = "+";
        }
        return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
};

let calcReturnTotalDecrease = (returnOrder) => {
    let diff = 0;
    let l = returnOrder && returnOrder.items ? returnOrder.items.length: 0;

    if(l) {
        for (let i = 0; i < l; i++) {
            let item = returnOrder.items[i];
            if (item.deleted) {
                diff += item.orderItem.price;
            } else if (item.diminishedPrice >= 0) {
                // when negative diminished prices are possible and how to handle?
                diff += (item.orderItem.price - item.diminishedPrice);
            }
        }
    }

    if (diff > 0 && returnOrder.returnFee > 0) {
        diff -= returnOrder.returnFee;
    }

    return roundNumber(diff, 2);
};


export let getCurrentReturnItemUpdateInfo = (returnOrder) => {
    let updateInfo = {};

    if(returnOrder) {
        updateInfo.currency = returnOrder.currency;
        updateInfo.isProductVariantsInStock = returnOrder.productVariantsInStock;

        updateInfo.reconversionOrderItems = returnOrder.reconversionOrder ? returnOrder.reconversionOrder.itemsCount : 0;
        updateInfo.reconversionTotal = returnOrder.reconversionOrder ? returnOrder.reconversionOrder.total : 0;

        // TODO: simplify calculations with use of calcDiminishedTotal() and fee; do not forget reconversion cases; pass remainder amount from backend to take it into account here
        updateInfo.returnTotalDecrease = calcReturnTotalDecrease(returnOrder);
        updateInfo.returnTotal = roundNumber(returnOrder.total - updateInfo.returnTotalDecrease, 2);

        let enoughReturn = updateInfo.reconversionTotal <= updateInfo.returnTotal;
        let paymentPending = returnOrder.reconversionOrder && returnOrder.reconversionOrder.pending;
        let enoughRefund = paymentPending ? false : updateInfo.reconversionTotal <= updateInfo.returnTotal - returnOrder.refund;

        updateInfo.removeReconversionOrder = !(enoughReturn || enoughRefund);

        if (updateInfo.removeReconversionOrder) {
            if (returnOrder.gift) {
                updateInfo.refund = 0;
                updateInfo.gift = updateInfo.returnTotal;
            } else {
                updateInfo.refund = updateInfo.returnTotal;
                updateInfo.gift = 0;
            }
        } else {
            if (returnOrder.gift) {
                let giftSum = returnOrder.gift - updateInfo.returnTotalDecrease;
                updateInfo.gift = giftSum > 0 ? roundNumber(giftSum, 2) : 0;
                updateInfo.refund = 0;
            } else {
                let refundSum = returnOrder.refund - updateInfo.returnTotalDecrease;
                updateInfo.gift = 0;
                updateInfo.refund = refundSum > 0 ? roundNumber(refundSum, 2) : 0;
            }
        }
    }

    // this is used in return-order-update-confirmation.controller.js and in return-order-update-confirmation.html
    return updateInfo;
};

/**
 * getItemProduct {Function}
 * @description - get products from received return item and return them as object
 * @return products {Object}
 *
 * */
export let getItemProduct = (data) => {
    let items = data.getField('items');
    let products = {};

    items && items.forEach((productItem)=>{
        let {orderItem: {orderItemId}} = productItem;

        products[orderItemId] = productItem;
        products[orderItemId].changedValues = null;
        products[orderItemId].wasStatusChanged = false;
        products[orderItemId].wasStatusChangedByUser = false;
        products[orderItemId].isCommentsAreaExpanded = getExpandedState(products[orderItemId]);

    });

    return products;
};

/**
 * getToggleExpandedItemProduct {Function}
 * @description - get specific product by id and toggle it parameter responsible fro expanding comment area
 * @return products {Object}
 *
 * */
export let getToggleExpandedItemProduct = (products, id) => {
    return {
        ...products,
        [id]: {
            ...products[id],
            isCommentsAreaExpanded: !products[id].isCommentsAreaExpanded
        }
    };
};

/**
 * getToggleItemProductFlaggedState {Function}
 * @description - get specific product by id and toggle it parameter responsible for flagged state
 * @return products {Object}
 *
 * */
export let getToggleItemProductFlaggedState = (products, id) => {
    return {
        ...products,
        [id]: {
            ...products[id],
            flagged: !products[id].flagged
        }
    };
};


/**
 * updateCurrentReturnItem {Function}
 * @description - get specific returns item order
 * @return products {Object}
 *
 * */
export let updateCurrentReturnItem = (data) => {

    let jsonView = data.toJSONView();
    let updatedJSON = {
        ...jsonView,
        isApprovedFromTheServer: jsonView.approved && jsonView.status !== NOT_PROCESSED,
    };

    let updatedItem = new GeneralModel(updatedJSON);

    return  updatedItem;
};

/**
 * changeProductState {Function}
 * @description - change state for specific product item
 * @return products {Object}
 *
 * */
export let changeProductState = (products, dataForUpdate) => {
    let {id, state} = dataForUpdate;

    return {
        ...products,
        [id]: {
            ...products[id],
            status: state,
            deleted: state === REMOVED ? true : false,
            wasStatusChanged: true,
            wasStatusChangedByUser: true
        }
    }
};

/**
 * toggleApproveItemState {Function}
 * @description - change approve/refund state for specific product item
 * @return products {Object}
 *
 * */
export let toggleApproveItemState = (item) => {

    let jsonView = item.toJSONView();
    let updatedJSON = {
        ...jsonView,
        approved: jsonView.isApprovedFromTheServer ? jsonView.isApprovedFromTheServer : !jsonView.approved
    };

    let updatedItem = new GeneralModel(updatedJSON);

    return  updatedItem;
};

/**
 * isReadyForSubmit {Function}
 * @description - function for comparing default Returns Order State (from server) and state changed buy user actions
 * @return {Boolean}
 *
 * */
export let isReadyForSubmit = (defaultState=null, returnItemState=null, productsState=null) => {
    let isReadyForSubmit = false;
    let items;

    if(defaultState) {
        if(defaultState.getField('status') === 'arrived') {
            return false;
        }
    }

    items = defaultState && defaultState.getField ? defaultState.getField('items') : [];

    if(productsState) {
        for(let i in productsState) {
            let currentProductState = productsState[i];
            if(getSubmitBasedOnComparison(items, currentProductState, i)) {
                isReadyForSubmit = true
            };
        }
    }

    if(returnItemState && defaultState && !isReadyForSubmit) {
        if(JSON.stringify(defaultState.toJSONView()) !== JSON.stringify(returnItemState.toJSONView())) {
            isReadyForSubmit = true;
        }
    }

    return isReadyForSubmit;
};

/**
 * updateProductFilter {Function}
 * @description - update specific filter of specific returns order product
 *
 * */
export let updateProductFilter = (products, payload) => {
    let {filterName, value, productId, isSaveAsChangedValue} = payload;
    let updatedProductsFilters;

    if(filterName === 'REFUND') {
        filterName = 'diminishedPrice'
    }

    if(filterName === 'diminishedPrice') {
        value = parseFloat(value)
    }

    if(isSaveAsChangedValue) {
        updatedProductsFilters = {
            ...products,
            [productId]: {
                ...products[productId],
                [filterName]: value,
                changedValues: {
                    ...products[productId]['changedValues'],
                    [filterName]: value
                }
            }
        }
    } else {
        updatedProductsFilters = {
            ...products,
            [productId]: {
                ...products[productId],
                [filterName]: value
            }
        }
    }

    return updatedProductsFilters;
};

/**
 * updateProductFilterToDefault {Function}
 * @description - update specific filter to default state
 *
 * */
export let updateProductFilterToDefault = (products, defaultState, productId) => {
    let defaultProducts = defaultState.getField('items');
    let defaultProductItem;

    defaultProducts.forEach((productItem)=>{
        let {orderItem: {orderItemId}} = productItem;
        let defaultStateId = orderItemId;

        if(productId === defaultStateId) {
            defaultProductItem = productItem;
        }
    });


    return {
        ...products,
        [productId]: {
            ...products[productId],
            changedValues: null,
            nextAction: defaultProductItem['nextAction'],
            condition: defaultProductItem['condition'],
            diminishedPrice: defaultProductItem['diminishedPrice'],
        }
    };
};

/**
 * autoToggleApproveItemState {Function}
 * @description - checks if each returns order product is in state other than PENDING,
 *                and if so - change flag approved on return order to true
 * @return {GeneralModel entity}
 *
 * */
export let autoToggleApproveItemState = (returnOrderState, productsState, currentShopType=null) => {
    let isAutoSetApproveItemState = true;

    if(currentShopType === 'ROYAL_DESIGN') {
        if(returnOrderState.getField('reconversionOrder') && returnOrderState.getField('reconversionItems')) {
            isAutoSetApproveItemState = returnOrderState.getField('approved');
        } else {
            isAutoSetApproveItemState = isNeedToSetApproveItemState(productsState, returnOrderState);
        }
    } else {
        isAutoSetApproveItemState = isNeedToSetApproveItemState(productsState, returnOrderState);
    }

    return new GeneralModel({
        ...returnOrderState.toJSONView(),
        approved: isAutoSetApproveItemState
    })
};


/**
 * concatenateData {Function}
 * @description - change filters
 * @return {Object} updatedFilters
 *
 * */
export let concatenateData = (stateData, newData) => {

    let stateDataJSON = stateData.toJSONView();
    let newDataJSON = newData.toJSONView();

    let concatObject = {
        ...stateDataJSON,
        data: [
            ...stateDataJSON.data,
            ...newDataJSON.data,
        ]
    };

    return new GeneralModel(concatObject)
};

/**
 * updateSCTimestamp {Function}
 * @description - update return item with send to CS timestamp
 * @return {Object} updatedFilters
 *
 * */
export let updateSCTimestamp = (currentReturnItem, timestampData=null) => {

    let updatedReturnItem;

    if(timestampData && timestampData.getField('timestamp')) {
        updatedReturnItem = new GeneralModel({
            ...currentReturnItem.toJSONView(),
            sentToCsTimeStamp: timestampData.getField('timestamp')
        });
    } else {
        updatedReturnItem = currentReturnItem;
    }

    return updatedReturnItem;
};

/**
 * isReadyForSubmitAfterReducedValue {Function}
 * @description - update  submit button state
 * @return {Boolean}
 *
 * */
export function isReadyForSubmitAfterReducedValue(reducedValues={}, currentReturn) {
    let isReadyForSubmit = false;
    let currentReturnId = currentReturn && currentReturn.getField ? currentReturn.getField('returnOrderId') : null;
    let currentReducedValues = currentReturnId ? reducedValues[currentReturnId] : null;

    if(currentReducedValues) {
        for(let key in currentReducedValues) {
            let currentValue = currentReducedValues[key];
            let {percentageValue, newDiminishedPrice, defaultDiminishedPrice} = currentValue;
            if(newDiminishedPrice !== defaultDiminishedPrice && (percentageValue > 0 || percentageValue === '')) {
                isReadyForSubmit = true;
            }
        }
    }

    return isReadyForSubmit;
}

/**
 * isReadyForSubmitAfterFixedFee {Function}
 * @description - update  submit button state
 * @return {Boolean}
 *
 * */
export function isReadyForSubmitAfterFixedFee(returnFees, currentReturn) {
    let isReadyForSubmit = false;
    let currentReturnId = currentReturn && currentReturn.getField ? currentReturn.getField('returnOrderId') : null;
    let currentReturnFee = currentReturnId ? returnFees && returnFees[currentReturnId] || returnFees && returnFees[currentReturnId] === 0 ? returnFees[currentReturnId] : '' : '';
    let returnFee = currentReturn && currentReturn.getField ? currentReturn.getField('returnFee') : 0;

    if(currentReturnFee && (currentReturnFee > 0 && currentReturnFee !== returnFee) || currentReturnFee === 0) {
        isReadyForSubmit = true
    }

    return isReadyForSubmit;
}

/**
 * getFixedFee {Function}
 * @description - return fixed fee value
 * @return {Number}
 *
 * */
export function getFixedFee(returnFees, currentReturn) {
    let currentReturnId = currentReturn && currentReturn.getField ? currentReturn.getField('returnOrderId') : null;
    let currentReducedValues = currentReturnId ? returnFees[currentReturnId] : 0;
    return currentReducedValues;
}

/**
 * getMerchantFiles {Function}
 * @description - return merchant files
 * @return {Number}
 *
 * */
export function getMerchantFiles(merchantFiles, currentReturn){
    let currentReturnId = currentReturn && currentReturn.getField ? currentReturn.getField('returnOrderId') : null;
    let currentMerchantFiles = currentReturnId ? merchantFiles[currentReturnId] : 0;
    return currentMerchantFiles;
}

/**
 * isReadyForSubmitAfterMerchantFiles {Function}
 * @description - return boolean for submit button after merchant files were updated
 * @return {Boolean}
 *
 * */
export function isReadyForSubmitAfterMerchantFiles(merchantFiles=[], currentReturn=[]) {
    let isReadyForSubmit = false;
    let currentReturnId = currentReturn && currentReturn.getField ? currentReturn.getField('returnOrderId') : null;
    let currentReturnItems = currentReturn && currentReturn.getField ? currentReturn.getField('items') : [];
    let currentMerchantsFiles = merchantFiles[currentReturnId];
    let isTheSame = (newImages, defaultImages) => {
        let isSame = true;

        newImages.forEach((newImage)=>{
           let defaultImage = find(defaultImages, (image)=>{ return image.imagePath === newImage.data})

            if(!defaultImage) {
                isSame = false;
            }
        });

        if(defaultImages && newImages && defaultImages.length !== newImages.length) {
            isSame = false;
        }

        return isSame;
    };

    if(currentReturnItems && isArray(currentReturnItems)) {
        currentReturnItems.forEach((item)=>{
            let currentItemId = item.returnOrderItemId;
            let merchantsFiles = currentMerchantsFiles && currentMerchantsFiles[currentItemId] ? currentMerchantsFiles[currentItemId] : [];

            if(!isTheSame(merchantsFiles, item.merchantImages))  {
                isReadyForSubmit = true;
            }

        });
    }

    return isReadyForSubmit;
}