import React, {Component} from 'react';
import ReactTable from 'react-table';
import Pagination from './../Pagination/Pagination';
import {connect} from "react-redux";
import {getTranslate} from "react-localize-redux";
import Loader from "../../containers/Loader/Loader";

const defaultTypes = {
    rows:[],
    columns:[],
    isPagination:true,
    isResizable: false,
    isSelectable: false,
    defaultColumnWidths:[],
    resizableColumnsIds: [],
    /* TODO: make it fit for all column with long text not only for customers */
    tableColumnExtensions: [ ],
};

class LoadingCmp extends React.Component {
    render() {
        let {loading} = this.props;
        return !!loading && <Loader/>
    }
}

class CustomTable extends Component {

    constructor(props) {
        super(props);
        this.myTableRef = React.createRef();

        this.state = {
            selection: []
        }
    }

    /**
    * changeSelection {Function}
    * @description - function in case of selections controls in table
    *
    * */
    changeSelection = (selection) => {
        this.setState({ selection });
    };

    render() {
        let {
            translate,
            data,
            columns,
            isPaginationActive=false,
            currentPage,
            pageSizeOption,
            totalPages,
            prevAction=()=>{},
            nextAction=()=>{},
            onPageSizeChangeAction=()=>{},
            isLoading,
            ...rest
        } = this.props;

        return(
            <div className="b-page-table" ref={this.myTableRef}>
                <ReactTable
                    data={data}
                    columns={columns}
                    noDataText={translate('table.search.results.not.found.text')}
                    LoadingComponent={LoadingCmp}
                    {...rest}
                />
                {
                    isPaginationActive && <Pagination
                        currentPage={currentPage}
                        pageSizeOption={pageSizeOption}
                        totalPages={totalPages}
                        prevAction={prevAction}
                        nextAction={nextAction}
                        onPageSizeChangeAction={onPageSizeChangeAction}
                        data={data}
                        isLoading={isLoading}
                    />
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        translate: getTranslate(state.localize)
    };
}


CustomTable.defaultProps = defaultTypes;

export default connect(mapStateToProps, null)(CustomTable);