import React, {Component} from 'react';
import CustomTranslation from "../CustomTranslation/CustomTranslation";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    commentWasChanged
} from './actions/returnCommentsAreasActions';

class ReturnCommentsAreas extends Component {

    constructor(props) {
        super(props);
        this.managerText = React.createRef();
        this.privateText = React.createRef();
    }

    /**
     * onChange {Function}
     * @description: change text areas value
     *
     * */
    onChange = (e) => {
        let {orderItemId} = this.props;
        let {target, target: {value}} = e;
        let privateText = this.privateText.current.value;
        let managerText = this.managerText.current.value;
        let privateTextDefault = this.privateText.current.getAttribute('data-default-value');
        let managerTextDefault = this.managerText.current.getAttribute('data-default-value');

        if(privateText === privateTextDefault && managerText === managerTextDefault) {
            this.props.commentWasChanged(orderItemId, false);
        } else {
            this.props.commentWasChanged(orderItemId, true);
        }

        target.value = value;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {managerValue, privateValue} = this.props;

        if(prevProps.managerValue !== managerValue || prevProps.privateValue !== privateValue){
            this.managerText.current.value = managerValue;
            this.privateText.current.value = privateValue;
        }
    }

    componentDidMount() {
        let managerTextCurrent = this.managerText && this.managerText.current ? this.managerText.current : null;
        let privateTextCurrent = this.privateText && this.privateText.current ? this.privateText.current : null;

        if(!managerTextCurrent.value && managerTextCurrent.getAttribute('data-default-value')) {
            managerTextCurrent.value = managerTextCurrent.getAttribute('data-default-value');
        }

        if(!privateTextCurrent.value && privateTextCurrent.getAttribute('data-default-value')) {
            privateTextCurrent.value = privateTextCurrent.getAttribute('data-default-value');
        }
    }

    render() {
        let {
            translate,
            managerComment={},
            privateComment={},
            referrer=null,
        } = this.props;
        let insManagerCommentActive = this.managerText && this.managerText.current && this.managerText.current.value;
        let insPrivateCommentActive = this.privateText && this.privateText.current && this.privateText.current.value;
        return (
            <div className='b-returns-comments-areas b-flex' ref={referrer}>
                <div className="b-returns-comments-areas__comments_internal">
                    <div className="b-returns-comments-areas__comments_title">
                        <CustomTranslation id='return.order.details.manager.comment'/>
                    </div>
                    <div className="b-returns-comments-areas__comments_field">
                        <textarea className={insManagerCommentActive ? "b-returns-comments-areas__comments_textarea active" : "b-returns-comments-areas__comments_textarea"}
                                  data-default-value={managerComment.value || ''}
                                  onChange={(e) => {this.onChange(e)}}
                                  disabled={managerComment.disabled}
                                  placeholder={translate('return.order.details.manager.sub.comment')}
                                  ref={this.managerText}
                        />
                    </div>
                </div>
                <div className="b-returns-comments-areas__comments_customer">
                    <div className="b-returns-comments-areas__comments_title">
                        <CustomTranslation id='return.order.details.internal.comment'/>
                    </div>
                    <div className="b-returns-comments-areas__comments_field">
                        <textarea className={insPrivateCommentActive ? "b-returns-comments-areas__comments_textarea active" : "b-returns-comments-areas__comments_textarea"}
                                  data-default-value={privateComment.value || ''}
                                  onChange={(e) => {this.onChange(e, true)}}
                                  placeholder={translate('return.order.details.internal.sub.comment')}
                                  ref={this.privateText}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.returnCommentsAreasState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            commentWasChanged
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(ReturnCommentsAreas);