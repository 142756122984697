import Const from './../const';

/*
* requestStart {Function}
* @return Object in React Action format before request start
*
* */
let requestStart = () =>{
    return {
        type: Const.SECURITY_START_FETCH_REQUEST
    }
};


/*
* is2F {React Action}
* @description - send request to the server to get check 2FA state
*
* */
export function is2F(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.SECURITY_CHECK_2F,
            ...requestData
        });
    }
}

/*
* update2F {React Action}
* @description - send request to the server to update 2FA state
*
* */
export function update2F(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.SECURITY_UPDATE_2F,
            ...requestData
        });
    }
}