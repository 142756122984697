import GeneralModel from "./../../../models/GeneralModel";

/**
* customModelAction {Function}
* @description - function is passing action with custom data to redux state
*
* */
export default (next, action, dataModel={}) => {
    next({
        type: action.type,
        data: new GeneralModel(dataModel)
    });
}


