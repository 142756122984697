import Const from '../const';

const defaultState = {
    username: '',
    password: '',
    verificationCode: '',
    is2fAuthentication: false,
    was2fAChecked: false,
    isPasswordVisible: false,
    isLoading: false,
    isAutoFocus: true
};

export default function (state = defaultState, action = {}) {
    let {data, type, payload} = action;
    switch (type) {
        case Const.LOGIN_SEND_START_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
        case Const.LOGIN_SEND_REQUEST: {
            return {
                ...state,
                isLoading: false
            }
        }
        case Const.LOGIN_CHECK_2F: {
            return {
                ...state,
                isLoading: false,
                is2fAuthentication: data && data.toJSONView().response ? data.toJSONView().response : false,
                was2fAChecked: true,
                isAutoFocus: data && data.toJSONView().response ? false : true
            }
        }
        case Const.LOGIN_SET_IS_PASSWORD_VISIBLE:
            return {
                ...state,
                isPasswordVisible: payload
            };
        case Const.LOGIN_SET_USERNAME:
            return {
                ...state,
                username: payload
            };
        case Const.LOGIN_SET_PASSWORD:
            return {
                ...state,
                password: payload
            };
        case Const.LOGIN_SET_2FA_CODE:
            return {
                ...state,
                verificationCode: payload
            };
        case Const.LOGIN_SET_TO_DEFAULT:
            return {
                username: '',
                password: '',
                verificationCode: '',
                is2fAuthentication: false,
                was2fAChecked: false,
                isPasswordVisible: false,
                isLoading: false,
                isAutoFocus: true
            };
        case Const.LOGIN_CLEAN_CREDENTIALS:
            return {
                username: '',
                password: '',
            };
        default:
            return state;
    }
}
