import Const from './../const';
//import ResetPasswordConst from './../../ResetPassword/const';

/*
* isActive {Function}
* @description - define activate or not success notification snack bar as we should do this only for specific action type;
* @return isActive {Boolean}
*
* */
let isActive = (action) => {
    let {payload: {actionType=''}} = action;
    let isActive;
    switch (actionType) {
        /*case ResetPasswordConst.RESET_PASSWORD_SEND_IDENTIFICATOR_ON_EMAIL:
            isActive = true;
            break;*/
        default:
            isActive = false;
            break;
    }

    return isActive;
};

const defaultState = {
    isActive: false,
    text: '',
    actionType: '',
    status: null,
    data: {}
};

export default function (state = defaultState, action = {}) {
    let {type, payload} = action;
    switch (type) {
        case Const.SERVER_SUCCESS_NOTIFIER_IS_ACTIVE:
            return {
                ...state,
                isActive: isActive(action),
                text: payload.statusText,
                actionType: payload.actionType,
                status: payload.errorStatus,
            };
        case Const.SERVER_SUCCESS_NOTIFIER_CLEAR:
            return {
                ...state,
                isActive: false,
                text: '',
                actionType: '',
                data: {}
            };
        default:
            return state;
    }
}
