import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {setCurrentPageTitle} from "./../../components/Header/actions/headerActions";
import PageContent from "../../containers/PageContent/PageContent";
import {fetchCustomers} from "./actions/customersActions";
import {
    toFixedCurrency,
    getBooleanShopProperty,
    getAppropriateMarket,
    isNeedToShowMarketLanguage,
    isNeedToShowMarket,
} from './../../utilites/index';
import {Redirect, withRouter} from "react-router";
import CustomTranslation from "./../../components/CustomTranslation/CustomTranslation";
import CustomTable from './../../components/CustomTable/CustomTable';
import {isEmpty} from 'lodash';
import {getTranslate} from "react-localize-redux";
import Search from "../../components/Search/Search";
import defaultRequestConst from './defaultRequestConst';


class Customers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: true,
            currentPage: 0,
            pageSizeOption: defaultRequestConst.limit,
            searchValue: ''
        };
        this.searchTimeout = null;
    }

    /**
     * onSearchField {Function}
     * @description - callback on change search field. Does specific action
     *
     * */
    onSearchField = (event) => {
        let searchValue = event.currentTarget.value;
        let {DefaultRequestConst} = this.props;

        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = setTimeout(() => {
            if (searchValue && searchValue.length > 2) {
                this.fetchCustomers({
                    ...DefaultRequestConst,
                    search: searchValue
                });
            }
        }, 900);

        if (!searchValue) {
            this.setState({
                searchValue: ''
            }, ()=> {
                this.fetchCustomers({
                    ...DefaultRequestConst,
                    search: ''
                });
            })
        } else {
            this.setState({
                searchValue: searchValue
            })
        }
    };

    /***
     * getSearchControlComponent {Function}
     * @description - method to get search control for returns page
     * @return {React Component}
     *
     * */
    getSearchControlComponent = () => {
        let {translate} = this.props;
        let {searchValue} = this.state;

        return (
            <Search
                placeholder={translate('search')}
                onSubmitAction={this.onSubmit}
                onValidAction={this.enableButton}
                onInvalidAction={this.disableButton}
                onSearchFieldAction={this.onSearchField}
                isClearSearchControlPresent={searchValue}
                onClearSearchAction={this.clearSearch}
                styleClass='b-search--customers'
            />
        )
    };

    /**
     * clearSearch {Function}
     * @description - clearing the search field
     *
     * */
    clearSearch = () => {
        let {DefaultRequestConst} = this.props;

        this.setState({
            searchValue: ''
        },()=>{
            this.fetchCustomers({
                ...DefaultRequestConst,
                search: ''
            });
        });

    };

    /***
     * getTableData {Function}
     * @description - return data array to render in returns table
     * @return {Array}
     *
     * */
    getTableData = (data) => {
        return isEmpty(data.toJSONView()) ? [] : data.getField('data')
    };

    /**
     * handleTableRowClick {Function}
     * @description - function return object with action which fires when click on specific table's row
     * @return {Object}
     *
     * */
    handleTableRowClick = (state, rowInfo) => {
        return {
            onClick: (e) => {
            },
            style: {},
        }
    };

    /***
     * renderTable {Function}
     * @description - render table using data from server side
     * @return {React Component}
     *
     * */
    renderTable = () => {
        let {data, isLoading} = this.props;
        let {currentPage, pageSizeOption} = this.state;
        let prefix = 'customer.list.columns';
        let tableDate = this.getTableData(data);
        let totalReturnsAmount = data.getField('paging') ? data.getField('paging').total : 1;
        let totalPages = Math.ceil(totalReturnsAmount / pageSizeOption);
        const columns = [{
            Header: <CustomTranslation id={`${prefix}.extId`}/>,
            id: 'shopCustomerId',
            accessor: (row) =>  row.shopCustomerId,
            Cell: ({row}) => {
                return row.shopCustomerId
            }
        }, {
            Header: <CustomTranslation id={`${prefix}.firstName`}/>,
            id: 'firstName',
            accessor: (row) => row.firstName,
            Cell: ({row}) => {
                return row.firstName
            }
        }, {
            Header: <CustomTranslation id={`${prefix}.lastName`}/>,
            id: 'lastName',
            accessor: (row) => row.lastName,
            Cell: ({row}) => {
                return row.lastName
            }
        }, {
            Header: <CustomTranslation id={`${prefix}.email`}/>,
            id: 'email',
            accessor: (row) => row.email,
            Cell: ({row}) => {
                return row.email
            }
        }, {
            Header: <CustomTranslation id={`${prefix}.updatedTime`}/>,
            accessor: 'updateTimeLabel',
            Cell: ({row}) => {
                return row.updateTimeLabel ? row.updateTimeLabel : '-'
            },
        }, {
            Header: <CustomTranslation id={`${prefix}.shopName`}/>,
            id: 'shopId',
            accessor: (row)=> row.shopId,
            Cell: ({row}) => {
                return this.getMarket(row.shopId || row._original.shopId)
            },
        }, {
            Header: <CustomTranslation id={`${prefix}.storeCredit`}/>,
            accessor: 'storeCredit',
            Cell: ({row}) => {
                return row.storeCredit ? toFixedCurrency(row.storeCredit.balance) : '-'
            },
        }];

        return <CustomTable
            className={'-highlight -striped'}
            data={tableDate}
            columns={columns}
            loading={isLoading}
            isLoading={isLoading}
            getTrProps={this.handleTableRowClick}
            defaultPageSize={pageSizeOption}
            showPagination={false}
            pageSize={tableDate.length}
            style={{
                // This will force the table body to overflow and scroll, since there is not enough room
                height: tableDate.length > 10 ? "650px": tableDate.length ? "auto" : "140px"
            }}
            isPaginationActive={true}
            currentPage={currentPage}
            pageSizeOption={totalReturnsAmount}
            totalPages={totalPages}
            prevAction={() => {
                this.goToAppropriateTablePage(true)
            }}
            nextAction={() => {
                this.goToAppropriateTablePage()
            }}
            onPageSizeChangeAction={this.onPageSizeChange}
        />
    };

    /***
     * getMarket {Function}
     * @description - receives shopId as parameter and generats appropriate market name based on main shop configurations
     * @return {String}
     *
     * */
    getMarket = (shopId) => {
        let {mainPropertiesState} = this.props;
        return getAppropriateMarket(mainPropertiesState, shopId, isNeedToShowMarket(mainPropertiesState), isNeedToShowMarketLanguage(mainPropertiesState));
    };

    /***
     * goToAppropriateTablePage {Function}
     * @description - callback fires when user use table pagination
     * */
    goToAppropriateTablePage = (isPrev = false) => {
        let {currentPage} = this.state;

        if (isPrev && currentPage !== 0) {
            this.onChangeCurrentPageAction(currentPage - 1)
        } else {
            this.onChangeCurrentPageAction(currentPage + 1)
        }

    };

    /***
     * onPageSizeChange {Function}
     * @description - action calls when page size was changed using table page size dropdown
     *
     * */
    onPageSizeChange = (pageSize) => {
        let {DefaultRequestConst} = this.props;

        this.setState({
            pageSizeOption: pageSize
        }, () => {
            this.fetchCustomers({
                ...DefaultRequestConst,
                limit: pageSize
            })
        });

    };

    /**
     * onChangeCurrentPageAction {Function}
     * @description - action calls on page change using table next/previous controls
     *
     * */
    onChangeCurrentPageAction = (nextCurrentPage) => {
        let {DefaultRequestConst} = this.props;
        let {pageSizeOption} = this.state;

        this.setState({
            currentPage: nextCurrentPage
        }, () => {
            this.fetchCustomers({
                ...DefaultRequestConst,
                limit: pageSizeOption,
                page: nextCurrentPage
            })
        })

    };

    /***
     * fetchCustomers {Function}
     * @description - main function to get orders from server
     *
     * */
    fetchCustomers = (requestData = null) => {
        let {DefaultRequestConst} = this.props;
        let dataForRequest = requestData ? requestData : {
            ...DefaultRequestConst
        };

        this.props.fetchCustomers({
            route: 'getCustomers',
            isWithGetParams: true,
            requestData: dataForRequest
        });
    };

    componentDidMount() {
        let {pageName} = this.props;
        this.props.setCurrentPageTitle(pageName);
        this.fetchCustomers();
    }

    render() {
        let {isFoldedOpen} = this.props;

        return (
            getBooleanShopProperty(this.props, 'customers.page.enabled')
                ?
                <div className='b-app__page b-customers-page'>
                    <PageContent isFoldedOpen={isFoldedOpen} classNameCustom='b-page-content--customers'>
                        <div className="b-app__page_body">
                            <div className="b-app__body_item">
                                <div className="b-app__page_controls b-flex b-flex--reverse b-returns-page__controls">
                                    <div className="b-app__page_controls_item">
                                        {this.getSearchControlComponent()}
                                    </div>
                                </div>
                                <div className="b-app__table_wrapper">
                                    {this.renderTable()}
                                </div>
                            </div>
                        </div>
                    </PageContent>
                </div>
                :
                <Redirect to="/"/>
        )
    };
}

function mapStateToProps(state) {
    return {
        ...state.customersPageState,
        mainPropertiesState: state.mainPropertiesState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            setCurrentPageTitle,
            fetchCustomers
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Customers));