import React from 'react';
import TextFieldFormsy from '../../FormsyBasedFields/TextFieldFormsy';

function EmailField({TranslateComponent, userNameFromLoginPage}) {
    return (
        <div className='b-reset-password__item'>
            <div className='b-reset-password__p'>
                <TextFieldFormsy
                    type='text'
                    name='username'
                    value={userNameFromLoginPage}
                    label={<TranslateComponent id={'login.form.username'} />}
                    fullWidth={true}
                    variant='outlined'
                    required
                />
            </div>
        </div>
    )
}

export default EmailField;