const Const = {
    RETURN_EVENT_LOG_POPUP_SHOW: 'RETURN_EVENT_LOG_POPUP_SHOW',
    RETURN_EVENT_LOG_POPUP_HIDE: 'RETURN_EVENT_LOG_POPUP_HIDE',
    RETURN_EVENT_LOG_POPUP_TOGGLE_EVENT: 'RETURN_EVENT_LOG_POPUP_TOGGLE_EVENT',
    RETURN_EVENT_LOG_POPUP_FETCH_EVENTS: 'RETURN_EVENT_LOG_POPUP_FETCH_EVENTS',
    RETURN_EVENT_LOG_POPUP_START_FETCH_EVENTS: 'RETURN_EVENT_LOG_POPUP_START_FETCH_EVENTS',
    RETURN_EVENT_LOG_POPUP_UPDATE_EVENT: 'RETURN_EVENT_LOG_POPUP_UPDATE_EVENT',
    RETURN_EVENT_LOG_POPUP_START_UPDATE_EVENT: 'RETURN_EVENT_LOG_POPUP_START_UPDATE_EVENT',
    RETURN_EVENT_LOG_STATIC_CLOSE: 'RETURN_EVENT_LOG_STATIC_CLOSE',
    RETURN_EVENT_LOG_CLEAR: 'RETURN_EVENT_LOG_CLEAR'
};

export default Const;