import {isObject} from 'lodash';

/**
 * getChangedFilters {Function}
 * @description - change filters
 * @return {Object} updatedFilters
 *
 * */
export let getChangedFilters = (filters, payload) => {
    let {label, value} = payload;
    let labelArr = label.split('.');
    let filterName = labelArr[labelArr.length-1];

    // this is necessary to handle date range filter
    if(isObject(value) && value.type) {
        let filterValue = value[value.type];
        return {
            ...filters,
            [value.type]: filterValue ? filterValue.format('YYYY-MM-DD') : ''
        }
        // this is necessary to handle all other filter
    } else {
        return {
            ...filters,
            [filterName]: value
        }
    }
};
