import { applyMiddleware, compose, createStore } from 'redux'
import returnadoMiddleWare from '../middlewares/returnadoMiddleWare';
import allReducers from "../reducers/rootRedusers";
import thunk from "redux-thunk";

export default function configureStore(initialState = {}) {
    let composeEnhancers = compose

    if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
        if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
            composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    }

    const middlewares = [thunk, returnadoMiddleWare]
    const enhancers = [applyMiddleware(...middlewares)]
    return createStore(
        allReducers,
        initialState,
        composeEnhancers(...enhancers)
    )
}
