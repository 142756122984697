import Const from './../const';

/*
* requestStart {Function}
* @return Object in React Action format before request start
*
* */
let requestStart = () =>{
    return {
        type: Const.RESET_PASSWORD_START_REQUEST
    }
};

/*
* checkTokenRequestStart {Function}
* @return Object in React Action format before check token request start
*
* */
let checkTokenRequestStart = () =>{
    return {
        type: Const.RESET_PASSWORD_START_CHECK_TOKEN_REQUEST
    }
};

/*
* setIsPasswordVisible {React Action}
* @description - change visibility in the password field
*
* */
export function setIsPasswordVisible(value) {
    return dispatch => {
        dispatch({
            type: Const.RESET_PASSWORD_IS_PASSWORD_VISIBLE,
            payload: value
        });
    }
}

/*
* setUserNameFormTokenToState {React Action}
* @description - change visibility in the password field
*
* */
export function setUserNameFormTokenToState(username) {
    return dispatch => {
        dispatch({
            type: Const.RESET_PASSWORD_SET_USER_NAME_FROM_TOKEN,
            payload: username
        });
    }
}

/*
* clearState {React Action}
* @description - clear reset password state
*
* */
export function clearState(username) {
    return dispatch => {
        dispatch({
            type: Const.RESET_PASSWORD_CLEAR_STATE,
            payload: username
        });
    }
}

/*
* sendDataOnEmail {React Action}
* @description - send request to the server for sending info to user email with link
*                necessary data for reset password
*
* */
export function sendDataOnEmail(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.RESET_PASSWORD_SEND_IDENTIFICATOR_ON_EMAIL,
            ...requestData
        });
    }
}

/*
* checkToken {React Action}
* @description - request to check if token for reset password is valid
*
* */
export function checkToken(requestData) {
    return dispatch => {
        dispatch(checkTokenRequestStart());
        dispatch({
            type: Const.RESET_PASSWORD_CHECK_TOKEN,
            ...requestData
        });
    }
}

/*
* updatePassword {React Action}
* @description - request to check if token for reset password is valid
*
* */
export function updatePassword(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.RESET_PASSWORD_UPDATE_PASSWORD,
            ...requestData
        });
    }
}
