
const DefaultRequestConst = {
    asc: false,
    limit: 250,
    page: 0,
    reconversion: '',
    search:'',
    shipmentStatus:'',
    sort: 'date',
    status: '',
};

export default DefaultRequestConst;