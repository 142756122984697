import ReturnsItemConst from './../../pages/Returns/const';
import InboundOrderConst from './../../pages/Inbound/const';

const Const = {
    DROP_ZONE_LOAD_FILES: 'DROP_ZONE_LOAD_FILES',
    DROP_ZONE_REPLACE_FILES: 'DROP_ZONE_REPLACE_FILES',
    DROP_ZONE_FILES_WERE_UPDATED: 'DROP_ZONE_FILES_WERE_UPDATED',
    DROP_ZONE_START_FILES_HANDLING: 'DROP_ZONE_START_FILES_HANDLING',
    DROP_ZONE_CLEAR_STATE: 'DROP_ZONE_CLEAR_STATE',
    DROP_ZONE_S3_IMAGES_UPLOADED: 'DROP_ZONE_S3_IMAGES_UPLOADED',
    [ReturnsItemConst.RETURNS_CONFIRM_UPDATE_ORDER]: ReturnsItemConst.RETURNS_CONFIRM_UPDATE_ORDER,
    [InboundOrderConst.INBOUND_CONFIRM_UPDATE_ORDER]: InboundOrderConst.INBOUND_CONFIRM_UPDATE_ORDER,
};

export default Const;