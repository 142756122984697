import LeftSideBarConst from './../../components/LeftSideBar/const';
import ReturnsPageConst from './../Returns/const';
import ReturnDescriptionConst from "./../../components/ReturnDescription/const";

const Const = {
    INBOUND_START_FETCH_REQUEST: 'INBOUND_START_FETCH_REQUEST',
    INBOUND_FETCH_REQUEST: 'INBOUND_FETCH_REQUEST',
    INBOUND_CHANGE_PRIVATE_COMMENT: 'INBOUND_CHANGE_PRIVATE_COMMENT',
    INBOUND_CHANGE_CUSTOMER_COMMENT: 'INBOUND_CHANGE_CUSTOMER_COMMENT',
    INBOUND_CHANGE_PRODUCT_FILTER: 'INBOUND_CHANGE_PRODUCT_FILTER',
    INBOUND_CHANGE_DIMINISHED_PRICE: 'INBOUND_CHANGE_DIMINISHED_PRICE',
    INBOUND_CLOSE_CONFIRMATION_DIALOG: 'INBOUND_CLOSE_CONFIRMATION_DIALOG',
    INBOUND_SET_PRODUCT_FILTER_TO_DEFAULT: 'INBOUND_SET_PRODUCT_FILTER_TO_DEFAULT',
    INBOUND_SEND_NOTE_TO_THE_CUSTOMER_START: 'INBOUND_SEND_NOTE_TO_THE_CUSTOMER_START',
    INBOUND_SEND_NOTE_TO_THE_CUSTOMER: 'INBOUND_SEND_NOTE_TO_THE_CUSTOMER',
    INBOUND_CLOSE_SEND_NOTE_TO_THE_CUSTOMER: 'INBOUND_CLOSE_SEND_NOTE_TO_THE_CUSTOMER',
    INBOUND_SET_EXPAND_PANEL_INDEX: 'INBOUND_SET_EXPAND_PANEL_INDEX',
    INBOUND_CONFIRM_UPDATE_ORDER: 'INBOUND_CONFIRM_UPDATE_ORDER',
    INBOUND_CONFIRM_UPDATE_ORDER_CLOSE: 'INBOUND_CONFIRM_UPDATE_ORDER_CLOSE',
    INBOUND_SET_FIXED_RETURN_FEE: 'INBOUND_SET_FIXED_RETURN_FEE',
    [ReturnsPageConst.RETURNS_CHANGE_FILTERS_BY_INBOUND_METRICA]: ReturnsPageConst.RETURNS_CHANGE_FILTERS_BY_INBOUND_METRICA,

    INBOUND_SET_ITEM_REPORT_STATE: 'INBOUND_SET_ITEM_REPORT_STATE',
    INBOUND_SET_ITEM_STATE: 'INBOUND_SET_ITEM_STATE',
    INBOUND_MOVE_ITEMS: 'INBOUND_MOVE_ITEMS',
    INBOUND_SET_NEW_ITEMS_ORDER: 'INBOUND_SET_NEW_ITEMS_ORDER',
    INBOUND_CLEAR_STATE: 'INBOUND_CLEAR_STATE',
    INBOUND_CHANGE_SEARCH_VALUE: 'INBOUND_CHANGE_SEARCH_VALUE',
    INBOUND_TOGGLE_APPROVE_FLAG: 'INBOUND_TOGGLE_APPROVE_FLAG',
    [LeftSideBarConst.LEFT_SIDE_BAR_IS_FOLDED_OPEN]: LeftSideBarConst.LEFT_SIDE_BAR_IS_FOLDED_OPEN,

    INBOUND_CONFIRM_POPUP_BEFORE_UPDATE_ORDER_SHOW: 'INBOUND_CONFIRM_POPUP_BEFORE_UPDATE_ORDER_SHOW',
    INBOUND_CONFIRM_POPUP_BEFORE_UPDATE_ORDER_CLOSE: 'INBOUND_CONFIRM_POPUP_BEFORE_UPDATE_ORDER_CLOSE',

    INBOUND_METRICS_START_FETCH_REQUEST: 'INBOUND_METRICS_START_FETCH_REQUEST',
    INBOUND_METRICS_FETCH_REQUEST: 'INBOUND_METRICS_FETCH_REQUEST',
    INBOUND_CHANGE_SUBMIT_STATE_BASED_ON_REDUCED_VALUES: 'INBOUND_CHANGE_SUBMIT_STATE_BASED_ON_REDUCED_VALUES',

    [ReturnDescriptionConst.RETURN_DESCRIPTION_SET_REFUND]: ReturnDescriptionConst.RETURN_DESCRIPTION_SET_REFUND,

    DROP_ZONE_S3_IMAGES_UPLOADED: 'DROP_ZONE_S3_IMAGES_UPLOADED'
};

export default Const;