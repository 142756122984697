import GeneralModel from './../../../models/GeneralModel';
import Const from "./../const";


const defaultState = {
    data: new GeneralModel({}),
    isFoldedOpen: false,
    is2fChecked: false,
    isLoading: false,
    qrCodeImg: null
};


export default function (state = defaultState, action = {}) {
    let {type, data, payload} = action;
    switch (type) {
        case Const.SECURITY_START_FETCH_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Const.LEFT_SIDE_BAR_IS_FOLDED_OPEN:
            return {
                ...state,
                isFoldedOpen: payload
            };
        case Const.SECURITY_CHECK_2F:
            return {
                ...state,
                isLoading: false,
                is2fChecked: data.getField('response')
            };
        case Const.SECURITY_UPDATE_2F:
            return {
                ...state,
                isLoading: false,
                is2fChecked: data.getField('response') === "Manager updated" ? false : true,
                qrCodeImg: data.getField('response') === "Manager updated" ? null : data.getField('response'),
            };
        default:
            return state;
    }
}
