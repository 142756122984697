import Const from './../const';

const defaultState = {
    isLoading: false,
    isCheckTokenLoading: false,
    isPasswordVisible: false,
    isTokenValid: false,
    wasEmailSend: false,
    wasTokenVerified: false,
    wasPasswordUpdated: false,
    isAutoFocus: true,
    tokenUserName: '',
    userNameFromLoginPage: ''
};

let getStateByRequestStatus = (data) => {
    return data.getField('requestStatus') === 'success';
};

export default function (state = defaultState, action = {}) {
    let {type, payload} = action;

    switch (type) {
        case Const.RESET_PASSWORD_START_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Const.RESET_PASSWORD_START_CHECK_TOKEN_REQUEST:
            return {
                ...state,
                isLoading: true,
                isCheckTokenLoading: true,
            };
        case Const.RESET_PASSWORD_SET_USER_NAME_FROM_TOKEN:
            return {
                ...state,
                tokenUserName: payload
            };
        case Const.RESET_PASSWORD_CHECK_TOKEN:
            return {
                ...state,
                isLoading: false,
                isCheckTokenLoading: false,
                isTokenValid: getStateByRequestStatus(action.data),
                wasTokenVerified: true
            };
        case Const.RESET_PASSWORD_UPDATE_PASSWORD:
            return {
                ...state,
                wasPasswordUpdated: getStateByRequestStatus(action.data) ? true : false,
                isLoading: false
            };
        case Const.RESET_PASSWORD_SEND_IDENTIFICATOR_ON_EMAIL:
            return {
                ...state,
                wasEmailSend: getStateByRequestStatus(action.data),
                isLoading: false
            };
        case Const.RESET_PASSWORD_IS_PASSWORD_VISIBLE:
            return {
                ...state,
                isPasswordVisible: payload
            };
        case Const.RESET_PASSWORD_CLEAR_STATE:
            return {
                isLoading: false,
                isCheckTokenLoading: false,
                isPasswordVisible: false,
                isTokenValid: false,
                wasEmailSend: false,
                wasTokenVerified: false,
                wasPasswordUpdated: false,
                isAutoFocus: true,
                tokenUserName: ''
            };
        case Const.LOGIN_SET_USERNAME:
            return {
                ...state,
                userNameFromLoginPage: payload
            };
        default:
            return state;
    }
}
