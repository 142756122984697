/**
 * getChangedFilters {Function}
 * @description - change filters
 * @return {Object} updatedFilters
 *
 * */
export let getChangedFilters = (filters, payload) => {
    let {label, value} = payload;
    let labelArr = label.split('.');
    let filterName = labelArr[labelArr.length-1];

    return {
        ...filters,
        [filterName]: value
    }
};