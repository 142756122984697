import Const from './../const';
import LoginConst from '../../Login/const';
import ReturnsConst from '../../../pages/Returns/const';
import ResetPasswordConst from './../../ResetPassword/const';
import LocalizationConst from "../../Localization/const";
import MainPropertiesConst from "../../MainProperties/const";
import config from "../../../configs/configs";

/**
* isActive {Function}
* @description - define activate or not an error notification snack bar as we should do this only for specific action type;
* @return isActive {Boolean}
*
* */
let isActive = (action) => {
    let {payload: {actionType=''}, type=''} = action;
    let isActive;

    switch (actionType) {
        case LoginConst.LOGIN_SEND_REQUEST:
        case LocalizationConst.LOCALIZATION_GET_LOGIN_LOCALIZATION_REQUEST:
        case ResetPasswordConst.RESET_PASSWORD_UPDATE_PASSWORD:
        case MainPropertiesConst.SHOP_PROPERTIES_GET_PROPERTIES_REQUEST:
        case ReturnsConst.RETURNS_PRE_APPROVE:
            isActive = true;
            break;
        default:
            isActive = false;
            break;
    }

    switch (type) {
        case Const.ERROR_PAGE_SERVER_ERROR:
            isActive = true;
            break;
        default:
            isActive = false;
            break;
    }



    return isActive;
};

const defaultState = {
    isActive: false,
    text: '',
    actionType: '',
    status: null,
    data: {},
    language: 'en',
    notes: config.notificationDialogs.notes
};

export default function (state = defaultState, action = {}) {
    let {type, payload={}} = action;
    let {statusText, actionType, errorStatus, errorData, language, error={}} = payload;
    switch (type) {
        case Const.ERROR_PAGE_SERVER_ERROR:
        case Const.SERVER_ERROR_NOTIFIER_IS_ACTIVE:
            return {
                ...state,
                isActive: isActive(action),
                text: statusText,
                actionType: actionType,
                status: errorStatus,
                data: errorData,
                language: language
            };
        case Const.SERVER_ERROR_500_502:
            return {
                ...state,
                isActive: true,
                text: `Error ${error.status}`,
                actionType: actionType,
                status: errorStatus,
                data: errorData,
                language: language
            };
        case Const.SERVER_ERROR_NOTIFIER_CLEAR:
            return {
                ...state,
                isActive: false,
                text: '',
                actionType: '',
                data: {},
                language: 'en'
            };
        default:
            return state;
    }
}
