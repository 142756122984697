import Const from './../const';

const defaultState = {
    currentOrderId: null,
    isLoading: false,
    isActive: false,
    isStaticEventLogVisible: true,
    events: []
};

export default function (state = defaultState, action = {}) {
    let {type, payload={}, data} = action;
    switch (type) {
        case Const.RETURN_EVENT_LOG_POPUP_START_FETCH_EVENTS:
            return {
                ...state,
                isLoading: true
            };
        case Const.RETURN_EVENT_LOG_POPUP_TOGGLE_EVENT:
        case Const.RETURN_EVENT_LOG_POPUP_FETCH_EVENTS:
        case Const.RETURN_EVENT_LOG_POPUP_UPDATE_EVENT:
            return {
                ...state,
                currentOrderId: data.getField('requestData').returnOrderId,
                events: data && data.toJSONView ? data.toJSONView().response : [],
                isLoading: false
            };
        case Const.RETURN_EVENT_LOG_POPUP_SHOW:
            return {
                ...state,
                currentOrderId: payload.returnOrderId ? payload.returnOrderId : null,
                isActive: true
            };
        case Const.RETURN_EVENT_LOG_POPUP_HIDE:
            return {
                ...state,
                isActive: false
            };
        case Const.RETURN_EVENT_LOG_STATIC_CLOSE:
            return {
                ...state,
                isStaticEventLogVisible: false
            };
        case Const.RETURN_EVENT_LOG_CLEAR:
            return {
                ...defaultState
            };
        default:
            return state;
    }
}
