import React from "react";
import {Tooltip} from '@material-ui/core';

function TooltipWrapper (props) {
    let {children, className, text, placement='top'} = props;
    return (
        <div className={className ? `${className} b-tooltip-wrapper`: `b-tooltip-wrapper`}>
            {children}
            <Tooltip title={text} placement={placement}>
                <div className={`b-tooltip-wrapper__inner`}></div>
            </Tooltip>
        </div>
    );
}

export default TooltipWrapper;