import GeneralModel from './../../../models/GeneralModel';
import DefaultRequestConstObject from './../defaultRequestConst';
import Const from "./../const";
import {getChangedFilters} from "./returnDataReducerMethods";
import moment from "moment";

let startDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
let endDate =  moment(new Date()).format('YYYY-MM-DD');
let baseCurrency = 'SEK';

const defaultState = {
    data: new GeneralModel({}),
    csvData: null,
    isLoading: false,
    isFoldedOpen: false,
    filters: {
        startDate: startDate,
        endDate: endDate,
        status: "",
        type: "",
        reason: "",
        baseCurrency: baseCurrency,
        market: ""
    },
    DefaultRequestConst: DefaultRequestConstObject,
};


export default function (state = defaultState, action = {}) {
    let {type, data, payload} = action;
    switch (type) {
        case Const.LEFT_SIDE_BAR_IS_FOLDED_OPEN:
            return {
                ...state,
                isFoldedOpen: payload
            };
        case Const.RETURN_DATA_REPORT_START_FETCH_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Const.RETURN_DATA_REPORT_CHANGE_FILTER:
            return {
                ...state,
                filters: getChangedFilters(state.filters, payload)
            };
        case Const.RETURN_DATA_REPORT_FETCH:
            return {
                ...state,
                data: data,
                isLoading: false
            };
        case Const.RETURN_DATA_REPORT_CSV_FETCH:
            return {
                ...state,
                csvData: data,
                isLoading: false
            };
        case Const.RETURN_DATA_REPORT_CLEAR_FILTERS:
            return {
                ...state,
                filters: {
                    startDate: startDate,
                    endDate: endDate,
                    status: "",
                    type: "",
                    reason: "",
                    baseCurrency: baseCurrency,
                    market: ""
                }
            };
        default:
            return state;
    }
}
