import Const from './../const';

const defaultState = {
    merchantFiles: {},
    isHandlingRunning: false,
    imagesToS3Upload: null,
    isSimpleReturnReFetch: 0
};

const setFiles = (stateFiles, files, returnOrderId, orderItemId) => {

    if(!stateFiles[returnOrderId]) {
        stateFiles[returnOrderId] = {
            [orderItemId]: files
        };
    } else if(stateFiles[returnOrderId] && stateFiles[returnOrderId][orderItemId]) {
        stateFiles[returnOrderId] = {
            ...stateFiles[returnOrderId],
            [orderItemId]: [
                ...stateFiles[returnOrderId][orderItemId],
                ...files
            ]
        }
    } else if(stateFiles[returnOrderId] && !stateFiles[returnOrderId][orderItemId]) {
        stateFiles[returnOrderId] = {
            ...stateFiles[returnOrderId],
            [orderItemId]: files
        }
    }

    return Object.assign({}, stateFiles);
};

const replaceFiles = (stateFiles, files, returnOrderId, orderItemId) => {
    if(returnOrderId && orderItemId && stateFiles[returnOrderId]) {
        stateFiles[returnOrderId][orderItemId] = files;
    }

    if(returnOrderId && orderItemId && !stateFiles[returnOrderId]) {
        stateFiles[returnOrderId] = {};
        stateFiles[returnOrderId][orderItemId] = files;
    }

    return Object.assign({}, stateFiles);
};

export default function (state = defaultState, action = {}) {
    let {payload={}, data=null} = action;
    let {files=[], returnOrderId, orderItemId} = payload;
    switch (action.type) {
        case Const.DROP_ZONE_START_FILES_HANDLING:
            return {
                ...state,
                isHandlingRunning: true
            };
        case Const.DROP_ZONE_LOAD_FILES:
            return {
                ...state,
                merchantFiles: setFiles(state.merchantFiles, files, returnOrderId, orderItemId)
            };
        case Const.DROP_ZONE_REPLACE_FILES:
            return {
                ...state,
                merchantFiles: replaceFiles(state.merchantFiles, files, returnOrderId, orderItemId)
            };
        case Const.DROP_ZONE_FILES_WERE_UPDATED:
            return {
                ...state,
                isHandlingRunning: false
            };
        case Const.DROP_ZONE_CLEAR_STATE:
            return {
                ...state,
                merchantFiles: {},
                isHandlingRunning: false,
                imagesToS3Upload: null
            };
        case Const.RETURNS_CONFIRM_UPDATE_ORDER:
        case Const.INBOUND_CONFIRM_UPDATE_ORDER:
            return {
                ...state,
                imagesToS3Upload: data ? data.toJSONView() : null,
                isSimpleReturnReFetch: data ? state.isSimpleReturnReFetch : state.isSimpleReturnReFetch+1
            };
        default:
            return state;
    }
}
