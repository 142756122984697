import Const from './../const';


/*
* close {React Action}
* @description - close success notify window
*
* */
export function close() {
    return dispatch => {
        dispatch({
            type: Const.SERVER_SUCCESS_NOTIFIER_CLEAR,
        });
    }
}
