import React, {Component} from "react";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomTranslation from "../../components/CustomTranslation/CustomTranslation";
import Checkbox from '@material-ui/core/Checkbox';
import {withStyles} from "@material-ui/core/styles";
import {yellow, green, red, grey} from "@material-ui/core/colors";
import {isArray} from 'lodash';
import {connect} from "react-redux";

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        background: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})(props => <div className='b-partial-approval__arrived_indicator_item' {...props} />);

const RedCheckbox = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})(props => <div
    className='b-partial-approval__arrived_indicator_item b-partial-approval__arrived_indicator_item--red' {...props} />);

const YellowCheckbox = withStyles({
    root: {
        color: yellow[400],
        '&$checked': {
            color: yellow[600],
        },
    },
    checked: {},
})(props => <div
    className='b-partial-approval__arrived_indicator_item b-partial-approval__arrived_indicator_item--yellow' {...props} />);

const GreyCheckbox = withStyles({
    root: {
        color: grey[400],
        '&$checked': {
            color: grey[400],
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

const WhiteCheckbox = withStyles({
    root: {
        color: grey,
        '&$checked': {
            color: grey,
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

/**
 * isReturnReconversion {Function}
 *
 * */
const isReturnReconversion = (item) => {
    let isReconversion = false;
    let returnItem = {};

    if (item && item.toJSONView) {
        returnItem = item.toJSONView();
        let {reconversionItems = []} = returnItem;

        if (reconversionItems && reconversionItems.length) {
            isReconversion = true;
        }
    }

    return isReconversion;
};

class PartialApproval extends Component {
    render() {
        let {
            isReturnItemTotallyHandled = false,
            isReturnsPartiallyHandled = false,
            isAllItemsRejectedRemoved = false,
            toggleApproveRefundExchange = () => {
            },
            item = {
                getField: () => {
                }
            },
            className = '',
            refund={},
        } = this.props;
        let itemsAmount = isArray(item.getField('items')) ? item.getField('items').length : 0;
        let isReconversion = isReturnReconversion(item);
        let currentRefund = refund[item.getField('returnOrderId')];


        return (
            <div className={className ? `b-partial-approval ${className}` : `b-partial-approval`}>
                <FormGroup row>
                    <ul className="b-partial-approval__list b-flex">
                        <li className="b-partial-approval__list_item">
                            <FormControlLabel
                                control={
                                    isReturnItemTotallyHandled
                                        ?
                                        <GreenCheckbox
                                            checked={true}
                                            onChange={() => {
                                            }}
                                            value=''
                                        />
                                        :
                                        isReturnsPartiallyHandled
                                            ?
                                            <YellowCheckbox
                                                checked={true}
                                                onChange={() => {
                                                }}
                                                value=''
                                            />
                                            :
                                            <RedCheckbox
                                                checked={true}
                                                onChange={() => {
                                                }}
                                                value=''
                                            />
                                }
                                className='b-partial-approval__list_label'
                                label={
                                    itemsAmount > 1 ?
                                        <CustomTranslation id='return.order.details.arrived.items'/>
                                        :
                                        <>
                                            <CustomTranslation id='return.order.details.arrived.item'/><span
                                            className='b-partial-approval__not_visible'>s</span>
                                        </>
                                }
                            />
                        </li>
                        {
                            isAllItemsRejectedRemoved
                                ?
                                ''
                                :
                                <li className="b-partial-approval__list_item">
                                    <FormControlLabel
                                        control={
                                            item.getField('isApprovedFromTheServer')
                                                ?
                                                <GreyCheckbox
                                                    checked={item.getField('isApprovedFromTheServer')}
                                                    onChange={() => {
                                                        toggleApproveRefundExchange()
                                                    }}
                                                    value=''
                                                    disabled={item.getField('isApprovedFromTheServer')}
                                                />
                                                :
                                                <WhiteCheckbox
                                                    checked={item.getField('approved')}
                                                    onChange={() => {
                                                        toggleApproveRefundExchange()
                                                    }}
                                                    value=''
                                                    disabled={item.getField('isApprovedFromTheServer')}
                                                />
                                        }
                                        className='b-partial-approval__list_label'
                                        label={
                                            isReconversion && currentRefund
                                                ?
                                                <CustomTranslation
                                                    id='return.order.details.approve'
                                                    renderAsString={true}
                                                />
                                                :
                                                isReconversion
                                                    ?
                                                    // approve exchange
                                                    <CustomTranslation
                                                        id='return.order.details.approve.exchange'
                                                        renderAsString={true}
                                                    />
                                                    :
                                                    // approve refund
                                                    <CustomTranslation
                                                        id='return.order.details.approve.refund'
                                                        renderAsString={true}
                                                    />

                                        }
                                    />
                                </li>
                        }

                    </ul>
                </FormGroup>
            </div>
        );
    }
}

function mapStateToProps({returnDescriptionState}) {
    return {
        ...returnDescriptionState
    };
}

export default connect(mapStateToProps, null)(PartialApproval)