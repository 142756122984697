import Const from './../const';

/*
* requestStart {Function}
* @return Object in React Action format before request start
*
* */
let requestStart = () =>{
    return {
        type: Const.CUSTOMERS_START_FETCH_REQUEST
    }
};

/*
* fetchCustomers {React Action}
* @description - send request to the server to get orders data
*
* */
export function fetchCustomers(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.CUSTOMERS_FETCH_REQUEST,
            ...requestData
        });
    }
}

