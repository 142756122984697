import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import { getTranslate } from 'react-localize-redux';
import Grid from "@material-ui/core/Grid";
import Refresh from "@material-ui/icons/Refresh";
import CustomTranslation from "./../CustomTranslation/CustomTranslation";
import CircularProgress from '@material-ui/core/CircularProgress';
import {bindActionCreators} from "redux";
import {
    getDashboardMetrics,
    setFilteringToOrderPage
} from "./actions/dashboardsMetricsActions";

class DashboardMetrics extends Component {

    setFilteringToOrderPage = (filters) => {
        if(!filters) {
            return;
        }
        let {history} = this.props;
        this.props.setFilteringToOrderPage(filters);
        history.push('/returns');
    };

    getModificatorByTitleKey = (titleKey='') => {
        let titleKeyArr = titleKey.split('.');

        return titleKeyArr[titleKeyArr.length-1].toLowerCase();
    };

    getMetrics = () => {
        let {type=''} = this.props;
        let routeName = type === 'Inbound' ? 'getInboundMetrics' : 'getDashboardMetrics';
        this.props.getDashboardMetrics({
            route: routeName,
            requestData: {}
        })
    };

    refreshMetrics = () => {
        this.getMetrics();
    };

    componentDidMount() {
        this.getMetrics();
    }

    render (){

        let {classes, wereItemsFound, data, isMetricsLoading} = this.props;
        let metricsItems = data.getField('response');

        return (
            <div className={wereItemsFound ? 'b-dashboard-metrics__metrics b-dashboard-metrics__hided' : 'b-dashboard-metrics__metrics b-dashboard-metrics__visible'}>
                <Grid container className="b-dashboard-metrics__grid-row" spacing={1}>
                    {
                        metricsItems && metricsItems.length
                            ?
                            metricsItems.map((metricsItem, i)=>{
                                let {additionalInfo} = metricsItem;
                                return (
                                    <Grid key={i} item xs={12} lg={3} className="b-dashboard-metrics__block">
                                        <div className="b-dashboard-metrics__wrapper">
                                            <div className='b-dashboard-metrics__metrics_head_control' onClick={()=>{this.refreshMetrics()}}>
                                                {
                                                    !isMetricsLoading
                                                    ?
                                                    <Refresh />
                                                    :
                                                    <CircularProgress color="secondary" className="b-translation__loader" />
                                                }

                                            </div>
                                            <div className={`b-dashboard-metrics__metrics_item b-dashboard-metrics__metrics_item--${this.getModificatorByTitleKey(metricsItem.titleKey)}`}
                                                 onClick={()=>{this.setFilteringToOrderPage(metricsItem.filters)}}>
                                                <div className="b-dashboard-metrics__metrics_item_header">
                                                    <div className="b-dashboard-metrics__metrics_item_header_title">
                                                        <CustomTranslation id={metricsItem.titleKey} />
                                                    </div>
                                                </div>
                                                <div className="b-dashboard-metrics__metrics_item_content">
                                                    <div className="b-dashboard-metrics__metrics_item_content_value">
                                                        {metricsItem.itemsAmount}
                                                    </div>
                                                </div>
                                                <div className="b-dashboard-metrics__metrics_item_detail">
                                                    <div className="b-dashboard-metrics__metrics_additional">
                                                        {
                                                            additionalInfo && additionalInfo.additionalTitleKey
                                                                ?
                                                                <CustomTranslation
                                                                    id={additionalInfo.additionalTitleKey}/>
                                                                :
                                                                ''
                                                        }

                                                    </div>
                                                    {
                                                        additionalInfo && additionalInfo.percents
                                                            ?
                                                            <div className="b-dashboard-metrics__metrics_percents">
                                                                {additionalInfo.percents}
                                                            </div>
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })
                            :
                            ''
                    }

                </Grid>
            </div>

        )




    };
}

function mapStateToProps(state) {
    return {
        ...state.dashboardsMetricsState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            getDashboardMetrics,
            setFilteringToOrderPage
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(DashboardMetrics));