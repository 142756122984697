import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {setCurrentPageTitle} from "./../../components/Header/actions/headerActions";
import PageContent from "../../containers/PageContent/PageContent";
import {getTranslate} from "react-localize-redux";
import {is2F, update2F} from './actions/securityActions';
import Switch from '@material-ui/core/Switch';
import CustomTranslation from "./../../components/CustomTranslation/CustomTranslation";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {getDataFromSS, getBooleanShopProperty} from "../../utilites";
import CustomButton from "../../containers/CustomButton/CustomButton";
import {Redirect} from "react-router";

class Security extends Component {

    update2F = (event, isGenerateNewQa = false) => {
        this.props.update2F({
            route: 'update2f',
            requestData: {
                use2FA: isGenerateNewQa ? true : event.target.checked,
                username: getDataFromSS('login').manager.username
            }
        })
    };

    check2f = (requestData = null) => {
        this.props.is2F({
            route: 'check2f',
            requestData: requestData ? requestData : {
                username: getDataFromSS('login').manager.username
            }
        });
    };

    componentDidMount() {
        let {pageName} = this.props;
        this.props.setCurrentPageTitle(pageName);
        this.check2f()
    }

    render() {
        let {isFoldedOpen, is2fChecked, translate, qrCodeImg, isLoading} = this.props;

        return (
            getBooleanShopProperty(this.props, 'security.page.enabled')
                ?
                <div className='b-app__page b-security-page'>
                    <PageContent isFoldedOpen={isFoldedOpen} classNameCustom='b-page-content--security'>
                        <div className="b-app__page_body">
                            <div className="b-app__body_item">
                                <div className="b-security-page__headline">
                                    <h1 className={`b-app__page_content_title b-app__page_content_title--center_align b-security-page__additional_header`}>
                                        <CustomTranslation id={'security.list.2FA.title'}/>
                                    </h1>
                                </div>
                                <div className="b-security-page__description">
                                    <CustomTranslation id={'security.list.2FA.description'}/>
                                </div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={is2fChecked}
                                            onChange={this.update2F}
                                            value={is2fChecked}
                                            color="primary"
                                        />
                                    }
                                    label={translate('security.list.2FA.switchDescription')}
                                />
                                {
                                    is2fChecked
                                        ?
                                        <div className="b-security-page__barCode_btn">
                                            <CustomButton
                                                type='button'
                                                variant='contained'
                                                fullWidth={false}
                                                color='primary'
                                                aria-label={<CustomTranslation id='security.list.2FA.generationBtn'/>}
                                                loading={isLoading}
                                                name={<CustomTranslation id='security.list.2FA.generationBtn'/>}
                                                onClick={(e) => {
                                                    this.update2F(e, true)
                                                }}
                                            />
                                        </div>
                                        :
                                        ''
                                }
                                {
                                    is2fChecked && qrCodeImg
                                        ?
                                        <div className="b-security-page__barCode">
                                            <img className="b-security-page__img" src={qrCodeImg}
                                                 alt={translate('security.list.2FA.title')}/>
                                        </div>
                                        :
                                        ''
                                }
                            </div>
                        </div>
                    </PageContent>
                </div>
                :
                <Redirect to="/"/>

        )
    };
}

function mapStateToProps(state) {
    return {
        ...state.securityPageState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            setCurrentPageTitle,
            is2F,
            update2F
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(Security);