import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import ReactSelectMaterialUi from "react-select-material-ui";
import {getTranslate} from "react-localize-redux";
import {find} from 'lodash';
import {TextField} from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import {Cancel} from "@material-ui/icons";
import {setReduceProductValue, sendUpdatedProductValues, clearReducedData, setReducedValuesToDefault} from "./actions/fixedPercentageSelectorReducerActions";
import CustomTranslation from "../CustomTranslation/CustomTranslation";


function isNumberKey(evt) {
    let decimal = /\d+(\.\d+)?$/;

    if(evt.target.value.match(decimal)) {
        return true;
    }

    return true;
}

class FixedPercentageSelector extends Component {

    constructor(props) {
        super(props);
        this.select = React.createRef();
        this.diminishedRef = React.createRef();
        this.percentageRef = React.createRef();
        this.state = {
            inputValue: '',
        }
    }

    /**
     * clearValue {clearValue}
     * @description - set reduced values to default
     *
     * */
    clearValue = (returnsProductId, returnOrderId) => {
        let {
            defaultProductPrice,
            diminishedPrice
        } = this.props;
        let value = defaultProductPrice * ( 100 - 0 ) / 100;
        this.setState({
            inputValue: value,
        },()=>{
            this.diminishedRef.current.value = value;
            this.percentageRef.current.value = '';
            this.props.setReducedValuesToDefault(returnsProductId, returnOrderId, defaultProductPrice, diminishedPrice)
        })
    };

    /**
     * getSelectorPercentage {Function}
     * @description - calculate percentage based on default product price and value entered in reduced percentage field
     * @return {Number}
     *
     * */
    getSelectorPercentage = (defaultProductPrice, numericInputValue) => {
        return Math.round((defaultProductPrice - numericInputValue) / defaultProductPrice * 100);
    };

    /**
     * changeReducedValue {Function}
     * @description - handler fires on change both (reduce value and new product value) inputs
     *
     * */
    changeReducedValue = (value, returnsProductId, returnOrderId, caseHandler) => {
        let {
            defaultProductPrice
        } = this.props;
        let numericValue = value || value == 0 ? parseFloat(value) : '';
        switch (caseHandler) {
            case "percentage":
                let numericPercentage = numericValue < 101 ? numericValue === 100 ? 100 : defaultProductPrice * ( 100 - numericValue ) / 100 : '';
                this.setState({
                    inputValue: numericPercentage ? numericPercentage : ''
                });
                break;
            case "diminishedPrice":
                this.setState({
                    inputValue: numericValue || numericValue === 0 ? parseFloat(value) : ''
                });
                break;
            default:
                console.log('changeReducedValue failed');
                break;
        }
    };

    /**
     * getValue {Function}
     * @description - get appropriate value to the inputs
     * @return {number}
     *
     * */
    getValue = (key) => {
        let {
            defaultProductPrice
        } = this.props;
        let {inputValue} = this.state;

        let numericInputValue = inputValue || inputValue == 0 ? parseFloat(inputValue) : '';
        let numericPercentage = numericInputValue ? this.getSelectorPercentage(defaultProductPrice, numericInputValue) : '';
        let percentageValueFromRef = this.percentageRef.current ? this.percentageRef.current.value : '';

        switch(key) {
            case "diminishedPrice":
                return percentageValueFromRef==100 && numericPercentage !== 100 ? 0 : (numericInputValue && numericInputValue < defaultProductPrice+1 || numericInputValue === 0) ? numericInputValue : '';
                break;
            case "percentage":
                return  percentageValueFromRef == 100 ? 100 : numericPercentage && numericPercentage < 100 ? numericPercentage : 0;
                break;
            default:
                console.log('getValue failed');
                break
        }
    };

    /**
     * isDropDownVisible {Function}
     * @description - define are options for dropdown available or not
     * @return {Boolean}
     *
     * */
    isDropDownVisible = (options) => {
        return options && options.length;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {returnOrderId, wasReducedValueCleared, returnsProductId, defaultProductPrice, diminishedPrice} = this.props;
        let {inputValue} = this.state;

        if (prevProps.returnOrderId !== returnOrderId || prevProps.wasReducedValueCleared !== wasReducedValueCleared) {
            if (this.select && this.select.current) {
                this.select.current.setState({
                    selectedOption: null,
                });
            }
        }

        if(prevState.inputValue !== inputValue && (inputValue || inputValue === 0)) {
            this.props.setReduceProductValue(this.getValue("diminishedPrice"), returnsProductId, returnOrderId, defaultProductPrice, this.getValue("percentage"), diminishedPrice)
        }

        if(prevProps.diminishedPrice !== diminishedPrice) {
            this.changeReducedValue(diminishedPrice, returnsProductId, returnOrderId,"diminishedPrice")
        }

    }

    componentWillUnmount() {
        this.props.clearReducedData();
    }

    render() {
        let {
            key=null,
            returnsProductId=null,
            returnOrderId=null,
            isLabeled,
            isDisabled,
            defaultProductPrice,
            isInbound,
            currency
        } = this.props;
        let shopSettings = this.props.mainSettings.toJSONView();
        let settingsOptions = find(shopSettings.response, (setting) => {
            return setting.name === 'return.returnFeeFixed.percentages'
        });
        let settingsOptionsItem = settingsOptions ? JSON.parse(settingsOptions.value) : null;
        let optionsForDropDown = [];
        if (settingsOptions) {
            optionsForDropDown = settingsOptionsItem.map((item) => {
                return {
                    value: item,
                    label: `${item} %`
                }
            });
        }
        return (
            <div className={isInbound ? "b-fixed-percentage-selector b-fixed-percentage-selector--inbound b-flex" : "b-fixed-percentage-selector b-flex"}>
                <div className="b-fixed-percentage-selector__default_product_price">
                    {isLabeled ? <label className="b-fixed-percentage-selector__default_product_price_label">
                        <CustomTranslation id='return.order.details.price'/>
                    </label>: ''}
                    {`${defaultProductPrice} ${currency}`}
                </div>
                <div className='b-fixed-percentage-selector__reduce_sign'> - </div>
                <div className="b-fixed-percentage-selector__hybrid">
                    <div className="b-fixed-percentage-selector__control_wrapper">
                        {isLabeled ? <label className={isDisabled ? "b-fixed-percentage-selector__input_label b-fixed-percentage-selector__input_label--disabled":"b-fixed-percentage-selector__input_label"}>
                            <CustomTranslation id="return.order.details.return.product.reduce.value" />
                        </label> : ''}

                        {
                            this.getValue("diminishedPrice") === 0 && isDisabled
                                ?
                                <TextField
                                    className={
                                        this.isDropDownVisible(optionsForDropDown) ?
                                            isDisabled ? "b-fixed-percentage-selector__control_input b-fixed-percentage-selector__control_input--disabled" : "b-fixed-percentage-selector__control_input" :
                                            isDisabled ? "b-fixed-percentage-selector__control_input b-fixed-percentage-selector__control_input--disabled b-fixed-percentage-selector__control_input--no_dropdown" : "b-fixed-percentage-selector__control_input  b-fixed-percentage-selector__control_input--no_dropdown"}
                                    value={100}
                                    disabled={isDisabled}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                {
                                                    this.getValue("percentage") && !isDisabled
                                                        ?
                                                        <Cancel className="b-fixed-fee-selector__clear_control"
                                                                fontSize="small"
                                                                onClick={() => {
                                                                    this.clearValue(returnsProductId, returnOrderId)
                                                                    //this.changeReducedValue(0, returnsProductId, returnOrderId, "percentage")
                                                                }}/>
                                                        :
                                                        ''
                                                }
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                :
                                <TextField
                                    className={
                                        this.isDropDownVisible(optionsForDropDown) ?
                                            isDisabled ? "b-fixed-percentage-selector__control_input b-fixed-percentage-selector__control_input--disabled" : "b-fixed-percentage-selector__control_input" :
                                            isDisabled ? "b-fixed-percentage-selector__control_input b-fixed-percentage-selector__control_input--disabled b-fixed-percentage-selector__control_input--no_dropdown" : "b-fixed-percentage-selector__control_input  b-fixed-percentage-selector__control_input--no_dropdown"}
                                    value={this.getValue("percentage")}
                                    inputRef={this.percentageRef}
                                    disabled={isDisabled}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                {
                                                    this.getValue("percentage") && !isDisabled
                                                        ?
                                                        <Cancel className="b-fixed-fee-selector__clear_control"
                                                                fontSize="small"
                                                                onClick={() => {
                                                                    this.clearValue(returnsProductId, returnOrderId)
                                                                    //this.changeReducedValue(0, returnsProductId, returnOrderId, "percentage")
                                                                }}/>
                                                        :
                                                        ''
                                                }
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={(e) => {
                                        if (isNumberKey(e)) {
                                            this.changeReducedValue(e.target.value, returnsProductId, returnOrderId, "percentage");
                                        }
                                    }}
                                />
                        }


                        {
                            this.isDropDownVisible(optionsForDropDown)
                            ?
                            <ReactSelectMaterialUi
                                className={isDisabled ? "b-fixed-percentage-selector__control b-fixed-percentage-selector__control--disabled" : "b-fixed-percentage-selector__control"}
                                key={key}
                                ref={this.select}
                                options={optionsForDropDown}
                                disabled={isDisabled}
                                onChange={(percentageValue) => {
                                    this.changeReducedValue(percentageValue, returnsProductId, returnOrderId, "percentage")
                                }}
                            /> :
                        ''}
                    </div>
                </div>
                <div className='b-fixed-percentage-selector__equal_sign'> = </div>
                <div className="b-fixed-percentage-selector__result_wrapper">
                    {isLabeled ? <div className={isDisabled ? "b-fixed-percentage-selector__result_label b-fixed-percentage-selector__result_label--disabled" : "b-fixed-percentage-selector__result_label"}>
                        <CustomTranslation id="return.order.details.return.product.new.value" />
                    </div> : '' }
                    <input
                        className={"b-fixed-percentage-selector__result_diminished_price"}
                        type="number"
                        step="0.1"
                        max={defaultProductPrice}
                        value={this.getValue("diminishedPrice")}
                        ref={this.diminishedRef}
                        disabled={isDisabled}
                        onChange={(e) => {
                            let value = e.target.value;
                            if(value == '' || value == 0 || value > 0 && parseFloat(value) < defaultProductPrice+0.1) {
                                this.changeReducedValue(value, returnsProductId, returnOrderId, "diminishedPrice");
                            }
                        }}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.mainPropertiesState,
        ...state.fixedPercentageSelectorState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            setReduceProductValue,
            sendUpdatedProductValues,
            clearReducedData,
            setReducedValuesToDefault
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(FixedPercentageSelector);