import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import  {
    fetchReturns,
    setReturnOrderFilter,
    setTableCurrentPage,
    showBeforeSwitchReturnPopup,
    clearFixedFeeControl
} from "./actions/returnsTableActions";
import CustomTranslation from "./../../components/CustomTranslation/CustomTranslation";
import {
    toFixedCurrency,
    formatDate,
    getAppropriateMarket,
    getFiltersAppropriateMarkets,
    isNeedToShowMarketLanguage,
    isNeedToShowMarket,
    getTableHeight
} from './../../utilites/index';
import PageFiltersItems from "../../components/PageFiltersItems/PageFiltersItems";
import {isEmpty, find} from 'lodash';
import CustomTable from './../../components/CustomTable/CustomTable';
import Link from "@material-ui/core/Link";
import Search from "../../components/Search/Search";
import defaultRequestConst from './defaultRequestConst';
import WarningIcon from '@material-ui/icons/Warning';
import {withStyles} from "@material-ui/core";
import {withRouter} from "react-router";
import {getTranslate} from "react-localize-redux";
import Clipboard from 'react-clipboard.js';
import copySrc from "./../../images/returns/copy-to-clipboard-icon.png";
import TooltipWrapper from "../../containers/TooltipWrapper/TooltipWrapper";

const tableStyles = theme => {
    return ({
        returnStatusStyles: {
            color: theme.palette.secondary.main,
        },
    })
};

class ReturnsTable extends Component {

    constructor(props) {
        super(props);
        this.wrapperEl = React.createRef();
        this.state = {
            expanded: true,
            currentPage: 0,
            pageSizeOption: defaultRequestConst.limit,
            searchValue:'',
            copies: {}
        };
        this.searchTimeout = null;
        this.clipBoardTimeout = null;
    }

    /**
     * onSearchField {Function}
     * @description - callback on change search field. Does specific action
     *
     * */
    onSearchField = (event) => {
        let searchValue = event.currentTarget.value;
        let { DefaultRequestConst } = this.props;

        if(this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = setTimeout(()=>{
            if(searchValue && searchValue.length > 2) {

                this.setState({
                    searchValue: searchValue
                },()=> {
                    this.fetchReturns({
                        ...DefaultRequestConst,
                        search: searchValue
                    });
                });

            }
        }, 200);

        if(!searchValue) {
            this.setState({
                searchValue: ''
            },()=> {
                this.fetchReturns({
                    ...DefaultRequestConst,
                    search: ''
                });
            });

        }
    };

    /**
     * getStatusColor {Function}
     * @description - get appropriate color fo returns item status
     * @return {String} - color hex
     * */
    getStatusColor = (status) => {
        let {theme :{ palette: {brand: {returnStatusColors}}} } = this.props;
        return returnStatusColors[status] || 'none';
    };

    /**
     * getShipmentStatusColor {Function}
     * @description - get appropriate color fo returns item shipment status
     * @return {String} - color hex
     * */
    getShipmentStatusColor = (status) => {
        let {theme :{ palette: {brand: {returnShipmentStatusColors}}} } = this.props;
        return returnShipmentStatusColors[status] || 'none';
    };

    /**
     * clickOnRow {Function}
     * @description - action fires when click on specific table's row
     *
     * */
    clickOnRow = (id) => {
        let {history} = this.props;
        this.setState({
            expanded: false
        }, () => {
            //this.props.clearFixedFeeControl();
            history.push('/returns/:returnId'.replace(':returnId', id));
        })
    };

    /**
     * handleTableRowClick {Function}
     * @description - function return object with action which fires when click on specific table's row
     * @return {Object}
     *
     * */
    handleTableRowClick = (state, rowInfo={}) => {
        let {match: {params: {returnId}}, isReadyForSubmit, wasReturnItemLoaded} = this.props;
        let {row={}} = rowInfo;
        let {_original={}} = row;
        let {returnOrderId} = _original;
        return {
            onClick: (e) => {
                /* TODO: nee to restore functionality with dialog when switch between returns in case when previous was changed  */
                if(e.target.getAttribute('class') !== "b-returns-table__clip_to_buffer_ctrl") {
                    if(isReadyForSubmit && wasReturnItemLoaded) {
                        this.props.showBeforeSwitchReturnPopup(returnOrderId);
                        this.props.clearFixedFeeControl();
                    } else {
                        this.clickOnRow(returnOrderId)
                    }
                }
            },
            style: {
                background: returnOrderId === parseInt(returnId) ? '#5C616F' : '',
                color: returnOrderId === parseInt(returnId) ? 'white' : ''
            },
        }
    };

    /**
     * onChangeCurrentPageAction {Function}
     * @description - action calls on page change using table next/previous controls
     *
     * */
    onChangeCurrentPageAction = (nextCurrentPage) => {
        let {DefaultRequestConst, filters} = this.props;
        let {pageSizeOption} = this.state;
        let updatedFilters = {};

        if(filters.shopName) {
            updatedFilters.shopId = filters.shopName
        }

        /*if(filters.remarksFilter) {
            updatedFilters[filters.remarksFilter] = true
        }

        if(filters.returnType) {
            updatedFilters[filters.returnType] = true
        }*/

        // TODO: Temporary decision till server side will handle date parameters
        let combineFilters = {
            ...DefaultRequestConst,
            ...filters,
            ...updatedFilters,
            limit: pageSizeOption,
            page: nextCurrentPage
        };

        //delete combineFilters.remarksFilter;
        delete combineFilters.startDate;
        delete combineFilters.endDate;

        this.setState({
            currentPage: nextCurrentPage
        }, ()=>{
            this.fetchReturns(combineFilters)
        })

    };

    /**
     * onPageSizeChange {Function}
     * @description - action calls when page size was changed using table page size dropdown
     *
     * */
    onPageSizeChange = (pageSize) => {
        let {DefaultRequestConst} = this.props;

        this.setState({
            pageSizeOption: pageSize
        }, () => {
            this.fetchReturns({
                ...DefaultRequestConst,
                limit: pageSize
            })
        });

    };

    /**
     * getMarket {Function}
     * @description - receives shopId as parameter and generate an appropriate market name based on main shop configurations
     * @return {String}
     *
     * */
    getMarket = (shopId) => {
        let {mainPropertiesState} = this.props;
        return getAppropriateMarket(mainPropertiesState, shopId, isNeedToShowMarket(mainPropertiesState), isNeedToShowMarketLanguage(mainPropertiesState));
    };

    /**
     * getTableData {Function}
     * @description - return data array to render in returns table
     * @return {Array}
     *
     * */
    getTableData = (data) => {
        return data && data.toJSONView && isEmpty(data.toJSONView()) ? [] : data && data.getField ? data.getField('data') : []
    };

    /**
     * getRowCurrency {Function}
     * @description - take a row data as parameter and return currency attribute value
     * @return {String}
     *
     * */
    getRowCurrency = (row) => {
        return row.currency || row._original.currency;
    };

    /**
     * getShipmentStatuses {Function}
     * @description - take a row data as parameter and return currency attribute value
     * @return {Array}
     *
     * */
    getShipmentStatuses = (shipments=[], classes) => {
        return shipments.map((shipment, key)=>{
            let shipmentStatus = shipment.status;
            return (
                <span key={key} className={`b-page__statuses_table_item ${classes.returnStatusStyles}`} style={{backgroundColor: this.getShipmentStatusColor(shipmentStatus)}}>
                    <CustomTranslation id={`return.order.shipmentStatuses.${shipmentStatus}`} />
                </span>
            )
        })
    };

    /**
     * getCopyToClipBoardComponent {Function}
     * @description - render the component to copy to clipboard
     * @return {React component}
     *
     * */
    getCopyToClipBoardComponent = (textToCopy, returnOrderId=false) => {
        let clipboardId = returnOrderId ? returnOrderId +' '+textToCopy :  textToCopy;
        this[clipboardId] = React.createRef();
        return (
            <span className="b-returns-table__clip_to_buffer_tooltip_wrapper">
                <Clipboard
                    style={{backgroundImage: `url(${copySrc})`}}
                    className="b-returns-table__clip_to_buffer_ctrl"
                    data-clipboard-text={textToCopy}
                    onSuccess={()=>{
                        if(this.clipBoardTimeout) {
                            clearTimeout(this.clipBoardTimeout)
                        }

                        if(this[clipboardId] && this[clipboardId].current) {
                            this[clipboardId].current.setAttribute('class', 'b-returns-table__clip_to_buffer_tooltip active');
                            this.clipBoardTimeout = setTimeout(()=>{
                                this[clipboardId].current.setAttribute('class', 'b-returns-table__clip_to_buffer_tooltip');
                            }, 500)
                        }
                    }}
                >
                </Clipboard>
                <div ref={this[clipboardId]} className="b-returns-table__clip_to_buffer_tooltip">
                    <CustomTranslation id='returns.table.copyToClipboard.tooltip.text' renderAsString={true} />
                </div>
            </span>
        )
    };

    /**
     * getReturnId {Function}
     * @description - generate order id data based on mismatch value
     * @return {HTML elements}
     *
     * */
    getReturnId = (mismatch, returnId, mismatchDescription='') => {
        return (
            <>
                {
                    mismatch ?
                        <span className="b-returns-page__mismatch_warning">
                            <TooltipWrapper text={mismatchDescription} className="b-returns-page__in_table_tooltip" placement="top">
                                 <WarningIcon className="b-returns-page__mismatch_warning_icon" fontSize="small"/>
                            </TooltipWrapper>
                            {returnId}
                            {this.getCopyToClipBoardComponent(returnId)}
                        </span>
                        :
                        <>
                            <span>{returnId}</span>
                            {this.getCopyToClipBoardComponent(returnId)}
                        </>
                }
            </>
        )
    };

    /**
     * renderTable {Function}
     * @description - render table using data from server side
     * @return {React Component}
     *
     * */
    renderTable = () => {
        let {data, classes, translate, isLoading, isExternalIdToShow=false} =  this.props;
        let {currentPage, pageSizeOption} = this.state;
        let prefix = 'return.order.list.columns';
        let tableData = this.getTableData(data);
        let totalReturnsAmount = data && data.getField && data.getField('paging') ? data.getField('paging').total : 1;
        let totalPages = Math.ceil(totalReturnsAmount/pageSizeOption);
        const columns = [{
            Header: <CustomTranslation id={`return.order.list.columns.rma.number`} /> ,
            id: 'returnOrderId',
            accessor:(row) => row.returnOrderId,
            Cell: ({row}) => {
                return (
                    <span className="b-returns-table__clip_to_buffer_wrapper">
                        {this.getReturnId(row._original.mismatch, row.returnOrderId, row._original.mismatchDescription)}
                    </span>
                )
            }
        }, {
            Header: `${translate('order.list.columns.extId')} #`,
            id: isExternalIdToShow ? 'orderExternalSequentialId' ? 'orderExternalSequentialId' : 'orderExternalId' : 'orderExternalId',
            accessor:(row) => { return isExternalIdToShow ? row.orderExternalSequentialId ? row.orderExternalSequentialId : row.orderExternalId : row.orderExternalId },
            Cell: ({row}) => {
                let {orderExternalId, orderExternalSequentialId} = row._original;
                let id = isExternalIdToShow ? orderExternalSequentialId ? orderExternalSequentialId : orderExternalId : orderExternalId;
                return (
                    <span className="b-returns-table__clip_to_buffer_wrapper">
                        <Link  color="primary" href={`#/orders/${id}`}>
                            {id}
                        </Link>
                        {this.getCopyToClipBoardComponent(id, row._original.returnOrderId)}
                    </span>
                )
            },
        }, {
            Header: <CustomTranslation id={`${prefix}.date`} />,
            accessor: 'createDate',
            Cell: ({row}) => { return formatDate(row.createDate)},
        },{
            Header: <CustomTranslation id={`${prefix}.customer`} />,
            id: 'customer',
            accessor:(row) => { return row.customer.firstName },
            Cell: ({row})=>{
                let {_original: {customer}} = row;
                return `${customer.firstName} ${customer.lastName} (${customer.email})`
            },
            width: 240,
            maxWidth: 200
        }, {
            Header: <CustomTranslation id={`${prefix}.status`} />,
            accessor: 'status',
            Cell: ({row})=>{
                return (
                    <span className={`b-page__statuses_table_item ${classes.returnStatusStyles}`} style={{backgroundColor: this.getStatusColor(row.status)}}>
                        <CustomTranslation id={`return.order.statuses.${row.status}`} />
                    </span>
                )
            },
        }, {
            Header: <CustomTranslation id={`${prefix}.shipmentStatus`} />,
            id: 'shipments',
            accessor: (row) => { return row.shipments},
            Cell: ({row})=>{
                return (
                    this.getShipmentStatuses(row.shipments, classes)
                )
            },
        },{
            Header: <CustomTranslation id={`${prefix}.return.total`} />,
            accessor: 'returnTotal',
            Cell: ({row})=> {
                return toFixedCurrency(row.returnTotal, this.getRowCurrency(row))
            },
        },{
            Header: <CustomTranslation id={`${prefix}.reconversion`} />,
            accessor: 'reconversion',
            Cell: ({row})=> {
                return toFixedCurrency(row.reconversion, this.getRowCurrency(row))
            },
        },{
            Header: <CustomTranslation id={`${prefix}.refund`} />,
            accessor: 'refund',
            Cell: ({row})=> {
                return toFixedCurrency(row.refund, this.getRowCurrency(row), 2, false)
            },
        },{
            Header: <CustomTranslation id={`${prefix}.shopName`} />,
            id: 'shopId',
            accessor: (row) => { return row.shopId },
            Cell: ({row})=> {
                return this.getMarket(row.shopId || row._original.shopId)
            },
        }];

        return <CustomTable
            className={'-highlight -striped'}
            data={tableData}
            columns={columns}
            defaultSorted={[
                {
                    id: "returnOrderId",
                    desc: true
                }
            ]}
            loading={isLoading}
            isLoading={isLoading}
            getTrProps={this.handleTableRowClick}
            defaultPageSize={pageSizeOption}
            showPagination={false}
            pageSize={tableData && tableData.length ? tableData.length : 0}
            style={{
                // This will force the table body to overflow and scroll, since there is not enough room
                height: getTableHeight(tableData)
            }}
            isPaginationActive={true}
            currentPage={currentPage}
            pageSizeOption={totalReturnsAmount}
            totalPages={totalPages}
            prevAction={()=>{this.goToAppropriateTablePage(true)}}
            nextAction={()=>{this.goToAppropriateTablePage()}}
            onPageSizeChangeAction={this.onPageSizeChange}
        />
    };

    goToAppropriateTablePage = (isPrev=false) => {
        let {currentPage} = this.state;

        if(isPrev && currentPage !== 0) {
            this.onChangeCurrentPageAction(currentPage-1)
        } else {
            this.onChangeCurrentPageAction(currentPage+1)
        }

    };

    /**
     * getSearchControlComponent {Function}
     * @description - method to get search control for returns page
     * @return {React Component}
     *
     * */
    getSearchControlComponent = () => {
        let {translate} = this.props;
        let {searchValue} = this.state;

        return (
            <Search
                placeholder={translate('search')}
                onSubmitAction={this.onSubmit}
                onValidAction={this.enableButton}
                onInvalidAction={this.disableButton}
                onSearchFieldAction={this.onSearchField}
                onClearSearchAction={this.clearSearch}
                isClearSearchControlPresent={searchValue}
                styleClass='b-search--returns'
                styleClassCleanCtr='b-returns-page__clear_search_ctrl'
            />
        )
    };

    /**
     * clearSearch {Function}
     * @description - clearing the search field
     *
     * */
    clearSearch = () => {
        let {DefaultRequestConst} = this.props;

        this.setState({
            searchValue: ''
        },()=>{
            this.fetchReturns({
                ...DefaultRequestConst,
                search: ''
            });
        });

    };

    /**
     * fetchReturns {Function}
     * @description - send request to get Returns
     *
     * */
    fetchReturns = (requestData=null) => {
        let {DefaultRequestConst} = this.props;
        let dataForRequest = requestData ? requestData : {
            ...DefaultRequestConst
        };

        this.props.fetchReturns({
            route: 'getReturns',
            isWithGetParams: true,
            requestData: dataForRequest
        });
    };

    /**
     * changeFilterAction {Function}
     * @description - change filter action value
     *
     * */
    changeFilterAction = (value, item) => {
        let {label=''} = item;
        this.setState({
            expanded: true,
            currentPage: 0,
            pageSizeOption: defaultRequestConst.limit,
            searchValue:''
        }, () => {
            this.props.setReturnOrderFilter(label, value);
        });
    };

    /**
     * clearFilterAction {Function}
     * @description - clear filter action value
     *
     * */
    clearFilterAction = (value, item) => {
        let {label='', clearedValue=''} = item;
        this.setState({
            expanded: true,
            currentPage: 0,
            pageSizeOption: defaultRequestConst.limit,
            searchValue:''
        }, () => {
            this.props.setReturnOrderFilter(label, clearedValue);
        });
    };

    /**
     * getFiltersValue {Function}
     * @description - get default values for Filters
     * @return {Object}
     *
     * */
    getFiltersValue = () => {
        let {filters={}} = this.props;
        let {status, shipmentStatus, creationType, returnType, shopName, remarksFilter} = filters;
        return {
            0: status,
            1: shipmentStatus,
            2: creationType,
            3: returnType,
            4: remarksFilter,
            5: shopName
        }
    };

    /**
     * getDisabledStates {Function}
     * @description - get default enabled/disabled states for Filters
     * @return {Object}
     *
     * */
    getDisabledStates = () => {
        return {
            0: false,
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
        }
    };

    /**
     * getFilterShopNames {Function}
     * @description - generates appropriate options for Market dropdown in Filters
     * @return {Object}
     *
     * */
    getFilterShopNames = () => {
        let {mainPropertiesState} = this.props;
        return getFiltersAppropriateMarkets(mainPropertiesState, isNeedToShowMarket(mainPropertiesState), isNeedToShowMarketLanguage(mainPropertiesState));
    };

    /**
     * isFilterAbsent
     * @description - check are there any filter to be implemented
     * @return Boolean
     *
     * */
    isFiltersAbsent = () => {
        let isFiltersAbsent = true;
        let {filters} = this.props;

        for(let i in filters) {
            if(filters[i]) {
                isFiltersAbsent = false;
            }
        }

        return isFiltersAbsent;
    };

    /**
     * getFiltersRequestObject {Function}
     * @description - get object to make request including filters and default request parameters
     * @return {Object}
     * */
    getFiltersRequestObject = () => {
        let {DefaultRequestConst, filters } = this.props;
        let {returnType, shipmentStatus, status, shopName, creationType, remarksFilter} = filters;
        let requestObject = {
            ...DefaultRequestConst,
            status: status,
            shopId: shopName,
            shipmentStatus: shipmentStatus,
            creationType: creationType,
            remarksFilter: remarksFilter,
            returnType: returnType
        };

        /*if(remarksFilter) {
            requestObject[remarksFilter] = true
        }

        if(returnType) {
            requestObject[returnType] = true
        }*/

        return requestObject;
    };

    componentDidMount() {
        if(!this.isFiltersAbsent()) {
            this.fetchReturns(this.getFiltersRequestObject())
        } else {
            this.fetchReturns();
        }
    }

    componentWillUnmount () {
        clearTimeout(this.searchTimeout);
        clearTimeout(this.clipBoardTimeout);
    };

    componentDidUpdate (prevProps, prevState, snapShots) {
        let {
            data,
            filters,
            currentReturnItem
        } = this.props;

        let {searchValue} = this.state;
        let prevReturnItem = prevProps.currentReturnItem;

        if(prevProps.filters !== filters) {
            this.fetchReturns(this.getFiltersRequestObject());
        }

        // each time when returns items were fetched after search control changed, we need to be sure that we are rendering the proper return details.
        // in case we aren't - need to fetch proper details
        if(prevProps.data !== data) {
            let returns = data && data.getField ? data.getField('data') : [];
            let isCurrentSearchValueValidId = searchValue ? find(returns, (returnItem)=>{ return returnItem.returnOrderId === parseInt(searchValue) }) : false;
            if(isCurrentSearchValueValidId) {
                this.clickOnRow(searchValue)
            }
        }

        if(prevReturnItem && currentReturnItem && prevReturnItem !== currentReturnItem) {
            if(prevReturnItem.toJSONView().status !== currentReturnItem.toJSONView().status && prevReturnItem.toJSONView().returnOrderId === currentReturnItem.toJSONView().returnOrderId ) {
                this.fetchReturns(this.getFiltersRequestObject());
            }
        }
    };

    render (){
        return (
            <>
                <div className="b-app__page_controls b-flex b-returns-page__controls">
                    <div className="b-app__page_controls_item b-returns-table__filter_item">
                        <PageFiltersItems
                            pageId="returns"
                            classStyles="b-returns-page__filters"
                            defaultValues={this.getFiltersValue()}
                            disabledStates={this.getDisabledStates()}
                            changeAction={this.changeFilterAction}
                            clearAction={this.clearFilterAction}
                            isLabeled={true}
                            replaceFilter={{
                                'customer.list.columns.shopName': this.getFilterShopNames()
                            }}
                            tooltips={{
                                'return.order.list.column.creationType': <CustomTranslation
                                    id='return.order.list.column.creationType.tooltip'/>
                            }}
                        />
                    </div>
                    <div className="b-app__page_controls_item b-returns-table__filter_item">
                        {this.getSearchControlComponent()}
                    </div>
                </div>
                <div className="b-app__table_wrapper">
                    {this.renderTable()}
                </div>
            </>

        )
    };
}

function mapStateToProps(state) {
    return {
        ...state.returnsTableState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            fetchReturns,
            setReturnOrderFilter,
            setTableCurrentPage,
            showBeforeSwitchReturnPopup,
            clearFixedFeeControl
          },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)((withStyles(tableStyles, {withTheme: true})(withRouter(ReturnsTable))));