import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

function Loader({wrapperClass='', size=24}) {
    return (
        <div className={`b-loader__wrapper ${wrapperClass}`}>
             <CircularProgress className='b-loader__item' size={size} />
        </div>
    );
}


export default Loader;