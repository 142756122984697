import axios from 'axios';
import configuration from '../../configs/configs';
import defaultAction from './../customActions/defaultAction/defaultAction';
import customModelAction from '../customActions/customModelAction/customModelAction';
import serverErrorNotifierAction from '../customActions/serverErrorAction/serverErrorNotifierAction';
import serverErrorNotifier_500_502_action from '../customActions/serverErrorAction/serverError500-502';
import {getDataFromSS} from "../../utilites";

const axiosInstance = axios.create({
    headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: false
    },
});

const {BASE_API, BASE_URL} = configuration;


/**
* getRequestUrl {Function}
* @description - method for generating proper url string for request
* @return requestUrl {String}
*
* */
let getRequestUrl = (route, isRouteLocal, requestData) => {
    let {lang, shopId, returnsId, returnOrderId} = requestData;
    let requestUrl;
    let landExp = /\$lang/g;
    let shopIdExp = /\$shopId/g;
    let returnOrderExp = /\$returnsItemId/g;
    let returnOrderIdExp = /\$returnOrderId/g;

    /* TODO: need figure out more elegant way for replacements */
    requestUrl = configuration.requestsMethodsTypes[route].route
                .replace(landExp, lang)
                .replace(shopIdExp, shopId)
                .replace(returnOrderExp, returnsId)
                .replace(returnOrderIdExp, returnOrderId);

    return isRouteLocal ? requestUrl : BASE_URL + BASE_API + requestUrl;
};

/**
 * makeRequest {Function}
 * @description - make request and call actions after data received
 *
 * */
let makeRequest = (request, next, action) => {
    request.then(function (response) {
        //function is passing action with data from server response to redux state
        defaultAction(response, next, action);
    }).catch(function (error) {
        let {response={}} = error;
        let {data={}, status=''} = response;

        /* TODO: find out best solution forward action type in case of error */
        //function is passing action with custom data to redux state
        customModelAction(next, action, {requestStatus: 'error', ...data, requestData: action.requestData});

        if(status !== undefined) {
            switch (status) {
                case 500:
                case 502:
                    serverErrorNotifier_500_502_action(error, next, action);
                    break;
                default:
                    // function is passing action with error data to redux state and activate error notifier
                    serverErrorNotifierAction(error, next, action);
                    break;
            }
        }

    }) ;
};

/**
 * Function
 * @description - middleware for async request
 * @params:
 * route {String} - request string
 * apiParams {Object} - null by default for GET request. Object for POST request
 * apiCallback {Function} - function called before result go to reducer
 *
 * */
export default async (store, next, action) => {
    let {route, isRouteLocal=false, isWithGetParams=false, requestData=null, requestHeaders={}, cancelToken=null} = action;
    let request;
    let requestUrl;
    let headers = {};

    // set appropriate headers
    if (getDataFromSS('login') && getDataFromSS('login').token) {
        headers['Authorization'] = 'Bearer ' + getDataFromSS('login').token;
    } else {
        if(requestHeaders && requestHeaders.Authorization) {
            headers['Authorization'] = requestHeaders.Authorization;
        } else {
            headers['Authorization'] = false;
        }
    }

    if(!route) {
        return next(action);
    } else {
        // Get request URl
        requestUrl = getRequestUrl(route, isRouteLocal, requestData);

        //--- generate a request config ---//
        let config = {
            headers
        };

        if(isWithGetParams) {
            config.params = {...requestData}
        }

        if(cancelToken) {
            config.cancelToken = cancelToken
        }

        if(route === 'getShipmentLabel') {
            config.responseType = 'arraybuffer'
        }
        //--- end generate a request config ---//

        switch(configuration.requestsMethodsTypes[route].type) {
            case 'GET':
                request = axiosInstance.get(requestUrl, config);
                makeRequest(request, next, action);
                break;
            case 'POST':
                request = axiosInstance.post(requestUrl, requestData, config);
                makeRequest(request, next, action);
                break;
            case 'PATCH':
                request = axiosInstance.patch(requestUrl, requestData, config);
                makeRequest(request, next, action);
                break;
            default:
                throw new Error(`request for ${route} has no method...`);
                break;
        }
    }
};