import GeneralModel from './../../../models/GeneralModel';
import DefaultRequestConstObject from './../defaultRequestConst';
import Const from "./../const";


const defaultState = {
    data: new GeneralModel({}),
    isLoading: false,
    isFoldedOpen: false,
    DefaultRequestConst: DefaultRequestConstObject
};


export default function (state = defaultState, action = {}) {
    let {type, data, payload} = action;
    switch (type) {
        case Const.LEFT_SIDE_BAR_IS_FOLDED_OPEN:
            return {
                ...state,
                isFoldedOpen: payload
            };
        case Const.CUSTOMERS_START_FETCH_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case Const.CUSTOMERS_FETCH_REQUEST:
            return {
                ...state,
                data: data,
                isLoading: false
            };
        default:
            return state;
    }
}
