import LoginConst from "./../Login/const";

const Const = {
    RESET_PASSWORD_CLEAR_STATE: 'RESET_PASSWORD_CLEAR_STATE',
    RESET_PASSWORD_CHECK_TOKEN: 'RESET_PASSWORD_CHECK_TOKEN',
    RESET_PASSWORD_START_REQUEST: 'RESET_PASSWORD_START_REQUEST',
    RESET_PASSWORD_UPDATE_PASSWORD: 'RESET_PASSWORD_UPDATE_PASSWORD',
    RESET_PASSWORD_IS_PASSWORD_VISIBLE: 'RESET_PASSWORD_IS_PASSWORD_VISIBLE',
    RESET_PASSWORD_SET_USER_NAME_FROM_TOKEN: 'RESET_PASSWORD_SET_USER_NAME_FROM_TOKEN',
    RESET_PASSWORD_START_CHECK_TOKEN_REQUEST: 'RESET_PASSWORD_START_CHECK_TOKEN_REQUEST',
    RESET_PASSWORD_SEND_IDENTIFICATOR_ON_EMAIL: 'RESET_PASSWORD_SEND_IDENTIFICATOR_ON_EMAIL',
    [LoginConst.LOGIN_SET_USERNAME]: LoginConst.LOGIN_SET_USERNAME
};

export default Const;