import React, { Component } from "react";
import {
    Chart,
    Tooltip,
    ValueAxis,
    Legend,
    ArgumentAxis,
    ScatterSeries,
    SplineSeries
} from '@devexpress/dx-react-chart-material-ui';
import Grid from "@material-ui/core/Grid";
import {
    stackOrderNone
} from 'd3-shape';
import {EventTracker, HoverState} from '@devexpress/dx-react-chart';
import {find} from "lodash";
import {formatDate} from "./../../../utilites";

const OverTimePoint = (props) => {
    let {color, val, arg, state} = props;
    if (state === "hovered") {
        return (
            <path
                className="test"
                transform={"translate(" + arg + "," + val + ")"}
                d="M10.706085419025319,0A10.706085419025319,10.706085419025319,0,1,1,-10.706085419025319,0A10.706085419025319,10.706085419025319,0,1,1,10.706085419025319,0"
                fill="#fff"
                visibility="visible"
                strokeWidth={4}
                stroke={color}></path>
        )
    } else {
        return (
            <path
                className="test"
                transform={"translate(" + arg + "," + val + ")"}
                d="M6.206085419025319,0A6.206085419025319,6.206085419025319,0,1,1,-6.206085419025319,0A6.206085419025319,6.206085419025319,0,1,1,6.206085419025319,0"
                fill={color}
                visibility="visible"
                strokeWidth={2}
                stroke="#fff"></path>
        );
    }
};

class OverTimeCart extends Component {

    /**
     * renderLegend {Function}
     * @description - helper to generate chart legend
     * @return {HTML element}
     * */
    renderLegend = (legend) => {
        let sortedItem = {};
        legend.children.map((child, key) => {
            if (key < 3) {
                let currentLegendItem = find(child.props.children, (item) => {
                    return item.props.name === child.key
                });
                let currentColor = currentLegendItem.props.color;

                sortedItem[key] = (
                    <li key={key} className="b-analytics-overtime-chart__legend_list_item">
                        <span style={{backgroundColor: currentColor}}
                              className="b-analytics-overtime-chart__legend_list_item_marker"></span>
                        {child.key}
                    </li>
                );
            }
        });

        return (
            <ul className="b-analytics-overtime-chart__legend_list">
                {[sortedItem[2], sortedItem[0], sortedItem[1]]}
            </ul>
        )

    };

    /**
     * getArgumentAxisLabelComponent {Function}
     * @description - helper to generate label with localization
     * @return {SVG element}
     * */
    getArgumentAxisLabelComponent = (label) => {
        let {x, y, dy, textAnchor, text} = label;
        return (
            <text className="b-analytics-overtime-chart__argument_axis_label" x={x} y={y} dy={dy}
                  textAnchor={textAnchor}>{formatDate(parseInt(text), 'YYYY-MM-DD')}</text>
        )
    };

    /**
     * getChartData {Function}
     * @description - go through all data items and convert numeric timestamp to string
     * @return {Array}
     *
     * */
    getChartData = (chartData) => {
        chartData.forEach((chartSegment) => {
            chartSegment.date = chartSegment.date.toString()
        });
        return chartData;
    };

    render() {
        let {
            chartData = [],
            chartTitle = '',
            chartControls = [],
            chartControlName = '',
            chartControlAction = () => {
            }
        } = this.props;
        let dataForChart = this.getChartData(chartData);

        return (
            <Grid container className="b-analytics-page__grid_container" spacing={0}>
                <Grid item xs={12} md={12} lg={12} className="b-analytics-page__block">
                    <div className="b-analytics-page__chart_wrapper_header b-flex">
                        <div className="b-analytics-page__chart_wrapper_title">{chartTitle}</div>
                        <div className="b-analytics-page__chart_wrapper_controls">
                            <ul className="b-analytics-page__chart_wrapper_list">
                                { chartControls.map((control, key) => (
                                    <li key={key}
                                        className={`b-analytics-page__chart_wrapper_list_item${control.isActive ? ' active' : ''}`}
                                        onClick={() => {
                                            chartControlAction(control.type, chartControlName)
                                        }}>
                                        {control.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className={`b-analytics-overtime-chart`}>
                        <Chart data={dataForChart} height={308}>
                            <ArgumentAxis
                                tickFormat={() => tick => tick}
                                valueMarginsEnabled={false}
                                labelComponent={this.getArgumentAxisLabelComponent}
                            />
                            <ValueAxis showLine={true}/>
                            <SplineSeries
                                name="Refunds"
                                valueField="refunds"
                                argumentField="date"
                                color="#ff973b"
                            />
                            <SplineSeries
                                name="Re-conversion"
                                valueField="reconversion"
                                argumentField="date"
                                color="#a8d57c"
                            />
                            <SplineSeries
                                name="Orders"
                                valueField="orders"
                                argumentField="date"
                                color="#767676"
                            />
                            <ScatterSeries
                                name="Orders"
                                valueField="orders"
                                argumentField="date"
                                color="#6d6d6d"
                                pointComponent={OverTimePoint}
                            />
                            <ScatterSeries
                                name="Re-conversion"
                                valueField="reconversion"
                                argumentField="date"
                                color="#93c760"
                                pointComponent={OverTimePoint}
                            />
                            <ScatterSeries
                                name="Refunds"
                                valueField="refunds"
                                argumentField="date"
                                color="#fb8b28"
                                pointComponent={OverTimePoint}
                            />
                            <EventTracker/>
                            <HoverState/>
                            <Tooltip enabled={true}/>
                            <Legend
                                position="bottom"
                                offset={stackOrderNone}
                                rootComponent={this.renderLegend}
                            />
                        </Chart>
                    </div>
                </Grid>
            </Grid>
        )
    };
}

export default OverTimeCart;