import React from 'react';
import PropTypes from 'prop-types';
import {VelocityComponent} from 'velocity-react';
import 'velocity-animate/velocity.ui';


/*
    transition.fadeIn
    transition.fadeOut
    transition.flipXIn
    transition.flipXOut
    transition.flipYIn
    transition.flipYOut
    transition.flipBounceXIn
    transition.flipBounceXOut
    transition.flipBounceYIn
    transition.flipBounceYOut
    transition.swoopIn
    transition.swoopOut
    transition.whirlIn
    transition.whirlOut
    transition.shrinkIn
    transition.shrinkOut
    transition.expandIn
    transition.expandOut
    transition.bounceIn
    transition.bounceOut
    transition.bounceUpIn
    transition.bounceUpOut
    transition.bounceDownIn
    transition.bounceDownOut
    transition.bounceLeftIn
    transition.bounceLeftOut
    transition.bounceRightIn
    transition.bounceRightOut
    transition.slideUpIn
    transition.slideUpOut
    transition.slideDownIn
    transition.slideDownOut
    transition.slideLeftIn
    transition.slideLeftOut
    transition.slideRightIn
    transition.slideRightOut
    transition.slideUpBigIn
    transition.slideUpBigOut
    transition.slideDownBigIn
    transition.slideDownBigOut
    transition.slideLeftBigIn
    transition.slideLeftBigOut
    transition.slideRightBigIn
    transition.slideRightBigOut
    transition.perspectiveUpIn
    transition.perspectiveUpOut
    transition.perspectiveDownIn
    transition.perspectiveDownOut
    transition.perspectiveLeftIn
    transition.perspectiveLeftOut
    transition.perspectiveRightIn
    transition.perspectiveRightOut
*/

const CustomAnimate = (props) => {
    const children = React.cloneElement(props.children, {
        style: {
            ...props.children.style,
            visibility: 'hidden'
        }
    });
    return <VelocityComponent ref={ev => props.setRef && props.setRef(ev)} {...props} children={children} />
};

CustomAnimate.propTypes = {
    children: PropTypes.element.isRequired
};

CustomAnimate.defaultProps = {
    animation          : 'transition.fadeIn',
    runOnMount         : true,
    targetQuerySelector: null,
    interruptBehavior  : 'stop',
    visibility         : 'visible',
    duration           : 300,
    delay              : 50,
    easing             : [0.4, 0.0, 0.2, 1],
    display            : null,
    setRef             : undefined
};

export default CustomAnimate;
