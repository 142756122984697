import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Redirect, withRouter} from "react-router";
import PageContent from "./../../containers/PageContent/PageContent";
import {setCurrentPageTitle} from "./../../components/Header/actions/headerActions";
import Grid from "@material-ui/core/Grid";
import {fade} from "@material-ui/core/styles/colorManipulator";
import {withStyles} from "@material-ui/core";
import {
    createTemporaryLinkElement,
    getBooleanShopProperty,
    getFiltersAppropriateMarkets,
    getTableHeight,
    isNeedToShowMarket,
    isNeedToShowMarketLanguage
} from "./../../utilites";
import {
    clearFilters,
    fetchDashboard,
    fetchReturnDataReportSCV,
    setDashboardFilter,
    setGenerateAndExportButtonsSate
} from "./actions/analyticsActions";
import BarChart from "./components/BarChart";
import {find} from "lodash";
import OverTimeCart from "./components/OverTimeCart";
import CustomTable from "../../components/CustomTable/CustomTable";
import {getTranslate} from "react-localize-redux";
import CustomButton from "./../../containers/CustomButton/CustomButton";
import CustomTranslation from "./../../components/CustomTranslation/CustomTranslation";
//import TooltipWrapper from "./../../containers/TooltipWrapper/TooltipWrapper";
import PageFiltersItems from "./../../components/PageFiltersItems/PageFiltersItems";
import AnalyticsCard from "./components/AnalyticsCard";

const styles = (theme) => {
    return ({
        exportCSVBtn: {
            background: fade(theme.palette.brand.btnColors.reject, 0.5),
            '&:hover': {
                background: theme.palette.brand.btnColors.reject
            }
        }
    })
};

class Analytics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            returnsChartControls: [
                {
                    name: 'Return reasons',
                    isActive: true,
                    type: 'returnReasons'
                },
                {
                    name: 'Product conditions',
                    isActive: false,
                    type: 'productConditions'
                },
                {
                    name: 'Next action',
                    isActive: false,
                    type: 'nextActions'
                },
            ],
            highestChartControls: [
                {
                    name: 'Highest value generated',
                    isActive: true,
                    type: 'highestValueGenerated'
                },
                {
                    name: 'Lowest value generated',
                    isActive: false,
                    type: 'lowestValueGenerated'
                },
                {
                    name: 'Return rate',
                    isActive: false,
                    type: 'returnRate'
                },
                {
                    name: 'Re-conversion rate',
                    isActive: false,
                    type: 'reconversionRate'
                },
            ]
        }
    }

    fetchDashboard = (requestData = null, isInitial=false) => {
        let {filters} = this.props;
        let dataForRequest = requestData ? requestData : {
            ...filters
        };

        if (dataForRequest['baseCurrency']) {
            dataForRequest.currency = dataForRequest['baseCurrency'];
            delete dataForRequest['baseCurrency'];
        }

        if(isInitial) {
            dataForRequest['fromDate'] = dataForRequest['startDate'];
            dataForRequest['toDate'] = dataForRequest['endDate'];
            dataForRequest['isInitial'] = true;

            delete dataForRequest['endDate'];
            delete dataForRequest['startDate'];
        }

        this.props.fetchDashboard({
            route: 'getDashboard',
            isWithGetParams: true,
            requestData: dataForRequest
        });
    };

    /**
     * getDisabledStates {Function}
     * @description - get objects with filter's values (based on reducer state)
     * @return {Object}
     *
     * */
    getFiltersValue = (index) => {
        let {
            filters = {}
        } = this.props;
        let {
            startDate,
            endDate,
            status,
            shipmentStatus,
            creationType,
            returnType,
            shopName,
            baseCurrency,
            remarksFilter,
            reason,
            condition,
            nextAction
        } = filters;
        // need add specific default for Asket
        switch (index) {
            case 1:
                return {
                    0: [startDate, endDate],
                    1: status,
                    2: shipmentStatus,
                    3: creationType,
                    4: returnType,
                    5: remarksFilter
                };
            case 2:
                return {
                    0: reason,
                    1: condition,
                    2: nextAction,
                    3: baseCurrency,
                    4: shopName
                };
            default:
                return {}
        }
    };

    /**
     * getDisabledStates {Function}
     * @description - generates objects with filter's state (enable/disable)
     * @return {Object}
     *
     * */
    getDisabledStates = (index) => {
        switch (index) {
            case 1:
                return {
                    0: false,
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false
                };
            case 2:
                return {
                    0: false,
                    1: false,
                    2: false,
                    3: false,
                    4: false
                };
            default:
                return {}

        }
    };

    /**
     * getFilterShopNames {Function}
     * @description - generates appropriate options for Market dropdown in Filters
     * @return {Object}
     *
     * */
    getFilterShopNames = () => {
        let {mainPropertiesState} = this.props;
        return getFiltersAppropriateMarkets(mainPropertiesState, isNeedToShowMarket(mainPropertiesState), isNeedToShowMarketLanguage(mainPropertiesState));
    };


    /**
     * getReasons {Function}
     * @description - get return reasons for appropriate filter from main properties.
     * @return {Array}
     * */
    getReasons = () => {
        let {mainPropertiesState={}} = this.props;
        let {returnReasons=[]} = mainPropertiesState;
        let reasons = returnReasons && returnReasons.length ? returnReasons.map((reason)=>{
            return {
                value: reason,
                label: reason
            }
        }) : [];

        reasons.unshift( {
            label: 'ALL',
            value: ''
        });

        return reasons;
    };

    /**
     * changeFilterAction {Function}
     * @description - change filter action value
     *
     * */
    changeFilterAction = (value, item, productId, defaultSettings, key) => {
        let {label = ''} = item;
        this.props.setDashboardFilter(label, value);
    };

    /**
     * clearFilterAction {Function}
     * @description - clear filter action value
     *
     * */
    clearFilterAction = (value, item) => {
        let {label = '', clearedValue = ''} = item;
        this.props.setDashboardFilter(label, clearedValue);
    };


    /**
     * reRenderTheChart {Function}
     * @description - set active chart control
     * TODO: we also would need to create functionality to filter charts data by chosen control type
     * */
    reRenderTheChart = (type, chartControlName) => {
        let controls = [];

        this.state[chartControlName].forEach((control) => {
            if (control.type !== type) {
                controls.push({
                    ...control,
                    isActive: false
                })
            } else {
                controls.push({
                    ...control,
                    isActive: true
                })
            }
        });

        this.setState({
            [chartControlName]: controls
        })

    };

    /**
     * getTableColumns {Function}
     * @description - get data to render dashboard's table's column titles
     * @return {Array}
     *
     * */
    getTableColumns = () => {
        let {translate} = this.props;

        return [
            {
                Header: '#',
                accessor: 'index',
                Cell: ({row}) => {
                    return row.index
                },
                width: 100
            },
            {
                Header: `${translate('order.list.columns.shopName')}`,
                accessor: 'country',
                Cell: ({row}) => {
                    return <b>{row.country}</b>
                },
                width: 100
            },
            {
                Header: `${translate('dashboard.table.column.returnPercents')}`,
                accessor: 'return',
                Cell: ({row}) => {
                    return row.return
                }
            },
            {
                Header: `${translate('dashboard.table.column.returnValuePercents')}`,
                accessor: 'returnValuePercents',
                Cell: ({row}) => {
                    return row.returnValuePercents
                }
            },
            {
                Header: `${translate('dashboard.table.column.returnValue')}`,
                accessor: 'returnValue',
                Cell: ({row}) => {
                    return row.returnValue + " " + row._original.currency
                }
            },
            {
                Header: `${translate('dashboard.table.column.reconversionPercents')}`,
                accessor: 'reconversionPercents',
                Cell: ({row}) => {
                    return row.reconversionPercents
                }
            },
            {
                Header: `${translate('dashboard.table.column.reconversionValue')}`,
                accessor: 'reconversion',
                Cell: ({row}) => {
                    return row.reconversion + " " + row._original.currency
                }
            },
            {
                Header: `${translate('dashboard.table.column.amountOfReturns')}`,
                accessor: 'returnsAmount',
                Cell: ({row}) => {
                    return row.returnsAmount
                }
            }
        ];
    };

    /**
     * downloadCSVReport {Function}
     * @description - enable possibility to download csv file
     *
     * */
    downloadCSVReport = (csvDataModel) => {
        let csvData = csvDataModel.getField('response');
        let separator = ',';
        let {startDate, endDate} = this.props.filters;

        if(csvDataModel && csvData) {
            let reader = new FileReader();
            let fileName = `ReturnadoAnalyticsReport_${startDate}_${endDate}.csv`;
            let blob = new Blob([`sep=${separator}\n${csvData}`], {type : 'text/csv;charset=utf-8'});

            reader.onload = reader.onload = () => {createTemporaryLinkElement(fileName, blob)};

            reader.readAsText(blob);
        }
    };

    /**
     * getFiltersForRequest {Function}
     * @description - filters on analytics page has their own names different on default system handling. So need to overwrite them
     * @return {Object}
     *
     * */
    getFiltersForRequest = (filters) => {
        let overwrittenFilters = {};

        for (let filterKey in filters) {
            if (filters.hasOwnProperty(filterKey)) {
                if (filterKey === 'endDate' && filters[filterKey]) {
                    overwrittenFilters['toDate'] = filters[filterKey]
                } else if (filterKey === 'startDate' && filters[filterKey]) {
                    overwrittenFilters['fromDate'] = filters[filterKey]
                } else if (filterKey === 'status' && filters[filterKey]) {
                    overwrittenFilters['returnStatus'] = filters[filterKey]
                } else if (filterKey === 'baseCurrency' && filters[filterKey]) {
                    overwrittenFilters['currency'] = filters[filterKey]
                } else if (filterKey === 'condition' && filters[filterKey]) {
                    overwrittenFilters['itemCondition'] = filters[filterKey]
                } else if (filterKey === 'nextAction' && filters[filterKey]) {
                    overwrittenFilters['itemNextAction'] = filters[filterKey]
                } else if (filterKey === 'nextAction' && filters[filterKey]) {
                    overwrittenFilters['itemNextAction'] = filters[filterKey]
                } else if (filterKey === 'shopName' && filters[filterKey]) {
                    overwrittenFilters['shopId'] = filters[filterKey]
                } else if (filterKey === 'reason' && filters[filterKey]) {
                    overwrittenFilters['reclamationReason'] = filters[filterKey]
                } else {
                    overwrittenFilters[filterKey] = filters[filterKey]
                }
            }
        }
        return overwrittenFilters;
    };

    /**
     * getTableData {Function}
     * @description - get data to render dashboard's table
     * @return {Array}
     *
     * */
    getTableData = () => {
        let {data} = this.props;
        return data;
    };

    /**
     * fetchCSVReport {Function}
     * @description - generate csv report based on filters
     * */
    fetchCSVReport = () => {
        let {filters} = this.props;
        this.props.fetchReturnDataReportSCV({
            route: 'getAnalyticsReportCsv',
            isWithGetParams: true,
            requestData: this.getFiltersForRequest(filters)
        });
    };

    /**
     * getFilterNextActions {Function}
     * @description - generates options to set in next actions filter dropdown
     * @return {Array}
     *
     * */
    getFilterNextActions = (actions) => {
        let filterActions = [
            {
                label: "ALL",
                value: ""
            }
        ];

        actions.forEach((actionItem)=>{
            filterActions.push({
                label: actionItem,
                value: actionItem
            })
        });

        return filterActions;
    };

    /**
     * getFilterConditions {Function}
     * @description - generates options to set in next conditions filter dropdown
     * @return {Array}
     *
     * */
    getFilterConditions = (conditions) => {
        let filterConditions = [
            {
                label: "ALL",
                value: ""
            }
        ];

        conditions.forEach((conditionItem)=>{
            filterConditions.push({
                label: conditionItem,
                value: conditionItem
            })
        });

        return filterConditions;
    };

    getSplitedLabel (title) {
        let titlesParts = title.split(' ')

        return title
    }

    /**
     * getTranslatedChartLabels {Function}
     * @description generates proper localization key for bar chart labels
     * @return {React Component}
     * */
    getTranslatedChartLabels = (arr, type) => {
        let {translate} = this.props;
        let prefix = '';
        switch (type) {
            case 'returnReasons':
                prefix = 'products.report.returnReason';
                break;
            case 'productConditions':
                prefix = 'return.order.details.conditions';
                break;
            default:
                prefix = 'return.order.details.nextActions';
                break
        }

        return arr.map((item, i) => {
            let str = type === 'returnReasons' ? this.getSplitedLabel(translate(`${prefix}.${item.argumentField}`)) : translate(`${prefix}.${item.argumentField}`);
            return {
                ...item,
                argumentField: str
            }
        })
    }

    componentDidMount() {
        let {pageName} = this.props;
        this.props.setCurrentPageTitle(pageName);
        this.fetchDashboard(null, true);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {filters, csvData} = this.props;

        if (prevProps.filters !== filters) {
            this.props.setGenerateAndExportButtonsSate(filters)
        }

        if(prevProps.csvData !== csvData) {
            this.downloadCSVReport(csvData);
        }
    }

    componentWillUnmount() {
        this.props.clearFilters()
    }

    render() {
        let {
            classes,
            ratesCards,
            valuesCards,
            charts,
            isReadyToGenerateOrExport,
            filters,
            isLoading,
            translate,
            itemConditions,
            itemNextActions
        } = this.props;
        let {returnsChartControls, highestChartControls} = this.state;
        let tableData = this.getTableData();
        let activeReturnChartControl = find(returnsChartControls, (control) => {
            return control.isActive
        });
        let activeRatesChartControl = find(highestChartControls, (control) => {
            return control.isActive
        });
        let returnChartData = this.getTranslatedChartLabels(charts[activeReturnChartControl.type], activeReturnChartControl.type) || [];
        let ratesChartData = charts[activeRatesChartControl.type] || charts['returnRate'];
        let performanceChartData = charts['performance'] || [];

        return (
            getBooleanShopProperty(this.props, 'analytics.page.enabled')
                ?
                <div className='b-app__page b-analytics-page' tabIndex="0">
                    <PageContent isFoldedOpen={false} classNameCustom='b-page-content--dashboard' isAnalyticsPage={true}>
                        <div className="b-app__page_body">
                            <div className="b-app__body_item">
                                <Grid container className="b-analytics-page__grid_container" spacing={2}>
                                    <Grid item xs={12} md={12} lg={12} className="b-analytics-page__block">
                                        <PageFiltersItems
                                            pageId="analytics"
                                            classStyles="b-analytics-page__filters"
                                            defaultValues={this.getFiltersValue(1)}
                                            disabledStates={this.getDisabledStates()}
                                            changeAction={this.changeFilterAction}
                                            clearAction={this.clearFilterAction}
                                            isLabeled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12} className="b-analytics-page__block">
                                        <PageFiltersItems
                                            pageId="analytics2"
                                            classStyles="b-analytics-page__filters"
                                            defaultValues={this.getFiltersValue(2)}
                                            disabledStates={this.getDisabledStates()}
                                            changeAction={this.changeFilterAction}
                                            clearAction={this.clearFilterAction}
                                            isLabeled={true}
                                            replaceFilter={{
                                                'returns.report.list.columns.reason': this.getReasons(),
                                                'customer.list.columns.shopName': this.getFilterShopNames(),
                                                'return.order.details.nextAction': this.getFilterNextActions(itemNextActions),
                                                'return.order.details.condition': this.getFilterConditions(itemConditions),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <div className="b-analytics-page__generate_controls b-flex">
                                    <div className="b-analytics-page__generate_controls_item"/>
                                    <div className="b-analytics-page__generate_controls_item">
                                        {/*<TooltipWrapper text={<CustomTranslation id='tooltip.wrapper.functionality.unavailable' renderAsString={false} />} >*/}
                                        <CustomButton
                                            type='button'
                                            variant='contained'
                                            fullWidth={true}
                                            color='primary'
                                            aria-label={<CustomTranslation id='products.report.list.columns.csv'/>}
                                            loading={isLoading}
                                            isdisabled={isReadyToGenerateOrExport}
                                            name={<CustomTranslation id='products.report.list.columns.csv'/>}
                                            className={`${classes.exportCSVBtn} b-analytics-page__generate_controls_btn`}
                                            onClick={this.fetchCSVReport}
                                        />
                                        {/*</TooltipWrapper>*/}
                                    </div>
                                    <div className="b-analytics-page__generate_controls_item">
                                        <CustomButton
                                            type='button'
                                            variant='contained'
                                            fullWidth={true}
                                            color='primary'
                                            aria-label={<CustomTranslation id='products.report.list.columns.generate'/>}
                                            loading={isLoading}
                                            isdisabled={isReadyToGenerateOrExport}
                                            className="b-analytics-page__generate_controls_btn"
                                            name={<CustomTranslation id='products.report.list.columns.generate'/>}
                                            onClick={() => {
                                                this.fetchDashboard(this.getFiltersForRequest(filters))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="b-app__body_item">
                                <div className="b-analytics-page__metric_wrapper">
                                    <div className="b-analytics-page__metric_header">
                                        <div className="b-analytics-page__tabs">
                                            <ul className="b-analytics-page__tabs_list">
                                                <li className="b-analytics-page__tabs_item active">
                                                    <CustomTranslation id="dashboard.card.returnRate.Returns"/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="b-analytics-page__metric_body">
                                        { ratesCards && !!ratesCards.length && (
                                                <div className="b-analytics-page__cards_wrapper b-flex">
                                                    {ratesCards.map((card, key) => {
                                                        return <AnalyticsCard key={key} type='rates' card={card}/>
                                                    })}
                                                </div>
                                        )}

                                        { valuesCards && !!valuesCards.length && (
                                            <div className="b-analytics-page__cards_wrapper b-flex">
                                                { valuesCards.map((card, key) => {
                                                    return <AnalyticsCard key={key} type='values' card={card}/>
                                                })}
                                            </div>
                                        )}

                                        { performanceChartData && !!performanceChartData.length && (
                                            <div className="b-analytics-page__chart_wrapper">
                                                <OverTimeCart
                                                    chartData={performanceChartData}
                                                    chartTitle="Performance over time"
                                                />
                                            </div>
                                        )}

                                        { returnChartData && !!returnChartData.length && (
                                            <div className="b-analytics-page__chart_wrapper">
                                                <BarChart
                                                    chartData={returnChartData}
                                                    isLoading={isLoading}
                                                    chartControlName="returnsChartControls"
                                                    chartTitle={activeReturnChartControl.type}
                                                    chartControlAction={this.reRenderTheChart}
                                                    chartControls={returnsChartControls}
                                                />
                                            </div>
                                        )}

                                        { ratesChartData && !!ratesChartData.length && (
                                            <div className="b-analytics-page__chart_wrapper">
                                                <BarChart
                                                    chartData={ratesChartData}
                                                    isLoading={isLoading}
                                                    chartControlName="highestChartControls"
                                                    chartTitle={activeRatesChartControl.type}
                                                    chartControlAction={this.reRenderTheChart}
                                                    chartControls={highestChartControls}
                                                />
                                            </div>
                                        )}

                                        { tableData && !!tableData.length && (
                                            <div className="b-analytics-page__chart_wrapper">
                                                <Grid container className="b-analytics-page__grid_container"
                                                      spacing={0}>
                                                    <Grid item xs={12} md={12} lg={12}
                                                          className="b-analytics-page__block">
                                                        <div
                                                            className="b-analytics-page__chart_wrapper_header b-analytics-page__chart_wrapper_header--simple b-flex">
                                                            <div className="b-analytics-page__chart_wrapper_title">
                                                                {translate('dashboard.table.performancePerCountry')}
                                                            </div>
                                                            <div className="b-analytics-page__chart_wrapper_controls"/>
                                                        </div>
                                                        <CustomTable
                                                            className={'-highlight -striped'}
                                                            data={tableData}
                                                            columns={this.getTableColumns()}
                                                            loading={isLoading}
                                                            isLoading={isLoading}
                                                            getTrProps={this.handleTableRowClick}
                                                            pageSize={tableData && tableData.length ? tableData.length : 0}
                                                            style={{
                                                                // This will force the table body to overflow and scroll, since there is not enough room
                                                                height: getTableHeight(tableData)
                                                            }}
                                                            showPagination={false}
                                                            isPaginationActive={false}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PageContent>
                </div>
                :
                <Redirect to="/orders"/>
        );
    };
}

function mapStateToProps(state) {
    return {
        ...state.analyticsPageState,
        mainPropertiesState: state.mainPropertiesState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            setGenerateAndExportButtonsSate,
            fetchReturnDataReportSCV,
            setCurrentPageTitle,
            setDashboardFilter,
            fetchDashboard,
            clearFilters
        },
        dispatcher,
    );
}

export default withStyles(styles)(connect(mapStateToProps, matchDispatchToProps)(withRouter(Analytics)));