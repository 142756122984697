import React, {useState} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Link from "@material-ui/core/Link";


function Transition(props) {
    return <Slide direction="up" {...props} />;
}

function PageNotFound() {

    let {isVisible=true} = useState();

    return (
        <div className="b-page-not-found">
            <Dialog
                open={isVisible}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {"Page not found"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Sorry, the page you are looking for doesn't exist
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className="b-page-not-found__controls">
                        <Link href='#/' color="primary">
                            Back to Main
                        </Link>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default PageNotFound;