import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    fetchEvents,
    show,
    hide,
    clear,
    updateEventLog,
    closeStaticEventLog
} from "./actions/returnEventLogPopupActions";
import CustomTranslation from "../CustomTranslation/CustomTranslation";
import ConfirmBeforeUpdateDialog from "../ConfirmBeforeUpdateDialog/ConfirmBeforeUpdateDialog";
import CancelIcon from '@material-ui/icons/Cancel';
import {formatDate} from "./../../utilites";
import {filter} from 'lodash'
import {Scrollbars} from "react-custom-scrollbars";

class ReturnEventLogPopup extends Component {

    closeStaticWindow = () => {
        this.props.closeStaticEventLog()
    };

    /**
     * isEventsLength {Function}
     * @description - defines if there are some events or not
     * @return {Boolean}
     *
     * */
    isEventsLength = (events = []) => {
        return !!events.length
    };

    /**
     * delegateAction {Function}
     * @description - method responsible to all a specific action based on data attributes of the passed target
     *
     * */
    delegateAction = (e) => {
        let target = e.target;
        let {returnOrderId} = this.props;
        let returnId = target.getAttribute('data-returnid');
        let eventId = target.getAttribute('data-eventid');
        let shopId = target.getAttribute('data-shopid');
        let state = target.getAttribute('data-state');

        if (returnId && eventId && shopId) {
            this.updateEventLog(returnId, eventId, shopId, state);
        } else {
            if (returnId || eventId || shopId || state) {
                this.props.show(returnOrderId)
            }
        }
    };

    /**
     * getLogEventBody {Functionality}
     * @description - get content for event log popup window
     * @return {HTML structure}
     * */
    getLogEventBody = (isPopup) => {
        let {events = []} = this.props;
        let externalEvents = filter(events, {external: true, toggle: true});
        return (
            <span
                className={isPopup ? "b-log-event-popup" : !!externalEvents && externalEvents.length ? "b-log-event-popup-static" : "b-log-event-popup-static b-log-event-popup-static--hidden"}
                onClick={(e) => {
                    this.delegateAction(e)
                }}
            >
                {isPopup ?
                    ''
                    :
                    this.isEventsLength(events) && (
                        <CancelIcon data-close={true} className='b-log-event-popup-static__close_ctrl'
                                    onClick={this.closeStaticWindow}/>
                    )}
                {isPopup ?
                    ''
                    :
                    this.isEventsLength(externalEvents) && (
                        <span className="b-log-event-popup__title">
                            <CustomTranslation id="eventlog.warning.from.external.system.title"/>
                        </span>
                    )}
                {isPopup ?
                    this.isEventsLength(events) && (events.map((event, key) => {
                        return (
                            <span key={key} className="b-log-event-popup__wrapper">
                                <span className="b-log-event-popup__item b-log-event-popup__item--date_time">
                                    <span
                                        className="b-log-event-popup__date">{formatDate(event.date, 'YYYY-MM-DD')}</span>
                                    <span className="b-log-event-popup__time">{formatDate(event.date, 'hh:mm')}</span>
                                </span>
                                <span className="b-log-event-popup__item b-log-event-popup__item--icon">
                                    <span className="b-log-event-popup__icon_wrapper">
                                        <span className="b-log-event-popup__icon" style={{
                                            backgroundImage: 'url(' + event.iconUrl + ')',
                                        }}/>
                                    </span>
                                </span>
                                <span
                                    className={event.toggle ? "b-log-event-popup__item b-log-event-popup__item--event b-flex" : "b-log-event-popup__item b-log-event-popup__item--event b-log-event-popup__item--event b-log-event-popup__item b-log-event-popup__item--event_full b-flex"}>
                                    <span
                                        className={!event.active ? "b-log-event-popup__event_info b-log-event-popup__event_info--lined" : "b-log-event-popup__event_info"}>
                                        <CustomTranslation id={event.textKey}/> <br/>
                                        <span>{event.description}</span>
                                    </span>
                                </span>
                                {!!event.toggle && (
                                    <span className="b-log-event-popup__item b-log-event-popup__item--status b-flex">
                                        <span className="b-log-event-popup__event_status">
                                            {event.active ?
                                                <span
                                                    className="b-log-event-popup__event_status_wrapper b-log-event-popup__event_status_wrapper--clear"
                                                    onClick={() => {
                                                        this.handleEvent(event, false)
                                                    }}>
                                                    <CustomTranslation id="eventlog.control.clear"/>
                                                </span>
                                                :
                                                <span
                                                    className="b-log-event-popup__event_status_wrapper b-log-event-popup__event_status_wrapper--cleared"
                                                    onClick={() => {
                                                        this.handleEvent(event, true)
                                                    }}>
                                                    <CustomTranslation id="eventlog.control.cleared"/>
                                                </span>
                                            }
                                        </span>
                                    </span>
                                )}
                            </span>
                        )
                    }))
                    :
                    this.isEventsLength(externalEvents) && (
                        <Scrollbars className="b-log-event-popup-static__body_list" autoHeight autoHeightMax={312}>
                            {externalEvents.map((event, key) => {
                                return (
                                    <p key={key} className="b-log-event-popup-static__p">
                                        {`${formatDate(event.date, 'YYYY-MM-DD')} ${formatDate(event.date, 'hh:mm')} - `}
                                        <span
                                            className={!event.active ? "b-log-event-popup-static__event_info b-log-event-popup-static__event_info--lined" : "b-log-event-popup-static__event_info"}>
                                            <CustomTranslation id={event.textKey} renderAsString={true}/>
                                        </span> {' '}
                                        {!!event.toggle && (
                                            event.active ?
                                                <span
                                                    className="b-log-event-popup__event_status_wrapper b-log-event-popup__event_status_wrapper--inline b-log-event-popup-static__event_status_wrapper--clear"
                                                    data-returnid={event.returnId}
                                                    data-eventid={event.eventId}
                                                    data-shopid={event.shopId}
                                                    data-state={false}>
                                                <CustomTranslation id="eventlog.control.clear"/>
                                            </span>
                                                :
                                                <span
                                                    className="b-log-event-popup__event_status_wrapper b-log-event-popup__event_status_wrapper--inline b-log-event-popup-static__event_status_wrapper--cleared"
                                                    data-returnid={event.returnId}
                                                    data-eventid={event.eventId}
                                                    data-shopid={event.shopId}
                                                    data-state={true}>
                                                <CustomTranslation id="eventlog.control.cleared"/>
                                            </span>
                                        )}
                                    </p>
                                )
                            })}
                        </Scrollbars>
                    )}
            </span>
        )
    };

    /**
     * handleEvent {Function}
     * @description - send request to the server to set specific event in true or false state
     *
     * */
    handleEvent = (event, isActive) => {
        let {returnId, eventId, shopId} = event;
        this.updateEventLog(returnId, eventId, shopId, isActive);
    };


    /**
     * updateEventLog {Function}
     * @description - send request to the server to гзвфеу specific event
     *
     * */
    updateEventLog = (returnId, eventId, shopId, isActive) => {
        this.props.updateEventLog({
            route: 'updateReturnEventLog',
            requestData: {
                shopId,
                returnId,
                eventId,
                "active": isActive
            }
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {isActive, isPopupView, currentOrderId} = this.props;
        if (prevProps.isActive !== isActive && isPopupView) {
            this.props.fetchEvents({
                route: 'fetchReturnEventsLog',
                requestData: {
                    returnOrderId: currentOrderId
                }
            })
        }
    }

    componentWillUnmount() {
        this.props.clear()
    }

    componentDidMount() {
        let {isPopupView, returnOrderId} = this.props;
        if (!isPopupView && returnOrderId) {
            this.props.fetchEvents({
                route: 'fetchReturnEventsLog',
                requestData: {
                    returnOrderId
                }
            })
        }
    }

    render() {
        let {
            isActive = false,
            isPopupView = false,
            isStaticEventLogVisible
        } = this.props;

        return (
            <>
                {
                    isPopupView
                        ?
                        <div className='b-return-event-log-popup'>
                            <ConfirmBeforeUpdateDialog
                                isOpen={isActive}
                                title={<CustomTranslation id='eventlog.popup.title'/>}
                                maxWidth={'md'}
                                content={this.getLogEventBody(true)}
                                isConfirmBtnActive={false}
                                closeAction={() => {
                                    this.props.hide()
                                }}
                                closeBtnName={<CustomTranslation id='login.v2.inboundPage.btn.goBack.text'/>}
                            />
                        </div>
                        :
                        !!isStaticEventLogVisible && (
                            <div className="b-log-event-log">
                                {this.getLogEventBody()}
                            </div>
                        )
                }

            </>

        );
    }
}

function mapStateToProps(state) {
    return {
        ...state.returnEventLogPopupState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            fetchEvents,
            updateEventLog,
            closeStaticEventLog,
            show,
            hide,
            clear
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(ReturnEventLogPopup);