import Const from './../const';

/**
 * requestStart {Function}
 * @return Object in React Action format before request start
 *
 * */
let requestStart = () => {
    return {
        type: Const.ORDERS_START_FETCH_REQUEST
    }
};

/**
 * fetchOrders {React Action}
 * @description - send request to the server to get orders data
 *
 * */
export function fetchOrders(requestData) {
    return dispatch => {
        dispatch(requestStart());
        dispatch({
            type: Const.ORDERS_FETCH_REQUEST,
            ...requestData
        });
    }
}

/**
 * setOrderFilter {React Action}
 * @description - set an appropriate filter
 *
 * */
export function setOrderFilter(label, value) {
    return dispatch => {
        dispatch({
            type: Const.ORDERS_CHANGE_ORDER_FILTER,
            payload: {
                label,
                value
            }
        });
    }
}

/**
 * clearState {React Action}
 * @description - clear current state
 *
 * */
export function clearState() {
    return dispatch => {
        dispatch({
            type: Const.ORDERS_CLEAR_STATE,
        });
    }
}
