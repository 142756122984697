import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Link, Redirect} from 'react-router-dom';
import {withRouter} from "react-router";
import ReactRouterPause from '@allpro/react-router-pause'
import {getTranslate} from "react-localize-redux";
import {
    searchForReturnOrder,
    clearInboundState,
    setReportItemState,
    sortInboundItemsRotate,
    changeSearchValue,
    closeConfirmationDialog,
    closeSendToCustomerDialog,
    setExpandPanelIndex,
    updateReturnOrder,
    closeConfirmUpdateOrder,
    toggleApproveRefundExchange,
    setDescriptionRefund
} from './actions/inboundActions';
import {setCurrentPageTitle} from "./../../components/Header/actions/headerActions";
import PageContent from './../../containers/PageContent/PageContent';
import FlipMove from 'react-flip-move';
import InboundItem from './components/InboundItem';
import CustomTranslation from './../../components/CustomTranslation/CustomTranslation';
import CustomDialog from './../../components/CustomDialog/CustomDialog';
import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Button,
    Grid, Tooltip
} from '@material-ui/core';
import {
    toFixedCurrency,
    getDataFromSS,
    getBeforeReturnUpdateTextsToConfirm,
    getDiminishedPrice,
    getBooleanShopProperty
} from "../../utilites";
import {cleanComment} from "./../../components/ReturnCommentsAreas/actions/returnCommentsAreasActions";
import Loader from "./../../containers/Loader/Loader";
import DashboardsMetrics from './../../components/DashboardMetrics/DashboardsMetrics';
import {find, isObject, orderBy} from 'lodash';
import configuration from "../../configs/configs";
import ConfirmUpdateDialog from "../Returns/components/ConfirmUpdateDialog";
import Search from "./../../components/Search/Search";
import ReturnDescription from "./../../components/ReturnDescription/ReturnDescription";
import PartialApproval from "./../../containers/PartialApproval/PartialApproval";
import Pagination from './../../components/Pagination/Pagination';
import ConfirmBeforeUpdateDialog from "../../components/ConfirmBeforeUpdateDialog/ConfirmBeforeUpdateDialog";
import ExpandIconSrc from "./../../images/general/arrow_icon.png";
import {Warning as WarningIcon} from "@material-ui/icons";
import ReturnEventLogPopup from "../../components/ReturnEventLogPopup/ReturnEventLogPopup";

/* TODO: need to implement in same concept on returns page */
const {PENDING, ARRIVED, REJECTED, REMOVED} = configuration.states;
const baseIntervalTime = 100;

const styles = theme => {
    return ({
        button: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            minWidth: 155
        },
        btnReject: {
            background: theme.palette.brand.btnColors.reject
        },
        btnApprove: {
            background: theme.palette.brand.btnColors.approve
        },
        btnDelete: {
            background: theme.palette.brand.btnColors.delete
        },
        grow: {
            flexGrow: 1,
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        inboundHeader: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            }
        }
    })
};


class ListItem extends Component {
    /**
     * getFilterNextActions {Function}
     * @description - generates options to set in next actions filter dropdown
     * @return {Array}
     *
     * */
    getFilterNextActions = (item) => {
        let actions = item.getField && item.getField("itemNextActions") ? item.getField("itemNextActions") : [];
        let filterActions = [];

        actions.forEach((actionItem)=>{
            filterActions.push({
                label: actionItem,
                value: actionItem
            })
        });

        return filterActions;
    };

    /**
     * getFilterConditions {Function}
     * @description - generates options to set in next conditions filter dropdown
     * @return {Array}
     *
     * */
    getFilterConditions = (item) => {
        let conditions = item.getField && item.getField("itemConditions") ? item.getField("itemConditions") : [];
        let filterConditions = [];

        conditions.forEach((conditionItem)=>{
            filterConditions.push({
                label: conditionItem,
                value: conditionItem
            })
        });

        return filterConditions;
    };
    render() {
        let {
            view,
            index,
            item,
            toggleReportAction,
            currentReturnOrderId,
            rotateOrderAction,
            getBoldAction,
            currency,
            orderIndex,
            reportStatus,
            wasStatusChanged,
            currentReturnItem,
            wasProductChanged,
            wasReturnUpdated,
            parentComponent,
            updateReturnCommentsStateAction,
            isSendToSCLoading,
            returnOrder
        } = this.props;
        const listClass = `b-inbound-page__order_item ${view}`;
        const style = {zIndex: 100 - index};
        let itemFilterActions = this.getFilterNextActions(returnOrder);
        let itemFilterConditions = this.getFilterConditions(returnOrder);

        return (
            <li id={item.returnOrderItemId} className={listClass} style={style}>
                <InboundItem key={index}
                             index={index}
                             item={item}
                             currency={currency}
                             itemFilterActions={itemFilterActions}
                             itemFilterConditions={itemFilterConditions}
                             orderIndex={orderIndex}
                             currentReturnOrderId={currentReturnOrderId}
                             toggleReportAction={toggleReportAction}
                             rotateOrderAction={rotateOrderAction}
                             getBoldAction={getBoldAction}
                             reportStatus={reportStatus}
                             wasStatusChanged={wasStatusChanged}
                             currentReturnItem={currentReturnItem}
                             wasProductChanged={wasProductChanged}
                             wasReturnUpdated={wasReturnUpdated}
                             parentComponent={parentComponent}
                             updateReturnCommentsStateAction={updateReturnCommentsStateAction}
                             isSendToSCLoading={isSendToSCLoading}
                />
            </li>
        );
    }
};

class Inbound extends Component {

    constructor(props) {
        super(props);
        let {defaultRequestConst} = this.props;
        this.searchForm = React.createRef();
        this.searchInput = React.createRef();
        this.currentOrderIdToSave = null;
        this.navigation = null;

        this.state = {
            isDialogOpen: false,
            currentPage: defaultRequestConst.page,
            pageSizeOption: defaultRequestConst.limit,
            showSaveChangesDialog: false,
            changedReturns: {}
        };

        this.barcodeArr = [];
        this.productIds = {};
    }

    /**
     * onSubmit {Function}
     * @description - actions runs on submit the inbound search form
     * @return {React component}
     *
     * */
    onSubmit = (model) => {
        let {defaultRequestConst} = this.props;

        if (model.search) {
            this.props.searchForReturnOrder({
                route: 'getInboundReturns',
                isWithGetParams: true,
                requestData: {
                    returnsId: model.search.trim(),
                    ...defaultRequestConst
                }
            })
        }
    };

    /**
     * getCenterControlComponent {Function}
     * @description - generate template for controls for rendering in center of PageControlItems Component
     * @return {React component}
     *
     * */
    getCenterControlComponent = () => {
        let {classes} = this.props;
        return (
            <h1 className={`${classes.inboundHeader} b-app__page_content_title b-app__page_content_title--center_align b-inbound-page__additional_header`}>
                <CustomTranslation id="login.v2.inboundPage.startScanning"/>
            </h1>
        )
    };

    /**
     * getRightControlComponent {Function}
     * @description - generate template for controls for rendering in right side of PageControlItems Component
     * @return {React component}
     *
     * */
    getRightControlComponent = (currentReturnOrderId) => {

        let {isReadyForSubmit} = this.props;
        let {changedReturns} = this.state;

        return (
            <>
                <Button variant="contained"
                        color="primary"
                        data-order-id={currentReturnOrderId}
                        className={!isReadyForSubmit[currentReturnOrderId] ? `b-inbound-page__confirm_control_btn` : `b-inbound-page__confirm_control_btn b-inbound-page__confirm_control_btn--disabled`}
                        disabled={!isReadyForSubmit[currentReturnOrderId] && !changedReturns[currentReturnOrderId]}
                        onClick={() => {
                            this.openDialog(currentReturnOrderId)
                        }}>
                    <CustomTranslation id="return.order.savePartial.confirmation.submit.button"/>
                </Button>
            </>
        )
    };

    /**
     * getInboundSearchField {Function}
     * @description - generate template for search field on inbound page
     * @return {React component}
     *
     * */
    getInboundSearchField = () => {
        let {translate, searchValue, searchValuePlaceholder, wereItemsFound, isOrderFound} = this.props;
        return (
            <Search
                value={searchValue}
                formRef={this.searchForm}
                inputRef={this.searchInput}
                placeholder={!wereItemsFound && isOrderFound === null ? translate('login.v2.inboundPage.inboundSearchPlaceholder') : searchValuePlaceholder}
                onSubmitAction={this.onSubmit}
                onValidAction={this.enableButton}
                onInvalidAction={this.disableButton}
                onSearchFieldAction={this.inboundChangeSearchValue}
                onClearSearchAction={this.clearInboundState}
                isSearchButtonControlPresent={true}
                isClearSearchControlPresent={wereItemsFound}
                isSearchBtnDisabled={!searchValue}
                styleClass='b-search--inbound'
                isItInbound={true}
            />
        )
    };

    inboundChangeSearchValue = (e) => {
        this.props.changeSearchValue(e.target.value)
    };

    /**
     * toggleReport {Function}
     * @description - toggle the state of the report flag
     *
     * */
    toggleReport = (orderItemId, currentReturnOrderId) => {
        this.props.setReportItemState(orderItemId, currentReturnOrderId);
    };

    /**
     * sortInboundItemsRotate {Function}
     * @description - responsible to change order of inbound items
     *
     * */
    sortInboundItemsRotate = (orderIndex, currentReturnOrderId) => {
        setTimeout(() => {
            const items = this.props.updatedStateItems[currentReturnOrderId].slice();
            let removedItem = items.splice(orderIndex, 1)[0];
            items.push(removedItem);
            this.props.sortInboundItemsRotate('rotate', items, currentReturnOrderId);
        }, baseIntervalTime)
    };

    /**
     * renderInboundItems {Function}
     * @description - generate template fro each existed inbound items
     * @return {React component (collection)}
     *
     * */
    renderInboundItems = (items, currentReturnOrderId, returnOrder, currency) => {
        let {reportStatus, wasStatusChanged, wasProductChanged, isSendToSCLoading, wasReturnUpdated} = this.props;
        return items.map((productItem, i) => {
            let stateId = productItem.returnOrderItemId;

            if(!this.productIds[currentReturnOrderId]) {
                this.productIds[currentReturnOrderId] = {};
                this.productIds[currentReturnOrderId]['ids'] = [];
                this.productIds[currentReturnOrderId]['ids'].push(stateId);
            } else {
                this.productIds[currentReturnOrderId]['ids'].push(stateId)
            }
            return (
                <ListItem
                    key={stateId}
                    view={this.props.view}
                    currentReturnOrderId={currentReturnOrderId}
                    returnOrder={returnOrder}
                    currency={currency}
                    index={i}
                    orderIndex={i}
                    item={productItem}
                    toggleReportAction={this.toggleReport}
                    rotateOrderAction={this.sortInboundItemsRotate}
                    getBoldAction={this.getBold}
                    reportStatus={reportStatus}
                    wasStatusChanged={wasStatusChanged}
                    currentReturnItem={returnOrder}
                    wasProductChanged={wasProductChanged}
                    wasReturnUpdated={wasReturnUpdated}
                    parentComponent={this}
                    updateReturnCommentsStateAction={this.updateReturnCommentsState}
                    isSendToSCLoading={isSendToSCLoading}
                />
            );
        });
    };

    /**
     * updateReturnCommentsState {Function}
     * @description - change state according to was returned comments changed or not
     * @return - React Component
     *
     * */
    updateReturnCommentsState = (newState) => {
        let {changedReturns} = this.state;

        this.setState({
            changedReturns: {
                ...changedReturns,
                ...newState
            },
        })
    };

    /**
     * getBold {Function}
     * @description - return wrapped value into element styled for bold font weight
     * @return - React Component
     *
     * */
    getBold = (value) => {
        return <span className="b-inbound-page__bold_item">{value}</span>
    };

    /**
     * openDialog {Function}
     * @description - change state to open Dialog
     *
     * */
    openDialog = (currentReturnOrderId) => {
        if (currentReturnOrderId) {
            this.currentOrderIdToSave = currentReturnOrderId;
            this.setState({
                isDialogOpen: true
            });
        }
    };

    /**
     * closeDialog {Function}
     * @description - change state to close Dialog
     *
     * */
    closeDialog = () => {
        this.setState({
            isDialogOpen: false,
        }, () => {
            this.currentOrderIdToSave = null;
            this.props.closeConfirmationDialog();
        });
    };

    /**
     * closeDialog {Function}
     * @description - change state to close Dialog
     *
     * */
    closeSendToCsDialog = () => {
        this.props.closeSendToCustomerDialog()
    };

    /**
     * clearInboundState {Function}
     * @desription - reset Inbound Page state to default
     *
     * */
    clearInboundState = () => {
        this.props.clearInboundState();
        localStorage.removeItem('inboundFilters');

        let {current} = this.searchForm;
        let currentField = this.searchInput.current;
        current.reset();

        this.setState({
            changedReturns: {}
        }, () => {
            setTimeout(() => {
                if(currentField) {
                    currentField.focus()
                }
            }, 500);
        });

    };

    /**
     * handleNavigationAttempt {Function}
     * @description - calls whet message about unsaved changes is shown
     * @return {Null}
     *
     * */
    handleNavigationAttempt = (navigation, location, action) => {
        if (!this.navigation) {
            this.navigation = navigation;
        }
        this.setState({showSaveChangesDialog: true});
        // Return null to 'pause' and save the route so can 'resume'
        return null;
    };

    /**
     * closeSaveChangesDialog {Function}
     * @description - close message about unsaved changes
     * @return {Null}
     *
     * */
    closeSaveChangesDialog = () => {
        this.setState({showSaveChangesDialog: false})
    };

    /**
     * handleStay {Function}
     * @description - executes in case if user click 'save changes' on dialog about unsaved changes
     * @return {Null}
     *
     * */
    handleStay = () => {
        let {isReadyForSubmit} = this.props;
        let idsForSaving = [];

        for (let i in isReadyForSubmit) {
            if (isReadyForSubmit[i]) {
                idsForSaving.push(parseInt(i));
            }
        }

        this.closeSaveChangesDialog();
        this.navigation.resume();

        if (idsForSaving && idsForSaving.length) {
            idsForSaving.forEach((id) => {
                this.confirmChanges(id);
            })
        }
    };

    /**
     * handleLeave {Function}
     * @description - executes in case if user click 'do not save changes' on dialog about unsaved changes
     * @return {Null}
     *
     * */
    handleLeave = () => {
        let pausedLocation = this.navigation.pausedLocation().pathname;
        this.closeSaveChangesDialog();
        this.navigation.resume();
        setTimeout(() => {
            this.navigation.push(pausedLocation);
            this.navigation.cancel();
        }, baseIntervalTime);
    };

    /**
     * getConfirmBeforeSavingTitle {Function}
     * @description - get specific content for dialog to confirm return order changes
     * @return  {Array}
     *
     * */
    getConfirmBeforeSavingContent = () => {
        let {updatedStateItems, currentReturnItems, updatedReturnItems, reducedValues, returnFeeFixed, refund} = this.props;

        let idForSaving = this.currentOrderIdToSave;

        let currentReturnItem = find(currentReturnItems, (returnOrder) => {
            return returnOrder.getField('returnOrderId') === idForSaving;
        });
        let currentUpdatedReturnId = find(updatedReturnItems, (returnOrder) => {
            return returnOrder.getField('returnOrderId') === idForSaving;
        });
        let currentReturnOrderProducts = updatedStateItems[idForSaving];

        return getBeforeReturnUpdateTextsToConfirm({
            returnItemDefault: currentReturnItem,
            currentReturnItem: currentUpdatedReturnId,
            currentItemProducts: currentReturnOrderProducts,
            isReturnItemTotallyHandled: this.isReturnItemTotallyHandled,
            isReturnsPartiallyHandled: this.isReturnsPartiallyHandled,
            isInbound: true,
            reducedValues,
            returnFeeFixed,
            refund
        });
    };

    /**
     * getDataForRequest {Function}
     * @description - generates data to send on server to update Return Order
     * @return {Null}
     *
     * */
    getDataForRequest = (id) => {
        let {
            updatedStateItems,
            currentReturnItems,
            updatedReturnItems,
            currentReturnItemsUpdateInfo,
            currentOrderIdForSaving,
            returnFees,
            reducedValues,
            merchantFiles
        } = this.props;
        let idForSaving = id && !isObject(id) ? id : this.currentOrderIdToSave ? this.currentOrderIdToSave : currentOrderIdForSaving;
        let currentReturnItem = find(currentReturnItems, (returnOrder) => {
            return returnOrder.getField('returnOrderId') === idForSaving;
        });
        let currentUpdatedReturnId = find(updatedReturnItems, (returnOrder) => {
            return returnOrder.getField('returnOrderId') === idForSaving;
        });
        let currentReturnOrderProducts = updatedStateItems[idForSaving];
        let currentReturnItemUpdateInfo = currentReturnItemsUpdateInfo[idForSaving];
        let loginData = getDataFromSS('login');
        let requestData = {};
        let currentReducedValues = reducedValues && reducedValues[idForSaving] ? reducedValues[idForSaving] : null;
        let currentReturnFee = returnFees ? returnFees[idForSaving] || returnFees[idForSaving] == 0 ? returnFees[idForSaving] : null : null;
        let isApprovedByStatus = true;
        let currentMerchantFiles = merchantFiles && merchantFiles[idForSaving] ? merchantFiles[idForSaving] : null;

        if (currentReturnItem && currentReturnOrderProducts.length) {
            requestData.returnOrderId = currentReturnItem.getField('returnOrderId');
            requestData.newGift = currentReturnItemUpdateInfo.gift;
            requestData.removeReconversionOrder = currentReturnItemUpdateInfo.removeReconversionOrder;
            requestData.shopId = loginData.shopId;
            requestData.items = [];

            if(currentReturnFee || currentReturnFee === 0) {
                requestData.returnFeeFixed = currentReturnFee;
            }

            currentReturnOrderProducts.forEach((product) => {
                let {returnOrderItemId, orderItem: {orderItemId}, status} = product;
                let productMerchantFiles = currentMerchantFiles && currentMerchantFiles[returnOrderItemId] ? currentMerchantFiles[returnOrderItemId] : [];

                if (status === PENDING) {
                    requestData.approved = currentUpdatedReturnId.getField('approved') ? currentUpdatedReturnId.getField('approved') : false
                }

                if(status !== REJECTED && status !== REMOVED) {
                    isApprovedByStatus = false
                }

                let updatedProduct = {
                    diminishedPrice: status === REMOVED || status === REJECTED ? 0 : currentReducedValues && currentReducedValues[orderItemId] && currentReducedValues[orderItemId].newDiminishedPrice !== undefined ? currentReducedValues[orderItemId].newDiminishedPrice : product.diminishedPrice,
                    nextActionApiKey: product.nextAction,
                    removed: product.deleted,
                    returnOrderItemId: returnOrderItemId,
                    statusApiKey: status,
                    flagged: product.flagged,
                    merchantImages: productMerchantFiles.map((fileItem)=>{
                        let {file={}} = fileItem;
                        let {name=null, path='', size=null} = file;

                        return {
                            file: {
                                path: size ? name : path
                            }
                        }
                    })
                };

                if (this[returnOrderItemId] && this[returnOrderItemId].current) {
                    let textareaFields = this[returnOrderItemId].current.querySelectorAll('textarea');

                    if (textareaFields && textareaFields.length === 2) {
                        if (textareaFields[0] && textareaFields[0].value) {
                            updatedProduct.managerComment = textareaFields[0].value;
                        }

                        if (textareaFields[1] && textareaFields[1].value) {
                            updatedProduct.privateComment = textareaFields[1].value;
                        }
                    }

                    this[returnOrderItemId] = null;
                }

                if (product.condition) {
                    updatedProduct.conditionApiKey = product.condition;
                }

                if (product.status === ARRIVED) {
                    updatedProduct.arrivalDate = new Date().getTime();
                }

                requestData.items.push(updatedProduct);

            });

            requestData.approved = currentUpdatedReturnId.getField('approved') || isApprovedByStatus;
        }

        return requestData;

    };

    /**
     * confirmChanges {Function}
     * @description - executes when user click to save confirmation to save changes made on Return Order
     *
     * */
    confirmChanges = (id = null) => {
        let {current} = this.searchForm;
        current.reset();

        this.props.updateReturnOrder({
            route: 'updateReturnOrder',
            requestData: this.getDataForRequest(id)
        });

        //this.clearInboundState();
        this.closeDialog();
    };

    /**
     * handleCustomerInfoExpand {Function}
     * @desription - expand/collapse customer Info panel
     *
     * */
    handleCustomerInfoExpand = (key, barcodeId, returnId) => {
        let {currentExpandPanel} = this.props;
        let index = currentExpandPanel === key ? 999999 : key;
        let {changedReturns} = this.state;

        this.setState({
            changedReturns: {
                ...changedReturns,
                [returnId]: false
            }
        }, () => {
            this.props.setExpandPanelIndex(index);
        })
    };

    getReturnValue = (items = []) => {
        let price = 0;

        items.forEach((product) => {
            price += product.diminishedPrice
        });

        return price;
    };

    /**
     * goBackToMetricState {Function}
     * @description - clear inbound state and show state with metric
     *
     * */
    goBackToMetricState = () => {
        this.props.clearInboundState();
    };

    /**
     * getConfirmUpdatePopupTitle {Function}
     * @description - get appropriate title for return order update confirm popup
     * @return {React Component}
     *
     * */
    getConfirmUpdatePopupTitle = () => {
        let title = <CustomTranslation id='return.order.confirmation.titles.default'/>;
        return title;
    };

    /**
     * getConfirmUpdatePopupContent {Function}
     * @description - get appropriate content for return order update confirm popup
     * @return {React Component}
     *
     * */
    getConfirmUpdatePopupContent = () => {
        let content = <CustomTranslation id='return.order.confirmation.success.popup.text'/>;
        return content;
    };

    /**
     * isSaveReminderShouldBeShown {Function}
     * @description - checks if we need to save show notification about unsaved changes
     * @return {Boolean}
     *
     * */
    isSaveReminderShouldBeShown = () => {
        let {isReadyForSubmit} = this.props;
        let isNeedToBeShown = false;

        for (let i in isReadyForSubmit) {
            if (isReadyForSubmit[i]) {
                isNeedToBeShown = true;
            }
        }

        return isNeedToBeShown;
    };

    /**
     * confirmAction {Function}
     * @description - method fires on click 'Ok' button on return order update confirm popup
     *
     * */
    confirmAction = () => {
        this.props.closeConfirmUpdateOrder();
    };

    /**
     * getConfirmBeforeSavingTitle {Function}
     * @description - get specific title for dialog to confirm return order changes
     * @return  {String}
     *
     * */
    getConfirmBeforeSavingTitle = () => {
        return <CustomTranslation id='return.before.update.popup.title'/>;
    };

    /**
     * isReturnItemTotallyHandled {Function}
     * @description - go through all return item products and check if they were handled
     * @return  {Boolean}
     *
     * */
    isReturnItemTotallyHandled = (items) => {
        let isReturnItemTotallyHandled = true;

        for (let i in items) {
            let currentItem = items[i];
            if (currentItem.status === PENDING) {
                isReturnItemTotallyHandled = false;
            }
        }
        return isReturnItemTotallyHandled;
    };

    /**
     * isAllItemsRejectedRemoved {Function}
     * @description - go through all return item products and check if all of them have status REMOVED and REJECTED
     * @return  {Boolean}
     *
     * */
    isAllItemsRejectedRemoved = (items) => {
        let isAllItemsRejectedRemoved = true;

        for (let i in items) {
            let currentItem = items[i];
            if(currentItem.status !== REJECTED && currentItem.status !== REMOVED) {
                isAllItemsRejectedRemoved = false;
            }
        }

        return isAllItemsRejectedRemoved;
    };

    /**
     * isReturnsPartiallyHandled {Function}
     * @description - go through all return item products and check if at least one of them has no status PENDING
     * @return  {Boolean}
     *
     * */
    isReturnsPartiallyHandled = (items) => {
        let isPartiallyHanded = false;

        for (let i in items) {
            let currentItem = items[i];
            if (currentItem.status !== PENDING) {
                isPartiallyHanded = true
            }
        }
        return isPartiallyHanded;
    };

    /**
     * toggleApproveRefundExchange {Function}
     * @description - handle the ability to enable/disable approve refund/exchange checkbox
     *
     * */
    toggleApproveRefundExchange = (currentReturnOrderId) => {
        this.props.toggleApproveRefundExchange(currentReturnOrderId);
    };

    /**
     * getPartialApprovedBlock {Function}
     * @description - need to render controls for partially approving order
     * @return {React component}
     *
     * */
    getPartialApprovedBlock = (returnOrder, isExpanded, products) => {
        let returnOrderId = returnOrder.getField('returnOrderId');
        return (
            <div className="b-inbound-item__arrived_indicator">
                <PartialApproval
                    isReturnItemTotallyHandled={this.isReturnItemTotallyHandled(products)}
                    isReturnsPartiallyHandled={this.isReturnsPartiallyHandled(products)}
                    isAllItemsRejectedRemoved={this.isAllItemsRejectedRemoved(products)}
                    toggleApproveRefundExchange={() => {
                        this.toggleApproveRefundExchange(returnOrderId)
                    }}
                    item={returnOrder}
                    className={isExpanded ? 'b-partial-approval--inbound b-partial-approval--inbound_expanded' : 'b-partial-approval--inbound'}
                />
            </div>
        )
    };

    /**
     * onChangeCurrentPageAction {Function}
     * @description - action calls on page change using table next/previous controls
     *
     * */
    onChangeCurrentPageAction = (nextCurrentPage) => {
        let {defaultRequestConst, searchValuePlaceholder} = this.props;

        this.setState({
            currentPage: nextCurrentPage
        }, () => {
            this.props.searchForReturnOrder({
                route: 'getInboundReturns',
                isWithGetParams: true,
                requestData: {
                    returnsId: searchValuePlaceholder,
                    ...defaultRequestConst,
                    page: nextCurrentPage
                }
            });
        })

    };

    /**
     * goToAppropriateTablePage {Function}
     * @description - handle table paging change
     *
     * */
    goToAppropriateTablePage = (isPrev = false) => {
        let {currentPage} = this.state;

        if (isPrev && currentPage !== 0) {
            this.onChangeCurrentPageAction(currentPage - 1)
        } else {
            this.onChangeCurrentPageAction(currentPage + 1)
        }

    };


    componentWillUnmount() {
        localStorage.removeItem('inboundFilters');
        this.props.clearInboundState();
    };

    componentDidMount() {
        let currentField = this.searchInput.current;
        let {pageName} = this.props;
        this.props.setCurrentPageTitle(pageName);

        setTimeout(() => {
            if(currentField) {currentField.focus()}
        }, 500);

        localStorage.removeItem('inboundFilters');
    };

    componentDidUpdate(prevProps, prevState, snapShots) {
        let {isOrderFound, updatedStateItems, currentReturnItems=[], defaultRequestConst, were3ImagesUploaded, data} = this.props;
        if (prevProps.isOrderFound !== isOrderFound && isOrderFound === true) {
            this.setState({
                isAddInfoExpanded: true
            })
        }

        if (prevProps.updatedStateItems !== updatedStateItems) {
            for(let key in updatedStateItems) {
                let currentReturnOrder = find(currentReturnItems, (returnItem)=>{return parseInt(returnItem.getField('returnOrderId')) === parseInt(key)});
                let currentReturnId = currentReturnOrder ? currentReturnOrder.getField('returnOrderId') : null;
                let prevUpdatedReturnOrder = prevProps.updatedStateItems[currentReturnId];
                let currentUpdateReturnOrder = updatedStateItems[currentReturnId];
                let wasDiminishedPriceUpdated = false;


                currentUpdateReturnOrder.forEach((currentProduct)=>{
                    let currentProductId = currentProduct.returnOrderItemId;
                    let prevProduct = find(prevUpdatedReturnOrder, (prevItem) => { return prevItem.returnOrderItemId === currentProductId});

                    if(prevProduct) {
                        if(prevProduct.diminishedPrice !== currentProduct.diminishedPrice) {
                            wasDiminishedPriceUpdated = true
                        }
                    }
                });

                if(wasDiminishedPriceUpdated) {
                    this.props.setDescriptionRefund(getDiminishedPrice(updatedStateItems[key], currentReturnOrder), currentReturnId)
                }
            }

        }

        //need to reload return data after it was saved on server side
        if(prevProps.were3ImagesUploaded !== were3ImagesUploaded) {
            this.props.searchForReturnOrder({
                route: 'getInboundReturns',
                isWithGetParams: true,
                requestData: {
                    returnsId: this.searchInput.current.placeholder,
                    ...defaultRequestConst
                }
            })
        }

        if(prevProps.data !== data && data.getField('requestStatus') === 'error') {
            console.log('inbound data was not loaded')
        }

        /*if(prevProps.currentReturnItems !== this.props.currentReturnItems) {
            this.props.currentReturnItems.forEach((returnOrder)=> {
                let returnId = returnOrder.getField('returnOrderId');
                let target = document.getElementById(`saveBarcode_${returnId}`);
                JsBarcode(target, 'returnadoSave', {
                    background: "transparent",
                    lineColor: "#ffffff",
                    height: 115,
                    fontSize: 0,
                    marginBottom: 0
                });
            })
        }*/
    }

    render() {
        let {
            data,
            isFoldedOpen,
            wereItemsFound = false,
            classes,
            isDialogActive,
            isDialogSendToCSActive,
            isOrderFound,
            updatedStateItems,
            isLoading,
            enterLeaveAnimation,
            updatedReturnItems,
            currentExpandPanel,
        } = this.props;
        let isExternalIdToShow = getBooleanShopProperty(this.props, 'use.sequential.order.external.id');
        let sortedReturnItems = [];

        if(updatedReturnItems && updatedReturnItems.length) {
            sortedReturnItems = orderBy(updatedReturnItems, (returnItem)=>{ return returnItem.toJSONView().returnOrderId }, ['desc'])
        }

        let {isDialogOpen, showSaveChangesDialog} = this.state;

        return (
            getBooleanShopProperty(this.props, 'inbound.page.enabled')
                ?
                <div className='b-app__page b-inbound-page'>
                <ConfirmBeforeUpdateDialog
                    isOpen={isDialogOpen || isDialogActive}
                    title={this.getConfirmBeforeSavingTitle()}
                    description={<CustomTranslation id='return.before.update.popup.description'/>}
                    content={isDialogOpen || isDialogActive ? this.getConfirmBeforeSavingContent() : []}
                    confirmAction={this.confirmChanges}
                    closeAction={this.closeDialog}
                />
                <ConfirmUpdateDialog
                    isOpen={/*isUpdateConfirmPopupActive*/false}
                    title={this.getConfirmUpdatePopupTitle()}
                    content={this.getConfirmUpdatePopupContent()}
                    confirmAction={this.confirmAction}
                />
                <ConfirmUpdateDialog
                    isOpen={isDialogSendToCSActive}
                    title={<CustomTranslation id='login.v2.inboundPage.SendToCs.popup.title'/>}
                    content={<CustomTranslation id='login.v2.inboundPage.SendToCs.popup.text' data={{
                        customerSupportEmail: 'customerservice@royaldesign.com',
                    }}/>}
                    confirmAction={this.closeSendToCsDialog}
                />
                <CustomDialog
                    isOpen={showSaveChangesDialog}
                    title={<CustomTranslation id='return.order.leave.unsaved.warning.title'/>}
                    content={<CustomTranslation id='return.order.leave.unsaved.warning.text'/>}
                    closeAction={this.handleLeave}
                    acceptAction={this.handleStay}
                    acceptBtnName={<CustomTranslation id='return.order.leave.unsaved.warning.save.button'/>}
                    closeBtnName={<CustomTranslation id='return.order.leave.unsaved.warning.leave.button'/>}
                />
                <ReactRouterPause
                    handler={this.handleNavigationAttempt}
                    when={this.isSaveReminderShouldBeShown()}
                    config={{allowBookmarks: true}}
                />
                {
                    isLoading && <Loader wrapperClass='b-inbound-page__loader_wrapper'/>
                }
                <PageContent isFoldedOpen={isFoldedOpen} classNameCustom='b-page-content--inbound'>
                    <>
                        {
                            !wereItemsFound && isOrderFound === null
                            &&
                            <div className="b-inbound-page__headline">
                                {this.getCenterControlComponent()}
                            </div>
                        }

                        <div className="b-inbound-page__search_wrapper">
                            {this.getInboundSearchField()}
                        </div>
                        <div className="b-inbound-page__body">
                            {
                                sortedReturnItems.map((returnOrder, key) => {
                                    let {orderExternalSequentialId = '', orderExternalId=''} = returnOrder.getField('order') || {};
                                    let currentReturnOrderId = returnOrder.getField('returnOrderId');
                                    let currency = returnOrder.getField('currency') || '';
                                    let products = updatedStateItems[currentReturnOrderId];
                                    let isExpanded = key === currentExpandPanel;
                                    let relatedOrderId = isExternalIdToShow ? orderExternalSequentialId ? orderExternalSequentialId : orderExternalId : orderExternalId;
                                    return (
                                        wereItemsFound && isOrderFound === true
                                        &&
                                        <div className="b-inbound-page__sub_header"
                                             key={currentReturnOrderId + '_' + key}>
                                            <Grid container className="b-grid__container b-inbound-item__body" spacing={0}>
                                                <Grid item xs={12} lg={12} className="b-inbound-item__block"></Grid>
                                                <Grid item xs={12} lg={12} className="b-inbound-item__block">
                                                    <div className="b-inbound-page__customer_info">
                                                        {this.getPartialApprovedBlock(returnOrder, isExpanded, products)}
                                                        <ExpansionPanel className="b-inbound-page__accordion"
                                                                        expanded={isExpanded} onChange={() => {
                                                            this.handleCustomerInfoExpand(key, `saveBarcode_${currentReturnOrderId}`, returnOrder.getField('returnOrderId'))
                                                        }}>
                                                            <ExpansionPanelSummary expandIcon={<img alt='icon' src={ExpandIconSrc} className="b-inbound-item__expand_icon" />}
                                                                                   className={isExpanded ? "b-inbound-item__accordion_control_panel" : "b-inbound-item__accordion_control_panel b-inbound-item__accordion_control_panel--collapsed"}>

                                                                {
                                                                    returnOrder.getField('mismatch')
                                                                        ?
                                                                        <Tooltip title={''} className="b-mismatch-tooltip b-mismatch-tooltip--inbound" placement="top">
                                                                            <WarningIcon className="b-mismatch-tooltip__mismatch_warning_icon b-mismatch-tooltip__mismatch_warning_icon--inbound" fontSize="default"/>
                                                                        </Tooltip>
                                                                        :
                                                                        ''
                                                                }
                                                                <CustomTranslation
                                                                    id='login.v2.inboundPage.orderDetailsMessage'
                                                                    data={{
                                                                        id: this.getBold(returnOrder.getField('returnOrderId')),
                                                                        number: <a className="b-inbound-page__order_id_relation" href={`#/orders/${relatedOrderId}`}>{this.getBold(relatedOrderId)}</a>,
                                                                        itemsCount: this.getBold(products.length || 0),
                                                                        returnValue: this.getBold(toFixedCurrency(this.getReturnValue(products), currency))
                                                                    }}/>
                                                            </ExpansionPanelSummary>
                                                            <ExpansionPanelDetails className="b-inbound-page__accordion_main">
                                                                <div
                                                                    className='b-inbound-item__accordion_details_block'>
                                                                    <Grid container className={`b-grid__container`} spacing={0}>
                                                                        <Grid item xs={12} md={12} lg={12} className="b-inbound-page__block">
                                                                            {
                                                                                returnOrder.getField('mismatch')
                                                                                    ?
                                                                                    <div className="b-log-event-popup__left_align">
                                                                                        <ReturnEventLogPopup isPopupView={false} returnOrderId={returnOrder.getField('returnOrderId')}/>
                                                                                    </div>
                                                                                    :
                                                                                    ''
                                                                            }
                                                                            <ReturnDescription
                                                                                status={returnOrder.getField('status')}
                                                                                items={returnOrder.getField('items')}
                                                                                currentItemProducts={products}
                                                                                returnItem={returnOrder}
                                                                                styleClass='b-return-description--inbound'
                                                                                wasEventPopupRendered={key == 0 ? false : true}
                                                                                isInbound={true}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                <div
                                                                    className={wereItemsFound && isOrderFound === true ? 'b-inbound-page__visible' : 'b-inbound-page__hided'}>
                                                                    <div className="b-inbound-item__body">
                                                                        <div
                                                                            className={`b-inbound-page__confirm_control`}>
                                                                            <div
                                                                                className={`b-inbound-page__confirm_control_body`}>
                                                                                {this.getRightControlComponent(currentReturnOrderId)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="b-inbound-item__body">
                                                                        {
                                                                            key === currentExpandPanel && products && products.length &&
                                                                            <FlipMove
                                                                                key={currentReturnOrderId}
                                                                                staggerDurationBy="150"
                                                                                duration={300}
                                                                                easing="ease-out"
                                                                                enterAnimation="fade"
                                                                                leaveAnimation={enterLeaveAnimation}
                                                                                typeName="ul"
                                                                                className="b-inbound-page__list"
                                                                            >
                                                                                {this.renderInboundItems(products, currentReturnOrderId, returnOrder, currency)}
                                                                            </FlipMove>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </ExpansionPanelDetails>
                                                        </ExpansionPanel>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} lg={1} className="b-inbound-item__block"> </Grid>
                                            </Grid>
                                        </div>
                                    )
                                })
                            }
                            {
                                Object.keys(updatedStateItems).length > 1
                                    ?
                                    <Grid container className={`b-grid__container b-inbound-item__body`} spacing={0}>
                                        <Grid item xs={12} md={1} lg={1} className="b-inbound-item__block"></Grid>
                                        <Grid item xs={12} md={10} lg={10} className="b-inbound-item__block">
                                            <div className="b-inbound-page__pagination_wrapper">
                                                <Pagination
                                                    currentPage={this.state.currentPage}
                                                    totalPages={Math.ceil(data.getField('paging').total / 10)}
                                                    pageSizeOption={this.state.pageSizeOption}
                                                    data={data.getField('data')}
                                                    prevAction={() => {
                                                        this.goToAppropriateTablePage(true)
                                                    }}
                                                    nextAction={() => {
                                                        this.goToAppropriateTablePage()
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={1} lg={1} className="b-inbound-item__block"></Grid>
                                    </Grid>
                                    :
                                    ''
                            }
                            {
                                isOrderFound === false
                                    ?
                                    <>
                                        <h2 className={`${classes.inboundHeader} b-app__page_content_title b-app__page_content_title--center_align b-inbound-page__additional_header`}>
                                            <CustomTranslation id='login.v2.inboundPage.noReturnFound'/>
                                        </h2>
                                        <p className='b-inbound-page__no_order_found_control'>
                                            <CustomTranslation id='login.v2.inboundPage.ifCreateReturnForCustomer'/>
                                            <Link to='/'
                                                  className='b-inbound-page__no_order_found_control_link'><CustomTranslation
                                                id='login.v2.inboundPage.ClickHere.Link.text'/></Link>
                                        </p>
                                        <p className='b-inbound-page__no_order_found_control'>
                                            <CustomTranslation
                                                id='login.v2.inboundPage.ifPerformRefindReturnFiltering'/> <Link
                                            to='/returns'
                                            className='b-inbound-page__no_order_found_control_link'><CustomTranslation
                                            id='login.v2.inboundPage.ClickHere.Link.text'/></Link>
                                        </p>
                                        <p className='b-inbound-page__no_order_found_control'>
                                            <Button variant="contained" color="primary"
                                                    onClick={this.goBackToMetricState}>
                                                <CustomTranslation id='login.v2.inboundPage.btn.goBack.text'/>
                                            </Button>
                                        </p>
                                    </>
                                    :
                                    ''
                            }


                            {
                                isOrderFound === null
                                    ?
                                    <DashboardsMetrics wereItemsFound={wereItemsFound} type='Inbound'/>
                                    :
                                    ''
                            }
                        </div>
                    </>
                </PageContent>
            </div>
                :
                <Redirect to="/"/>
        )
    };
}

function mapStateToProps(state) {
    return {
        ...state.inboundPageState,
        ...state.fixedPercentageSelectorState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            searchForReturnOrder,
            sortInboundItemsRotate,
            setReportItemState,
            clearInboundState,
            changeSearchValue,
            closeConfirmationDialog,
            closeSendToCustomerDialog,
            setExpandPanelIndex,
            updateReturnOrder,
            closeConfirmUpdateOrder,
            setCurrentPageTitle,
            toggleApproveRefundExchange,
            cleanComment,
            setDescriptionRefund
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles, {withTheme: true})(withRouter(Inbound)));