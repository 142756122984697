import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withStyles} from "@material-ui/core/styles";
import {Divider} from "@material-ui/core";
import List from "@material-ui/core/List";
import {withRouter} from "react-router";
import {closeMobileMenu} from "./actions/mainNavigationActions";
import CustomTranslation from "./../CustomTranslation/CustomTranslation";
import configuration from "../../configs/configs";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Remove from "@material-ui/icons/Remove";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Replay from "@material-ui/icons/Replay";
import Group from "@material-ui/icons/Group";
import CreditCard from "@material-ui/icons/CreditCard";
import Description from "@material-ui/icons/Description";
import Settings from "@material-ui/icons/Settings";
import Security from "@material-ui/icons/Security";
import Analytics from "@material-ui/icons/Assessment";
import GroupIcon from '@material-ui/icons/Group';
import TrackChanges from "@material-ui/icons/TrackChanges";
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import logoSrc from "./../../images/general/logo-element.png";
import logoTextSrc from "./../../images/general/logo-text.png";
import {getBooleanShopProperty, isCurrentManagerSuperAdmin} from "./../../utilites";
import {find, isEmpty} from 'lodash';

const drawerWidth = 280;

const styles = theme => {
    return {
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px',
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        leftMenuHeader: {
            ...theme.mixins.toolbar,
        },
        mainNavCollapse: {
            background: theme.palette.brand.main[600],
        },
        navbarContent: {
            flex: '1 1 auto',
        },
        menuItemIconColor: {
            color: theme.palette.primary.main
        },
        menuItemText: {
            color: theme.palette.secondary.main,
            padding: '0 5px'
        },
        item: {
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.secondary.main
        }
    }
};


let DefaultNavHeader = ({data, classes, currentShopLogoClass, logoSrc}) => {
    return (
        <div className={`b-navigation-menu__header ${classes.leftMenuHeader}`}>
            <img alt={''} src={logoSrc}
                 className={currentShopLogoClass ? `b-navigation-menu__logo b-navigation-menu__logo--${currentShopLogoClass}` : 'b-navigation-menu__logo'}/>
        </div>
    )
};

let MobileNavHeader = ({
                           data, classes, theme, clickAction = () => {
    }
                       }, logoSrc) => {
    return (
        <div className={classes.drawerHeader}>
            <IconButton onClick={clickAction}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
            </IconButton>
        </div>
    )
};

let managementName = 'userManagement';
let ordersName = 'orders';
let returnsName = 'return.orders';
let customersName = 'customers';
let billingName = 'billing';
let reportsName = 'reports';
let settingsName = 'settings';
let securityName = 'security';
let inboundName = 'inbound';
let analyticsName = 'analytics';


class MainNavigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: {}
        };
    }

    /**
     * getIcon {Function}
     * @description - defines appropriate icon for main navigation item item
     * @ return {Material-ui Icon Component}
     *
     * */
    getIcon = (menuId) => {

        switch (menuId) {
            case managementName:
                return <GroupIcon />;
            case ordersName:
                return <ShoppingCart/>;
            case returnsName:
                return <Replay/>;
            case customersName:
                return <Group/>;
            case billingName:
                return <CreditCard/>;
            case reportsName:
                return <Description/>;
            case settingsName:
                return <Settings/>;
            case securityName:
                return <Security/>;
            case analyticsName:
                return <Analytics/>;
            default:
                return <TrackChanges/>;
        }
    };


    /**
     * handleClick {Function}
     * @description - defines appropriate icon for main navigation item item
     * @ return {Material-ui Icon Component}
     *
     * */
    handleClick = (name) => {

        let {isOpen} = this.state;

        if (isOpen[name] === undefined) {
            this.setState(state => ({
                isOpen: {
                    ...isOpen,
                    [name]: true
                }
            }))
        } else {
            this.setState(state => ({
                isOpen: {
                    ...isOpen,
                    [name]: !isOpen[name]
                }
            }))
        }


    };

    /**
     * getCurrentShopLogoClass {Function}
     * @description - convert string to use it in class name of current logo
     * @return {String}
     *
     * */
    getCurrentShopLogoClass = (mainSettings) => {
        let config = mainSettings && mainSettings.getField ? mainSettings.getField('response') : {};
        let classNameStr = '';
        let shopType = null;
        let shopUITheme = null;

        if (!isEmpty(config)) {
            shopType = find(config, (setting) => {
                return setting.name === 'shop.type';
            })
        }

        if (!isEmpty(config)) {
            shopUITheme = find(config, (setting) => {
                return setting.name === 'ui.theme';
            })
        }

        classNameStr += shopType ? shopType.value : '';
        classNameStr += shopUITheme ? '_' + shopUITheme.value : '';

        return classNameStr ? classNameStr.toLowerCase() : '';
    };

    /**
     * getCollapseState {Function}
     * @description - In case of submenu set active/inactive state
     * @return {Boolean}
     *
     * */
    getCollapseState = (name) => {
        let {isOpen} = this.state;
        let {isFoldedOpen, isMobile} = this.props;
        let isCollapsed = isOpen[name] === undefined ? false : isOpen[name];

        return isFoldedOpen ? isCollapsed : isMobile ? isCollapsed : false;
    };

    /**
     * isEnabled {Function}
     * @description - define is page enabled based on settings from server side.
     * @return {Boolean}
     *
     * */
    isEnabled = (item) => {
        switch (item.name) {
            case managementName:
                return getBooleanShopProperty(this.props, 'management.page.enabled');
            case ordersName:
                return getBooleanShopProperty(this.props, 'orders.page.enabled');
            case returnsName:
                return getBooleanShopProperty(this.props, 'returns.page.enabled');
            case customersName:
                return getBooleanShopProperty(this.props, 'customers.page.enabled');
            case billingName:
                return getBooleanShopProperty(this.props, 'billing.page.enabled');
            case reportsName:
                return getBooleanShopProperty(this.props, 'reports.page.enabled');
            case settingsName:
                return getBooleanShopProperty(this.props, 'settings.page.enabled');
            case securityName:
                return getBooleanShopProperty(this.props, 'security.page.enabled');
            case inboundName:
                return getBooleanShopProperty(this.props, 'inbound.page.enabled');
            case analyticsName:
                return getBooleanShopProperty(this.props, 'analytics.page.enabled');
            default:
                return true;
        }
    };

    /**
     * isPageVisibleForNonSuperAdminUser {Function}
     * @description - define is page enabled based on current manager admin status.
     * @return {Boolean}
     *
     * */
    isPageVisibleForNonSuperAdminUser = (item) => {
        let isSuperAdmin = isCurrentManagerSuperAdmin();
        let isVisible = true;

        if(item.name === managementName && !isSuperAdmin) {
            isVisible = false;
        }

        return isVisible;
    };

    /**
     * getCurrentShopLogoSRC {Function}
     * @description - get shop logo url
     * @return {String || Null}
     * */
    getCurrentShopLogoSRC = (mainSettings) => {
        let config = mainSettings && mainSettings.getField ? mainSettings.getField('response') : {};
        let logoSrc = null;

        if (!isEmpty(config)) {
            logoSrc = find(config, (setting) => {
                return setting.name === 'shop.logo.url';
            })
        }

        return logoSrc && logoSrc.value ? logoSrc.value : null;
    };

    render() {
        let {classes, data, match, theme, isMobile = false, isFoldedOpen, isMobileActive = false, mainConfig} = this.props;
        let currentShopLogoClass = this.getCurrentShopLogoClass(mainConfig.mainSettings);
        let logoSrcStr = this.getCurrentShopLogoSRC(mainConfig.mainSettings);

        return (
            <nav className={classes.navbarContent}>
                {
                    isMobile
                        ?
                        <MobileNavHeader logoSrc={logoSrcStr} classes={classes} data={data} theme={theme} clickAction={() => {
                            this.props.closeMobileMenu(false)
                        }}/>
                        :
                        <DefaultNavHeader logoSrc={logoSrcStr} classes={classes} data={data} currentShopLogoClass={currentShopLogoClass}/>
                }
                <Divider/>
                <List>
                    {
                        configuration.navigation.map((item, iterator) => {
                            let itemElem = '';
                            let {items, name} = item;
                            items
                                ?
                                itemElem = <React.Fragment key={iterator}>
                                    <ListItem
                                        onClick={() => {
                                            this.handleClick(name)
                                        }}
                                        button
                                        className={`${classes.item} list-item b-navigation-menu__item`}
                                    >
                                        <ListItemIcon
                                            className={`b-navigation-menu__item_icon ${classes.menuItemIconColor}`}>
                                            {this.getIcon(name)}
                                        </ListItemIcon>
                                        <ListItemText disableTypography className={classes.menuItemText} inset primary={<CustomTranslation id={`sideMenu.${item.name}`} />}/>
                                        {this.getCollapseState(name) ? <ExpandLess/> : <ExpandMore/>}
                                    </ListItem>
                                    <Collapse in={this.getCollapseState(name)} timeout="auto" unmountOnExit className={classes.mainNavCollapse}>
                                        <List component="div" disablePadding>
                                            {
                                                items.map((subItem, key) => {
                                                    return (
                                                        <ListItem
                                                            key={key}
                                                            button
                                                            href={`#${subItem.route}`}
                                                            component='a'
                                                            selected={match.path === subItem.route}
                                                            className={`${classes.item} list-item b-navigation-menu__item`}
                                                        >
                                                            <ListItemIcon
                                                                className={`b-navigation-menu__item_icon ${classes.menuItemIconColor}`}>
                                                                <Remove/>
                                                            </ListItemIcon>
                                                            <ListItemText disableTypography
                                                                          className={classes.menuItemText} inset
                                                                          primary={<CustomTranslation
                                                                              id={`sideMenu.${subItem.name}`}/>}/>
                                                        </ListItem>);
                                                })
                                            }
                                        </List>
                                    </Collapse>
                                </React.Fragment>
                                :
                                itemElem = <ListItem
                                    key={iterator}
                                    href={`#${item.route}`}
                                    component='a'
                                    button
                                    selected={match.path === item.route}
                                    className={`${classes.item} list-item b-navigation-menu__item`}
                                >
                                    <ListItemIcon
                                        className={`b-navigation-menu__item_icon ${classes.menuItemIconColor}`}>
                                        {this.getIcon(name)}
                                    </ListItemIcon>
                                    <ListItemText disableTypography className={classes.menuItemText}
                                                  primary={<CustomTranslation id={`sideMenu.${name}`}/>}/>
                                </ListItem>;
                            return this.isEnabled(item) && this.isPageVisibleForNonSuperAdminUser(item) ? itemElem : '';
                        })
                    }
                </List>
                <Divider/>
                <div
                    className={isFoldedOpen || isMobileActive ? `${classes.drawerHeader} b-navigation-menu__bottom_logo_wrapper b-navigation-menu__bottom_logo_wrapper--open` : `${classes.drawerHeader} b-navigation-menu__bottom_logo_wrapper`}>
                    <img alt={''} src={logoSrc} className='b-navigation-menu__bottom_logo'/>
                    <img alt={''} src={logoTextSrc} className='b-navigation-menu__bottom_logo_text'/>
                </div>
            </nav>
        )
    };
}

function mapStateToProps({leftSideBarState}) {
    return {
        ...leftSideBarState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            closeMobileMenu
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles, {withTheme: true})(withRouter(MainNavigation)));