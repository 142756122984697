import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import ReactSelectMaterialUi from "react-select-material-ui";
import {getTranslate} from "react-localize-redux";
import {Tooltip, TextField} from '@material-ui/core';
import {find} from 'lodash';
import ErrorIcon from '@material-ui/icons/Error';
import CustomTranslation from "../CustomTranslation/CustomTranslation";
import InputAdornment from "@material-ui/core/InputAdornment";
import {setRefundListReturnFee, clearData} from "./actions/fixedFeeSelectorActions";
import {Cancel} from "@material-ui/icons";

class FixedFeeSelector extends Component {

    constructor(props) {
        super(props);
        this.select = React.createRef();
    }

    /**
     * changeAction {Function}
     * @description - change action to apply fixed return fee
     *
     * */
    changeAction = (value)=>{
        let {
            returnOrderId = null,
            defaultReturnFee='',
        } = this.props;
        if(value || parseInt(value) === 0) {
            this.props.setRefundListReturnFee(value, returnOrderId)
        } else {
            this.props.setRefundListReturnFee(defaultReturnFee, returnOrderId)
        }
    };

    /**
     * clearAction {Function}
     * @description - clear action to apply fixed return fee
     *
     * */
    clearAction = ()=>{
        let {
            returnOrderId = null,
        } = this.props;

        this.props.setRefundListReturnFee('', returnOrderId);
    };

    /**
     * isDropDownVisible {Function}
     * @description - define are options for dropdown available or not
     * @return {Boolean}
     *
     * */
    isDropDownVisible = (options) => {
        return options && options.length;
    };

    componentDidUpdate(prevProps, prevState, snapshots) {
        /*let {wasDataCleared, returnOrderId} = this.props;
        if (prevProps.returnOrderId !== returnOrderId || prevProps.wasDataCleared !== wasDataCleared) {
            this.setState({
                value: ''
            }, () => {
                if (this.select && this.select.current) {
                    this.select.current.setState({
                        selectedOption: null,
                    });
                }
            })
        }*/
    }

    componentWillUnmount() {
        this.props.clearData()
    }

    render() {
        let {
            returnFeeFixedError = null,
            shopId = null,
            translate,
            isDisabled=false,
            isReconversionRemoved=false,
            isReturnFeeAllow=true,
            returnFees,
            returnOrderId
        } = this.props;
        let shopSettings = this.props.mainSettings.toJSONView();
        let settingsOptions = find(shopSettings.response, (setting) => {
            return setting.name === 'return.returnFeeFixed.options'
        });
        let settingsOptionsItem = settingsOptions ? JSON.parse(settingsOptions.value) : null;
        let currentReturnFixedFeeOptions = settingsOptionsItem && settingsOptionsItem[shopId] ? settingsOptionsItem[shopId] : null;
        let optionsForDropDown = [];
        let value = returnFees[returnOrderId] || returnFees[returnOrderId] === 0 ? returnFees[returnOrderId] : '';
        if (settingsOptions && shopId && currentReturnFixedFeeOptions && currentReturnFixedFeeOptions.length) {
            optionsForDropDown = settingsOptionsItem[shopId].map((item) => {
                return {
                    value: item,
                    label: item
                }
            });
        }
        return (
                <div className="b-fixed-fee-selector">
                    <div className={value && (returnFeeFixedError || !isReturnFeeAllow) ? "b-fixed-fee-selector__hybrid b-fixed-fee-selector__hybrid--error" : "b-fixed-fee-selector__hybrid"}>
                        <TextField
                            className={this.isDropDownVisible(optionsForDropDown) ? isDisabled ? "b-fixed-fee-selector__control_input b-fixed-fee-selector__control_input--disabled" : "b-fixed-fee-selector__control_input" : isDisabled ? "b-fixed-fee-selector__control_input b-fixed-fee-selector__control_input--no_dropdown" : "b-fixed-fee-selector__control_input b-fixed-fee-selector__control_input--disabled b-fixed-fee-selector__control_input--no_dropdown" }
                            variant="outlined"
                            type="number"
                            value={value}
                            onChange={(e) => {
                                let {value} = e.target;
                                if(value == '' || parseInt(value) === 0 || parseFloat(value) > 0) {
                                    this.changeAction(value)
                                }
                            }}
                            placeholder={translate("return.returnFeeFixed.placeholder")}
                            disabled={isDisabled}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {value && !isDisabled || value === 0 && !isDisabled ? <Cancel className="b-fixed-fee-selector__clear_control" fontSize="small" onClick={()=>{this.clearAction()}} /> : ''}
                                    </InputAdornment>
                                )
                            }}
                        />
                        {
                            this.isDropDownVisible(optionsForDropDown) ?
                                <ReactSelectMaterialUi
                                    className={isDisabled ? "b-fixed-fee-selector__control b-fixed-fee-selector__control--disabled": "b-fixed-fee-selector__control"}
                                    ref={this.select}
                                    options={optionsForDropDown}
                                    onChange={(value) => {
                                        this.changeAction(value)
                                    }}
                                    disabled={isDisabled}
                                    placeholder={'Apply return fee'}
                                />
                            :
                            ''
                        }
                    </div>
                    {
                        !isDisabled && value
                            ?
                            <span className={isReconversionRemoved && returnFeeFixedError || !isReturnFeeAllow ? "b-fixed-fee-selector__error b-fixed-fee-selector__error--two_lines" : "b-fixed-fee-selector__error"}>
                                {isReconversionRemoved ? <CustomTranslation id='return.order.details.return.reconversion.deleted.error'/> : '' }
                                {isReconversionRemoved && returnFeeFixedError || !isReturnFeeAllow ? <br /> : ''}
                                {returnFeeFixedError || value && !isReturnFeeAllow ? <CustomTranslation id='return.order.details.return.fixed.fee.error'/> : ''}
                            </span>
                            :
                            ''
                    }
                    <Tooltip title={this.props.translate('return.order.details.return.fixed.fee.tooltip')}
                             className="b-fixed-fee-selector__tooltip" placement="top">
                        <ErrorIcon className="b-custom-tooltip-icon" fontSize='small'/>
                    </Tooltip>
                </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.fixedFeeSelectorState,
        ...state.mainPropertiesState,
        translate: getTranslate(state.localize)
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            setRefundListReturnFee,
            clearData
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(FixedFeeSelector);