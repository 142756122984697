/* requestsMethodsTypes - key/map Object   */

import {local, aws} from './envs'

let requestsMethodsTypes = {
    /* Login */
    'login': {
        route: 'login',
        type: 'POST'
    },
    /* get main properties*/
    'getMainProps': {
        route: 'shop/properties/$shopId',
        type: 'GET'
    },
    /* get main properties*/
    'getSubShops': {
        route: 'shop/subshops',
        type: 'GET'
    },
    /* get shop return and complaint reasons */
    'getReturnReasons': {
        route: 'report/reasons',
        type: 'GET'
    },
    /* Reset Password*/
    'askForPasswordReset': {
        route: 'login/restore',
        type: 'PATCH'
    },
    /* Check Token For Reset Password*/
    'checkTokenForPasswordReset': {
        route: 'manager/checkToken',
        type: 'POST'
    },
    /* Update Password*/
    'updatePassword': {
        route: 'manager/update',
        type: 'PATCH'
    },
    /* Get Localization */
    'getLocale': {
        route: 'texts/shopId/$shopId/language/$lang',//'/i18n/$lang.json',
        type: 'GET'
    },
    /* Get Localization */
    'getLoginLocale': {
        route: 'texts/language/$lang',
        type: 'GET'
    },
    /* Get Returns */
    'getReturns': {
        route: 'return-orders',
        type: 'GET'
    },
    /* Get Return Item */
    'getReturnsItem': {
        route: 'return-orders/$returnsItemId',
        type: 'GET'
    },
    /* Get Return Order Shipment Label */
    'getShipmentLabel': {
        route: 'return-orders/$returnsItemId/shipping-label',
        type: 'GET'
    },
    /* Pre-approve return order: create and send shipping label */
    'preApprove': {
        route: 'return-orders/$returnsItemId/pre-approve',
        type: 'GET'
    },
    /* Update Returns Order */
    'updateReturnOrder': {
        route: 'return-orders/update',
        type: 'POST'
    },
    /* Get Inbound Returns Order */
    'getInboundReturns': {
        route: 'inbound/$returnsItemId',
        type: 'GET'
    },
    /* Get Base Metrics*/
    'getDashboardMetrics': {
        route: 'dashboard/pre-approval/weekly',
        type: 'GET'
    },
    /* Get Inbound Metrics*/
    'getInboundMetrics': {
        route: 'inbound/metrics',
        type: 'GET'
    },
    /* get Orders */
    'getOrders': {
        route: 'orders',
        type: 'GET'
    },
    /* get Customers */
    'getCustomers': {
        route: 'customers',
        type: 'GET'
    },
    /* check 2FA */
    'check2f': {
        route: 'login/check/2fa',
        type: 'POST'
    },
    /* update 2FA */
    'update2f': {
        route: 'manager/update/2fa',
        type: 'POST'
    },
    /* get item data (product) report*/
    'getItemDataReport': {
        route: 'report/product',
        type: 'GET'
    },
    /* get item data (product) report csv */
    'getItemDataReportCsv': {
        route: 'report/product/csv',
        type: 'GET'
    },
    /* get analytics report csv */
    'getAnalyticsReportCsv': {
        route: 'dashboard/performance-summary/report',
        type: 'GET'
    },
    /* get returns data (product) report*/
    'getReturnDataReport': {
        route: 'report/returns',
        type: 'GET'
    },
    /* get returns data (product) report csv */
    'getReturnDataReportCsv': {
        route: 'report/returns/csv',
        type: 'GET'
    },
    /* send not to customer service */
    'sendToCs': {
        route: 'sendtocs',
        type: 'POST'
    },
    'getManagers': {
        route: 'user-management',
        type: 'GET'
    },
    'changeAdminStatus': {
        route: 'user-management/change-status',
        type: 'POST'
    },
    'sendRequestToResetPassword': {
        route: 'user-management/resend-invitation',
        type: 'POST'
    },
    'addNewAdmin': {
        route: 'user-management/create',
        type: 'POST'
    },
    'getDashboard': {
        route: 'dashboard/performance-summary',
        type: 'GET'
    },
    'fetchReturnEventsLog': {
        route: 'return/events/log/$returnOrderId',
        type: 'GET'
    },
    'updateReturnEventLog': {
        route: 'return/event/log/update',
        type: 'POST'
    }
};

/* Main navigation */
let navigation = [
    {
        name: 'analytics',
        route: '/analytics'
    },
    {
        name: 'orders',
        route: '/orders'
    },
    {
        name: 'return.orders',
        route: '/returns'
    },
    {
        name: 'customers',
        route: '/customers'
    },
    {
        name: 'billing',
        route: '/billing'
    },
    {
        name: 'reports',
        items: [
            {
                name: 'products-report',
                route: '/products-report'
            },
            {
                name: 'returns-report',
                route: '/returns-report'
            },
        ]
    },
    {
        name: 'settings',
        route: '/settings'
    },
    {
        name: 'security',
        route: '/security'
    },
    {
        name: 'inbound',
        route: '/inbound'
    },
    {
        name: 'userManagement',
        route: '/management'
    }
];

let defaultProductNextActions = [
    {
        label: 'DISCARD',
        value: 'DISCARD'
    },
    {
        label: 'REPAIR',
        value: 'REPAIR'
    },
    {
        label: 'RESTOCK',
        value: 'RESTOCK'
    },
    {
        label: 'SEND_BACK',
        value: 'SEND_BACK'
    },
    {
        label: 'TO_PRODUCER',
        value: 'TO_PRODUCER'
    },
    {
        label: 'HANDLED_MANUALLY',
        value: 'HANDLED_MANUALLY'
    },
]

let filteringItems = {
    analytics: [
        {
            label: 'dashboard.filters.label.returnRegistered',
            type: 'dateRange'
        },
        {
            label: 'return.order.details.status',
            type: 'multipleSelect',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'ALL_ITEMS_BACK',
                    value: 'ALL_ITEMS_BACK'
                },
                {
                    label: 'PROCESSING',
                    value: 'PROCESSING'
                },
                {
                    label: 'NOT_PROCESSED',
                    value: 'NOT_PROCESSED'
                },
                {
                    label: 'PRE_APPROVAL',
                    value: 'PRE_APPROVAL'
                },
            ]
        },
        {
            label: 'return.order.details.shipmentStatus',
            type: 'multipleSelect',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'NOT_IN_TRANSIT',
                    value: 'NOT_IN_TRANSIT'
                },
                {
                    label: 'READY_FOR_PICKUP',
                    value: 'READY_FOR_PICKUP'
                },
                {
                    label: 'DROPPED_OFF',
                    value: 'DROPPED_OFF'
                },
                {
                    label: 'IN_TRANSIT',
                    value: 'IN_TRANSIT'
                },
                {
                    label: 'DELIVERED',
                    value: 'DELIVERED'
                },
                {
                    label: 'WARNING',
                    value: 'WARNING'
                },
                {
                    label: 'N/A',
                    value: 'NA'
                },
                {
                    label: 'OTHER',
                    value: 'OTHER'
                },
            ]
        },
        {
            label: 'return.order.list.column.creationType',
            type: 'select',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'VIA_WIDGET',
                    value: 'VIA_WIDGET'
                },
                {
                    label: 'VIA_SHIPPING_STATUS',
                    value: 'VIA_SHIPPING_STATUS'
                },
            ]
        },
        {
            label: 'returns.report.list.columns.returnType',
            type: 'select',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'RECONVERSION',
                    value: 'reconversion'
                },
                {
                    label: 'COMPLAINT',
                    value: 'isComplaintOrder'
                },
                {
                    label: 'REFUND',
                    value: 'isRefundOrder'
                }
            ]
        },
        {
            label: 'return.order.details.remarksFilter',
            type: 'select',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'FLAGGED',
                    value: 'itemFlagged'
                },
                {
                    label: 'WARNING',
                    value: 'mismatch'
                }
            ]
        }
    ],
    analytics2: [
        {
            label: 'returns.report.list.columns.reason',
            type: 'multipleSelect',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'SIZE_TOO_BIG',
                    value: 'SIZE_TOO_BIG'
                },
                {
                    label: 'SIZE_TOO_SMALL',
                    value: 'SIZE_TOO_SMALL'
                },
                {
                    label: 'DOESNT_MEET_EXPECTATIONS',
                    value: 'DOESNT_MEET_EXPECTATIONS'
                },
                {
                    label: 'MANUFACTURING_ERROR',
                    value: 'MANUFACTURING_ERROR'
                },
                {
                    label: 'DELAYED_DELIVERY',
                    value: 'DELAYED_DELIVERY'
                },
                {
                    label: 'INCORRECT_PRODUCT_DELIVERED',
                    value: 'INCORRECT_PRODUCT_DELIVERED'
                },
                {
                    label: 'BAD_FIT',
                    value: 'BAD_FIT'
                },
                {
                    label: 'NOT_AS_DESCRIBED',
                    value: 'NOT_AS_DESCRIBED'
                },
                {
                    label: 'DISLIKED_COLOR',
                    value: 'DISLIKED_COLOR'
                },
                {
                    label: 'REGRET',
                    value: 'REGRET'
                },
                {
                    label: 'DAMAGED_IN_SHIPPING',
                    value: 'DAMAGED_IN_SHIPPING'
                },
                {
                    label: 'ORDERED_MORE_THAN_ONE_VERSION',
                    value: 'ORDERED_MORE_THAN_ONE_VERSION'
                },
                {
                    label: 'WARRANTY_ISSUE',
                    value: 'WARRANTY_ISSUE'
                },
                {
                    label: 'RECEIVED_INCORRECT_ITEM',
                    value: 'RECEIVED_INCORRECT_ITEM'
                },
                {
                    label: 'OTHER_LEAVE_COMMENT',
                    value: 'OTHER_LEAVE_COMMENT'
                },
            ]
        },
        {
            label: 'return.order.details.condition',
            type: 'multipleSelect',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'USED',
                    value: 'USED'
                },
                {
                    label: 'TAGS_LEFT',
                    value: 'TAGS_LEFT'
                },
                {
                    label: 'BROKEN',
                    value: 'BROKEN'
                },
                {
                    label: 'DIDNT_ARRIVE',
                    value: 'DIDNT_ARRIVE'
                },
                {
                    label: 'UNOPENED',
                    value: 'UNOPENED'
                },
            ]
        },
        {
            label: 'return.order.details.nextAction',
            type: 'multipleSelect',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'DISCARD',
                    value: 'DISCARD'
                },
                {
                    label: 'REPAIR',
                    value: 'REPAIR'
                },
                {
                    label: 'RESTOCK',
                    value: 'RESTOCK'
                },
                {
                    label: 'SEND_BACK',
                    value: 'SEND_BACK'
                },
                {
                    label: 'TO_PRODUCER',
                    value: 'TO_PRODUCER'
                },
                {
                    label: 'HANDLED_MANUALLY',
                    value: 'HANDLED_MANUALLY'
                },
            ]
        },
        {
            label: 'products.report.list.columns.baseCurrency',
            type: 'select',
            isClearable: true,
            clearedValue: 'SEK',
            items: [
                {
                    label: 'SEK',
                    value: 'SEK'
                },
                {
                    label: 'EUR',
                    value: 'EUR'
                }
            ]
        },
        {
            label: 'customer.list.columns.shopName',
            type: 'multipleSelect',
            isClearable: true,
            items: []
        },
    ],
    inboundItem: [
        {
            label: 'return.order.details.condition',
            type: 'select',
            isClearable: false,
            items: [
                {
                    label: 'USED',
                    value: 'USED'
                },
                {
                    label: 'TAGS_LEFT',
                    value: 'TAGS_LEFT'
                },
                {
                    label: 'BROKEN',
                    value: 'BROKEN'
                },
                {
                    label: 'DIDNT_ARRIVE',
                    value: 'DIDNT_ARRIVE'
                },
                {
                    label: 'UNOPENED',
                    value: 'UNOPENED'
                },
            ]
        },
        {
            label: 'return.order.details.nextAction',
            type: 'select',
            isClearable: false,
            items: defaultProductNextActions
        },
        {
            label: 'return.product.filter.title.REFUND',
            type: 'number'
        }
    ],
    returns : [
        {
            label: 'return.order.details.status',
            type: 'multipleSelect',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'ALL_ITEMS_BACK',
                    value: 'ALL_ITEMS_BACK'
                },
                {
                    label: 'PROCESSING',
                    value: 'PROCESSING'
                },
                {
                    label: 'NOT_PROCESSED',
                    value: 'NOT_PROCESSED'
                },
                {
                    label: 'PRE_APPROVAL',
                    value: 'PRE_APPROVAL'
                },
            ]
        },
        {
            label: 'return.order.details.shipmentStatus',
            type: 'multipleSelect',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'NOT_IN_TRANSIT',
                    value: 'NOT_IN_TRANSIT'
                },
                {
                    label: 'READY_FOR_PICKUP',
                    value: 'READY_FOR_PICKUP'
                },
                {
                    label: 'DROPPED_OFF',
                    value: 'DROPPED_OFF'
                },
                {
                    label: 'IN_TRANSIT',
                    value: 'IN_TRANSIT'
                },
                {
                    label: 'DELIVERED',
                    value: 'DELIVERED'
                },
                {
                    label: 'WARNING',
                    value: 'WARNING'
                },
                {
                    label: 'N/A',
                    value: 'NA'
                },
                {
                    label: 'OTHER',
                    value: 'OTHER'
                },
            ]
        },
        {
            label: 'return.order.list.column.creationType',
            type: 'select',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'VIA_WIDGET',
                    value: 'VIA_WIDGET'
                },
                {
                    label: 'VIA_SHIPPING_STATUS',
                    value: 'VIA_SHIPPING_STATUS'
                },
            ]
        },
        {
            label: 'returns.report.list.columns.returnType',
            type: 'select',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'RECONVERSION',
                    value: 'reconversion'
                },
                {
                    label: 'COMPLAINT',
                    value: 'isComplaintOrder'
                },
                {
                    label: 'REFUND',
                    value: 'isRefundOrder'
                }
            ]
        },
        {
            label: 'return.order.details.remarksFilter',
            type: 'select',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'FLAGGED',
                    value: 'itemFlagged'
                },
                {
                    label: 'WARNING',
                    value: 'mismatch'
                }
            ]
        },
        {
            label: 'customer.list.columns.shopName',
            type: 'multipleSelect',
            isClearable: true,
            items: []
        },
    ],
    orders: [
        {
            label: 'customer.list.columns.shopName',
            type: 'multipleSelect',
            isClearable: true,
            items: []
        }
    ],
    returnsProduct: [
        {
            label: 'return.order.details.condition',
            type: 'select',
            isClearable: false,
            items: [
                {
                    label: 'BROKEN',
                    value: 'BROKEN'
                },
                {
                    label: 'DIDNT_ARRIVE',
                    value: 'DIDNT_ARRIVE'
                },
                {
                    label: 'TAGS_LEFT',
                    value: 'TAGS_LEFT'
                },
                {
                    label: 'UNOPENED',
                    value: 'UNOPENED'
                },
                {
                    label: 'USED',
                    value: 'USED'
                },
            ]
        },
        {
            label: 'return.order.details.nextAction',
            type: 'select',
            isClearable: false,
            items: [
                {
                    label: 'DISCARD',
                    value: 'DISCARD'
                },
                {
                    label: 'REPAIR',
                    value: 'REPAIR'
                },
                {
                    label: 'RESTOCK',
                    value: 'RESTOCK'
                },
                {
                    label: 'SEND_BACK',
                    value: 'SEND_BACK'
                },
                {
                    label: 'TO_PRODUCER',
                    value: 'TO_PRODUCER'
                },
                {
                    label: 'HANDLED_MANUALLY',
                    value: 'HANDLED_MANUALLY'
                },
            ]
        },
        {
            label: 'return.product.filter.title.REFUND',
            type: 'number'
        },
    ],
    itemDataReport1part: [
        {
            label: 'dashboard.filters.label.returnRegistered',
            type: 'dateRange'
        },
        {
            label: 'products.report.list.columns.status',
            type: 'multipleSelect',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'ALL_ITEMS_BACK',
                    value: 'ALL_ITEMS_BACK'
                },
                {
                    label: 'PROCESSING',
                    value: 'PROCESSING'
                },
                {
                    label: 'NOT_PROCESSED',
                    value: 'NOT_PROCESSED'
                },
            ]
        },
        {
            label: 'returns.report.list.columns.reason',
            type: 'multipleSelect',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'SIZE_TOO_BIG',
                    value: 'SIZE_TOO_BIG'
                },
                {
                    label: 'SIZE_TOO_SMALL',
                    value: 'SIZE_TOO_SMALL'
                },
                {
                    label: 'DOESNT_MEET_EXPECTATIONS',
                    value: 'DOESNT_MEET_EXPECTATIONS'
                },
                {
                    label: 'MANUFACTURING_ERROR',
                    value: 'MANUFACTURING_ERROR'
                },
                {
                    label: 'DELAYED_DELIVERY',
                    value: 'DELAYED_DELIVERY'
                },
                {
                    label: 'INCORRECT_PRODUCT_DELIVERED',
                    value: 'INCORRECT_PRODUCT_DELIVERED'
                },
                {
                    label: 'BAD_FIT',
                    value: 'BAD_FIT'
                },
                {
                    label: 'NOT_AS_DESCRIBED',
                    value: 'NOT_AS_DESCRIBED'
                },
                {
                    label: 'DISLIKED_COLOR',
                    value: 'DISLIKED_COLOR'
                },
                {
                    label: 'REGRET',
                    value: 'REGRET'
                },
                {
                    label: 'DAMAGED_IN_SHIPPING',
                    value: 'DAMAGED_IN_SHIPPING'
                },
                {
                    label: 'ORDERED_MORE_THAN_ONE_VERSION',
                    value: 'ORDERED_MORE_THAN_ONE_VERSION'
                },
                {
                    label: 'WARRANTY_ISSUE',
                    value: 'WARRANTY_ISSUE'
                },
                {
                    label: 'RECEIVED_INCORRECT_ITEM',
                    value: 'RECEIVED_INCORRECT_ITEM'
                },
                {
                    label: 'OTHER_LEAVE_COMMENT',
                    value: 'OTHER_LEAVE_COMMENT'
                },
            ]
        },
    ],
    itemDataReport2part: [
        {
            label: 'products.report.list.columns.type',
            type: 'select',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'REFUND',
                    value: 'REFUND'
                },
                {
                    label: 'EXCHANGE',
                    value: 'EXCHANGE'
                }
            ]
        },
        {
            label: 'products.report.list.columns.baseCurrency',
            type: 'select',
            isClearable: true,
            clearedValue: 'SEK',
            items: [
                {
                    label: 'SEK',
                    value: 'SEK'
                },
                {
                    label: 'EUR',
                    value: 'EUR'
                }
            ]
        },
        {
            label: 'products.report.list.columns.market',
            type: 'multipleSelect',
            isClearable: true,
            items: []
        },
    ],
    returnDataReport1part: [
        {
            label: 'dashboard.filters.label.returnRegistered',
            type: 'dateRange'
        },
        {
            label: 'products.report.list.columns.status',
            type: 'multipleSelect',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'ALL_ITEMS_BACK',
                    value: 'ALL_ITEMS_BACK'
                },
                {
                    label: 'PROCESSING',
                    value: 'PROCESSING'
                },
                {
                    label: 'NOT_PROCESSED',
                    value: 'NOT_PROCESSED'
                },
            ]
        },
        {
            label: 'returns.report.list.columns.reason',
            type: 'multipleSelect',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'SIZE_TOO_BIG',
                    value: 'SIZE_TOO_BIG'
                },
                {
                    label: 'SIZE_TOO_SMALL',
                    value: 'SIZE_TOO_SMALL'
                },
                {
                    label: 'DOESNT_MEET_EXPECTATIONS',
                    value: 'DOESNT_MEET_EXPECTATIONS'
                },
                {
                    label: 'MANUFACTURING_ERROR',
                    value: 'MANUFACTURING_ERROR'
                },
                {
                    label: 'DELAYED_DELIVERY',
                    value: 'DELAYED_DELIVERY'
                },
                {
                    label: 'INCORRECT_PRODUCT_DELIVERED',
                    value: 'INCORRECT_PRODUCT_DELIVERED'
                },
                {
                    label: 'BAD_FIT',
                    value: 'BAD_FIT'
                },
                {
                    label: 'NOT_AS_DESCRIBED',
                    value: 'NOT_AS_DESCRIBED'
                },
                {
                    label: 'DISLIKED_COLOR',
                    value: 'DISLIKED_COLOR'
                },
                {
                    label: 'REGRET',
                    value: 'REGRET'
                },
                {
                    label: 'DAMAGED_IN_SHIPPING',
                    value: 'DAMAGED_IN_SHIPPING'
                },
                {
                    label: 'ORDERED_MORE_THAN_ONE_VERSION',
                    value: 'ORDERED_MORE_THAN_ONE_VERSION'
                },
                {
                    label: 'WARRANTY_ISSUE',
                    value: 'WARRANTY_ISSUE'
                },
                {
                    label: 'RECEIVED_INCORRECT_ITEM',
                    value: 'RECEIVED_INCORRECT_ITEM'
                },
                {
                    label: 'OTHER_LEAVE_COMMENT',
                    value: 'OTHER_LEAVE_COMMENT'
                },
            ]
        },
    ],
    returnDataReport2part: [
        {
            label: 'products.report.list.columns.type',
            type: 'select',
            isClearable: true,
            items: [
                {
                    label: 'ALL',
                    value: ''
                },
                {
                    label: 'REFUND',
                    value: 'REFUND'
                },
                {
                    label: 'EXCHANGE',
                    value: 'EXCHANGE'
                }
            ]
        },
        {
            label: 'products.report.list.columns.baseCurrency',
            type: 'select',
            isClearable: true,
            clearedValue: 'SEK',
            items: [
                {
                    label: 'SEK',
                    value: 'SEK'
                },
                {
                    label: 'EUR',
                    value: 'EUR'
                }
            ]
        },
        {
            label: 'products.report.list.columns.market',
            type: 'multipleSelect',
            isClearable: true,
            items: []
        }
    ]
};

let states = {
    ARRIVED: 'ARRIVED',
    REJECTED: 'REJECTED',
    REMOVED: 'REMOVED',
    PENDING: 'PENDING',
    PROCESSED: 'ALL_ITEMS_BACK',
    NOT_PROCESSED: 'NOT_PROCESSED',
    PRE_APPROVAL: 'PRE_APPROVAL'
};

let orderStatusLabelsKeys = {
    RETURN_NOT_ALLOWED: 'order.status.returnNotAllowed',
    RECONVERSION: 'order.status.reconversion',
    RETURNED: 'order.status.returned'
};

/* Localization Default Supported language */
let supportedLanguages = [
    { name: 'english', code: 'en' },
    { name: 'swedish', code: 'sv' },
];

/* timeouts */
let timeouts = {
    ordersPageOpenWidgetBtnVisibility: 2000
};

let notificationDialogs = {
    notes: {
        en: {
            title: 'Unfortunately our service is momentarily unavailable',
            description: 'We should be back up soon, try refreshing the page in a couple of minutes, if problem persists please contact ',
            goBack: 'go back'
        },
        sv: {
            title: 'Tjänsten är ej tillgänglig just nu',
            description: 'Vi bör vara igång igen inom kort, var vänlig och ladda om sidan om ett par minuter. Om problemet kvarstår var vänlig och kontakta ',
            goBack: 'Tillbaka'
        }
    },
    unexpected: {
        en: {
            title: 'Something went wrong',
            description: 'We should be back up soon, try refreshing the page in a couple of minutes, if problem persists please contact ',
            goBack: 'go back'
        },
        sv: {
            title: 'Något gick fel',
            description: 'Tjänsten borde vara nåbar igen inom kort, försök uppdatera sidan om några minuter. Kvarstår problemet var vänlig ',
            goBack: 'Tillbaka'
        }
    }
};

/* Configurations for different Environments */

const baseConfig = {
    navigation: navigation,
    supportedLanguages: supportedLanguages,
    requestsMethodsTypes: requestsMethodsTypes,
    filteringItems: filteringItems,
    states: states,
    orderStatusLabelsKeys: orderStatusLabelsKeys,
    defaultProductNextActions: defaultProductNextActions,
    timeouts: timeouts,
    notificationDialogs: notificationDialogs
};

export default process.env.NODE_ENV === 'development'
    ? Object.assign(baseConfig, local)
    : Object.assign(baseConfig, aws);
