import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {isEmpty, isArray} from 'lodash';
import {
    formatDate,
    getTableHeight,
    getDataFromSS
} from './../../../utilites/index';
import {fetchManagers, changeStatus, requestResetPassword} from "./../actions/managementActions";
import CustomTranslation from "./../../../components/CustomTranslation/CustomTranslation";
import CustomTable from './../../../components/CustomTable/CustomTable';
import defaultRequestConst from './../defaultRequestConst';
import {Email, AddCircle, CheckCircle} from '@material-ui/icons';


class ManagerPageTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            pageSizeOption: defaultRequestConst.limit,
        }
    }


    /**
     * getTableData {Function}
     * @description - return data array to render in returns table
     * @return {Array}
     *
     * */
    getTableData = (data) => {
        return data && data.toJSONView && isEmpty(data.toJSONView()) ? [] : isArray(data.getField('data')) ? data.getField('data') : [] ;
    };

    /**
     * goToAppropriateTablePage {Function}
     * @description - callback fires when user use table pagination
     * */
    goToAppropriateTablePage = (isPrev = false) => {
        let {currentPage} = this.state;

        if (isPrev && currentPage !== 0) {
            this.onChangeCurrentPageAction(currentPage - 1)
        } else {
            this.onChangeCurrentPageAction(currentPage + 1)
        }

    };

    /**
     * onPageSizeChange {Function}
     * @description - action calls when page size was changed using table page size dropdown
     *
     * */
    onPageSizeChange = (pageSize) => {
        let {DefaultRequestConst} = this.props;

        this.setState({
            pageSizeOption: pageSize
        }, () => {
            this.fetchManagers({
                ...DefaultRequestConst,
                limit: pageSize
            })
        });

    };

    /**
     * onChangeCurrentPageAction {Function}
     * @description - action calls on page change using table next/previous controls
     *
     * */
    onChangeCurrentPageAction = (nextCurrentPage) => {
        let {DefaultRequestConst} = this.props;
        let {pageSizeOption} = this.state;

        this.setState({
            currentPage: nextCurrentPage
        }, () => {
            this.fetchManagers({
                ...DefaultRequestConst,
                limit: pageSizeOption,
                page: nextCurrentPage
            })
        })

    };

    /**
     * fetchManagers {Function}
     * @description - main function to get orders from server
     *
     * */
    fetchManagers = (requestData = null) => {
        let {DefaultRequestConst} = this.props;
        let dataForRequest = requestData ? requestData : {
            ...DefaultRequestConst
        };

        this.props.fetchManagers({
            route: 'getManagers',
            isWithGetParams: true,
            requestData: dataForRequest
        });
    };

    changeStatus = (managerId, enabled) => {
        this.props.changeStatus({
            route: 'changeAdminStatus',
            requestData: {
                managerId,
                enabled
            }
        })
    };

    /**
     * getEnabledControls {Function}
     * @description - generate proper control to handle manager's status
     * @return {HTML elements}
     *
     * */
    getEnabledControls = (isEnabled, managerId, rowData) => {
        let isOwnAccount = rowData.managerId === (getDataFromSS('login') && getDataFromSS('login').manager ? getDataFromSS('login').manager.managerId : '');

        let changeStatus = !isEnabled;
        return (
            <>
                {
                    isEnabled
                    ?
                    <>
                        <CheckCircle className={isOwnAccount ? "b-management-page__table_control b-management-page__table_control--own b-management-page__table_control--green": "b-management-page__table_control b-management-page__table_control--green"}
                                     fontSize="small"
                        /> <AddCircle  fontSize="small" className={isOwnAccount ? "b-management-page__table_control b-management-page__table_control--own b-management-page__rotate" : "b-management-page__table_control b-management-page__rotate"} onClick={()=>{ if(!isOwnAccount) {this.changeStatus(managerId, changeStatus)}}} />
                    </>
                        :
                    <>
                        <CheckCircle className={isOwnAccount ? "b-management-page__table_control b-management-page__table_control--own" : "b-management-page__table_control"} fontSize="small" onClick={()=>{if(!isOwnAccount) {this.changeStatus(managerId, changeStatus)}}} /> <AddCircle
                        fontSize="small"
                        className={isOwnAccount ? "b-management-page__table_control b-management-page__table_control--own b-management-page__table_control--red b-management-page__rotate" : "b-management-page__table_control b-management-page__table_control--red b-management-page__rotate"}/>
                    </>
                }

            </>
        )

    };

    /**
     * renderTable {Function}
     * @description - render table using data from server side
     * @return {React Component}
     *
     * */
    renderTable = () => {
        let {data, isLoading, translate} = this.props;
        let {currentPage, pageSizeOption} = this.state;
        let prefix = 'management.table.column.title';
        let tableData = this.getTableData(data);
        let totalReturnsAmount = data.getField('paging') ? data.getField('paging').total : 1;
        let totalPages = Math.ceil(totalReturnsAmount / pageSizeOption);
        const columns = [{
                Header: <CustomTranslation id={`${prefix}.name`}/>,
                id: "firstName",
                accessor: (row) => row.firstName,
                Cell: ({row}) => {
                    return `${row.firstName} ${row._original.lastName}`
                },
            },
            {
                Header: <CustomTranslation id={`${prefix}.email`}/>,
                id: 'email',
                accessor: (row) => row.email,
                Cell: ({row}) => {
                    return row.email
                },
            },
            {
                Header: <CustomTranslation id={`${prefix}.2fa`}/>,
                id: 'using2FA',
                accessor: (row) => row.using2FA,
                Cell: ({row}) => {
                    return `${row.using2FA ? translate("management.table.column.value.title.yes") : translate("management.table.column.value.title.no")}`
                },
            },
            {
                Header: <CustomTranslation id={`${prefix}.userClass`}/>,
                id: 'admin',
                accessor: (row) => row.admin,
                Cell: ({row}) => {
                    return `${row.admin ? translate("management.table.column.value.title.superAdmin") : translate("management.table.column.value.title.admin")}`
                },
            },
            {
                Header: <CustomTranslation id={`${prefix}.registered`}/>,
                id: 'createdTime',
                accessor: (row) => row.createdTime,
                Cell: ({row}) => {
                    return formatDate(row.createdTime)
                },
            },
            {
                Header: <CustomTranslation id={`${prefix}.accountStatus`}/>,
                accessor: 'enabled',
                Cell: ({row}) => {
                    return row.enabled ? translate('management.table.column.value.title.active') : translate('management.table.column.value.title.deactivated')
                },
            },
            {
                Header: <CustomTranslation id={`${prefix}.changeAccountStatus`}/>,
                accessor: 'controls',
                Cell: ({row}) => {
                    return this.getEnabledControls(row._original.enabled, row._original.managerId, row);

                }
            },
            {
                Header: <CustomTranslation id={`${prefix}.resendInvite`}/>,
                accessor: 'managerId',
                Cell: ({row}) => {
                    return <Email className="b-management-page__table_control"
                                  fontSize="small"
                                  onClick={() => {
                                      this.props.requestResetPassword({
                                          route: 'sendRequestToResetPassword',
                                          middleData: {
                                              firstName: row._original.firstName,
                                              lastName: row._original.lastName,
                                              email: row._original.email
                                          },
                                          requestData: {
                                              managerId: row.managerId
                                          }
                                      })
                                  }}
                    />;

                }
            }
        ];

        return <CustomTable
            className={'-highlight -striped'}
            data={tableData}
            columns={columns}
            defaultSorted={[
                {
                    id: "managerId",
                    desc: true
                }
            ]}
            loading={isLoading}
            isLoading={isLoading}
            defaultPageSize={pageSizeOption}
            showPagination={false}
            pageSize={tableData && tableData.length ? tableData.length : 0}
            style={{
                // This will force the table body to overflow and scroll, since there is not enough room
                height: getTableHeight(tableData)
            }}
            isPaginationActive={true}
            currentPage={currentPage}
            pageSizeOption={totalReturnsAmount}
            totalPages={totalPages}
            prevAction={() => {
                this.goToAppropriateTablePage(true)
            }}
            nextAction={() => {
                this.goToAppropriateTablePage()
            }}
            onPageSizeChangeAction={this.onPageSizeChange}
        />
    };

    render() {
        return (
            <>
                {this.renderTable()}
            </>
        )
    };
}

function mapStateToProps(state) {
    return {};
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            fetchManagers,
            changeStatus,
            requestResetPassword
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(ManagerPageTable);