import Const from './../const';
import FixedFeeSelectorConst from "../../FixedFeeSelector/const";

const defaultState = {
    refund: {},
    returnFees: {},
    reducedSum: 0,
    reducedSumChanged: 0
};

/**
 * getReducedValues {Function}
 * @description - calculate sum of the reduced values
 * @return {Number}
 * */
let getReducedValues = (reducedValues)=>{
    let reducedValuesSum = 0;
    for(let key in reducedValues) {
        if(reducedValues.hasOwnProperty(key)) {
            let currentItem = reducedValues[key];
            for(let jey in currentItem) {
                if(currentItem.hasOwnProperty(jey)) {
                    let defaultDiminishedPrice = currentItem[jey]['defaultDiminishedPrice'];
                    let newDiminishedPrice = currentItem[jey]['newDiminishedPrice'];
                    reducedValuesSum += parseFloat(defaultDiminishedPrice === newDiminishedPrice && (defaultDiminishedPrice !== 0 && newDiminishedPrice !== 0) ? 0 : currentItem[jey]['decreasedValue']) ;
                }
            }
        }
    }

    return reducedValuesSum;
};

/**
 * clearRefund {Function}
 * @description - calculate sum of the reduced values
 * @return {Number}
 * */
let clearRefund = (refunds, idToClean)=>{
    let newRefunds = {};

    for(let key in refunds) {
        if(key !== idToClean) {
            newRefunds[key] = refunds[key]
        }
    }

    return newRefunds;
};

export default function (state = defaultState, action = {}) {
    let {payload={}} = action;
    switch (action.type) {
        case Const.RETURN_DESCRIPTION_SET_REFUND:
            return {
                ...state,
                refund: {
                    ...state.refund,
                    [payload.returnOrderId]: payload.refund
                }
            };
        case Const.RETURN_DESCRIPTION_SET_REDUCED_SUM:
            return {
                ...state,
                reducedSumChanged: state.reducedSumChanged+1,
                reducedSum: getReducedValues(payload)
            };
        case Const.RETURN_DESCRIPTION_CLEAR_REFUND:
            return {
                ...state,
                refund: clearRefund(state.refund, payload)
            };
        case FixedFeeSelectorConst.FIXED_FEE_SELECTOR_SET_RETURN_FEE:
            return {
                ...state,
                returnFees: {
                    ...state.returnFees,
                    [payload.returnOrderId]: !payload.returnFee && payload.returnFee !== 0 ? '' : parseFloat(payload.returnFee)
                }
            };
        default:
            return state;
    }
}