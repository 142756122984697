import React, {Component} from 'react';
import {ArrowBackIos, ArrowForwardIos} from "@material-ui/icons";
import {Carousel} from "react-responsive-carousel";
import ImageZoom from "react-medium-image-zoom";

const zoomOverlayStyles = {
    overlay: {
        backgroundColor: '#7D818C',
        opacity: 0.9
    }
};

class ReclamationImagesGallery extends Component {

    renderFilesCarousel = (images = []) => {
        let {length} = images;
        if (images && length) {
            return images.map((fileItem, key) => {
                let {imageId, imagePath} = fileItem;
                return (
                    <div
                        className={length === 1 ? "b-reclamation-images-gallery__carousel_img_wrapper b-reclamation-images-gallery__carousel_img_wrapper--single" : "b-reclamation-images-gallery__carousel_img_wrapper"}
                        key={key} onClick={
                        (e) => {
                            let img = e && e.target ? e.target.querySelector('.b-reclamation-images-gallery__carousel_img') : null;
                            if (img) {
                                img.click()
                            }
                        }
                    }>
                        <ImageZoom
                            image={{
                                src: imagePath,
                                alt: imageId,
                                className: 'b-reclamation-images-gallery__carousel_img b-reclamation-images-gallery__carousel_img--small'
                            }}
                            zoomImage={{
                                src: imagePath,
                                alt: imageId,
                                className: 'b-reclamation-images-gallery__carousel_img b-reclamation-images-gallery__carousel_img--big'
                            }}
                            defaultStyles={zoomOverlayStyles}
                        />
                    </div>
                )
            });
        }
    };

    render() {
        let {
            images = []
        } = this.props;

        return (
            <div className='b-reclamation-images-gallery b-flex'>
                {
                    images && images.length
                        ?
                        <div className="b-reclamation-images-gallery__item">
                            <div className='b-reclamation-images-gallery__carousel_wrapper'>
                                {
                                    images.length > 1
                                    ?
                                        <Carousel
                                            showThumbs={false}
                                            showIndicators={false}
                                            showStatus={false}
                                            centerMode={true}
                                            axis='horizontal'

                                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                                hasPrev && (
                                                    <ArrowBackIos
                                                        className="b-reclamation-images-gallery__carousel_ctrl b-reclamation-images-gallery__carousel_ctrl--prev"
                                                        type="button" onClick={onClickHandler} title={label}/>
                                                )
                                            }
                                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                                hasNext && (
                                                    <ArrowForwardIos
                                                        className="b-reclamation-images-gallery__carousel_ctrl b-reclamation-images-gallery__carousel_ctrl--next"
                                                        type="button" onClick={onClickHandler} title={label}/>
                                                )
                                            }
                                        >
                                            {
                                                this.renderFilesCarousel(images)
                                            }
                                        </Carousel>
                                        :
                                        <ImageZoom
                                            image={{
                                                src: images[0].imagePath,
                                                alt: images[0].imageId,
                                                className: 'b-reclamation-images-gallery__carousel_img b-reclamation-images-gallery__carousel_img--small'
                                            }}
                                            zoomImage={{
                                                src: images[0].imagePath,
                                                alt: images[0].imageId,
                                                className: 'b-reclamation-images-gallery__carousel_img b-reclamation-images-gallery__carousel_img--big'
                                            }}
                                            defaultStyles={zoomOverlayStyles}
                                        />
                                }

                            </div>
                        </div>
                        :
                        ''
                }
            </div>
        )
    }

}

export default ReclamationImagesGallery;