import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Slide, withStyles} from '@material-ui/core';
import {getDataFromLS, getSupportEmail} from "../../utilites";
import {setCurrentPageTitle} from "../Header/actions/headerActions";
import CloseIcon from '@material-ui/icons/Cancel';
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";
import {closeErrorDialog} from "./actions/errorDialogActions";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => {
    return {
        btn: {
            width: '50%',
        }
    }
};

class ErrorDialog extends Component {

    componentDidMount() {
        let {pageName} = this.props;
        this.props.setCurrentPageTitle(pageName)
    }

    /**
    * getCurrentLanguage {Function}
    * @description - get current language from Local Storage
    *
    *
    * */
    getCurrentLanguage = () => {
        let language = getDataFromLS('language');
        return language;
    };

    /**
     * handleClose {Function}
     * @description - close the notifier
     *
     * */
    handleClose = () => {
        this.props.closeErrorDialog()
    };

    render (){
        let {notes={}, classes, isActive} = this.props;
        let currentLanguage = this.getCurrentLanguage();

        return (
            <div className='b-page-error'>
                <Dialog
                    open={isActive}
                    onClose={this.handleClose}
                    maxWidth={'sm'}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={isActive ? 'b-server-error-notifier b-server-error-notifier--error_dialog b-server-error-notifier--active' : 'b-server-error-notifier'}
                >
                    {this.handleClose && <CloseIcon className='b-server-error-notifier__close_ctrl' onClick={()=>{
                        if(this.isLoginError) {
                            this.handleWrongLoginError()
                        } else {
                            this.handleClose()
                        }
                    }} />}
                    <DialogTitle id="alert-dialog-slide-title" className="b-server-error-notifier__title_wrapper">
                        {notes[currentLanguage].title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                        <span className="b-server-error-notifier__text">
                            <span className="b-server-error-notifier__text_p">
                                {notes[currentLanguage].description} {getSupportEmail()}
                            </span>
                        </span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className="b-server-error-notifier__actions">
                        <Button onClick={()=>{
                                this.handleClose()
                        }} variant="contained" className={`${classes.btn}`} >
                            {notes[currentLanguage].goBack}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    };
}

function mapStateToProps({errorDialogState}) {
    return {
        ...errorDialogState
    };
}

function matchDispatchToProps(dispatcher) {
    return bindActionCreators(
        {
            setCurrentPageTitle,
            closeErrorDialog
        },
        dispatcher,
    );
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles)(ErrorDialog));